import * as authApi from 'api/auth'
import * as feedsApi from 'api/feeds'
import * as serviceApi from 'api/service'
import {
    FeedRowCount,
    UserType
} from 'helper/const'

export const FEED_NEED_REFRESH = 'FEED_NEED_REFRESH'
export const FEED_SCROLL = 'FEED_SCROLL'

export const FEEDS_REQUEST = 'FEEDS_REQUEST'
export const FEEDS_SUCCESS = 'FEEDS_SUCCESS'
export const FEEDS_FAILURE = 'FEEDS_FAILURE'
export const FEEDS_END = 'FEEDS_END'

export const FEED_LIKE_REQUEST = 'FEED_LIKE_REQUEST'
export const FEED_LIKE_SUCCESS = 'FEED_LKE_SUCCESS'
export const FEED_LIKE_FAILURE = 'FEED_LIKE_FAILURE'

export const FEED_AGREE_REQUEST = 'FEED_AGREE_REQUEST'
export const FEED_AGREE_SUCCESS = 'FEED_AGREE_SUCCESS'
export const FEED_AGREE_FAILURE = 'FEED_AGREE_FAILURE'

export const DOCTOR_KNOW_REQUEST = 'DOCTOR_KNOW_REQUEST'
export const DOCTOR_KNOW_SUCCESS = 'DOCTOR_KNOW_SUCCESS'
export const DOCTOR_KNOW_FAILURE = 'DOCTOR_KNOW_FAILURE'

export const FEED_UPDATE = "FEED_UPDATE"
export const FEED_REPLY_UPDATE = "FEED_REPLY_UPDATE"

export function feedNeedRefreshAction(forceRefresh) {
    return {
        type: FEED_NEED_REFRESH,
        forceRefresh
    }
}

export function feedScrollAction(scrollY) {
    return {
        type: FEED_SCROLL,
        scrollY
    }
}

function _feedsRequest() {
    return {
        type: FEEDS_REQUEST,
        message: "Fetching feeds..."
    }
}

function _feedsSuccess(rowIndex, feeds, filter) {
    return {
        type: FEEDS_SUCCESS,
        rowIndex,
        feeds,
        filter
    }
}

export function feedsEnd() {
    return {
        type: FEEDS_END
    }
}

function _feedsFailure(message) {
    return {
        type: FEEDS_FAILURE,
        message
    }
}


function feedUpdate(feed) {
    return {
        type: FEED_UPDATE,
        feed
    }
}


export function getFeeds(userId, userType, rowIndex, filter = null) {
    return async dispatch => {
        dispatch(_feedsRequest())

        // Get all feeds
        let response
        if (filter) {
            response = await feedsApi.feedSearch(rowIndex * FeedRowCount, FeedRowCount, filter)
        } else {
            response = await feedsApi.feedsByTime(rowIndex * FeedRowCount, FeedRowCount)
        }

        if (response.status === 0) {
            const feeds = response.data
            const fullFeeds = []
            if (filter && feeds.length <= 0) {
                dispatch(_feedsSuccess(rowIndex, fullFeeds, filter))
            }

            for (let index = 0; index < feeds.length; index++) {
                const orgFeed = feeds[index];
                let feed

                if (filter) {
                    feed = {
                        Title: orgFeed.title,
                        DoctorId: orgFeed.doctorId,
                        ImageLink: orgFeed.imageLink,
                        FeedType: orgFeed.feedType,
                        PatientId: orgFeed.patientId,
                        IsReplied: orgFeed.isReplied,
                        ArticleLink: orgFeed.articleLink,
                        Body: orgFeed.body,
                        ImageURL: orgFeed.imageURL,
                        feedID: orgFeed.feedID,
                        timeCreated: orgFeed.timeCreated
                    }
                } else {
                    feed = orgFeed
                }

                // Patient posted
                if (feed.PatientId !== 0) {
                    if (feed.IsReplied === true) {
                        // Get replies
                        feedsApi.feedRepliesGetAllByFeedID(feed.feedID).then(repliesRst => {
                            if (repliesRst.status === 0) {
                                feed.replies = repliesRst.data
                                dispatch(feedUpdate(feed))

                                for (let j = 0; j < 1/*feed.replies.length*/; j++) { // Only one reply for now
                                    const reply = feed.replies[j];
                                    reply.doctor = {}

                                    // Get doctor profile
                                    authApi.getUserProfile(UserType.doctor, reply.doctorId).then(doctorRst => {
                                        if (doctorRst.status === 0 && doctorRst.data.length > 0) {
                                            reply.doctor = Object.assign(reply.doctor, doctorRst.data[0])
                                            dispatch(feedUpdate(feed))
                                        } else {
                                            console.error("Get user profile error:", doctorRst)
                                        }
                                    }).catch(err => {
                                        console.error("Get user profile error:", err)
                                    })

                                    console.log("Is Liked:", feed.feedID, reply.doctorId, userId, userType, feed.FeedType)
                                    // Get like
                                    feedsApi.getIsLiked(feed.feedID, reply.doctorId, userId, userType, feed.FeedType).then(likeRst => {
                                        if (likeRst.status === 0) {
                                            reply.doctor.isLike = likeRst.data
                                            dispatch(feedUpdate(feed))
                                        } else {
                                            console.error("Get Is Liked Error:", likeRst)
                                        }
                                    }).catch(err => {
                                        console.error("Get Is Liked Error:", err)
                                    })

                                    // Get helped total count
                                    serviceApi.getHelped(reply.doctorId).then(helpedTotalRst => {
                                        if (helpedTotalRst.status === 0) {
                                            const helpedTotal = parseInt(helpedTotalRst.data, 10)
                                            reply.doctor.helpedTotal = helpedTotal
                                            dispatch(feedUpdate(feed))
                                        } else {
                                            console.error("Get Helped Total Error:", helpedTotalRst)
                                        }
                                    }).catch(err => {
                                        console.error("Get Helped Total Error:", err)
                                    })

                                    // Get helped count
                                    feedsApi.feedLikeGet(feed.feedID, reply.doctorId).then(helpedRst => {
                                        if (helpedRst.status === 0) {
                                            const helped = helpedRst.data || 0
                                            reply.doctor.helped = parseInt(helped, 10)
                                            dispatch(feedUpdate(feed))
                                        } else {
                                            console.error("Helped Count Failed:", helpedRst)
                                        }
                                    }).catch(err => {
                                        console.error("Helped Count Error:", err)
                                    })

                                    if (userType.toString() === UserType.doctor.toString()) {
                                        // Get is agreed
                                        feedsApi.getIsAgreed(feed.feedID, reply.doctorId, userId, feed.FeedType).then(agreedRst => {
                                            if (agreedRst.status === 0) {
                                                reply.doctor.agreed = agreedRst.data === "1" ? true : false
                                                dispatch(feedUpdate(feed))
                                            } else {
                                                console.error("Is Agreed Failed:", agreedRst)
                                            }
                                        }).catch(err => {
                                            console.error("Is Agreed Error:", err)
                                        })
                                    }

                                    // Get agreed count
                                    feedsApi.feedAgreeDoctor(feed.feedID, reply.doctorId).then(agreedCountRst => {
                                        if (agreedCountRst.status === 0) {
                                            reply.doctor.agreedCount = parseInt(agreedCountRst.data, 10)
                                            dispatch(feedUpdate(feed))
                                        } else {
                                            console.error("Agree Count Failed:", agreedCountRst)
                                        }
                                    }).catch(err => {
                                        console.error("Agree Count Error:", err)
                                    })

                                    // Get total doctor agreed count
                                    serviceApi.getAgreed(reply.doctorId).then(agreedTotalCountRst => {
                                        if (agreedTotalCountRst.status === 0) {
                                            reply.doctor.agreedTotalCount = parseInt(agreedTotalCountRst.data, 10)
                                            dispatch(feedUpdate(feed))
                                        } else {
                                            console.error("total doctor agreed count Failed:", agreedTotalCountRst)
                                        }
                                    }).catch(err => {
                                        console.error("total doctor agreed count Error:", err)
                                    })

                                    // Get like count
                                    feedsApi.feedLikeGetTotal(reply.doctorId).then(likeCountRst => {
                                        if (likeCountRst.status === 0) {
                                            reply.doctor.likeCount = parseInt(likeCountRst.data, 10)
                                            dispatch(feedUpdate(feed))
                                        } else {
                                            console.error("like count Failed:", likeCountRst)
                                        }
                                    }).catch(err => {
                                        console.error("like count Error:", err)
                                    })

                                    // Get known user count
                                    serviceApi.getKnowns(reply.doctorId).then(knownCountRst => {
                                        if (knownCountRst.status === 0) {
                                            reply.doctor.knownCount = parseInt(knownCountRst.data, 10)
                                            dispatch(feedUpdate(feed))
                                        } else {
                                            console.error("known user count Failed:", knownCountRst)
                                        }
                                    }).catch(err => {
                                        console.error("known user count Error:", err)
                                    })

                                    // Get is known doctor?
                                    feedsApi.doctorKnowCheckUnique(reply.doctorId, userType, userId).then(knownRst => {
                                        if (knownRst.status === 0) {
                                            const known = knownRst.data === "1" ? true : false
                                            reply.doctor.known = known
                                            dispatch(feedUpdate(feed))
                                        } else {
                                            console.error("is known doctor Error:", knownRst)
                                        }
                                    }).catch(err => {
                                        console.error("is known doctor Error:", err)
                                    })
                                }
                            } else {
                                console.error("Failed to get replies:", repliesRst)
                            }
                        }).catch(err => {
                            console.error("Get Replies Error:", err)
                        })
                    }
                }

                // Doctor posted
                if (feed.DoctorId !== 0) {
                    feed.doctor = {}

                    // Get doctor profile
                    authApi.getUserProfile(UserType.doctor, feed.DoctorId).then(doctorRst => {
                        if (doctorRst.status === 0 && doctorRst.data.length > 0) {
                            feed.doctor = Object.assign(feed.doctor, doctorRst.data[0])
                            dispatch(feedUpdate(feed))
                        } else {
                            console.error("Failed to get doctor profile:", doctorRst)
                        }
                    }).catch(err => {
                        console.error("Error to get doctor profile:", err)
                    })

                    // Get agreed count
                    feedsApi.feedAgreeDoctor(feed.feedID, feed.DoctorId).then(agreedCountRst => {
                        if (agreedCountRst.status === 0) {
                            feed.doctor.agreedCount = agreedCountRst.data
                            dispatch(feedUpdate(feed))
                        } else {
                            console.error("Failed to Get agreed count:", agreedCountRst)
                        }
                    }).catch(err => {
                        console.error("Error to Get agreed count:", err)
                    })

                    // Get total doctor agreed count
                    serviceApi.getAgreed(feed.DoctorId).then(agreedTotalCountRst => {
                        if (agreedTotalCountRst.status === 0) {
                            feed.doctor.agreedTotalCount = parseInt(agreedTotalCountRst.data, 10)
                            dispatch(feedUpdate(feed))
                        } else {
                            console.error("Failed to Get total doctor agreed count:", agreedTotalCountRst)
                        }
                    }).catch(err => {
                        console.error("Error to Get total doctor agreed count:", err)
                    })

                    // Get like
                    feedsApi.getIsLiked(feed.feedID, feed.DoctorId, userId, userType, feed.FeedType).then(likeRst => {
                        if (likeRst.status === 0) {
                            feed.doctor.isLike = likeRst.data
                            dispatch(feedUpdate(feed))
                        } else {
                            console.error("Failed to Get like:", likeRst)
                        }
                    }).catch(err => {
                        console.error("Error to Get like:", err)
                    })

                    // Get like count
                    feedsApi.feedLikeGetTotal(feed.DoctorId).then(likeCountRst => {
                        if (likeCountRst.status === 0) {
                            feed.doctor.likeCount = parseInt(likeCountRst.data, 10)
                            dispatch(feedUpdate(feed))
                        } else {
                            console.error("Failed to Get like count:", likeCountRst)
                        }
                    }).catch(err => {
                        console.error("Error to Get like count:", err)
                    })

                    // Get known user count
                    serviceApi.getKnowns(feed.DoctorId).then(knownCountRst => {
                        if (knownCountRst.status === 0) {
                            feed.doctor.knownCount = knownCountRst.data ? parseInt(knownCountRst.data, 10) : 0
                            dispatch(feedUpdate(feed))
                        } else {
                            console.error("Failed to Get like count:", knownCountRst)
                        }
                    }).catch(err => {
                        console.error("Error to Get like count:", err)
                    })

                    if (userType.toString() === UserType.doctor.toString()) {
                        // Get agreed
                        feedsApi.getIsAgreed(feed.feedID, feed.DoctorId, userId, feed.FeedType).then(agreedRst => {
                            if (agreedRst.status === 0) {
                                feed.doctor.agreed = agreedRst.data === "1" ? true : false
                                dispatch(feedUpdate(feed))
                            } else {
                                console.error("Failed to Get agreed:", agreedRst)
                            }
                        }).catch(err => {
                            console.error("Error to Get agreed:", err)
                        })
                    }

                    // Get helped total count
                    serviceApi.getHelped(feed.DoctorId).then(helpedTotalRst => {
                        if (helpedTotalRst.status === 0) {
                            const helpedTotal = parseInt(helpedTotalRst.data, 10)
                            feed.doctor.helpedTotal = helpedTotal
                            dispatch(feedUpdate(feed))
                        } else {
                            console.error("Failed to Get helped total count:", helpedTotalRst)
                        }
                    }).catch(err => {
                        console.error("Error to Get helped total count:", err)
                    })

                    // Get helped count
                    feedsApi.feedLikeGet(feed.feedID, feed.DoctorId).then(helpedRst => {
                        if (helpedRst.status === 0) {
                            const helped = parseInt(helpedRst.data, 10)
                            feed.doctor.helped = helped
                            dispatch(feedUpdate(feed))
                        } else {
                            console.error("Failed to Get helped count:", helpedRst)
                        }
                    }).catch(err => {
                        console.error("Error to Get helped count:", err)
                    })

                    // Get is known doctor?
                    feedsApi.doctorKnowCheckUnique(feed.DoctorId, userType, userId).then(knownRst => {
                        if (knownRst.status === 0) {
                            const known = knownRst.data === "1" ? true : false
                            feed.doctor.known = known
                            dispatch(feedUpdate(feed))
                        } else {
                            console.error("Failed to Get is known doctor:", knownRst)
                        }
                    }).catch(err => {
                        console.error("Error to Get is known doctor:", err)
                    })
                }

                fullFeeds.push(feed)
                dispatch(_feedsSuccess(rowIndex, fullFeeds, filter))
            }
            dispatch(feedsEnd())
        } else {
            dispatch(_feedsFailure(response.message))
        }
    }
}

function _feedLikeRequest() {
    return {
        type: FEED_LIKE_REQUEST,
        message: "Liking feed..."
    }
}

function _feedLikeSuccess(index, replyIndex, isLike, userType) {
    return {
        type: FEED_LIKE_SUCCESS,
        index,
        replyIndex,
        isLike,
        userType
    }
}

function _feedLikeFailure(message) {
    return {
        type: FEED_LIKE_FAILURE,
        message
    }
}

export function setLikeFeed(index, replyIndex, feedId, feedDoctorId, userId, userType, feedType, isLike) {
    return async dispatch => {
        if (isLike) {
            dispatch(_feedLikeRequest())

            // Get all feeds
            const response = await feedsApi.setIsLiked(feedId, feedDoctorId, userId, userType, feedType, isLike)
            if (response.status === 0) {
                dispatch(_feedLikeSuccess(index, replyIndex, isLike, userType))
            } else {
                dispatch(_feedLikeFailure(response.message))
            }
        }
    }
}

function _doctorKnowRequest() {
    return {
        type: DOCTOR_KNOW_REQUEST,
        message: "Doctor know..."
    }
}

function _doctorKnowSuccess(index, replyIndex, isKnow, doctorId) {
    return {
        type: DOCTOR_KNOW_SUCCESS,
        index,
        replyIndex,
        isKnow,
        doctorId
    }
}

function _doctorKnowFailure(message) {
    return {
        type: DOCTOR_KNOW_FAILURE,
        message
    }
}

export function setDoctorKnow(index, replyIndex, doctorId, userType, userId, isKnow) {
    return async dispatch => {
        if (isKnow) {
            dispatch(_doctorKnowRequest())
            const response = await feedsApi.setDoctorKnow(doctorId, userType, userId)

            if (response.status === 0) {
                dispatch(_doctorKnowSuccess(index, replyIndex, isKnow, doctorId))
            } else {
                dispatch(_doctorKnowFailure(response.message))
            }
        }
    }
}

function _feedAgreeRequest() {
    return {
        type: FEED_AGREE_REQUEST,
        message: "Liking feed..."
    }
}

function _feedAgreeSuccess(index, replyIndex, isAgree) {
    return {
        type: FEED_AGREE_SUCCESS,
        index,
        replyIndex,
        isAgree
    }
}

function _feedAgreeFailure(message) {
    return {
        type: FEED_AGREE_FAILURE,
        message
    }
}

export function setAgreeFeed(index, replyIndex, feedId, feedDoctorId, doctorId, feedType, isAgree) {
  return async dispatch => {
      if (isAgree) {
        dispatch(_feedAgreeRequest())
        // Agree feed
        const response = await feedsApi.setIsAgreed(feedId, feedDoctorId, doctorId, feedType, isAgree)
        if (response.status === 0) {
            dispatch(_feedAgreeSuccess(index, replyIndex, isAgree))
        } else {
            dispatch(_feedAgreeFailure(response.message))
        }
    }
  }
}
