import {
    DOCTOR_PAYMENT_SETTING_REQUEST,
    DOCTOR_PAYMENT_SETTING_SUCCESS,
    DOCTOR_PAYMENT_SETTING_FAILURE,

    DOCTOR_WITHDRAW_REQUEST,
    DOCTOR_WITHDRAW_SUCCESS,
    DOCTOR_WITHDRAW_FAILURE
} from 'redux/actions/payment'

export function payment (
    state = {
        paymentStatus: 0,
        message: null,
        paymentSetting: {},

        withdrawStatus: 0,
        withdrawMessage: null,
        balance: null
    },
    action
) {
    switch (action.type) {
        // Payment settings
        case DOCTOR_PAYMENT_SETTING_REQUEST:
            return {
                ...state,
                paymentStatus: 1,
                message: null
            }
        case DOCTOR_PAYMENT_SETTING_FAILURE:
            return {
                ...state,
                paymentStatus: 2,
                message: action.message
            }
        case DOCTOR_PAYMENT_SETTING_SUCCESS:
            return {
                ...state,
                paymentStatus: 0,
                paymentSetting: action.paymentSetting,
                balance: action.balance
            }
        // Withdraw
        case DOCTOR_WITHDRAW_REQUEST:
            return {
                ...state,
                withdrawStatus: 1,
                withdrawMessage: null
            }
        case DOCTOR_WITHDRAW_FAILURE:
            return {
                ...state,
                withdrawStatus: 2,
                withdrawMessage: action.message
            }
        case DOCTOR_WITHDRAW_SUCCESS:
            return {
                ...state,
                withdrawStatus: 0,
                balance: action.balance
            }
        default:
            return state
    }
}