import apiRequest, {apiRestRequest} from './apiRequest'

export function getSchedule(doctorUserId, doctorServiceSKU, doctorStartTimeStamp) {
    return apiRequest('doctorServicesGet', {doctorUserId, doctorServiceSKU, isDeleted: 0, doctorStartTimeStamp})
}

export function createSchedule(doctorUserId, doctorServiceSKU, doctorStartTimeStamp, doctorEndTimeStamp) {
    return apiRestRequest('doctorServices', 'post', {doctorUserId, doctorServiceSKU, doctorStartTimeStamp, doctorEndTimeStamp})
}

export function deleteSchedule(doctorServiceID) {
    return apiRestRequest('doctorServices', 'put', {isDeleted: 1}, doctorServiceID)
}

export function clearSchedule(doctorUserId, doctorServiceSKU, doctorStartTimeStamp) {
    return apiRequest('clearDoctorShedule', {doctorUserId, doctorServiceSKU, isDeleted: 1, isBooked: 0, doctorStartTimeStamp})
}

