import React from 'react'

export default class NavBack extends React.Component {
    back = () => {
        window.history.back()
    }

    render() {
        return (
            <div
                className='back-btn'
                onClick={this.back.bind(this)}
            >
                <i className='fa fa-arrow-left'/>
            </div>
        )
    }
}