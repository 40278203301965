import React from "react";
import { Grid } from "@material-ui/core";
import { SvgIcon } from "components";

export default function ServiceInfoItem ({...props}) {
    const {label, ico, value} = props
    return (
        <Grid container justify="space-between" alignItems="center" direction="row" className="service-info-item-container">
            <div className="info-desp">
                <SvgIcon data={ico} className="info-ico"/>
                <label className="info-label">{label}</label>
            </div>
            <label className="info-value">{value}</label>
        </Grid>
    )
}
