import { connect } from 'react-redux'
import DoctorProfile from './DoctorProfile'

function mapStateToProps(state) {
    const { auth } = state
    const { userProfile } = auth
    const doctorId = userProfile.doctorUserID
    
    return {
        doctorId,
        editable: true
    }
}
  
export default connect(mapStateToProps)(DoctorProfile)
