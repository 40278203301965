import Service from './Service.jsx'
import { connect } from 'react-redux'

function handleSKU(history, sku) {
    history.push({
        pathname: "/dashboard/service_schedule",
        state: {
            sku
        }
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return { handleSKU: (sku) => {handleSKU(ownProps.history, sku)} }
}

function mapStateToProps(state) {
    const { auth } = state
    const { userProfile } = auth
    const doctorId = userProfile.doctorUserID
    
    return {
        doctorId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Service)
