import React from 'react'

export default class ImageDetail extends React.Component {
    render() {
        if (!this.props.location.state) {
            this.props.history.replace({
                pathname: '/dashboard'
            })
            return null
        } else {
            const {imageUrl} = this.props.location.state
            
            return (<div className="image-detail-container">
                <img className="tile-img" src={imageUrl} alt="..."/>
            </div>)
        }
    }
}