import {
    LOAD_CARDS_REQUEST,
    LOAD_CARDS_SUCCESS,
    LOAD_CARDS_FAILURE
} from 'redux/actions/card.js'

export function card (
    state = {
        status: 0,
        message: null,
        cardList: []
    },
    action
) {
    switch (action.type) {
        case LOAD_CARDS_REQUEST:
            return {
                ...state,
                status: 1,
                message: null
            }
        case LOAD_CARDS_SUCCESS:
            return {
                ...state,
                status: 0,
                cardList: action.cards,
                message: null
            }
        case LOAD_CARDS_FAILURE:
            return {
                ...state,
                status: 2,
                message: action.message
            }
        default:
            return state
    }
}