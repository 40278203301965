import apiRequest from './apiRequest'

export function getAgreed(feedDoctorId) {
    return apiRequest('getAgreeTotalByDoctorId', { feedDoctorId })
}

export function getHelped(doctorId) {
    return apiRequest('feedRepliesHelpdoctor', { doctorId })
}

export function getFavorites(doctorId) {
    return apiRequest('doctorFavoritesGetByDoctorId', { doctor: doctorId })
}

export function feedLikeDoctorTotal(doctorId) {
    return apiRequest('feedLikeGetTotal', { feedDoctorId: doctorId })
}

export function getKnowns(doctorId) {
    return apiRequest('doctorknownsGet', { doctorId })
}

export function getDoctorAverageRating(doctorUserID) {
    return apiRequest('getAverageRatingByDoctorId', { doctorUserID })
}

export function isFavoriteDoctorPatient(doctorId, patientId) {
    return apiRequest('doctorFavortiesGetByDoctorPatient', { doctor: doctorId, patient: patientId })
}

export function isPatientLikeDoctor(patientId, doctorId) {
    return apiRequest('patientFavoriteCheckUnique', { patient: patientId, doctor: doctorId })
}

export function getDoctors() {
    return apiRequest('getDoctorDescByRating', null)
}

/**
 * Get filtered doctors
 * 
 * @param {firstName, middleName, lastName, areaOfPractice, medicalSchool, yearOfGraduate, location, startDateTime, endDateTime} params 
 */
export function getFilteredDoctors(params) {
    return apiRequest('doctorUserFilter', params)
}
