import React from 'react'
import { Button, Grid } from "@material-ui/core"
import { FeedType, UserType, ImageType } from 'helper/const'
import { compressBase64 } from 'helper/global'
import { connect } from 'react-redux'
import { AlertDialog, ItemGrid } from 'components'
import { Spinner } from 'components'

import * as questionApi from 'api/question'
import * as imageApi from 'api/image'
import * as notificationApi from 'api/notification'

import * as feedsAction from 'redux/actions/feeds'

const uuidv1 = require('uuid/v1')

class PostArticle extends React.Component {
    constructor(props) {
        super(props)

        const { userId, userType, feed } = props

        if (userType !== UserType.doctor) {
            props.history.push({
                pathname: '/dashboard'
            })
        }

        let title = ''
        let body = ''
        let imageURL = ''
        let articleLink = ''
        let feedID

        if (feed) {
            title = feed.Title || ''
            body = feed.Body || ''
            imageURL = feed.ImageURL || ''
            articleLink = feed.ArticleLink || ''
            feedID = feed.feedID
        } else {
            console.log("No Feed")
        }

        this.state = {
            alertDlgOpen: false,
            alertMessage: '',
            alertTitle: 'Post an article',
            article: {
                feedID: feedID,
                title: title,
                body: body,
                doctorId: userId,
                imageURL: imageURL,
                articleLink: articleLink,
                feedType: FeedType.doctorPosted
            },
            showSpinner: false
        }
    }


    componentWillReceiveProps(nextProps) {
        const { feed } = nextProps

        if (feed) {
            const { article } = this.state

            article.title = feed.Title || ''
            article.body = feed.Body || ''
            article.imageURL = feed.ImageURL || ''
            article.articleLink = feed.ArticleLink || ''
            article.feedID = feed.feedID

            console.log("Article:", article)

            this.setState({ article })
        } else {
            console.log("No Feed Found")
        }

    }


    onPickFile = (imgFile) => {
        if (imgFile) {
            let reader = new FileReader()

            reader.onload = (e) => {
                const photo = e.target.result
                const photoSize = parseInt(photo.length * 3 / 4, 10)   // Bytes

                this.setState({
                    photo,
                    photoSize: this.getFileSizeDesc(photoSize)
                })
            }

            reader.readAsDataURL(imgFile)
        }
    }


    getFileSizeDesc = (size) => {
        let result = parseInt(size, 10)

        if (isNaN(result)) {
            return size
        }

        if (result < 1024) {
            result = result + " Bytes"
        } else if (result < 1024 * 1024) {
            result = result / 1024  // KB
            result = parseInt(result * 10, 10) / 10

            result = result + " KB"
        } else {
            result = result / 1024 / 1024  // KB
            result = parseInt(result * 10, 10) / 10

            result = result + " MB"
        }

        return result
    }


    handleAlert = () => {
        this.setState({
            alertDlgOpen: false
        })
    }


    handleSave = async () => {
        const { article, photo } = this.state
        const { title, body, articleLink } = article

        let data = {...article}

        // Check validation
        if (!title || !body) {
            this.setState({
                alertMessage: 'Title and Description is required.',
                alertDlgOpen: true
            })
            return
        }

        if (body.length > 500) {
            this.setState({
                alertMessage: 'Content must be less than 500 words.',
                alertDlgOpen: true
            })

            return
        }

        if (photo) {
            const purePhoto = photo.split(",")[1]
            const base64Photo = compressBase64(purePhoto)
            const rstImg = await imageApi.uploadImage(ImageType.Article, base64Photo)

            if (rstImg.status === 0) {
                const imgUrl = rstImg.data
                data["imageURL"] = imgUrl
            }
        }

        await this.setState({ showSpinner: true })

        let rstPostArticle, isCreate
        if (data.feedID) {
            // Update Feed
            rstPostArticle = await questionApi.updateFeed(data.feedID, data)
            isCreate = false
        } else {
            // Create Feed
            rstPostArticle = await questionApi.createFeed(data)
            isCreate = true

            const { userId } = this.props
            const pushMessage = `Article ${title} was posted.`
            this.sendNotificationToAllDoctors(pushMessage, {
                type: "Notification",
                doctorId: userId,
                content: pushMessage,
                action: "PostArticle",
                pushGuid: uuidv1(),
                title: title,
                description: body,
                media: data["imageURL"],
                articleLink: articleLink
            })
        }

        await this.setState({ showSpinner: false })

        if (rstPostArticle.status !== 0) {
            this.setState({
                alertMessage: 'Failed to post an article',
                alertDlgOpen: true
            })
        } else {
            this.props.dispatch(feedsAction.feedNeedRefreshAction(!isCreate))
            this.props.history.replace('/#/dashboard/feed')
        }
    }


    handleChange = (key, value) => {
        this.setState(prevState => {
            const {article} = prevState
            article[key] = value

            return {
                article
            }
        })
    }


    async sendNotificationToAllDoctors(message, additional) {
        const rstPushUsers = await notificationApi.getAllDoctorsByDoctorId(additional.doctorId)

        console.log("Users:", rstPushUsers.data)
        if (rstPushUsers.status === 0) {
            let targetUsers = []
            for (let index = 0; index < rstPushUsers.data.length; index++) {
                const element = rstPushUsers.data[index];
                if (element.pushId) {
                    targetUsers.push(element.pushId)
                }
            }

            if (targetUsers.length > 0) {
                console.log("TargetUsers:", targetUsers)
                notificationApi.sendPush(targetUsers, message, additional)
            }
        }
    }

    render() {
        const { article, alertDlgOpen, alertTitle, alertMessage, photoSize } = this.state
        const { feedID, title, articleLink, body } = article

        return (
            <div className="post-article-main-cont">
                { feedID && <div className="title">Update an article</div>}
                { !feedID && <div className="title">Post an article</div>}

                <Grid container direction="column" alignItems="center" className="post-article-container">
                    <ItemGrid xs={12} md={12} lg={12} classes={{grid: "sub-container"}}>
                        <input
                            type='text'
                            className='article-title'
                            value={title}
                            onChange={(e) => {this.handleChange('title', e.target.value)}}
                            placeholder="Title"
                        />

                        <input
                            type='text'
                            className='url'
                            value={articleLink}
                            onChange={(e) => {this.handleChange('articleLink', e.target.value)}}
                            placeholder="Article Url"
                        />

                        <textarea
                            className='body'
                            onChange={(e) => {this.handleChange('body', e.target.value)}}
                            value={body}
                            placeholder="Description"
                        />

                        <div className="file-input-wrapper">
                            <Button className="picker-button">
                                <label className='item-input placeholder'>{photoSize ? "Attach new photo" : "Attach a photo"}</label>
                                {photoSize && <label className="photo-size">{photoSize}</label>}
                            </Button>

                            <input
                                href="fileInput"
                                type="file"
                                onChange={(event) => {
                                    const files = event.target.files
                                    if (files.length > 0) {
                                        this.onPickFile(files[0])
                                    }
                                }}/>
                        </div>

                        <Button className='update-button save-btn doctor-btn' onClick={this.handleSave.bind(this)}>Save</Button>
                    </ItemGrid>

                    <AlertDialog
                        title={alertTitle}
                        message={alertMessage}
                        ok="OK"
                        open={alertDlgOpen}
                        onClose={this.handleAlert}
                    />
                </Grid>

                <Spinner show={this.state.showSpinner} />
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    const { auth, feeds } = state
    const { userProfile } = auth
    const { data } = feeds

    let userType = UserType.unknown
    let userId = 0

    if (userProfile) {
        userType = userProfile.userType
        if (userType === UserType.patient) {
            userId = userProfile.patientUserID
        }

        if (userType === UserType.doctor) {
            userId = userProfile.doctorUserID
        }
    }

    let result = { userType, userId }

    if (ownProps.match.params.feedID) {
        const feedID = parseInt(ownProps.match.params.feedID, 10)
        if (feedID) {
            const feed = data.find(x => x && x.feedID === feedID)
            console.log("Feed:", feed)
            result.feed = feed
        }
    }

    return result
}

export default connect(mapStateToProps)(PostArticle)
