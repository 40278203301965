import * as noteApi from 'api/note'
import {
    UserType,
    NoteRowCount
} from 'helper/const'

export const GET_NOTES_REQUEST = 'GET_NOTES_REQUEST'
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS'
export const GET_NOTES_FAILURE = 'GET_NOTES_FAILURE'

export const SET_NOTE_REQUEST = 'SET_NOTE_REQUEST'
export const SET_NOTE_SUCCESS = 'SET_NOTE_SUCCESS'
export const SET_NOTE_FAILURE = 'SET_NOTE_FAILURE'

export const SET_CURRENT_PATIENT = 'SET_CURRENT_PATIENT'
export const SET_CURRENT_NOTE = 'SET_CURRENT_NOTE'

function _getNotesRequest() {
    return {
        type: GET_NOTES_REQUEST,
        message: "Fetching notes..."
    }
}

function _getNotesSuccess(patientId, rowIndex, notes) {
    return {
        type: GET_NOTES_SUCCESS,
        patientId,
        rowIndex,
        notes
    }
}

function _getNotesFailure(message) {
    return {
        type: GET_NOTES_FAILURE,
        message
    }
}

/**
 * Get user's notes
 * 
 * @param {current user type} userType
 * @param {patient id} patientId
 * @param {rowIndex} rowIndex
 */
export function getNotesAction(userType, patientId, rowIndex) {
  return async dispatch => {
    dispatch(_getNotesRequest())
    const response = await noteApi.getPatientNotes(userType, patientId, rowIndex * NoteRowCount, NoteRowCount)
    if (response.status === 0) {
        dispatch(_getNotesSuccess(patientId, rowIndex, response.data))
    } else {
        dispatch(_getNotesFailure(response.message))
    }
  }
}

export function setCurrentPatient(patientId) {
    return {
        type: SET_CURRENT_PATIENT,
        patientId
    }
}


function _setNoteRequest() {
    return {
        type: SET_NOTE_REQUEST,
        message: "Set note..."
    }
}

function _setNoteSuccess(patientId, notes) {
    return {
        type: SET_NOTE_SUCCESS,
        patientId,
        rowIndex: 0,
        notes
    }
}

function _setNoteFailure(message) {
    return {
        type: SET_NOTE_FAILURE,
        message
    }
}

/**
 * Set user's note
 * 
 * @param {patient note id} patientNoteId
 * @param {note text} noteText
 * @param {note data url} noteDataURL
 * @param {patient id} patientId
 * @param {zero if patient is creating else the doctor id} doctorId
 */
export function setNoteAction(patientNoteId, noteText, noteDataURL, patientId = null, doctorId = null) {
  return async dispatch => {
    dispatch(_setNoteRequest())
    var response
    if (patientNoteId) { // Update
        response = await noteApi.updatePatientNote(patientNoteId, noteText, noteDataURL)
    } else {
        response = await noteApi.createPatientNote(noteText, noteDataURL, patientId, doctorId)
    }
    
    if (response.status === 0) {
        const userType = doctorId === 0 ? UserType.patient : UserType.doctor
        const rowIndex = 0
        const resNotes = await noteApi.getPatientNotes(userType, patientId, rowIndex * NoteRowCount, NoteRowCount)
        if (resNotes) {
            dispatch(_setNoteSuccess(patientId, resNotes.data))
        } else {
            dispatch(_setNoteFailure("Saved note successfully. But failed to read note list."))
        }
    } else {
        dispatch(_setNoteFailure(response.message))
    }
  }
}

export function setCurrentNoteAction(patientNote) {
    return {
        type: SET_CURRENT_NOTE,
        patientNote
    }
}
