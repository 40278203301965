import React from "react"
import { Button, Grid } from "@material-ui/core"
import { Base, ServiceInfoItem, STextInput, FloatSelect } from "components"
import { GoogleApiKey } from 'helper/const'

import Rating from 'react-rating'

import {
    UserType,
    Images,
    SvgIconData,
    Qualifications,
    AreaOfPractices
} from 'helper/const'
import {compressBase64} from 'helper/global'

import * as imageApi from 'api/image'
import * as favoritesApi from 'api/favorites'
import * as serviceApi from 'api/service'
import * as authApi from 'api/auth'
import * as actions from 'redux/actions/auth'

export default class DoctorProfile extends React.Component {
    serviceList = [{
        ico: SvgIconData.message,
        label: "Instant Message A Doctor"
    }, {
        ico: SvgIconData.video,
        label: "Video Call A Doctor"
    }, {
        ico: SvgIconData.phone,
        label: "Voice Call A Doctor"
    }, {
        ico: SvgIconData.home,
        label: "Schedule A House Call"
    }, {
        ico: SvgIconData.building,
        label: "Schedule Office Appointment"
    }]


    years = []


    constructor(props) {
        super(props)
        
        this.state = {
            rating: 0,
            favorite: false,
            likes: 0,
            agreed: 0,
            knowns: 0,
            helped: 0,
            doctor: null
        }

        // Calc years
        const today = new Date()
        const thisYear = today.getFullYear()
        const endYear = 1799
        for (let i = thisYear; i > endYear; i--) {
            this.years.push(i)
        }

        this.initialize()
    }


    initialize = () => {
        let doctor = this.props.doctor

        if (!doctor) {
            this.getExtraInfos(this.props.doctorId)

            authApi.getUserProfile(UserType.doctor, this.props.doctorId).then(doctorRst => {
                if (doctorRst.status === 0) {
                    doctor = doctorRst.data[0]
                    this.setState({ doctor })
                }
            }).catch(err => console.error(err))
        } else {
            this.getExtraInfos(doctor.doctorUserID)
        }
    }

    getExtraInfos(userId) {
        this.getAgreed(userId)
        this.getFavorites(userId)
        this.getKnowns(userId)
        this.getHelped(userId)
        this.getAverageRating(userId)

        if (this.props.patientId) {
            this.isFavorite(userId, this.props.patientId)
        }
    }

    getAgreed(doctorId) {
        serviceApi.getAgreed(doctorId).then(res => {
            if (res.status === 0) {
                this.setState({ agreed: res.data })
            }
        }).catch(err => console.error("Failed to get agreed count:", err))
    }

    getFavorites(doctorId) {
        serviceApi.feedLikeDoctorTotal(doctorId).then(res => {
            if (res.status === 0) {
                let likes = parseInt(res.data, 10)
                if (isNaN(likes)) {
                    likes = 0
                }

                this.setState({ likes })
            }
        }).catch(err => console.error("Failed to get favorites:", err))
    }

    getKnowns(doctorId) {
        serviceApi.getKnowns(doctorId).then(res => {
            if (res.status === 0) {
                const knowns = res.data ? res.data : 0
                this.setState({ knowns })
            }
        }).catch(err => console.error("Failed to get knowns count:", err))
    }

    getHelped(doctorId) {
        serviceApi.getHelped(doctorId).then(res => {
            if (res.status === 0) {
                const helped = res.data ? res.data : 0
                this.setState({ helped })
            }
        }).catch(err => console.error("Failed to get helped count:", err))
    }

    getAverageRating(doctorId) {
        serviceApi.getDoctorAverageRating(doctorId).then(res => {
            if (res.status === 0) {
                const rating = res.data ? parseFloat(res.data[0].averageRating) : 0
                this.setState({ rating })
            }
        }).catch(err => console.error("Failed to get average rating:", err))
    }

    isFavorite(doctorId, patientId) {
        serviceApi.isPatientLikeDoctor(patientId, doctorId).then(res => {
            if (res.status === 0) {
                const favorite = res.data === "1" ? true : false
                this.setState({ favorite })
            }
        }).catch(err => console.error("Failed to get is favorite:", err))
    }

    handleFavorite = async () => {
        const { patientId } = this.props
        const { doctor } = this.state

        if (patientId && patientId !== 0) {
            if (doctor) {
                const doctorId = doctor.doctorUserID
                const res = await favoritesApi.setFavorite(UserType.patient, patientId, doctorId, !this.state.favorite)

                if (res.status === 0) {
                    this.setState(prevState => {
                        return {
                            favorite: !prevState.favorite
                        }
                    })
                }
            }
        }
    }

    handleChangeText = (key, value) => {
        this.setState(prevState => {
            let { doctor } = prevState
            doctor[key] = value

            return {
                doctor
            }
        })
    }

    handleAvatarChange = (imgFile) => {
        if (imgFile) {
            const reader = new FileReader()

            reader.onload = (e) => {
                const filePath = e.target.result
                this.setState(prevState => {
                    let { doctor } = prevState

                    if (doctor) {
                        doctor.profilePhotoURL = filePath
                    }

                    return {
                        doctor
                    }
                })
            }

            reader.readAsDataURL(imgFile)
        }
    }


    handleUpdateProfile = async () => {
        let data = {}
        const { doctor } = this.state

        let key
        for (key in doctor) {
            let ele = doctor[key]

            if (key === "profilePhotoURL") {
                let arrEle = ele.split(",")

                if (arrEle.length === 2) {
                    ele = arrEle[1]
                    ele = compressBase64(ele)

                    const rstImg = await imageApi.uploadImage(2, ele)

                    if (rstImg.status === 0) {
                        const imgUrl = rstImg.data
                        ele = imgUrl
                    } else {
                        console.log('Failed to upload image')
                        return
                    }
                }
            }

            data[key] = ele
        }

        this.props.dispatch(actions.updateProfile(UserType.doctor, doctor.doctorUserID, data))
    }


    handleMap = async () => {
        const { doctor } = this.state
        if (!doctor) {
            return
        }

        const { location, latitude, longitude } = doctor

        fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${location}&key=${GoogleApiKey}`)
            .then(response => response.json())
            .then(data => {
                console.log(data)
                const { status, results } = data

                let resultLat, resultLng, success
                if (status === "OK" && results && results.length > 0) {
                    const resultData = results[0]
                    const { geometry } = resultData
                    const { location } = geometry
                    const { lat, lng } = location

                    resultLat = lat
                    resultLng = lng

                    success = true
                } else {
                    resultLat = latitude
                    resultLng = longitude
                    success = false
                }

                console.log("Reverse geocode:", success, resultLat, resultLng)

                this.props.history.push({
                    pathname: '/dashboard/map_view',
                    state: {
                        lat: resultLat,
                        lng: resultLng,
                        success
                    }
                })
            })
    }


    render() {
        const readOnly = this.props.patientId ? true : false
        const { rating, likes, favorite, agreed, knowns, helped, doctor } = this.state
        if (!doctor) {
            return null
        }

        let avatar = (doctor.profilePhotoURL && doctor.profilePhotoURL.length > 0) ? doctor.profilePhotoURL : Images.doctorAvatar
        const {
            areaOfPractice,
            preFix,
            firstName,
            lastName,
            email,
            medicalSchool,
            yearOfGraduate,
            registrationBody,
            qualification,
            shortBiography,
            location
        } = doctor
        const name = `${preFix} ${firstName} ${lastName}`


        return (
            <div>
                <div className="title">Profile</div>

                <Base className="service-container" model="auth">
                    <Grid container justify="center" alignItems="center" direction="column">
                        <div className="avatar-container">
                            <div className="file-input-wrapper">
                                <input href="fileInput" type="file"
                                onChange={(event) => {
                                    const files = event.target.files
                                    this.handleAvatarChange(files[0])
                                }}/>
                                <img src={avatar} alt="..." className="avatar" />
                            </div>

                            {this.props.patientId && <Button variant='text' onClick={this.handleFavorite} className="fav-btn">
                                <i className={favorite ? "fas fa-star fa-0.5x star" : "far fa-star star"}></i>
                            </Button>}
                        </div>

                        <label className="name">{name}</label>
                        <label className="area-of-practice">{areaOfPractice}</label>
                    </Grid>

                    <Grid container justify="center" alignItems="center" direction="column" className="star-container">
                        <Rating
                            fractions={2}
                            emptySymbol={['far fa-star fa-2x star']}
                            fullSymbol={['fas fa-star fa-2x star']}
                            initialRating={rating}
                            onChange={(rating)=>{this.setState({rating})}}
                            readonly={true}
                        />
                    </Grid>

                    <Grid container justify="center" alignItems="center" direction="column">
                        <ServiceInfoItem
                            label="Likes"
                            ico={SvgIconData.heart}
                            value={likes}
                        />
                        <ServiceInfoItem
                            label="Agreed"
                            ico={SvgIconData.checkboxMarked}
                            value={agreed}
                        />
                        <ServiceInfoItem
                            label="Users Know"
                            ico={SvgIconData.multiUser}
                            value={knowns}
                        />
                        <ServiceInfoItem
                            label="Patients Helped"
                            ico={SvgIconData.accountCircle}
                            value={helped}
                        />
                    </Grid>

                    <div style={{ height: '2rem', width: '1px' }} />

                    {!readOnly &&
                    <STextInput
                        caption="Email"
                        value={email}
                        handleChange={(e) => this.handleChangeText('email', e.target.value)}
                        readOnly={readOnly}
                    />}
                    <STextInput
                        caption="Medical School"
                        value={medicalSchool}
                        handleChange={(e) => this.handleChangeText('medicalSchool', e.target.value)}
                        readOnly={readOnly}
                    />
                    {this.props.editable && <FloatSelect
                        caption="Graduated On"
                        value={yearOfGraduate}
                        options={this.years}
                        handleChange={e => {this.handleChangeText('yearOfGraduate', e.target.value)}}
                        readOnly={readOnly}
                    />}

                    {!this.props.editable && <STextInput
                        caption="Graduated On"
                        value={yearOfGraduate}
                        handleChange={e => {this.handleChangeText('yearOfGraduate', e.target.value)}}
                        readOnly={readOnly}
                    />}

                    {this.props.editable && <FloatSelect
                        caption="Area of Practice"
                        value={areaOfPractice}
                        options={AreaOfPractices}
                        handleChange={e => {this.handleChangeText('areaOfPractice', e.target.value)}}
                        readOnly={true}
                    />}

                    {!this.props.editable && <STextInput
                        caption="Area of Practice"
                        value={areaOfPractice}
                        handleChange={e => {this.handleChangeText('areaOfPractice', e.target.value)}}
                        readOnly={readOnly}
                    />}

                    <STextInput
                        caption="Registration Body"
                        value={registrationBody}
                        handleChange={(e) => this.handleChangeText('registrationBody', e.target.value)}
                        readOnly={readOnly}
                    />

                    {this.props.editable && <FloatSelect
                        id="qualification"
                        multiple={true}
                        caption="Qualifications"
                        value={(qualification === "") ? [] : qualification.split(",")}
                        options={Qualifications}
                        handleChange={e => {this.handleChangeText('qualification', e.target.value.join(","))}}
                        readOnly={readOnly}
                    />}

                    {!this.props.editable && <STextInput
                        caption="Qualifications"
                        value={(qualification === "") ? "" : qualification.split(",").join(", ")}
                        handleChange={e => {this.handleChangeText('yearOfGraduate', e.target.value)}}
                        readOnly={readOnly}
                    />}

                    <STextInput
                        multiple={true}
                        caption="Short Biography"
                        value={shortBiography}
                        handleChange={(e) => this.handleChangeText('shortBiography', e.target.value)}
                        readOnly={readOnly}
                    />
                    <STextInput
                        caption="Location"
                        value={location}
                        handleChange={(e) => this.handleChangeText('location', e.target.value)}
                        readOnly={readOnly}
                    />

                    <Button className="map-btn" onClick={this.handleMap}>View on map</Button>

                    {!readOnly && <Button className="update-button full-width doctor" onClick={this.handleUpdateProfile}>Update Profile</Button>}
                </Base>
            </div>
        )
    }
}