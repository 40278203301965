import React from 'react'
import { PatientInfo, AgreeTerms } from 'components'
import { Button } from '@material-ui/core'
import {Images, UserType} from 'helper/const'

export default class PatientSignup extends React.Component {
    state = {
        userProfile: {
        },
        agreed: false
    }

    constructor(props) {
        super(props)
        this.state.userProfile = props.userProfile
    }

    handleBack = () => {
        if (this.props.onBack) {
            this.props.onBack()
        }
    }

    handleUpdate = () => {
        if (this.props.onUpdate) {
            this.props.onUpdate(this.state.userProfile, this.state.agreed)
        }
    }

    handleAgreement = (agreed) => {
        this.setState({agreed})
    }

    render() {
        const { userProfile } = this.state

        return <div className="user-container">
            <div className="logo-container">
                <img src={Images.logo} alt="..."/>
                <label>eDocine</label>
            </div>

            <div className='user-content'>
                <div className="header">
                    <Button className='back' onClick={this.handleBack}>
                        <i className='fa fa-arrow-left'/>
                    </Button>
                    <label>Sign Up</label>
                </div>

                <div className='sub-header'>
                    <label>Fill in information below:</label>
                </div>

                <PatientInfo
                    userProfile={userProfile}
                    onChange={(data) => {this.setState({userProfile:data})}}
                    containerClass='patient-profile'
                />

                <div style={{ width: '1px', height: '130px' }} />

                <AgreeTerms
                    className="agree-terms"
                    onAgreement={this.handleAgreement}
                    userType = {UserType.patient}
                />

                <div className='push'></div>
            </div>
            <Button
                variant="text"
                color="primary"
                className="update_button patient"
                onClick={this.handleUpdate}
            >
                Sign up
            </Button>
        </div>
    }
}