import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  ListItem,
  Menu,
  MenuItem,
  withStyles
} from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  icon: {
    height: '100%',
    backgroundColor: 'transparent',
    position: 'absolute',
    right: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

class DropDown extends React.Component {
  button = undefined;

  constructor(props) {
      super(props)

      var selectedIndex = 0
      if (props.value && props.options) {
        selectedIndex = props.options.indexOf(props.value)
      }
      this.state = {
        anchorEl: null,
        selectedIndex
      };
    
  }

  componentWillReceiveProps(newProps) {
    if (newProps.value !== this.props.value) {
      const selectedIndex = newProps.options.indexOf(newProps.value)
      this.setState({
        selectedIndex
      })
    }
  }
 
  handleClickListItem = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, index) => {
    this.setState({ selectedIndex: index, anchorEl: null });
    if (this.props.onChange) {
        this.props.onChange(this.props.options[index])
    }
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, containerClass, itemClass, menuItemClass, options, IconComponent, disabled } = this.props;
    const { anchorEl } = this.state;
    const menuItemClasses = { root: containerClass}

    return (
        <div className={classes.root}>
            <List component="nav">
            <ListItem
                button
                aria-haspopup="true"
                aria-controls="lock-menu"
                aria-label="When device is locked"
                onClick={disabled ? null : this.handleClickListItem}
                classes={menuItemClasses}
            >
                <label className={itemClass}>{options[this.state.selectedIndex]}</label>
                <div className={classes.icon}>{IconComponent}</div>
            </ListItem>
            </List>
            <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            >
            {options.map((option, index) => (
                <MenuItem
                key={option}
                disabled={index === 0}
                selected={index === this.state.selectedIndex || index === 0}
                onClick={event => this.handleMenuItemClick(event, index)}
                >
                <label className={menuItemClass}>{option}</label>
                </MenuItem>
            ))}
            </Menu>
        </div>
    );
  }
}

DropDown.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DropDown);