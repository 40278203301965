import { sendGenRequest } from './apiRequest'
import { Api } from 'helper/const'
const OT = require("@opentok/client")

export function createSession() {
    return sendGenRequest(Api.chatUrl + '/CreateSession')
}

/**
 * Join to the session
 * @param {string} sessionId Session id
 */
export async function joinSession(sessionId) {
    return sendGenRequest(`${Api.chatUrl}/JoinSession?sessionid=${sessionId}`)
}

/**
 * Initialize current session
 * @param {string} apiKey Tokbox apikey
 * @param {string} sessionId current session id
 */
export function initSession(apiKey, sessionId) {
    var session = OT.initSession(apiKey, sessionId)
    return session
}