import Service from './Service.jsx'
import { connect } from 'react-redux'
import {
    UserType
} from 'helper/const'

function handleSKU(history, sku, doctorId) {
    history.push({
        pathname: "/dashboard/booking",
        state: {
            sku,
            doctorId
        }
    })
}

function mapDispatchToProps(dispatch, ownProps) {
    return { handleSKU: (sku, doctorId) => {handleSKU(ownProps.history, sku, doctorId)} }
}

function mapStateToProps(state, ownProps) {
    const { auth } = state
    const doctorId = parseInt(ownProps.match.params.doctorId, 10)

    let patientId = 0

    if (auth && auth.userProfile) {
        const user = auth.userProfile
        if (user.userType === UserType.patient) {
            patientId = user.patientUserID
        }
    }

    return {
        patientId,
        doctorId
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Service)
