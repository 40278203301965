import {reactLocalStorage} from 'reactjs-localstorage'

export default class Storage {
    static keyEmail = 'email'
    static keyPassword = 'password'
    static keyRememberMe = 'rememberme'
    static keyNotificationsSetting = 'notifications_setting'
    static keyLocationSetting = 'location_setting'
    static keyInstantMessageSetting = 'instant_message_setting'
    static keyAudioCallSetting = 'audio_call_setting'
    static keyVideoCallSetting = 'video_call_setting'
    static keyCardList = 'card_list'

    /* Common */
    static getValue(key, defaultValue = "") {
        return reactLocalStorage.get(key, defaultValue)
    }

    static setValue(key, value) {
        return reactLocalStorage.set(key, value)
    }

    /* Email */
    static getEmail() {
        return Storage.getValue(Storage.keyEmail)
    }

    static setEmail(value) {
        Storage.setValue(Storage.keyEmail, value)
    }

    /* Password */
    static getPassword() {
        return Storage.getValue(Storage.keyPassword)
    }

    static setPassword(value) {
        Storage.setValue(Storage.keyPassword, value)
    }

    /* Remember me */
    static getRememberMe() {
        const rst = Storage.getValue(Storage.keyRememberMe)
        if (rst === 'true') {
            return true
        } else {
            return false
        }
    }

    static setRememberMe(value) {
        Storage.setValue(Storage.keyRememberMe, value)
    }

    /* Notifications setting */
    static getNotificationsSetting(userId, userType) {
        const key = `${Storage.keyNotificationsSetting}_${userId}_${userType}`
        return Storage.getValue(key) === 'true'
    }

    static setNotificationsSetting(userId, userType, value) {
        const key = `${Storage.keyNotificationsSetting}_${userId}_${userType}`
        Storage.setValue(key, value)
    }

    /* Location setting */
    static getLocationSetting(userId, userType) {
        const key = `${Storage.keyLocationSetting}_${userId}_${userType}`
        return Storage.getValue(key) === 'true'
    }

    static setLocationSetting(userId, userType, value) {
        const key = `${Storage.keyLocationSetting}_${userId}_${userType}`
        Storage.setValue(key, value)
    }

    /* Instant message to a doctor setting */
    static getInstantMessageSetting(userId, userType) {
        const key = `${Storage.keyInstantMessageSetting}_${userId}_${userType}`
        return Storage.getValue(key) === 'true'
    }

    static setInstantMessageSetting(userId, userType, value) {
        const key = `${Storage.keyInstantMessageSetting}_${userId}_${userType}`
        Storage.setValue(key, value)
    }

    static getInstantMessageMaxDefaultTimestamp(userId, userType) {
        const key = `${Storage.keyInstantMessageSetting}_maxTimestamp_${userId}_${userType}`
        return Storage.getValue(key)
    }

    static setInstantMessageMaxDefaultTimestamp(userId, userType, value) {
        const key = `${Storage.keyInstantMessageSetting}_maxTimestamp_${userId}_${userType}`
        Storage.setValue(key, value)
    }

    /* Audio call to a doctor setting */
    static getAudioCallSetting(userId, userType) {
        const key = `${Storage.keyAudioCallSetting}_${userId}_${userType}`
        return Storage.getValue(key) === 'true'
    }

    static setAudioCallSetting(userId, userType, value) {
        const key = `${Storage.keyAudioCallSetting}_${userId}_${userType}`
        Storage.setValue(key, value)
    }

    static getAudioCallMaxDefaultTimestamp(userId, userType) {
        const key = `${Storage.keyAudioCallSetting}_maxTimestamp_${userId}_${userType}`
        return Storage.getValue(key)
    }

    static setAudioCallMaxDefaultTimestamp(userId, userType, value) {
        const key = `${Storage.keyAudioCallSetting}_maxTimestamp_${userId}_${userType}`
        Storage.setValue(key, value)
    }

    /* Video call to a doctor setting */
    static getVideoCallSetting(userId, userType) {
        const key = `${Storage.keyVideoCallSetting}_${userId}_${userType}`
        return Storage.getValue(key) === 'true'
    }

    static setVideoCallSetting(userId, userType, value) {
        const key = `${Storage.keyVideoCallSetting}_${userId}_${userType}`
        Storage.setValue(key, value)
    }

    static getVideoCallMaxDefaultTimestamp(userId, userType) {
        const key = `${Storage.keyVideoCallSetting}_maxTimestamp_${userId}_${userType}`
        return Storage.getValue(key)
    }

    static setVideoCallMaxDefaultTimestamp(userId, userType, value) {
        const key = `${Storage.keyVideoCallSetting}_maxTimestamp_${userId}_${userType}`
        Storage.setValue(key, value)
    }
}