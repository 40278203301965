import React from 'react'
import { FormControl, InputLabel, Input } from "@material-ui/core";

export default class STextInput extends React.Component {
    handleKeyPress = e => {
        if (this.props.handleEnter && e.key === 'Enter') {
            this.props.handleEnter()
        }
    }
    render() {
        const {caption, value, handleChange, onClick, multiple, readOnly} = this.props
        const inputClass = "value-input" + (readOnly ? " read-only": "")

        return (
            <FormControl className={"control-container"}>
                <InputLabel
                    classes={{
                        root: "caption",
                        focused: "focused",
                    }}
                    htmlFor="card-name"
                >
                    {caption}
                </InputLabel>
                <Input
                    multiline={multiple}
                    classes={{
                        input: inputClass,
                        underline: "underline",
                    }}
                    value={value}
                    onChange={handleChange}
                    onClick={onClick}
                    inputProps={{
                        onKeyPress: this.handleKeyPress
                    }}
                    disableUnderline={true}
                    id="card-name"
                    readOnly={readOnly}
                />
            </FormControl>
        )
    }
}
  