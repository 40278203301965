import {
    FEED_NEED_REFRESH,
    FEED_SCROLL,

    FEEDS_REQUEST,
    FEEDS_SUCCESS,
    FEEDS_FAILURE,
    FEEDS_END,

    FEED_LIKE_REQUEST,
    FEED_LIKE_SUCCESS,
    FEED_LIKE_FAILURE,

    FEED_AGREE_REQUEST,
    FEED_AGREE_SUCCESS,
    FEED_AGREE_FAILURE,

    DOCTOR_KNOW_REQUEST,
    DOCTOR_KNOW_SUCCESS,
    DOCTOR_KNOW_FAILURE,

    FEED_UPDATE
} from 'redux/actions/feeds'

import { FeedRowCount, FeedType, UserType } from 'helper/const'

export function feeds (
    state = {
        rowIndex: -1,
        isFeeding: false,
        filter: null,
        message: null,
        data: [],
        needRefresh: true,
        scrollY: 0
    },
    action
) {
    let loopIndex = 0

    switch (action.type) {
        case FEED_NEED_REFRESH:
            return {
                ...state,
                needRefresh: true,
                rowIndex: (action.forceRefresh ? 0 : state.rowIndex)
            }
        case FEED_SCROLL:
            return {
                ...state,
                scrollY: action.scrollY
            }
        case FEEDS_REQUEST:
            return Object.assign({}, state, {
                isFeeding: true
            })
        case FEEDS_FAILURE:
            return Object.assign({}, state, {
                isFeeding: false,
                message: action.message,
                needRefresh: false
            })
        case FEED_UPDATE:
            let newDataUpdate = [...state.data]

            for (loopIndex = 0; loopIndex < newDataUpdate.length; loopIndex++) {
                if (newDataUpdate[loopIndex].feedID === action.feed.feedID) {
                    newDataUpdate.splice(loopIndex, 1, action.feed)
                    break
                }
            }

            return {...state, data: newDataUpdate}
        case FEEDS_SUCCESS:
            let newDataSuccess = []

            if (action.rowIndex === 0) { // Refresh
                newDataSuccess = [...action.feeds]
            } else {
                if (action.rowIndex > state.rowIndex) {
                    newDataSuccess = [...state.data, ...action.feeds]
                } else {
                    newDataSuccess = [...state.data]
                    newDataSuccess.splice(action.rowIndex * FeedRowCount, action.feeds.length, ...action.feeds)
                }
            }

            let isFeeding = true
            if (action.feeds.length === FeedRowCount) {
                isFeeding = false
            }

            return Object.assign({}, state, {
                isFeeding,
                rowIndex: action.rowIndex,
                data: newDataSuccess,
                filter: action.filter
            })
        case FEEDS_END:
            return {
                ...state,
                isFeeding: false,
                needRefresh: false
            }

        case FEED_LIKE_REQUEST:
            return {...state, isFeeding: true}
        case FEED_LIKE_FAILURE:
            return {...state, isFeeding: false, message: action.message}
        case FEED_LIKE_SUCCESS:
            let newDataLikeSuccess = [...state.data]

            let feed = newDataLikeSuccess[action.index]
            let doctor = null

            if (feed.FeedType.toString() === FeedType.patientPosted.toString()) {
                doctor = feed.replies[action.replyIndex].doctor
            } else {
                doctor = feed.doctor
            }

            doctor.isLike = action.isLike
            doctor.likeCount += 1

            if (action.userType === UserType.patient) {
                doctor.helped += 1
            }

            return {...state, isFeeding: false, data: newDataLikeSuccess}

        case FEED_AGREE_REQUEST:
            return { ...state, isFeeding: true }
        case FEED_AGREE_FAILURE:
            return { ...state, isFeeding: false, message: action.message }
        case FEED_AGREE_SUCCESS:
            let newDataAgreeSuccess = [...state.data]
            feed = newDataAgreeSuccess[action.index]

            if (feed.FeedType.toString() === FeedType.patientPosted.toString()) {
                feed.replies[action.replyIndex].doctor.agreed = action.isAgree
                feed.replies[action.replyIndex].doctor.agreedCount += 1
                feed.replies[action.replyIndex].doctor.agreedTotalCount += 1
            } else {
                feed.doctor.agreed = action.isAgree
                feed.doctor.agreedCount += 1
                feed.doctor.agreedTotalCount += 1
            }

            return { ...state, isFeeding: false, data: newDataAgreeSuccess }

            /* Doctor know */
        case DOCTOR_KNOW_REQUEST:
            return {...state, isFeeding: true}
        case DOCTOR_KNOW_FAILURE:
            return {...state, isFeeding: false, message: action.message}
        case DOCTOR_KNOW_SUCCESS:
            let newDataKnowSuccess = [...state.data]

            const { doctorId } = action
            for (let i = 0; i < newDataKnowSuccess.length; i++) {
                const feed = newDataKnowSuccess[i]

                if (feed.doctor && feed.doctor.doctorUserID === doctorId) {
                    feed.doctor.known = action.isKnow
                    feed.doctor.knownCount += 1
                } else if (feed.replies) {
                    for (let j = 0; j < feed.replies.length; j++) {
                        const reply = feed.replies[j]
                        if (reply.doctor && reply.doctor.doctorUserID === doctorId) {
                            reply.doctor.known = action.isKnow
                            reply.doctor.knownCount += 1
                        }
                    }
                }
            }

            return {...state, isFeeding: false, data: newDataKnowSuccess}
        default:
            return state
    }
}