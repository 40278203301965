import React from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { TwitterLoginDialog } from 'components'
import { Images, SocialInfo } from 'helper/const'
import { Button, Checkbox } from "@material-ui/core"
import { AlertDialog } from 'components'

import Storage from 'Storage/storage'

var Codebird = require("codebird")
var cb = new Codebird()
cb.setConsumerKey(SocialInfo.twitter.consumerKey, SocialInfo.twitter.consumerSecret)

export default class AuthForm extends React.Component {
    state = {
        email: '',
        password: '',
        confirmPassword: '',
        twitterVerifyOpen: false,

        alertDlgOpen: false,
        alertMessage: '',
        alertTitle: 'Authentication'
    }

    componentDidMount() {
        if (this.props.isLogin) {
            this._initLoginForm()
        } else {
            this._initSignupForm()
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.isLogin !== this.props.isLogin) { // Switched between login and signup form
            if (newProps.isLogin) {
                this._initLoginForm()
            } else {
                this._initSignupForm()
            }
        }
    }

    handleAlert = () => {
        this.setState({
            alertDlgOpen: false
        })
    }

    showAlert = (title, message) => {
        this.setState(prevState => {
            const alertTitle = title ? title : prevState.alertTitle
            const alertMessage = message ? message : prevState.alertMessage
            return {
                alertTitle,
                alertMessage,
                alertDlgOpen: true
            }
        })
    }

    _initLoginForm() {
        const email = Storage.getEmail()
        const password = Storage.getPassword()
        const rememberMe = Storage.getRememberMe()
        this.setState({ email, password, rememberMe })
    }

    _initSignupForm() {
        this.setState({
            email: '',
            password: '',
            confirmPassword: ''
        })
    }

    handleRememberme = (checked) => {
        this.setState({
            rememberMe: checked
        })
    }

    handleLogin = (isLogin) => {
        const { login, signup } = this.props
        const { email, password, rememberMe } = this.state

        if (!email || !password) {
            this.showAlert(null, "Email and password is required.")
            return
        }

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!re.test(String(email).toLowerCase())) {
            this.showAlert(null, "Email address is invalid")
            return
        }

        if (isLogin) {
            login(email, password, null, null, null, rememberMe)
        } else {
            const { confirmPassword } = this.state

            if (password !== confirmPassword) {
                this.showAlert(null, "Password is invalid.")
            } else {
                signup(email, password, null, null, null, rememberMe)
            }
        }
    }

    handleSignup = () => {
        const { isLogin } = this.props

        if (isLogin) {
            this.props.toggleLogin()
        } else {
            this.handleLogin(false)
        }
    }

    handleTwitterLogin = () => {
        const self = this

        // gets a request token
        cb.__call(
            "oauth_requestToken",
            {oauth_callback: "oob"},
            function (reply) {
                // stores it
                cb.setToken(reply.oauth_token, reply.oauth_token_secret)
        
                // gets the authorize screen URL
                cb.__call(
                    "oauth_authorize",
                    {},
                    function (auth_url) {
                        window.codebird_auth = window.open(auth_url)
                        self.setState({
                            twitterVerifyOpen: true
                        })
                    }
                )
            }
        )
    }

    handleTwitterVerify = (pin) => {
        this.setState({
            twitterVerifyOpen: false
        })

        if (pin) {
            const self = this
            cb.__call(
                "oauth_accessToken",
                { oauth_verifier: pin },
                function (reply) {
                    const username = reply.screen_name
                    const twitterId = reply.user_id

                    if (twitterId) {
                        if (self.props.isLogin) {
                            self.props.login(null, null, null, twitterId, null, self.state.rememberMe)
                        } else {
                            localStorage.setItem("twitter_login_info", JSON.stringify({
                                username
                            }))

                            self.props.signup(null, null, null, twitterId, null)
                        }
                    }
                }
            )
        }
    }


    responseFacebook = (response) => {
        const facebookId = response.userID

        if (facebookId) {
            if (this.props.isLogin) {
                this.props.login(null, null, facebookId, null, null, this.state.rememberMe)
            } else {
                this.props.signup(null, null, facebookId, null, null)
            }
        } else {
            this.showAlert(null, "Failed facebook autherization.")
        }
    }


    render() {
        const { email, password, confirmPassword, rememberMe,  alertDlgOpen, alertTitle, alertMessage } = this.state
        const { isLogin, userType } = this.props
        const signLabel = isLogin ? 'Sign in with:' : 'Sign up with:'

        return <div className="auth-form-container">
            <input type="text" placeholder="Email" value={email} onChange={(e)=>{this.setState({email: e.target.value})}}/>
            <input type="password" placeholder="Password" value={password} onChange={(e)=>{this.setState({password: e.target.value})}}/>
            {!isLogin && <input type="password" placeholder="Confirm password" value={confirmPassword} onChange={(e)=>{this.setState({confirmPassword: e.target.value})}}/>}

            {isLogin && <a href="http://edocine.azurewebsites.net/Password/Reset">
                <label className="forgot-password">Forgot Password?</label>
            </a>}

            {isLogin && <label className="check-container">
                <Checkbox
                    className='check-input'
                    color="default"
                    checked={rememberMe === true}
                    onChange={(e) => {this.handleRememberme(e.target.checked)}}
                />
                Remember me
            </label>}
            
            {isLogin && <Button className="classic-login-button btn" onClick={() => {this.handleLogin(true)}}>Sign in</Button>}
            {!isLogin && <Button className="classic-signup-button btn" onClick={() => {this.handleSignup()}}>Sign up</Button>}

            <label className="sign-up-with">{signLabel}</label>
            <div className="social-view">
                <button className="social-login-button" onClick={this.handleTwitterLogin}><img src={Images.twitter} alt="Twitter"/></button>
                <FacebookLogin
                    appId={SocialInfo.facebookAppId}
                    clientId="7470ad6e7bb32f12eb37259c2b6e784a"
                    callback={this.responseFacebook}
                    render={renderProps => (
                        <button className="social-login-button" onClick={renderProps.onClick}><img src={Images.facebook} alt="Facebook"/></button>
                    )}
                />
            </div>

            <TwitterLoginDialog
                onClose={this.handleTwitterVerify}
                open={this.state.twitterVerifyOpen}
                isLogin={isLogin}
                userType={userType}
                title="Twitter login"
            />

            <AlertDialog
                title={alertTitle}
                message={alertMessage}
                ok="OK"
                open={alertDlgOpen}
                onClose={this.handleAlert}
            />
        </div>
    }
}