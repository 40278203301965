import React from 'react'
import { Button } from "@material-ui/core"
import { connect } from 'react-redux'

function NavPatientProfile(props) {
    const {currentPatientId} = props
    return (
        <Button
            className="nav-filter-button mr-1"
            href={`/#/dashboard/patient_notes/${currentPatientId}`}>
            Notes
        </Button>
    )
}

function mapStateToProps(state, ownProps) {
    const { note } = state
    const { currentPatientId } = note
    
    return {
        currentPatientId
    }
}
  
export default connect(mapStateToProps)(NavPatientProfile)