import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAILURE,
    UPDATE_PUSH_ID
} from 'redux/actions/auth'

export function auth (
    state = {
        isAuthenticating: false,
        isUpdatingProfile: false,
        isAuthed: false,
        isUpdated: false,
        pushId: null,
        message: null,
        toastMessage: '',
        toastVariant: 'info',
        userProfile: {
            patientUserID: 513,
            userType: 1
        },
    },
    action
) {
    switch (action.type) {
        case LOGIN_REQUEST:
            return Object.assign({}, state, {
                isAuthenticating: true
            })
        case LOGIN_FAILURE:
            return Object.assign({}, state, {
                isAuthenticating: false,
                message: action.message,
                isAuthed: false
            })
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isAuthenticating: false,
                isAuthed: true,
                userProfile: action.userProfile
            })

        case LOGOUT:
            return {
                ...state,
                isAuthed: false,
                userProfile: null
            }

        case UPDATE_PROFILE_REQUEST:
            return Object.assign({}, state, {
                isUpdatingProfile: true,
                isUpdated: false
            })
        case UPDATE_PROFILE_FAILURE:
            return Object.assign({}, state, {
                isUpdatingProfile: false,
                toastMessage: action.message,
                toastVariant: 'error',
                isUpdated: false
            })
        case UPDATE_PROFILE_SUCCESS:
            return Object.assign({}, state, {
                isUpdatingProfile: false,
                userProfile: Object.assign({}, state.userProfile, action.userProfile),
                toastMessage: "Profile is saved.",
                toastVariant: 'info',
                isUpdated: true
            })

        case UPDATE_PUSH_ID:
            return {
                ...state,
                pushId: action.pushId
            }
        default:
            return state
    }
}