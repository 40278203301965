import React from 'react'
import { connect } from 'react-redux'
import { Illnesses, UserType } from 'helper/const'

import { Search } from '@material-ui/icons'
import { Button } from "@material-ui/core"
import { MultiDropDown } from "components"

import * as bookingsAction from 'redux/actions/bookings'

class NavHistory extends React.Component {
    state = {
        searchText: null,
        illness: ''
    }

    handleChangeIllness = async (value) => {
        let prevIllness = this.state.illness || ''

        if (!prevIllness.includes('None') && value.includes('None')) {
            value = 'None'
        } else if (prevIllness.includes('None') && value !== prevIllness) {
            let illnessItems = value.split(",")

            illnessItems = illnessItems.filter(item => item.trim() !== 'None')
            value = illnessItems.join(", ")
        }

        await this.setState({ illness: value })

        this.handleSearch()
    }

    handleChangeText = (searchText) => {
        this.setState({ searchText })
    }

    handleSearch = () => {
        const { searchText, illness } = this.state
        this.props.dispatch(bookingsAction.searchBookings(searchText, illness))
    }

    onKeyUp = (e) => {
        if (e.keyCode === 13) {
            this.handleSearch()
        }
    }

    render() {
        const { illness } = this.state
        const { userType } = this.props

        let description = illness
        if (description.length > 20) {
            description = description.substr(0, 20) + '...'
        }

        return (
            <div>
                <div className="nav-feed-container">
                    <Button
                        className="search-button"
                        onClick={this.handleSearch}>
                        <Search style={{
                            width: '25px',
                            height: '25px'
                        }} className="search-ico" />
                    </Button>

                    <input
                        placeholder="Search"
                        onKeyUp={this.onKeyUp}
                        onChange={(e) => this.handleChangeText(e.target.value)}
                        className='search'
                    />
                </div>

                {userType === UserType.doctor && <div className="nav-feed-container">
                    <MultiDropDown
                        classes={{
                            root: "dropdown-container"
                        }}
                        showValue={description}
                        value={illness}
                        placeholder='Medical illness'
                        options={Illnesses}
                        onChange={(value) => this.handleChangeIllness(value)}
                        containerClass="item"
                        onlyIndex={0}
                        itemClass={!illness ? "item-input placeholder" : 'item-input'}
                        menuItemClass={'item-input menu-item'}
                        IconComponent={<i className="fa fa-angle-down ico-content"/>}
                        disabled={false} />
                </div>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { bookings, auth } = state
    const { userProfile } = auth
    const userType = userProfile.userType

    return {
        bookings,
        userType
    }
}

export default connect(mapStateToProps)(NavHistory)

