import React from 'react'
import { connect } from 'react-redux'
import { Button, IconButton, Grid, ListItem } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import { ItemGrid } from "components"
import { UserType } from 'helper/const'

import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'

import * as noteActions from 'redux/actions/note'

class NoteList extends React.Component {
    constructor(props) {
        super(props)

        const { patientId } = this.props

        this.props.dispatch(noteActions.setCurrentPatient(patientId))
        this.refresh()
    }

    refresh = () => {
        const { userType, patientId } = this.props
        const rowIndex = 0
        this.props.dispatch(noteActions.getNotesAction(userType, patientId, rowIndex))
    }

    fetchData = () => {
        const { userType, patientId, patientNotes } = this.props

        let rowIndex = patientNotes ? patientNotes.rowIndex : 0
        rowIndex += 1

        this.props.dispatch(noteActions.getNotesAction(userType, patientId, rowIndex))
    }

    handleNoteDetail = (patientNote) => {
        this.props.dispatch(noteActions.setCurrentNoteAction(patientNote))
        this.props.history.push('/dashboard/note_detail')
    }

    handleAdd = () => {
        const { doctorUserID } = this.props.userProfile
        const { patientId } = this.props

        this.props.dispatch(noteActions.setCurrentNoteAction({
            doctorUserID,
            patientId,
            noteText: '',
            noteDataURL: ''
        }))

        window.location.href = '/#/dashboard/note_detail'
    }


    render() {
        const { patientNotes, noteStatus } = this.props

        let data = []
        if (patientNotes) {
            data = patientNotes.data
        }

        return (
            <div className="note-list-top-container">
                <div className="horz-container title-view">
                    <div className="title-wrapper">
                        <IconButton
                            className="nav-btn"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => window.history.back()}>
                            <ArrowBack />
                        </IconButton>
                        <div className="title">Notes</div>
                    </div>

                    <Button
                        className="nav-filter-button"
                        onClick={this.handleAdd.bind(this)}
                        style={{
                            marginRight: '10px'
                        }}
                    >
                        Add
                    </Button>
                </div>

                <Grid container className="note-list-container" justify='center'>
                    <ItemGrid xs={12} sm={12} md={12}>
                        {data && data.length > 0 && <InfiniteScroll
                            dataLength={data.length} // This is important field to render the next data
                            next={this.fetchData.bind(this)}
                            hasMore={true}
                            height={'80vh'}
                            loader={<h4 style={{
                                marginLeft: '10px', marginTop: '10px'
                            }}>{noteStatus === 1 ? "Loading..." : "No results found"}</h4>}
                            endMessage={
                                <p style={{textAlign: 'center'}}>
                                    <b>Yay! You have seen it all</b>
                                </p>
                            }
                            // below props only if you need pull down functionality
                            refreshFunction={this.refresh}
                            pullDownToRefresh
                            pullDownToRefreshContent={
                                <h3 style={{textAlign: 'center'}}>&#8595; Pull down to refresh</h3>
                            }
                            releaseToRefreshContent={
                                <h3 style={{textAlign: 'center'}}>&#8593; Release to refresh</h3>
                            }
                        >
                            {data.map((item) => {
                                const {patientNoteID, timeCreated} = item
                                const displayDateTime = moment(timeCreated).format('DD/MM/YYYY HH:mm')
                                return (
                                    <ListItem button className="item" key={patientNoteID} onClick={() => {this.handleNoteDetail(item)}}>
                                        <label className="datetime">{displayDateTime}</label>
                                    </ListItem>
                                )
                            })}
                        </InfiniteScroll>}
                        {(!data || data.length === 0) && <div>No results found.</div>}
                    </ItemGrid>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { auth, note } = state
    const { userProfile } = auth
    const { userType } = userProfile

    let patientId
    if (userType === UserType.patient) {
        patientId = userProfile.patientUserID
    } else {
        patientId = parseInt(ownProps.match.params.patientId, 10)
    }

    const { data: noteData, noteStatus } = note
    const patientNotes = noteData[patientId]
    return {
        userProfile,
        userType,
        patientId,
        patientNotes,
        noteStatus
    }
}
  
export default connect(mapStateToProps)(NoteList)
  