import React from 'react'
import { Illnesses, Languages, Sex } from 'helper/const'
import { DropDown, ItemGrid, MultiDropDown } from "components"
import { Grid } from "@material-ui/core"
import moment from 'moment'
import DatePicker from 'react-datepicker'

const sexArr = [ Sex.none, Sex.male, Sex.female ]

export default class PatientInfo extends React.Component {
    handleChangeText = (key, value) => {
        const userProfile = JSON.parse(JSON.stringify(this.props.userProfile))

        if (key === 'illness') {
            let prevIllness = userProfile['illness'] || ''

            if (!prevIllness.includes('None') && value.includes('None')) {
                value = 'None'
            } else if (prevIllness.includes('None') && value !== prevIllness) {
                let illnessItems = value.split(",")

                illnessItems = illnessItems.filter(item => item.trim() !== 'None')
                value = illnessItems.join(", ")
            }

            userProfile[key] = value

            if (this.props.onChange) {
                this.props.onChange(userProfile)
            }
        } else {
            userProfile[key] = value

            if (this.props.onChange) {
                this.props.onChange(userProfile)
            }
        }
    }


    handleBirthDateChange = (date) => {
        const userProfile = JSON.parse(JSON.stringify(this.props.userProfile))
        userProfile.birthDate = date

        if (this.props.onChange) {
            this.props.onChange(userProfile)
        }
    }


    render() {
        const { containerClass, readOnly } = this.props
        const { userProfile } = this.props

        const { email, username, sex, birthDate, illness, language, facebookId, twitterId, googleId } = userProfile
        const isSocial = facebookId || twitterId || googleId

        const birthDateDesc = birthDate ? moment(birthDate) : null

        return <div className={`user-info ${containerClass}`}>
            <div  className="sub-body">
                <Grid container alignItems="center">
                    <div className="item-input-desc first">Patient Name</div>
                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <input
                            type="text"
                            className="item-input"
                            placeholder='Name'
                            defaultValue={username}
                            onChange={(e) => {this.handleChangeText('username', e.target.value)}}
                            disabled={readOnly}
                        />
                    </ItemGrid>

                    <div className="item-input-desc">Sex</div>
                    {!readOnly && <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <DropDown
                            classes={{
                                root: "dropdown-container"
                            }}
                            value={ sex }
                            options={ sexArr }
                            onChange={ (sex) => {this.handleChangeText('sex', sex)} }
                            containerClass="item"
                            itemClass={!sex || sex === Sex.none ? "item-input placeholder" : 'item-input'}
                            menuItemClass={'item-input menu-item'}
                            IconComponent={<i className="fa fa-angle-down ico-content"/>}
                            disabled={readOnly}
                        />
                    </ItemGrid>}

                    {readOnly && <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <input
                            type="text"
                            className="item-input"
                            placeholder='Sex'
                            defaultValue={sex}
                            disabled={readOnly}
                        />
                    </ItemGrid>}

                    <div className="item-input-desc">Date of Birth</div>
                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <DatePicker
                            dateFormat="DD/MM/YYYY"
                            selected={birthDateDesc}
                            onChange={this.handleBirthDateChange}
                            placeholderText="Date of Birth"
                            className="item-input"
                            showYearDropdown
                            scrollableYearDropdown
                            maxDate={moment()}
                            minDate={moment().subtract(100, "year")}
                            dropdownMode="select"
                            disabled={readOnly}
                        />
                    </ItemGrid>

                    {isSocial && <div className="item-input-desc">Email</div>}
                    {isSocial && <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <input
                            className="item-input"
                            placeholder='Email'
                            defaultValue={email}
                            disabled={readOnly}
                            onChange={(e) => {this.handleChangeText('email', e.target.value)}}/>
                        <div className='ico-wrapper'><i className="far fa-envelope ico-content"/></div>
                    </ItemGrid>}

                    <div className="item-input-desc">Medical illness</div>

                    {!readOnly && <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <MultiDropDown
                            classes={{ root: "dropdown-container" }}
                            value={ illness }
                            placeholder='Medical illness'
                            options={ Illnesses }
                            onChange={ (value) => this.handleChangeText('illness', value) }
                            containerClass="item"
                            onlyIndex={0}
                            itemClass={!illness ? "item-input placeholder" : 'item-input'}
                            menuItemClass={'item-input menu-item'}
                            IconComponent={<i className="fa fa-angle-down ico-content"/>}
                            disabled={readOnly}
                        />
                    </ItemGrid>}

                    {readOnly && <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <input
                            type="text"
                            className="item-input"
                            placeholder='Medical illness'
                            defaultValue={illness ? illness.split(",").join(", ") : ''}
                            disabled={readOnly}
                        />
                    </ItemGrid>}

                    <div className="item-input-desc">Languages spoken</div>

                    {!readOnly && <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <MultiDropDown
                            classes={{root: "dropdown-container"}}
                            value={ language }
                            placeholder='Languages spoken'
                            options={ Languages }
                            onChange={ (value) => {this.handleChangeText('language', value)} }
                            containerClass="item"
                            itemClass={!language ? "item-input placeholder" : 'item-input'}
                            menuItemClass={'item-input menu-item'}
                            IconComponent={<i className="fa fa-angle-down ico-content"/>}
                            disabled={readOnly}
                        />
                    </ItemGrid>}

                    {readOnly && <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <input
                            type="text"
                            className="item-input"
                            placeholder='Languages spoken'
                            defaultValue={language ? language.split(",").join(", ") : ''}
                            disabled={readOnly}
                        />
                    </ItemGrid>}
                </Grid>
            </div>
        </div>
    }
}