import {
    LOAD_EPRESCRIPTION_REQUEST,
    LOAD_EPRESCRIPTION_SUCCESS,
    LOAD_EPRESCRIPTION_FAILURE,

    UPDATE_EPRESCRIPTION_REQUEST,
    UPDATE_EPRESCRIPTION_SUCCESS,
    UPDATE_EPRESCRIPTION_FAILURE,

    LOAD_EPRESCRIPTION_PROVIDERS_REQUEST,
    LOAD_EPRESCRIPTION_PROVIDERS_SUCCESS, 
    LOAD_EPRESCRIPTION_PROVIDERS_FAILURE
} from 'redux/actions/eprescription'



export function eprescription (
    state = {
        status: 0,
        message: null,
        data: [],
    },
    action
) {
    switch (action.type) {
        case LOAD_EPRESCRIPTION_REQUEST:
        case UPDATE_EPRESCRIPTION_REQUEST:
            return {
                ...state,
                status: 1,
                message: null
            }
        case LOAD_EPRESCRIPTION_PROVIDERS_REQUEST:
            return {
                ...state,
                status: 3
            }
        case LOAD_EPRESCRIPTION_FAILURE:
        case UPDATE_EPRESCRIPTION_FAILURE:
            return {
                ...state,
                status: 2,
                message: action.message
            }
        case LOAD_EPRESCRIPTION_PROVIDERS_FAILURE:
            return {
                ...state,
                stats: 4
            }
        case LOAD_EPRESCRIPTION_PROVIDERS_SUCCESS:
            return {
                ...state,
                status: 5,
                serviceProviders: action.data
            }
        case LOAD_EPRESCRIPTION_SUCCESS:
            return {
                ...state,
                status: 0,
                data: action.data
            }
        case UPDATE_EPRESCRIPTION_SUCCESS:
            let newData = []

            if (state.data) {
                newData = JSON.parse(JSON.stringify(state.data))
            }

            if (action.data) {
                newData = newData.filter(item => item.ePrescriptionID !== action.data.ePrescriptionID)
                newData.push(action.data)
            }

            return {
                ...state,
                status: 0,
                data: newData
            }
        default:
            return state
    }
}

