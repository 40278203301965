import React from 'react'
import { connect } from 'react-redux'
import { UserType } from 'helper/const'
import { Grid, Button } from "@material-ui/core"
import { AlertDialog } from 'components'
import { Spinner } from 'components'
import moment from 'moment'

import * as bookingApi from 'api/booking'
import * as notificationApi from 'api/notification'
import * as emailApi from 'api/email'

const uuidv1 = require('uuid/v1')

class BookingConfirm extends React.Component {
    state = {
        showSpinner: false,
        declineNote: '',

        acceptDlgOpen: false,
        acceptMessage: 'Are you sure you want to accept this appointment request?',
        acceptTitle: 'Accept',

        declineDlgOpen: false,
        declineMessage: 'Are you sure you want to decline this appointment request?',
        declineTitle: 'Decline'
    }


    constructor(props) {
        super(props)

        if (!props.historyData) {
            props.history.goBack()
        }
    }


    showAcceptAlert = (title, message) => {
        this.setState(prevState => {
            const acceptTitle = title ? title : prevState.acceptTitle
            const acceptMessage = message ? message : prevState.acceptMessage

            return {
                acceptTitle,
                acceptMessage,
                acceptDlgOpen: true
            }
        })
    }

    showDeclineAlert = (title, message) => {
        this.setState(prevState => {
            const declineTitle = title ? title : prevState.declineTitle
            const declineMessage = message ? message : prevState.declineMessage

            return {
                declineTitle,
                declineMessage,
                declineDlgOpen: true
            }
        })
    }

    handleAccept = () => {
        this.showAcceptAlert()
    }


    handleDecline = () => {
        const { declineNote } = this.state

        if (!declineNote || declineNote.length === 0) {
            alert("Please fill in Reason")
            return
        }

        this.showDeclineAlert()
    }


    showSpinner = (show) => {
        this.setState({ showSpinner: show })
    }


    acceptProcess = async (result) => {
        this.setState({
            acceptDlgOpen: false
        })

        if (result) {
            this.showSpinner(true)

            const { historyData } = this.props
            const id = historyData.patientBookingID
            bookingApi.acceptDeclineBooking(id, 1).then(result => {
                if (result.status === 0) {
                    // Disable timeslot
                    bookingApi.captureDoctorServiceTimeSlot(historyData.booking.doctorServiceID)

                    // Create notification now
                    const doctorProfile = historyData.doctor
                    const doctorName = `${doctorProfile.preFix} ${doctorProfile.firstName} ${doctorProfile.lastName}`
                    const notification = "Doctor Accepted: " + historyData.doctorServiceName
                    const doctorContent = `Doctor Accepted: ${historyData.doctorServiceName} with ${doctorName}`

                    const pushUUID = uuidv1()
                    notificationApi.createNotification({
                        patientId: '' + historyData.patient.patientUserID,
                        content: notification,
                        action: "Accepted",
                        actionParam: '' + historyData.patientBookingID,
                        pushGuid: pushUUID,
                        doctorStartTimeStamp: '' + historyData.booking.doctorStartTimeStamp,
                        doctorEndTimeStamp: '' + historyData.booking.doctorEndTimeStamp,
                        doctorContent: doctorContent
                    }).then(notificationResult => {
                        console.log("Notification Result:", notificationResult)

                        const timezone = `UTC ${parseInt(new Date().getTimezoneOffset() / -60, 10)}`

                        const startDateTime = moment.unix(historyData.booking.doctorStartTimeStamp)
                        const startDate = startDateTime.format('DD/MM/YYYY')
                        const startTime = startDateTime.format('hh:mm a')

                        let msg = `Doctor Accepted: ${historyData.doctorServiceName} with ${doctorName} on ${startDate} at ${startTime} ${timezone}`
                        emailApi.notifyBookingConfirm(historyData.patient.patientUserID, msg, false, '')

                        msg = `${notification} on ${startDate} at ${startTime} ${timezone}`
                        this.sendNotificationToUser(historyData.patient.patientUserID, msg, pushUUID)

                        console.log("Finished sending notifications")
                        this.showSpinner(false)

                        this.props.history.goBack()
                    }).catch(err => {
                        console.error("Sending notification failed:", err)
                        this.showSpinner(false)
                    })
                } else {
                    console.error("Failed to accept:", result)
                    this.showSpinner(false)
                }
            }).catch(err => {
                console.error("Accept booking failed: ", err)
                this.showSpinner(false)
            })
        }
    }

    declineProcess = async (result) => {
        this.setState({
            declineDlgOpen: false
        })

        if (result) {
            this.showSpinner(true)

            const { historyData } = this.props
            const id = historyData.patientBookingID
            bookingApi.acceptDeclineBooking(id, -1, this.state.declineNote).then(result => {
                if (result.status === 0) {
                    // Release timeslot
                    bookingApi.releaseDoctorServiceTimeSlot(historyData.booking.doctorServiceID)

                    // Create notification now
                    const notification = `Doctor Declined: ${historyData.doctorServiceName}. Reason: ${this.state.declineNote}`

                    const pushUUID = uuidv1()
                    notificationApi.createNotification({
                        patientId: '' + historyData.patient.patientUserID,
                        content: notification,
                        pushGuid: pushUUID
                    })

                    const doctorProfile = historyData.doctor
                    const doctorName = `${doctorProfile.preFix} ${doctorProfile.firstName} ${doctorProfile.lastName}`

                    const msg = `${historyData.doctorServiceName} with ${doctorName}`
                    emailApi.notifyBookingConfirm(historyData.patient.patientUserID, msg, true, this.state.declineNote)

                    this.showSpinner(false)
                    this.sendNotificationToUser(historyData.patient.patientUserID, notification, pushUUID)

                    this.props.history.goBack()
                } else {
                    console.error("Failed to decline:", result)
                    this.showSpinner(false)
                }
            }).catch(err => {
                console.error("Accept booking failed: ", err)
                this.showSpinner(false)
            })
        }
    }

    async sendNotificationToUser(userId, message) {
        console.log("Send Notification To User:", userId, message)

        const rstPushUsers = await notificationApi.getPushUsers(userId, UserType.patient)
        if (rstPushUsers.status === 0) {
            let targetUsers = []
            for (let index = 0; index < rstPushUsers.data.length; index++) {
                const element = rstPushUsers.data[index];
                if (element.pushId && element.isLogged) {
                    targetUsers.push(element.pushId)
                }
            }

            if (targetUsers.length > 0) {
                notificationApi.sendPush(targetUsers, message)
            }
        }
    }

    changeDeclineReason = (event) => {
        const value = event.target.value
        this.setState({ declineNote: value })
    }


    render() {
        const { historyData, userType } = this.props
        const { declineNote } = this.state

        if (!historyData) {
            return <div>Error with history data. Please try again later.</div>
        }

        const { doctorServiceName, patientNote, booking, doctor, patient } = historyData
        console.log("HistoryData:", historyData)

        const startDateTime = moment.unix(booking.doctorStartTimeStamp)
        const startDate = startDateTime.format('DD/MM/YYYY')
        const startTime = startDateTime.format('hh:mm a')

        let content
        if (userType === UserType.patient) {
            if (!doctor) {
                return <div>Error with doctor data</div>
            }

            const doctorName = `${doctor.preFix} ${doctor.firstName} ${doctor.lastName}`
            content = `${doctorServiceName} with ${doctorName} on ${startDate} at ${startTime}`
        } else {
            if (!patient) {
                return <div>Error with patient data</div>
            }

            content = `${doctorServiceName} with ${patient.username} on ${startDate} at ${startTime}`
        }

        return (
            <div className="booking-cancel-start-container">
                <div className="title">Confirmation</div>

                <Grid container  direction='column'>
                    <label className="caption">{content}</label>
                    <label className="caption">Patient Note:</label>
                    <label className="caption">{patientNote}</label>
                    <Button className="btn" onClick={this.handleAccept}>Accept</Button>
                    <Button className="btn" onClick={this.handleDecline}>Decline</Button>
                    <label className="caption">Decline Reason:</label>
                    <textarea className="content reason" value={declineNote} onChange={this.changeDeclineReason}/>
                </Grid>

                <AlertDialog
                    title={this.state.acceptTitle}
                    message={this.state.acceptMessage}
                    ok="Accept"
                    cancel="Cancel"
                    open={this.state.acceptDlgOpen}
                    onClose={this.acceptProcess}
                />

                <AlertDialog
                    title={this.state.declineTitle}
                    message={this.state.declineMessage}
                    ok="Decline"
                    cancel="Cancel"
                    open={this.state.declineDlgOpen}
                    onClose={this.declineProcess}
                />

                <Spinner show={this.state.showSpinner} />
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { auth, bookings } = state
    const { userProfile } = auth
    const userType = userProfile.userType
    const userId = userType === UserType.patient ? userProfile.patientUserID : userProfile.doctorUserID
    const { data } = bookings

    let historyData
    if (data && ownProps.match.params) {
        let {patientBookingID} = ownProps.match.params
        patientBookingID = parseInt(patientBookingID, 10)
        historyData = data.find(x => parseInt(x.patientBookingID, 10) === patientBookingID)
    }

    return {
        userId,
        userType,
        historyData
    }
}

export default connect(mapStateToProps)(BookingConfirm)

