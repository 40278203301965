import React from 'react'
import { connect } from 'react-redux'
import { SignatureDialog, DropDown, ItemGrid } from "components"
import { Sex, ImageType, eServiceType, UserType } from 'helper/const'
import { compressBase64 } from 'helper/global'
import { Grid, Button } from "@material-ui/core";
import { Spinner } from 'components'

import moment from 'moment'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

import * as ePrescriptionAction from 'redux/actions/eprescription'
import * as imageApi from 'api/image'
import * as emailApi from 'api/email'

const sexArr = [
    Sex.none,
    Sex.male,
    Sex.female
]

class EPrescription extends React.Component {
    state = this.getInitialState()
    imageBase64 = null
    baseParams = null

    getInitialState() {
        return {
            sentRequest: false,
            email: '',
            patientName: '',
            patientEmail: '',
            patientAddress: '',
            patientAge: '',
            sex: '',
            doctorName: '',
            telephone: '',
            registrationNumber: '',
            date: moment(),
            information: '',
            serviceProviderID: 0,
            signature: '',
            photoSize: null,
            signatureDlgOpen: false,
            error: {}
        }
    }


    componentDidMount() {
        const { dispatch } = this.props

        // Load Prescriptions By Type. Prescription is 1
        dispatch(ePrescriptionAction.loadPrescriptionProvidersAction())
    }


    componentWillReceiveProps(nextProps) {
        const { eprescription } = nextProps

        if (eprescription.status === 1) {
            // Sent request
            this.setState({ sentRequest: true })
        } else if (this.state.sentRequest) {
            if (eprescription.status === 0) {
                this.setState(this.getInitialState())

                // Send email now
                const newParams = Object.assign(this.baseParams, {
                    userType: '' + this.props.userType,
                    userId: '' + this.props.userId,
                    providerId: '' + this.state.serviceProviderID
                })
                delete newParams.error

                if (this.imageBase64) {
                    console.log("Base64 Image Length:", this.imageBase64.length)
                } else {
                    console.log("No image selected")
                }

                emailApi.notifyDoctorEService(eServiceType.Prescription, this.imageBase64, newParams).then(rst => {
                    console.log("Finished sending email:", rst)
                    this.clearBase64Image()
                }).catch(err => console.error("Failed to send doctor eservice email.", err))

                alert("Sent successfully!")
            } else if (eprescription.status === 2) {
                this.setState({ sentRequest: false })
                console.error("Failed to send prescription information:", eprescription.message)
                alert("Failed to send prescription data")
            }
        }
    }

    clearBase64Image = () => {
        this.imageBase64 = null
    }

    handleSubmit = async () => {
        // Validate fields
        let fields = [
            "patientName",
            "patientEmail",
            "patientAddress",
            "patientAge",
            "sex",
            "doctorName",
            "telephone",
            "registrationNumber",
            "date",
            "information"
        ]

        if (!this.isDoctor()) {
            fields = [
                "patientName",
                "patientAddress",
                "patientAge",
                "sex",
                "telephone",
                "date",
                "information"
            ]
        }

        const params = JSON.parse(JSON.stringify(this.state))

        const error = {}
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i]
            const value = params[field]

            if (!value || (typeof value === 'string' && value.trim().length === 0)) {
                error[field] = 'Please input missing field'
            }
        }

        if (!params.email || params.email.length === 0) {
            const { eprescription } = this.props
            const { serviceProviders } = eprescription

            if (serviceProviders && serviceProviders.length > 0) {
                params.email = serviceProviders[0].email

                this.setState({
                    serviceProviderID: serviceProviders[0].servicesProviderID
                })
            }
        }

        if (params.signature) {
            const splitData = params.signature.split(",")
            if (splitData.length === 2) {
                const uploadData = splitData[1]

                // Upload images
                let rstImg = await imageApi.uploadImage(ImageType.Labs, compressBase64(uploadData))
                if (rstImg.status === 0) {
                    console.log("Upload image succeeded:", rstImg.data)
                    params.signature = rstImg.data
                } else {
                    console.log("Failed to upload attach image.")
                    return
                }
            }
        }

        if (Object.keys(error).length === 0) {
            // No errors
            const { date } = this.state
            const dateStr = date.format("DD/MM/YYYY")
            params.date = dateStr

            delete params.serviceProviderID
            delete params.photoSize
            delete params.signatureDlgOpen
            delete params.sentRequest

            this.baseParams = params

            const { dispatch } = this.props
            dispatch(ePrescriptionAction.updatePrescriptionAction(params))
        } else {
            this.setState({ error })
        }
    }


    handleChangeText = (field, value) => {
        if (field === 'serviceProvider') {
            const { eprescription } = this.props
            const { serviceProviders } = eprescription
            const selectedProvider = serviceProviders.find(item => item.name === value)

            this.setState({
                serviceProviderID: selectedProvider.servicesProviderID,
                email: selectedProvider.email
            })
        } else {
            this.setState({
                [field]: value
            })
        }
    }

    handleDateChange = (field, date) => {
        let newState = this.state
        newState[field] = date

        this.setState(newState)
    }


    getErrorClass(field) {
        let result = ' '

        if (this.state.error && this.state.error[field]) {
            result += 'error'
        }

        return result
    }


    onPickFile = (key, imgFile) => {
        if (imgFile) {
            let reader = new FileReader()
            reader.onload = (e) => {
                const fileContentsBase64 = e.target.result
                this.imageBase64 = fileContentsBase64.split(",")[1]
                const photoSize = parseInt(fileContentsBase64.length * 3 / 4, 10)   // Bytes

                const newState = this.state
                newState[key] = fileContentsBase64
                newState.photoSize = this.getFileSizeDesc(photoSize)

                this.setState(newState)
            }
            reader.readAsDataURL(imgFile)
        }
    }

    getFileSizeDesc = (size) => {
        let result = parseInt(size, 10)

        if (isNaN(result)) {
            return size
        }

        if (result < 1024) {
            result = result + " Bytes"
        } else if (result < 1024 * 1024) {
            result = result / 1024  // KB
            result = parseInt(result * 10, 10) / 10

            result = result + " KB"
        } else {
            result = result / 1024 / 1024  // KB
            result = parseInt(result * 10, 10) / 10

            result = result + " MB"
        }

        return result
    }


    isDoctor = () => {
        const { userType } = this.props

        if (userType === UserType.doctor) {
            return true
        }

        return false
    }


    handleSignature = () => {
        this.setState({ signatureDlgOpen: true })
    }

    handleSignatureCancel = () => {
        this.setState({ signatureDlgOpen: false })
    }

    handleSignatureFinished = (signatureBase64) => {
        this.imageBase64 = signatureBase64.split(",")[1]
        this.setState({
            signature: signatureBase64,
            signatureDlgOpen: false
        })
    }


    render() {
        const {
            patientName,
            patientEmail,
            patientAddress,
            patientAge,
            sex,
            doctorName,
            telephone,
            registrationNumber,
            date,
            information,
            serviceProviderID = 0,
            photoSize,
            signature
        } = this.state

        const { eprescription } = this.props
        const serviceProviders = eprescription ? (eprescription.serviceProviders || []) : []

        const selectedProvider = serviceProviders.find(item => item.servicesProviderID === serviceProviderID)
        let selectedProviderName = ''
        if (selectedProvider) {
            selectedProviderName = selectedProvider.name
        } else if (serviceProviders.length > 0) {
            selectedProviderName = serviceProviders[0].name
        }


        let signatureTitle = 'Signature'
        if (this.isDoctor() && signature && signature.length > 0) {
            signatureTitle = 'Signature (Selected)'
        }

        return (
            <div className="eservice-container">
                <div className="title">e-Prescription</div>

                <Grid container alignItems="center">
                    <div className="item-input-desc">Send request to:</div>
                    <ItemGrid xs={12} classes={{ grid: 'relative-container item-container' }}>
                        <DropDown
                            classes={{
                                root: "dropdown-container"
                            }}
                            value={selectedProviderName}
                            options={serviceProviders.map(item => item.name)}
                            onChange={(providerName) => { this.handleChangeText('serviceProvider', providerName) }}
                            containerClass="item"
                            itemClass={'item-input'}
                            menuItemClass={'item-input menu-item'}
                            IconComponent={<i className="fa fa-angle-down ico-content" />}
                        />
                    </ItemGrid>

                    <div className="item-input-desc first">Patient Name</div>
                    <ItemGrid xs={12} classes={{ grid: 'relative-container item-container' }}>
                        <input
                            type="text"
                            className={"item-input" + this.getErrorClass('patientName')}
                            // placeholder='Patient Name'
                            value={patientName}
                            onChange={(e) => this.handleChangeText('patientName', e.target.value)}
                        />
                    </ItemGrid>

                    {this.isDoctor() && <div className="item-input-desc first">Patient Email</div>}
                    {this.isDoctor() && <ItemGrid xs={12} classes={{ grid: 'relative-container item-container' }}>
                        <input
                            type="text"
                            className={"item-input" + this.getErrorClass('patientEmail')}
                            value={patientEmail}
                            onChange={(e) => this.handleChangeText('patientEmail', e.target.value)}
                        />
                    </ItemGrid>}

                    <div className="item-input-desc">Patient Address</div>
                    <ItemGrid xs={12} classes={{ grid: 'relative-container item-container' }}>
                        <input
                            type="text"
                            className={"item-input" + this.getErrorClass('patientAddress')}
                            // placeholder='Patient Address'
                            value={patientAddress}
                            onChange={(e) => this.handleChangeText('patientAddress', e.target.value)}
                        />
                    </ItemGrid>

                    <div className="item-input-desc">Patient Age</div>
                    <ItemGrid xs={12} classes={{ grid: 'relative-container item-container' }}>
                        <input
                            type="text"
                            className={"item-input" + this.getErrorClass('patientAge')}
                            // placeholder='Patient Age'
                            value={patientAge}
                            onChange={(e) => this.handleChangeText('patientAge', e.target.value)}
                        />
                    </ItemGrid>

                    <div className="item-input-desc">Sex</div>
                    <ItemGrid xs={12} classes={{ grid: 'relative-container item-container' }}>
                        <DropDown
                            classes={{
                                root: "dropdown-container"
                            }}
                            value={sex}
                            options={sexArr}
                            onChange={(sex) => { this.handleChangeText('sex', sex) }}
                            containerClass="item"
                            itemClass={(!sex || sex === Sex.none ? "item-input placeholder" : 'item-input') + this.getErrorClass('sex')}
                            menuItemClass={'item-input menu-item'}
                            IconComponent={<i className="fa fa-angle-down ico-content" />}
                        />
                    </ItemGrid>

                    {this.isDoctor() && <div className="item-input-desc">Doctor Name</div>}
                    {this.isDoctor() && <ItemGrid xs={12} classes={{ grid: 'relative-container item-container' }}>
                        <input
                            type="text"
                            className={"item-input" + this.getErrorClass('doctorName')}
                            // placeholder='Doctor Name'
                            value={doctorName}
                            onChange={(e) => this.handleChangeText('doctorName', e.target.value)}
                        />
                    </ItemGrid>}

                    <div className="item-input-desc">Telephone</div>
                    <ItemGrid xs={12} classes={{ grid: 'relative-container item-container' }}>
                        <input
                            type="text"
                            className={"item-input" + this.getErrorClass('telephone')}
                            // placeholder='Telephone'
                            value={telephone}
                            onChange={(e) => this.handleChangeText('telephone', e.target.value)}
                        />
                    </ItemGrid>

                    {this.isDoctor() && <div className="item-input-desc">Registration Number</div>}
                    {this.isDoctor() && <ItemGrid xs={12} classes={{ grid: 'relative-container item-container' }}>
                        <input
                            type="text"
                            className={"item-input" + this.getErrorClass('registrationNumber')}
                            // placeholder='Registration Number'
                            value={registrationNumber}
                            onChange={(e) => this.handleChangeText('registrationNumber', e.target.value)}
                        />
                    </ItemGrid>}

                    <div className="item-input-desc">Date</div>
                    <ItemGrid xs={12} classes={{ grid: 'relative-container item-container' }}>
                        <DatePicker
                            dateFormat="DD/MM/YYYY"
                            selected={date || null}
                            onChange={(value) => this.handleDateChange('date', value)}
                            // placeholderText="Date of Birth"
                            className={"item-input" + this.getErrorClass('date')}
                            showYearDropdown
                            scrollableYearDropdown
                            maxDate={moment()}
                            minDate={moment().subtract(100, "year")}
                            dropdownMode="select"
                        />
                    </ItemGrid>

                    <div className="item-input-desc">Prescription Information</div>
                    <ItemGrid xs={12} classes={{ grid: 'relative-container item-container' }}>
                        <textarea
                            type="text"
                            className={"item-input text-area" + this.getErrorClass('information')}
                            // placeholder='Prescription Information'
                            value={information}
                            onChange={(e) => this.handleChangeText('information', e.target.value)}
                        />
                    </ItemGrid>

                    {!this.isDoctor() && <ItemGrid xs={12} classes={{ grid: 'relative-container item-container' }}>
                        <div className="file-input-wrapper">
                            <Button className="picker-button">
                                <label className='item-input placeholder'>{photoSize ? "Attach a new file" : "Attach a file"}</label>
                                {photoSize && <label className="photo-size">{photoSize}</label>}
                            </Button>
                            <input href="fileInput" type="file"
                                onChange={(event) => {
                                    const files = event.target.files
                                    if (files.length > 0) {
                                        this.onPickFile('signature', files[0])
                                    }
                                }} />
                        </div>
                    </ItemGrid>}


                    {this.isDoctor() && <ItemGrid xs={12} classes={{ grid: 'relative-container item-container' }}>
                        <Button
                            variant="text"
                            color="primary"
                            className="save-button patient"
                            onClick={this.handleSignature}>{signatureTitle}</Button>
                    </ItemGrid>}
                </Grid>

                <Button
                    variant="text"
                    color="primary"
                    className="save-button patient"
                    onClick={this.handleSubmit}>Send</Button>

                <SignatureDialog
                    classes={{ paper: "picker-dialog-paper" }}
                    open={this.state.signatureDlgOpen}
                    signature={signature}
                    onCancel={() => this.handleSignatureCancel()}
                    onOK={(base64) => this.handleSignatureFinished(base64)} />


                <Spinner show={this.state.sentRequest === true} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth } = state
    const { userProfile } = auth

    const userType = userProfile.userType
    const userId = userType === UserType.patient ? userProfile.patientUserID : userProfile.doctorUserID

    const eprescription = state.eprescription

    return {
        eprescription,
        user: userProfile,
        userType,
        userId
    }
}

export default connect(mapStateToProps)(EPrescription)
