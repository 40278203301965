import apiRequest, {apiRestRequest} from './apiRequest'
import {
    UserType,
} from 'helper/const'

/**
 * Get patient notes.
 * Different according to current user type
 */
export function getPatientNotes(userType, patientId, rowIndex, rows) {
    if (userType === UserType.doctor) {
        return apiRequest('patientNotesGetForDoctor', {patient: patientId, rowIndex, rows})
    } else {
        return apiRequest('patientNotes', {doctor: 0, patient: patientId, rowIndex, rows})
    }
}

export function createPatientNote(noteText, noteDataURL, patientId, doctorId) {
    return apiRestRequest('patientNotes', 'post', {noteText, noteDataURL, patient: patientId, doctor: doctorId})
}

export function updatePatientNote(patientNoteId, noteText, noteDataURL) {
    var params = {}
    if (noteDataURL) {
        params.noteDataURL = noteDataURL
    }
    if (noteText) {
        params.noteText = noteText
    }
    return apiRestRequest('patientNotes', 'put', params, patientNoteId)
}
