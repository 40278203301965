import {
    GET_SERVICES_REQUEST,
    GET_SERVICES_SUCCESS,
    GET_SERVICES_FAILURE,

    FILTER_SERVICES_REQUEST,
    FILTER_SERVICES_SUCCESS,
    FILTER_SERVICES_FAILURE
} from 'redux/actions/services'

export function services (
    state = {
        isGettingServices: false,
        message: null,
        all: [],
        filtered: []
    },
    action
) {
    switch (action.type) {
        case GET_SERVICES_REQUEST:
            return Object.assign({}, state, {
                isGettingServices: true
            })
        case GET_SERVICES_FAILURE:
            return Object.assign({}, state, {
                isGettingServices: false,
                message: action.message
            })
        case GET_SERVICES_SUCCESS:
            return Object.assign({}, state, {
                isGettingServices: false,
                all: action.services,
                message: null
            })
        case FILTER_SERVICES_REQUEST:
            return {
                ...state,
                isGettingServices: true
            }
        case FILTER_SERVICES_SUCCESS:
            return {
                ...state,
                isGettingServices: false,
                filtered: action.services,
                message: null
            }
        case FILTER_SERVICES_FAILURE:
            return {
                ...state,
                isGettingServices: false,
                message: action.message
            }
        default:
            return state
    }
}