import {
    LOAD_ERADIOLOGIES_REQUEST,
    LOAD_ERADIOLOGIES_SUCCESS,
    LOAD_ERADIOLOGIES_FAILURE,

    UPDATE_RADIOLOGY_REQUEST,
    UPDATE_RADIOLOGY_SUCCESS,
    UPDATE_RADIOLOGY_FAILURE,

    LOAD_RADIOLOGY_TESTS_REQUEST,
    LOAD_RADIOLOGY_TESTS_SUCCESS,
    LOAD_RADIOLOGY_TESTS_FAILURE,

    LOAD_RADIOLOGY_PROVIDERS_REQUEST,
    LOAD_RADIOLOGY_PROVIDERS_SUCCESS,
    LOAD_RADIOLOGY_PROVIDERS_FAILURE
} from 'redux/actions/eradiologies'



export function eradiology (
    state = {
        status: 0,
        message: null,
        data: [],
    },
    action
) {
    switch (action.type) {
        case LOAD_ERADIOLOGIES_REQUEST:
        case UPDATE_RADIOLOGY_REQUEST:
            return {
                ...state,
                status: 1,
                message: null
            }
        case LOAD_RADIOLOGY_PROVIDERS_REQUEST:
            return {
                ...state,
                status: 6,
                message: null
            }
        case LOAD_RADIOLOGY_TESTS_REQUEST:
            return {
                ...state,
                status: 3,
                message: null
            }
        case LOAD_ERADIOLOGIES_FAILURE:
        case UPDATE_RADIOLOGY_FAILURE:
            return {
                ...state,
                status: 2,
                message: action.message
            }
        case LOAD_RADIOLOGY_PROVIDERS_FAILURE:
            return {
                ...state,
                status: 7,
                message: action.message
            }
        case LOAD_RADIOLOGY_TESTS_FAILURE:
            return {
                ...state,
                status: 4,
                message: action.message
            }
        case LOAD_ERADIOLOGIES_SUCCESS:
            return {
                ...state,
                status: 0,
                data: action.data
            }
        case LOAD_RADIOLOGY_PROVIDERS_SUCCESS:
            return {
                ...state,
                status: 8,
                serviceProviders: action.data
            }
        case LOAD_RADIOLOGY_TESTS_SUCCESS:
            return {
                ...state,
                status: 5,
                tests: action.data
            }
        case UPDATE_RADIOLOGY_SUCCESS:
            let newData = []

            if (state.data) {
                newData = JSON.parse(JSON.stringify(state.data))
            }

            if (action.data) {
                newData = newData.filter(item => item.eRadiologyID !== action.data.eRadiologyID)
                newData.push(action.data)
            }

            return {
                ...state,
                status: 0,
                data: newData
            }
        default:
            return state
    }
}

