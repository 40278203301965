import {
    GET_SCHEDULE_REQUEST,
    GET_SCHEDULE_SUCCESS,
    GET_SCHEDULE_FAILURE,

    ADD_SCHEDULE_REQUEST,
    ADD_SCHEDULE_SUCCESS,
    ADD_SCHEDULE_FAILURE,

    DELETE_SCHEDULE_REQUEST,
    DELETE_SCHEDULE_SUCCESS,
    DELETE_SCHEDULE_FAILURE,

    CLEAR_SCHEDULE_REQUEST,
    CLEAR_SCHEDULE_SUCCESS,
    CLEAR_SCHEDULE_FAILURE
} from 'redux/actions/schedule'

export function schedule (
    state = {
        scheduleStatus: 0,
        message: null,
        schedules: {}
    },
    action
) {
    switch (action.type) {
        case DELETE_SCHEDULE_REQUEST:
        case CLEAR_SCHEDULE_REQUEST:
        case ADD_SCHEDULE_REQUEST:
        case GET_SCHEDULE_REQUEST:
            return {
                ...state,
                scheduleStatus: 1,
                message: null
            }
        case ADD_SCHEDULE_SUCCESS:
        case GET_SCHEDULE_SUCCESS:
            var schedules = {...state.schedules}
            schedules[action.sku] = action.schedule
            return {
                ...state,
                schedules,
                scheduleStatus: 0,
                message: null
            }
        case CLEAR_SCHEDULE_SUCCESS:
            return {
                ...state,
                scheduleStatus: 5
            }
        case DELETE_SCHEDULE_FAILURE:
        case ADD_SCHEDULE_FAILURE:
        case GET_SCHEDULE_FAILURE:
            return {
                ...state,
                scheduleStatus: 2,
                message: action.message
            }
        case CLEAR_SCHEDULE_FAILURE:
            return {
                ...state,
                scheduleStatus: 6
            }
        case DELETE_SCHEDULE_SUCCESS:
            schedules = {...state.schedules}
            const data = schedules[action.sku]
            const index = data.findIndex(x => x.doctorServiceID === action.doctorServiceID)
            if (index >= 0) {
                data.splice(index, 1)
            }
            return {
                ...state,
                scheduleStatus: 0,
                schedules,
                message: null
            }
        default:
            return state
    }
}