import React from 'react'
import { Button } from "@material-ui/core"
import { UserType, ImageType } from 'helper/const'
import * as imageApi from 'api/image'
import * as feedsAction from 'redux/actions/feeds'
import * as global from 'helper/global'
import * as questionApi from 'api/question'
import * as notificationApi from 'api/notification'
import * as emailApi from 'api/email'
import { connect } from 'react-redux'
import { AlertDialog, AnswerDialog } from 'components'
const uuidv1 = require('uuid/v1')

class QuestionDetail extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            answerDlgOpen: false,
            alertDlgOpen: false,
            alertMessage: '',
            alertTitle: 'Answer a question'
        }

        const {userType} = props
        if (!props.location.state || userType !== UserType.doctor) {
            props.history.push({
                pathname: '/dashboard'
            })
        } else {
            const {question} = props.location.state
            this.state.question = question
        }
    }

    handleReply = () => {
        this.setState({
            answerDlgOpen: true
        })
    }

    handleAlert = () => {
        this.setState({
            alertDlgOpen: false
        })
    }

    answerQuestion = async (answer) => {
        const { question } = this.state
        const { userId } = this.props
        const { photo, value } = answer

        let data = {
            feedId: question.feedID,
            doctorId: userId,
            body: value
        }

        if (photo) {
            const purePhoto = photo.split(",")[1];
            const base64Photo = global.compressBase64(purePhoto)
            const rstImg = await imageApi.uploadImage(ImageType.Article, base64Photo)

            console.log("Image upload: ", rstImg)

            if (rstImg.status === 0) {
                const imgUrl = rstImg.data
                data["imageURL"] = imgUrl
            }
        }

        const rstReply = await questionApi.createFeedReply(data)

        if (rstReply.status === 0) {
            const rstFeed = await questionApi.updateFeed(question.feedID, {
                isReplied: "1"
            })

            if (rstFeed.status === 0) {
                const notification = `Question "${question.title}" was answered.`
                notificationApi.createNotification({
                    patientId: question.patientId,
                    content: notification,
                    action: "Answer",
                    actionParam: question.feedID,
                    PushGuid: uuidv1()
                })

                const rstPushUsers = await notificationApi.getPushUsers(question.patientId, UserType.patient)
                if (rstPushUsers.status === 0) {
                    let targetUsers = []
                    for (let index = 0; index < rstPushUsers.data.length; index++) {
                        const element = rstPushUsers.data[index];
                        if (element.pushId && element.isLogged) {
                            targetUsers.push(element.pushId)
                        }
                    }
                    if (targetUsers.length > 0) {
                        notificationApi.sendPush(targetUsers, notification)
                    }
                }

                // Send email
                emailApi.notifyQuestionAnswered(question.title, value, question.feedID, question.patientId, userId)

                this.props.dispatch(feedsAction.feedNeedRefreshAction())
                this.props.history.replace('/#/dashboard/feed')
            } else {
                this.setState({
                    alertMessage: "Failed to update feed status",
                    alertDlgOpen: true
                })    
            }
        } else {
            this.setState({
                alertMessage: "Failed to reply",
                alertDlgOpen: true
            })
        }
    }


    handleChange = (value) => {
        this.setState({
            value
        })
    }


    handleAnswer = async (rst) => {
        this.setState({
            answerDlgOpen: false
        })

        // Check if can send answer
        if (rst !== false) {
            if (!rst) {
                this.setState({
                    alertMessage: 'Answer is required.',
                    alertDlgOpen: true
                })
                return
            }

            const { question } = this.state
            const { feedID } = question
            const { userId } = this.props
            const rstAnswerCheck = await questionApi.isQuestionReplied(feedID, userId)

            if (rstAnswerCheck.status === 0) {
                const replyCount = rstAnswerCheck.data
                if (replyCount > 0) {
                    this.setState({
                        alertMessage: 'This question has been answered by another doctor, you cannot answer it again.',
                        alertDlgOpen: true
                    })
                } else {
                    // answer a question
                    this.answerQuestion(rst)
                }
            } else {
                this.setState({
                    alertMessage: 'Failed to check if already replied or not. Please check your network status.',
                    alertDlgOpen: true
                })
            }
        }
    }

    
    render() {
        const { question, alertDlgOpen, alertTitle, alertMessage } = this.state
        if (!question) {
            return null
        }

        const { title, body, timeCreated } = question
        let diffTime

        if (timeCreated) {
            diffTime = global.diffTime(new Date(timeCreated), new Date())
        } else {
            diffTime = 'A few minutes ago'
        }


        return (
            <div className='question-detail-container'>
                <label className='title'>Answer</label>
                <label className='question-title'>{title}</label>
                <label className='when'>{diffTime}</label>
                <label className='body'>{body}</label>
                <Button className='update-button full-width doctor' onClick={this.handleReply}>Reply</Button>

                <AnswerDialog
                    classes={{paper: "picker-dialog-paper"}}
                    open={this.state.answerDlgOpen}
                    onClose={this.handleAnswer}
                />

                <AlertDialog
                    title={alertTitle}
                    message={alertMessage}
                    ok="OK"
                    open={alertDlgOpen}
                    onClose={this.handleAlert}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth } = state
    const { userProfile } = auth

    let userType = UserType.unknown
    let userId = 0

    if (userProfile) {
        userType = userProfile.userType
        if (userType === UserType.patient) {
            userId = userProfile.patientUserID
        }

        if (userType === UserType.doctor) {
            userId = userProfile.doctorUserID
        }
    }

    return { userType, userId }
}

export default connect(mapStateToProps)(QuestionDetail)