// ##############################
// // // Sidebar styles
// #############################

import {
    drawerWidth,
    transition,
    boxShadow,
    defaultFont,
    primaryColor,
    primaryBoxShadow,
    infoColor,
    successColor,
    warningColor,
    dangerColor
} from "assets/jss/material-dashboard-react.jsx";

const sidebarStyle = theme => ({
    drawerPaper: {
        border: "none",
        position: "static",
        top: "0",
        bottom: "0",
        left: "0",
        zIndex: "1",

        // overflow: 'auto',
        ...boxShadow,
        width: drawerWidth,
        [theme.breakpoints.up("md")]: {
            width: drawerWidth,
            position: "static",
            height: "100%"
        },
        [theme.breakpoints.down("sm")]: {
            width: drawerWidth,
            ...boxShadow,
            position: "static",
            display: "block",
            top: "0",
            height: "100vh",
            right: "auto",
            left: "0",
            zIndex: "1032",
            visibility: "visible",
            overflowY: "visible",
            borderTop: "none",
            textAlign: "left",
            paddingRight: "0px",
            paddingLeft: "0",
            transform: `translate3d(${drawerWidth}px, 0, 0)`,
            ...transition
        }
    },
    logo: {
        position: "relative",
        padding: "15px 15px",
        zIndex: "4",
        fontSize: "16pt",
        fontWeight: 800,
        display: 'flex',
        alignItems: 'center',
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: "0",

            height: "1px",
            right: "15px",
            width: "calc(100% - 30px)",
            backgroundColor: "rgba(180, 180, 180, 0.3)"
        }
    },
    logoLink: {
        ...defaultFont,
        padding: "5px 0",
        display: "block",
        fontSize: "18px",
        textAlign: "left",
        fontWeight: "400",
        lineHeight: "30px",
        backgroundColor: "transparent",
        "&,&:hover": {
            color: "#FFFFFF",
            textDecoration: "none !important",
        }
    },
    logoImage: {
        width: "30px",
        height: "auto",
        display: "inline-block",
        marginTop: "-2px",
        marginLeft: "10px",
        marginRight: "15px"
    },
    img: {
        width: "100%",
        top: "22px",
        verticalAlign: "middle",
        border: "0"
    },
    background: {
        position: "absolute",
        zIndex: "1",
        height: "100%",
        width: "100%",
        display: "block",
        top: "0",
        left: "0",
        backgroundSize: "cover",
        backgroundPosition: "center center",

        "&:after": {
            position: "absolute",
            zIndex: "3",
            width: "100%",
            height: "100%",
            content: '""',
            display: "block",
            background: "#000",
            opacity: ".8"
        }
    },
    list: {
        paddingLeft: "0",
        paddingTop: "0",
        paddingBottom: "0",
        marginBottom: "1rem",
        listStyle: "none"
    },
    item: {
        position: "relative",
        display: "block",
        textDecoration: "none",
        "&:hover": {
            textDecoration: 'none'
        }
    },
    itemLink: {
        width: 'auto',
        transition: "all 300ms linear",
        margin: "10px 15px 0",
        borderRadius: "3px",
        position: "relative",
        display: "flex",
        padding: "10px 15px",
        backgroundColor: "transparent",
        textDecoration: "none",
        ...defaultFont,
    },
    itemIcon: {
        width: "30px",
        height: "30px",
        float: "left",
        marginRight: "15px",
        textAlign: "center",
        verticalAlign: "middle",
        color: "rgba(255, 255, 255, 0.8)",
    },
    itemIconImg: {
        width: '18px',
        height: '18px',
        fill: '#888888',
        objectFit: 'cover',
        margin: '0px'
    },
    itemIconImgMobile: {
        width: '30px',
        height: '30px',
        fill: '#888888',
        objectFit: 'cover',
        margin: '0px'
    },
    itemIconImgActive: {
        width: '18px',
        height: 'auto',
        fill: '#37c977'
    },
    itemText: {
        ...defaultFont,
        margin: "0",
        lineHeight: "30px",
        fontSize: "14px",
        fontWeight: 500,
        color: "#888888"
    },
    itemTextUserMobile: {
        ...defaultFont,
        margin: "0",
        lineHeight: "30px",
        fontSize: "20px",
        fontWeight: 500,
        color: "#888888"
    },
    whiteFont: {
        color: "#FFFFFF"
    },
    purple: {
        backgroundColor: primaryColor,
        ...primaryBoxShadow,
        "&:hover": {
            backgroundColor: primaryColor,
            ...primaryBoxShadow
        }
    },
    blue: {
        backgroundColor: infoColor,
        boxShadow: "0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)",
        "&:hover": {
            backgroundColor: infoColor,
            boxShadow: "0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)"
        }
    },
    green: {
        backgroundColor: successColor,
        boxShadow: "0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)",
        "&:hover": {
            backgroundColor: successColor,
            boxShadow: "0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)"
        }
    },
    orange: {
        backgroundColor: warningColor,
        boxShadow: "0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)",
        "&:hover": {
            backgroundColor: warningColor,
            boxShadow:
            "0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)"
        }
    },
    red: {
        backgroundColor: dangerColor,
        boxShadow: "0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)",
        "&:hover": {
            backgroundColor: dangerColor,
            boxShadow: "0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)"
        }
    },
    sidebarWrapper: {
        position: "relative",
        height: "calc(100vh - 75px)",
        overflow: "auto",
        width: "300px",
        zIndex: "4",
        overflowScrolling: 'touch'
    },
    '@media (max-width: 768px)': {
        drawerPaper: {
            position: 'fixed',
            [theme.breakpoints.up("md")]: {
                position: "fixed",
            },
            [theme.breakpoints.down("sm")]: {
                position: "fixed",
            }
        },
    },
});

export default sidebarStyle;
