import React from "react"
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import { Switch, Route, Redirect } from "react-router-dom"

import { withStyles, Grid } from "@material-ui/core"
import { Header, Sidebar } from "components"
import { Images } from 'helper/const'

import dashboardRoutes from "routes/dashboard.jsx"
import appStyle from "assets/jss/material-dashboard-react/appStyle.jsx"
import PerfectScrollbar from "perfect-scrollbar"


function switchRoutes(userType) {
    return (
        <Switch>
            {dashboardRoutes.map((prop, key) => {
                if (!prop.userType || prop.userType === userType) {
                    if (prop.redirect) {
                        return <Redirect from={prop.path} to={prop.to} key={key} />
                    }

                    return <Route path={'/dashboard' + prop.path} component={prop.component} key={key} />
                } else {
                    return null
                }
            })}
        </Switch>
    )
}

function absoluteRoutes(userType) {
    var rst = []

    for (let index = 0; index < dashboardRoutes.length; index++) {
        const element = dashboardRoutes[index]
        const eleUserType = element.userType

        if (eleUserType && eleUserType !== userType) {
            continue
        }

        const newEle = {...element, path: '/dashboard' + element.path}
        rst.push(newEle)
    }

    return rst
}

class App extends React.Component {
    state = {
        mobileOpen: false,
        switchRoutes: null
    }

    constructor(props) {
        super(props)

        const { userType, isAuthorized } = props
        if (!isAuthorized) {
            this.props.history.replace('/splash')
        }

        this.state.switchRoutes = switchRoutes(userType)
        this.state.absoluteRoutes = absoluteRoutes(userType)
    }

    componentWillReceiveProps(newProps) {
        if (newProps.userType !== this.props.userType) {
            this.setState({
                switchRoutes: switchRoutes(newProps.userType),
                absoluteRoutes: absoluteRoutes(newProps.userType)
            })
        }
    }

    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    getRoute() {
        return this.props.location.pathname !== "/maps"
    }

    componentDidMount() {
        if (!this.props.isAuthorized) {
            this.props.history.replace('/splash')
        }

        if (navigator.platform.indexOf('Win') > -1) {
            // eslint-disable-next-line
            const ps = new PerfectScrollbar(this.refs.mainPanel)
        }
    }


    componentDidUpdate() {
        this.refs.mainPanel.scrollTop = 0
    }


    render() {
        const { classes, userType, ...rest } = this.props

        return (
            <div className={classes.wrapper} style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'flex-start'
            }}>
                <Sidebar
                    style={{
                        flex: 1,
                    }}
                    routes={this.state.absoluteRoutes}
                    logoText={"eDocine"}
                    logo={Images.logo}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color="sidebar-item"
                    {...rest} />

                <div className={classes.mainPanel + ' mainPanel'} ref="mainPanel">
                    <Header
                        routes={this.state.absoluteRoutes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest} />

                    {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}

                    {this.getRoute() ? (<Grid container className="content-container">
                        <Grid item xs={12}>
                            {this.state.switchRoutes}
                        </Grid>
                    </Grid>) : (<div className={classes.map}>{this.state.switchRoutes}</div>)}
                </div>

                {this.props.children}
            </div>
        )
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired
}

const styledApp = withStyles(appStyle)(App)

function mapStateToProps(state) {
    const { auth } = state
    const { isAuthed, userProfile } = auth
    if (!userProfile) {
        return {
            userType: null,
            isAuthorized: false
        }
    }

    const { userType, firstName, username } = userProfile
    const isAuthorized = isAuthed && (firstName || username)

    return {
        userType,
        isAuthorized
    }
}

export default connect(mapStateToProps)(styledApp)
