import React from 'react'
import { connect } from 'react-redux'
import { UserType, Strings, ServiceSKU, startBookingAnytime } from 'helper/const'
import { Grid, Button } from "@material-ui/core"
import { AlertDialog } from 'components'
import moment from 'moment'

import * as emailApi from 'api/email'
import * as notificationApi from 'api/notification'
import * as bookingApi from 'api/booking'
import * as bookingActions from 'redux/actions/bookings'

const uuidv1 = require('uuid/v1')

class BookingCancelStart extends React.Component {
    state = {
        cancelNote: '',

        answerDlgOpen: false,
        answerMessage: '',
        answerTitle: 'Confirm',

        alertDlgOpen: false,
        alertMessage: '',
        alertTitle: 'Booking'
    }

    constructor(props) {
        super(props)

        if (!props.historyData) {
            props.history.goBack()
        }
    }


    showAlert = (title, message) => {
        this.setState(prevState => {
            const alertTitle = title ? title : prevState.alertTitle
            const alertMessage = message ? message : prevState.alertMessage

            return {
                alertTitle,
                alertMessage,
                alertDlgOpen: true
            }
        })
    }


    askAnswer = (title, message) => {
        this.setState(prevState => {
            const answerTitle = title ? title : prevState.answerTitle
            const answerMessage = message ? message : prevState.answerMessage

            return {
                answerTitle,
                answerMessage,
                answerDlgOpen: true
            }
        })
    }


    changeCancelReason = (e) => {
        const cancelNote = e.target.value
        this.setState({
            cancelNote
        })
    }


    handleStart = () => {
        this.askAnswer(null, Strings.confirmStartBooking)
    }


    handleCancel = () => {
        const {cancelNote} = this.state
        if (cancelNote) {
            if (cancelNote.length > 200) {
                this.showAlert(null, 'Cancel Reason must be less than 200 characters.')
            } else {
                this.askAnswer(null, Strings.confirmCancelBooking)
            }
        } else {
            this.showAlert(null, 'Please fill in Cancellation reason.')
        }
    }


    handleAnswer = (rst) => {
        this.setState({
            answerDlgOpen: false
        })


        if (rst) {
            const { answerMessage } = this.state

            switch (answerMessage) {
                case Strings.confirmCancelBooking:
                    this.cancelBooking()
                    break
                case Strings.confirmStartBooking:
                    this.startBooking()
                    break
                default:
                    break
            }
        }
    }


    cancelBooking = () => {
        const historyData = {...this.props.historyData}
        const { cancelNote } = this.state

        historyData.cancelNote = cancelNote

        this.props.dispatch(bookingActions.cancelBookingAction(historyData, this.props.userType))
        this.props.history.goBack()
    }


    startBooking = () => {
        const { patientBookingID, patient, booking } = this.props.historyData
        const sessionRequestParams = {
            bookingId: patientBookingID,
            buddyUserId: patient.patientUserID,
            isBuddyDoctor: false,
            buddyName: patient.username
        }

        let nextPage
        switch (booking.doctorServiceSKU) {
            case ServiceSKU.GENERAL_IM:
            case ServiceSKU.SPECIAL_IM:
                nextPage = 'instant_message'
                break
            case ServiceSKU.GENERAL_AUDIO_CALL:
            case ServiceSKU.SPECIAL_AUDIO_CALL:
                nextPage = 'audio_chat'
                break
            case ServiceSKU.GENERAL_VIDEO_CALL:
            case ServiceSKU.SPECIAL_VIDEO_CALL:
                nextPage = 'video_chat'
                break
            default:
                break
        }

        if (nextPage) {
            const nextPageUrl = `/dashboard/${nextPage}/${patientBookingID}`
            this.props.history.push({
                pathname: nextPageUrl,
                state: sessionRequestParams
            })
        }

        // this.props.dispatch(bookingActions.startSessionAction(patientBookingID))
        bookingApi.startSession(patientBookingID)
            .then(response => {
                if (response.status === 0) {
                    console.log("Successfully started session. Now notify to opponent user")
                    this.notifyOpponentUser(true)
                } else {
                    console.error("Failed to start session:", response)
                }
            })
            .catch(err => {
                console.error("Failed to start session:", err)
            })
    }

    notifyOpponentUser(isStart) {
        const { historyData, userType } = this.props

        // Create notification now
        let notification, doctorContent
        if (userType === UserType.patient) {
            notification = `Patient ${isStart ? "Started" : "Cancelled"}: ${historyData.doctorServiceName}`
            doctorContent = `Patient ${isStart ? "Started" : "Cancelled"}: ${historyData.doctorServiceName} with ${historyData.patient.username}`
        } else {
            notification = `Doctor ${isStart ? "Started" : "Cancelled"}: ${historyData.doctorServiceName}`
            doctorContent = `Doctor ${isStart ? "Started" : "Cancelled"}: ${historyData.doctorServiceName} with ${historyData.patient.username}`
        }

        const pushUUID = uuidv1()
        notificationApi.createNotification({
            patientId: '' + historyData.patient.patientUserID,
            content: notification,
            action: isStart ? "Started" : "Cancelled",
            actionParam: '' + historyData.patientBookingID,
            pushGuid: pushUUID,
            doctorStartTimeStamp: '' + historyData.booking.doctorStartTimeStamp,
            doctorEndTimeStamp: '' + historyData.booking.doctorEndTimeStamp,
            doctorContent: doctorContent
        }).then(notificationResult => {
            console.log("Notification Result:", notificationResult)

            const timezone = 'UTC ' + parseInt(new Date().getTimezoneOffset() / -60, 10)
            const startDateTime = moment.unix(historyData.booking.doctorStartTimeStamp)
            const startDate = startDateTime.format('DD/MM/YYYY')
            const startTime = startDateTime.format('hh:mm a')

            let msg = `${historyData.doctorServiceName} with ${historyData.patient.username} on ${startDate} at ${startTime} ${timezone}`
            emailApi.notifyBookingConfirm(historyData.patient.patientUserID, msg, false, '')

            msg = `${notification} on ${startDate} at ${startTime} ${timezone}`
            this.sendNotificationToUser(historyData.patient.patientUserID, msg, pushUUID)

            console.log("Finished sending notifications")
        }).catch(err => {
            console.error("Sending notification failed:", err)
        })
    }

    async sendNotificationToUser(userId, message) {
        console.log("Send Notification To User:", userId, message)

        const rstPushUsers = await notificationApi.getPushUsers(userId, UserType.patient)
        if (rstPushUsers.status === 0) {
            let targetUsers = []
            for (let index = 0; index < rstPushUsers.data.length; index++) {
                const element = rstPushUsers.data[index];
                if (element.pushId && element.isLogged) {
                    targetUsers.push(element.pushId)
                }
            }

            if (targetUsers.length > 0) {
                notificationApi.sendPush(targetUsers, message)
            }
        }
    }

    timestampToDateTime = (timestampSeconds) => {
        const date = new Date()
        date.setTime(timestampSeconds * 1000)

        return date
    }


    timestampToDateString = (timestampSeconds) => {
        const dateTime = this.timestampToDateTime(timestampSeconds)
        return ('0' + dateTime.getDate()).slice(-2) + '/' + ('0' + (dateTime.getMonth() + 1)).slice(-2) + '/' + dateTime.getFullYear()
    }

    timestampToTimeString = (timestampSeconds) => {
        const dateTime = this.timestampToDateTime(timestampSeconds)

        const hours = dateTime.getHours()
        const hoursDescription = hours > 12 ? (hours - 12) : hours
        const minutes = dateTime.getMinutes()
        const ap = hours >= 12 ? 'PM' : 'AM'

        return ('0' + hoursDescription).slice(-2) + ':' + ('0' + minutes).slice(-2) + ' ' + ap
    }


    isAbleToStart = (startTimestampSeconds, endTimestampSeconds) => {
        if (startBookingAnytime) {
            return true
        }

        const MINUTES_INTERVAL = 5

        const date = new Date()

        const currentTimestamp = date.getTime()

        const startLimit = startTimestampSeconds * 1000 - MINUTES_INTERVAL * 60 * 1000
        const endLimit = endTimestampSeconds * 1000

        console.log(startLimit, currentTimestamp, endLimit)

        if (startLimit <= currentTimestamp && currentTimestamp < endLimit) {
            return true
        }

        return false
    }


    render() {
        const { historyData, userType } = this.props
        if (!historyData) {
            return null
        }

        const { doctorServiceName, patientNote, booking, doctor, patient } = historyData
        const { cancelNote } = this.state

        let content, isAble2Start = false

        if (userType === UserType.patient) {
            if (!doctor) {
                return null
            }

            const doctorName = `${doctor.preFix} ${doctor.firstName} ${doctor.lastName}`
            content = `${doctorServiceName} with ${doctorName} on ${this.timestampToDateString(booking.doctorStartTimeStamp)} at ${this.timestampToTimeString(booking.doctorStartTimeStamp)}`
        } else {
            if (!patient) {
                return null
            }

            content = `${doctorServiceName} with ${patient.username} on ${this.timestampToDateString(booking.doctorStartTimeStamp)} at ${this.timestampToTimeString(booking.doctorStartTimeStamp)}`
        }

        isAble2Start = this.isAbleToStart(booking.doctorStartTimeStamp, booking.doctorEndTimeStamp)

        return (
            <div className="booking-cancel-start-container">
                <div className="title">Appointment</div>

                <Grid container  direction='column'>
                    <label className="caption">{content}</label>
                    <label className="caption">Patient Note:</label>
                    <label className="caption">{patientNote}</label>
                    <label className="caption">Cancellation Reason:</label>
                    <textarea className="content reason" value={cancelNote} onChange={this.changeCancelReason}/>
                    {isAble2Start && <Button className="btn" onClick={this.handleStart}>Start</Button>}
                    <Button className="btn" onClick={this.handleCancel}>Cancel</Button>
                </Grid>
                
                <AlertDialog
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    ok="OK"
                    open={this.state.alertDlgOpen}
                    onClose={() => {this.setState({
                        alertDlgOpen: false
                    })}}
                />

                <AlertDialog
                    title={this.state.answerTitle}
                    message={this.state.answerMessage}
                    ok="Yes"
                    cancel="No"
                    open={this.state.answerDlgOpen}
                    onClose={this.handleAnswer}
                />
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { auth, bookings } = state
    const { userProfile } = auth
    const userType = userProfile.userType
    const userId = userType === UserType.patient ? userProfile.patientUserID : userProfile.doctorUserID
    const { data } = bookings

    let historyData

    if (data && ownProps.match.params) {
        let {patientBookingID} = ownProps.match.params
        patientBookingID = parseInt( patientBookingID, 10)
        historyData = data.find(x => parseInt(x.patientBookingID, 10) === patientBookingID)
    }

    return {
        userId,
        userType,
        historyData
    }
}

export default connect(mapStateToProps)(BookingCancelStart)

