import React from 'react'
import { connect } from 'react-redux'

import { ListItem, Button } from "@material-ui/core"
import IOSSwitch from 'components/CustomInput/IOSSwitch'
import Storage from 'Storage/storage.js'
import { UserType, TermsUrl, ServerEmail } from 'helper/const'

import * as cardActions from 'redux/actions/card'


class Settings extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            orgNotificationsSetting: false,
            notificationsSetting: false,

            orgLocationSetting: false,
            locationSetting: false
        }
    }

    componentDidMount() {
        const { userId, userType } = this.props
        if (!userId || !userType) {
            return
        }

        const notificationsSetting = Storage.getNotificationsSetting(userId, userType)
        const locationSetting = Storage.getLocationSetting(userId, userType)

        this.setState({
            orgNotificationsSetting: notificationsSetting,
            notificationsSetting,

            orgLocationSetting: locationSetting,
            locationSetting
        })
    }

    share = () => {
        this.props.history.push('/dashboard/share')
    }

    goToTerms = () => {
        window.open(TermsUrl, "_blank")
    }

    handleMailing = () => {
        const subject = "App Feedback"
        const body_message = "Have a problem with edocine web app."

        var mailto_link = 'mailto:' + ServerEmail + '?subject=' + subject + '&body=' + body_message
        window.open(mailto_link)
    }

    loadCards = () => {
        const { dispatch, userProfile } = this.props
        const { customerID = "" } = userProfile

        if (customerID && customerID.length > 0) {
            dispatch(cardActions.loadCards(customerID))
        }
    }

    handlePaymentWithdrawal = () => {
        const { userType } = this.props

        if (userType === UserType.patient) {
            this.loadCards()
            this.props.history.push('/dashboard/patient_payment_setting')
        } else {
            this.props.history.push('/dashboard/doctor_payment_setting')
        }
    }

    handleDefaultServicesBooking = () => {
        this.props.history.push('/dashboard/default_services_booking')
    }

    _handleChange = name => event => {
        this.setState({
            [name]: event.target.checked
        })
    }

    _handleSave = () => {
        const { userId, userType } = this.props
        if (!userId || !userType) {
            return
        }

        const {
            orgNotificationsSetting,
            notificationsSetting,
            orgLocationSetting,
            locationSetting
        } = this.state

        Storage.setNotificationsSetting(userId, userType, notificationsSetting)
        Storage.setLocationSetting(userId, userType, locationSetting)

        this.setState({
            orgNotificationsSetting: notificationsSetting,
            orgLocationSetting: locationSetting
        })

        if (notificationsSetting !== orgNotificationsSetting) {
            const OneSignal = window.OneSignal || []
            OneSignal.setSubscription(notificationsSetting)
        }

        if (locationSetting !== orgLocationSetting) {
            // Should change location permission. This is not available programmatically.
            // Recommendation is to guide users to the settings page in web browser
        }
    }

    render() {
        const { notificationsSetting, locationSetting } = this.state
        const { userType } = this.props

        return (
            <div className="settings-container">
                <div className="title">Settings</div>

                <ListItem button className="item bottom" onClick={this.share}>
                    <label>Share application</label>
                    <img src={require('assets/img/icons/details-arrow.svg')} className="details-arrow"  alt=""/>
                </ListItem>

                <ListItem button className="item bottom" onClick={this.goToTerms}>
                    <label>Terms and Agreement</label>
                    <img src={require('assets/img/icons/details-arrow.svg')} className="details-arrow"  alt=""/>
                </ListItem>

                <ListItem button className="sub-title item bottom" onClick={this.handleMailing}>
                    <label>Help & Feedback</label>
                    <img src={require('assets/img/icons/details-arrow.svg')} className="details-arrow"  alt=""/>
                </ListItem>

                <ListItem button className="sub-title item bottom" onClick={this.handlePaymentWithdrawal}>
                    <label>{userType === UserType.patient ? "Payment Methods Management" : "Payment & Withdrawal"}</label>
                    <img src={require('assets/img/icons/details-arrow.svg')} className="details-arrow"  alt=""/>
                </ListItem>

                <ListItem className="item bottom switch">
                    <label>Notifications</label>
                    <IOSSwitch
                        checked={notificationsSetting}
                        onChange={this._handleChange('notificationsSetting')}
                        value='notificationsSetting'
                    />
                </ListItem>

                <ListItem className="item bottom switch">
                    <label>Location</label>
                    <IOSSwitch
                        checked={locationSetting}
                        onChange={this._handleChange('locationSetting')}
                        value='locationSetting'
                    />
                </ListItem>

                {userType === UserType.doctor &&
                    <ListItem button className="sub-title item bottom" onClick={this.handleDefaultServicesBooking}>
                        <label>Default Services Booking</label>
                        <img src={require('assets/img/icons/details-arrow.svg')} className="details-arrow" alt=""/>
                    </ListItem>}

                <div className="save-wrapper">
                    <Button className="save" onClick={this._handleSave}>
                        Save Changes
                    </Button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth } = state
    const { userProfile } = auth

    if (!userProfile) {
        return null
    }

    const { userType, patientUserID, doctorUserID } = userProfile
    const userId = (userType === UserType.patient) ? patientUserID : doctorUserID

    return {
        userProfile,
        userId,
        userType
    }
}

export default connect(mapStateToProps)(Settings)
