import { connect } from 'react-redux'
import DoctorProfile from './DoctorProfile'

function mapStateToProps(state, ownProps) {
  const { auth } = state
  const { userProfile } = auth
  const doctorId = parseInt(ownProps.match.params.doctorId, 10)
  var patientId
  if (userProfile) {
    patientId = userProfile.patientUserID
  }
  
  return {
    patientId,
    doctorId,
    editable: false
  }
}

export default connect(mapStateToProps)(DoctorProfile)
