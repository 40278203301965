import React from 'react'
import { connect } from 'react-redux'
import { Images, UserType } from 'helper/const'
import { Grid, Button } from "@material-ui/core"
import StarRatings from 'react-star-ratings'

import * as authApi from 'api/auth'
import * as bookingApi from 'api/booking'
import * as bookActions from 'redux/actions/bookings'


class RatingPage extends React.Component {
    state = {
        rating: 0
    }

    constructor(props) {
        super(props)

        if (!props.historyData) {
            props.history.goBack()
        }
    }


    getOpponentUserId = () => {
        const { userProfile, historyData } = this.props
        const userType = userProfile.userType

        if (parseInt(userType, 10) === UserType.patient) {
            return historyData.doctor.doctorUserID
        } else if (parseInt(userType, 10) === UserType.doctor) {
            return historyData.patient.patientUserID
        } else {
            return -1
        }
    }

    getOpponentUserType = () => {
        const { userProfile } = this.props
        const { userType } = userProfile

        if (userType === UserType.doctor) {
            return UserType.patient
        } else {
            return UserType.doctor
        }
    }


    getOpponentUserProfile = async () => {
        const opponentId = this.getOpponentUserId()
        const { userProfile } = this.props
        const { userType } = userProfile

        let rstOpponent
        if (userType === UserType.doctor) {
            rstOpponent = await authApi.getUserProfile(UserType.patient, opponentId)
        } else if (userType === UserType.patient) {
            rstOpponent = await authApi.getUserProfile(UserType.doctor, opponentId)
        }

        return rstOpponent
    }

    async componentDidMount() {
        const { historyData } = this.props

        // Get opponent user
        const rstOpponent = await this.getOpponentUserProfile()
        if (rstOpponent.status !== 0 && rstOpponent.data.length !== 1) {
            console.log("Failed to get opponent user info.")
            return
        }

        const oppoUser = rstOpponent.data[0]
        oppoUser.userType = this.getOpponentUserType()

        let ratingData
        const ratingResponse = await bookingApi.getRatingByBookingId(historyData.patientBookingID)
        if (ratingResponse.status === 0 && ratingResponse.data.length > 0) {
            ratingData = ratingResponse.data[0]
        }

        this.setState({
            oppoUser,
            ratingData
        })
    }


    changeRating = (newRating, name) => {
        this.setState({ rating: newRating })
    }


    handleRate = async () => {
        const { userProfile, historyData } = this.props
        const { userId } = userProfile
        const { ratingData, rating } = this.state

        let rateResponse
        if (ratingData) {
            console.log("Updating rate")
            rateResponse = await bookingApi.updateRate(ratingData.doctorRatingID, rating)
        } else {
            console.log("Creating rate")
            rateResponse = await bookingApi.createRate(this.getOpponentUserId(), userId, historyData.patientBookingID, rating)
        }

        console.log("Create/Update result:", rateResponse)

        this.props.history.push(`/dashboard/history`)

        bookActions.getBookingsAction(userId, UserType.patient)
    }


    render() {
        const { historyData, userProfile } = this.props
        const { userType } = userProfile
        const { rating, oppoUser } = this.state

        if (!historyData || !oppoUser) {
            return <div>Loading... Please wait</div>
        }

        const { doctorServiceName, doctor, patient } = historyData

        let title, content, oppoUserAvatar
        if (userType === UserType.patient) {
            if (!doctor) {
                return <div>Loading... Please wait</div>
            }

            const doctorName = `${doctor.preFix} ${doctor.firstName} ${doctor.lastName}`
            title = 'Rate the Doctor'
            content = `${doctorServiceName} with ${doctorName}`
            oppoUserAvatar = (oppoUser.profilePhotoURL && oppoUser.profilePhotoURL.length > 0) ? oppoUser.profilePhotoURL : Images.doctorAvatar
        } else {
            if (!patient) {
                return <div>Loading... Please wait</div>
            }

            title = 'Rate the Patient'
            content = `${doctorServiceName} with ${patient.username}`
            oppoUserAvatar = (oppoUser.photoProfileURL && oppoUser.photoProfileURL.length > 0) ? oppoUser.photoProfileURL : Images.patientAvatar
        }

        return (
            <div className="booking-cancel-start-container">
                <div className="title">{title}</div>

                <Grid container direction='column'>
                    <label className="caption">{content}</label>
                    <div className="avatar-container">
                        <img className="avatar" src={oppoUserAvatar} alt="Avatar"/>
                    </div>

                    <div className="rating-container">
                        <StarRatings
                            rating={rating}
                            starRatedColor="yellow"
                            changeRating={this.changeRating}
                            numberOfStars={5}
                            name='rating' />
                    </div>

                    <Button className="btn" onClick={this.handleRate}>Update</Button>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { auth, bookings } = state
    const { userProfile } = auth
    const { data } = bookings

    let historyData
    if (data && ownProps.match.params) {
        let {patientBookingID} = ownProps.match.params
        patientBookingID = parseInt( patientBookingID, 10)
        historyData = data.find(x => parseInt(x.patientBookingID, 10) === patientBookingID)
    }

    return {
        userProfile,
        historyData
    }
}

export default connect(mapStateToProps)(RatingPage)

