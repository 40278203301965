import React from 'react'
import { Button } from "@material-ui/core"
import { connect } from 'react-redux'
import * as noteActions from 'redux/actions/note'
import * as imageApi from 'api/image'
import { compressBase64 } from 'helper/global'
import {ImageType} from 'helper/const'

class NavNoteDetail extends React.Component {
    handleSave = async () => {
        const props = this.props
        if (!props.currentNote) {
            return
        }

        const currentNote = {...props.currentNote}
        
        if (currentNote.noteDataURL) {
            const splitData = currentNote.noteDataURL.split(",")
            if (splitData.length === 2) {
                const uploadData = splitData[1]
                // Upload images
                var rstImg = await imageApi.uploadImage(ImageType.DoctorNotes, compressBase64(uploadData))
                if (rstImg.status === 0) {
                    currentNote.noteDataURL = rstImg.data
                } else {
                    console.log("Failed to upload attach image.")
                    return
                }
            }
        }

        const {patientNoteID, noteText, noteDataURL, patientId, doctorId} = currentNote
        this.props.dispatch(noteActions.setNoteAction(patientNoteID, noteText, noteDataURL, patientId, doctorId))
    }

    render() {
        return (
            <Button
                className="nav-filter-button"
                onClick={this.handleSave.bind(this)}
            >
                Save
            </Button>
        )
    }
}

function mapStateToProps(state) {
    const { note } = state
    const {currentNote, noteStatus, message} = note
    return {
      currentNote,
      noteStatus,
      message
    }
}
  
export default connect(mapStateToProps)(NavNoteDetail)