import {
    GET_FAVORITES_REQUEST,
    GET_FAVORITES_SUCCESS,
    GET_FAVORITES_FAILURE,

    SET_FAVORITE_REQUEST,
    SET_FAVORITE_SUCCESS,
    SET_FAVORITE_FAILURE,

    SEARCH_FAVORITE,
} from 'redux/actions/favorites'

export function favorites (
    state = {
        isGettingFavorites: false,
        isSettingFavorite: false,
        message: null,
        data: [],
        searchText: ''
    },
    action
) {
    switch (action.type) {
        case GET_FAVORITES_REQUEST:
            return Object.assign({}, state, {
                isGettingFavorites: true
            })
        case GET_FAVORITES_FAILURE:
            return Object.assign({}, state, {
                isGettingFavorites: false,
                message: action.message
            })
        case GET_FAVORITES_SUCCESS:
            return Object.assign({}, state, {
                isGettingFavorites: false,
                data: action.favorites
            })

        case SET_FAVORITE_REQUEST:
            return Object.assign({}, state, {
                isGettingFavorite: true
            })
        case SET_FAVORITE_FAILURE:
            return Object.assign({}, state, {
                isSettingFavorite: false,
                message: action.message
            })
        case SET_FAVORITE_SUCCESS:
            return Object.assign({}, state, {
                isSettingFavorite: false,
                data: action.favorites
            })
        case SEARCH_FAVORITE:
            return {
                ...state,
                searchText: action.searchText
            }
        default:
            return state
    }
}