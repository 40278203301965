import * as favoritesApi from 'api/favorites'
import {
    UserType
} from 'helper/const'

export const GET_FAVORITES_REQUEST = 'GET_FAVORITES_REQUEST'
export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS'
export const GET_FAVORITES_FAILURE = 'GET_FAVORITES_FAILURE'

export const SET_FAVORITE_REQUEST = 'SET_FAVORITE_REQUEST'
export const SET_FAVORITE_SUCCESS = 'SET_FAVORITE_SUCCESS'
export const SET_FAVORITE_FAILURE = 'SET_FAVORITE_FAILURE'

export const SEARCH_FAVORITE = 'SEARCH_FAVORITE'

function _getFavoritesRequest() {
    return {
        type: GET_FAVORITES_REQUEST,
        message: "Fetching favorites..."
    }
}

function _getFavoritesSuccess(favorites) {
    return {
        type: GET_FAVORITES_SUCCESS,
        favorites
    }
}

function _getFavoritesFailure(message) {
    return {
        type: GET_FAVORITES_FAILURE,
        message
    }
}

export function getFavorites(userType, userId) {
  return async dispatch => {
    dispatch(_getFavoritesRequest())
    const response = await favoritesApi.getFavorites(userType, userId)
    if (response.status === 0) {
        dispatch(_getFavoritesSuccess(response.data))
    } else {
        dispatch(_getFavoritesFailure(response.message))
    }
  }
}

function _setFavoriteRequest() {
    return {
        type: SET_FAVORITE_REQUEST,
        message: "Setting favorite..."
    }
}

function _setFavoriteSuccess(favorites) {
    return {
        type: SET_FAVORITE_SUCCESS,
        favorites
    }
}

function _setFavoriteFailure(message) {
    return {
        type: SET_FAVORITE_FAILURE,
        message
    }
}

export function setFavorite(userType, patientId, doctorId) {
    return async dispatch => {
        dispatch(_setFavoriteRequest())
        const response = await favoritesApi.setFavorite(userType, patientId, doctorId)

        if (response.status === 0) {
            const data = response.data
            if (data === 1) {
                const userId = userType === UserType.patient ? patientId : doctorId
                const res = await favoritesApi.getFavorites(userType, userId)
                if (res.status === 0) {
                    dispatch(_setFavoriteSuccess(res.data))
                    return
                }
            }

            dispatch(_setFavoriteFailure("Failed to get new favorite list."))
        } else {
            dispatch(_setFavoriteFailure(response.message))
        }
    }
}

export function searchFavoriteAction(searchText) {
    return {
        type: SEARCH_FAVORITE,
        searchText
    }
}