import React from 'react'
import { Grid, Button } from "@material-ui/core";
import moment from 'moment'
import DatePicker from 'react-datepicker'

import {
    Languages,
    Qualifications,
    PrefixDoctor,
    AreaOfPractices
} from 'helper/const'

import {
    DropDown,
    MultiDropDown,
    ItemGrid
} from "components"


export default class DoctorInfo extends React.Component {
    years = ['Year of Graduation']

    constructor(props) {
        super(props)
        const {userProfile} = props

        userProfile.dateOfBirth = userProfile.dateOfBirth ? moment(userProfile.dateOfBirth) : null

        this.state = {
            userProfile
        }

        // Calc years
        const today = new Date()
        const thisYear = today.getFullYear()
        const endYear = 1799

        for (let i = thisYear; i > endYear; i--) {
            this.years.push(i)
        }
    }

    handleChangeText = (key, value) => {
        this.setState(prevState => {
            let { userProfile } = prevState
            userProfile[key] = value

            if (this.props.onChange) {
                this.props.onChange(userProfile)
            }

            return {
                userProfile: userProfile
            }
        })
    }

    handleBirthDateChange = (date) => {
        this.setState(prevState => {
            let { userProfile } = prevState
            userProfile.dateOfBirth = date
            if (this.props.onChange) {this.props.onChange(userProfile)}
            return {
                userProfile: userProfile
            }
        })
    }
        
    onPickFile = (key, imgFile) => {
        if (imgFile) {
            let reader = new FileReader()
            reader.onload = (e) => {
                const filePath = e.target.result
                this.setState(prevState => {
                    let { userProfile } = prevState
                    if (userProfile) {
                        userProfile[key] = filePath
                    }

                    return {
                        userProfile: userProfile
                    }
                })
            }
            reader.readAsDataURL(imgFile)
        }
    }
      
    render() {
        const {hideCerts} = this.props
        const { userProfile } = this.state
        const {
            email,
            preFix,
            firstName,
            middleName,
            lastName,
            dateOfBirth,
            language,
            medicalSchool,
            yearOfGraduate,
            areaOfPractice,
            licenseNumber,
            registrationBody,
            qualification,
            iDPhotoURL,
            iDPhoto2URL,
            certificatePhotoURL,

            facebookId,
            twitterId,
            googleId
        } = userProfile

        const isSocial = facebookId || twitterId || googleId

        return <div className="user-info">
            <div className="sub-title">
                <label>Personal Information of Doctor</label>
            </div>

            <div className="sub-body">
                <Grid container alignItems="center">
                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <DropDown
                            classes={{root: "dropdown-container"}}
                            value={ preFix }
                            options={ PrefixDoctor }
                            onChange={ (preFix) => {this.handleChangeText('preFix', preFix)} }
                            containerClass="item"
                            itemClass={!preFix ? "item-input placeholder" : 'item-input'}
                            menuItemClass={'item-input menu-item'}
                            IconComponent={<i className="fa fa-angle-down ico-content"/>}
                        />
                    </ItemGrid>

                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <input
                            type="text"
                            className="item-input"
                            placeholder='First name'
                            defaultValue={firstName}
                            onChange={(e) => {this.handleChangeText('firstName', e.target.value)}}
                        />
                    </ItemGrid>

                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <input
                            type="text"
                            className="item-input"
                            placeholder='Middle name'
                            defaultValue={middleName}
                            onChange={(e) => {this.handleChangeText('middleName', e.target.value)}}
                        />
                    </ItemGrid>
                    
                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <input
                            type="text"
                            className="item-input"
                            placeholder='Last name'
                            defaultValue={lastName}
                            onChange={(e) => {this.handleChangeText('lastName', e.target.value)}}
                        />
                    </ItemGrid>

                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <input
                            className="item-input"
                            placeholder='Email'
                            defaultValue={email}
                            onChange={(e) => {this.handleChangeText('email', e.target.value)}}
                            readOnly={isSocial ? '' : 'readonly'}/>
                        <div className='ico-wrapper'><i className="far fa-envelope ico-content"/></div>
                    </ItemGrid>


                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <DatePicker
                            dateFormat="DD/MM/YYYY"
                            selected={dateOfBirth ? dateOfBirth : null}
                            onChange={this.handleBirthDateChange}
                            placeholderText="Date of Birth"
                            className="item-input"
                            showYearDropdown
                            maxDate={moment()}
                            minDate={moment().subtract(100, "year")}
                            dropdownMode="select"
                        />
                    </ItemGrid>

                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <MultiDropDown
                            classes={{root: "dropdown-container"}}
                            value={ language }
                            placeholder='Languages spoken'
                            options={ Languages }
                            onChange={ (value) => {this.handleChangeText('language', value)} }
                            containerClass="item"
                            itemClass={!language ? "item-input placeholder" : 'item-input'}
                            menuItemClass={'item-input menu-item'}
                            IconComponent={<i className="fa fa-angle-down ico-content"/>}
                        />
                    </ItemGrid>
                </Grid>
            </div>


            <div className="sub-title mt-50p">
                <label>Academic & Practice Information of Doctor</label>
            </div>
            
            <div className="sub-body">
                <Grid container alignItems="center">
                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <input
                            type="text"
                            className="item-input"
                            placeholder='Medical school'
                            defaultValue={medicalSchool}
                            onChange={(e) => {this.handleChangeText('medicalSchool', e.target.value)}}
                        />
                    </ItemGrid>

                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <DropDown
                            classes={{root: "dropdown-container"}}
                            value={ yearOfGraduate }
                            options={ this.years }
                            onChange={ (year) => {this.handleChangeText('yearOfGraduate', year)} }
                            containerClass="item"
                            itemClass={!yearOfGraduate ? "item-input placeholder" : 'item-input'}
                            menuItemClass={'item-input menu-item'}
                            IconComponent={<i className="fa fa-angle-down ico-content"/>}
                        />
                    </ItemGrid>
                
                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <DropDown
                            classes={{root: "dropdown-container"}}
                            value={ areaOfPractice }
                            options={ AreaOfPractices }
                            onChange={ (value) => {this.handleChangeText('areaOfPractice', value)} }
                            containerClass="item"
                            itemClass={!areaOfPractice ? "item-input placeholder" : 'item-input'}
                            menuItemClass={'item-input menu-item'}
                            IconComponent={<i className="fa fa-angle-down ico-content"/>}
                        />
                    </ItemGrid>

                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <input
                            type="text"
                            className="item-input"
                            placeholder="License number"
                            defaultValue={licenseNumber}
                            onChange={(e) => {this.handleChangeText('licenseNumber', e.target.value)}}
                        />
                    </ItemGrid>

                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <input
                            type="text"
                            className="item-input"
                            placeholder="Registration body"
                            defaultValue={registrationBody}
                            onChange={(e) => {this.handleChangeText('registrationBody', e.target.value)}}
                        />
                    </ItemGrid>

                    <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <MultiDropDown
                            classes={{root: "dropdown-container"}}
                            value={ qualification }
                            placeholder='Qualifications'
                            options={ Qualifications }
                            onChange={ (value) => {this.handleChangeText('qualification', value)} }
                            containerClass="item"
                            itemClass={!qualification ? "item-input placeholder" : 'item-input'}
                            menuItemClass={'item-input menu-item'}
                            IconComponent={<i className="fa fa-angle-down ico-content"/>}
                        />
                    </ItemGrid>

                    {!hideCerts && <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <div className="file-input-wrapper">
                            <Button className="picker-button">
                                <label className='item-input placeholder'>Submitting copy photo of ID Front</label>
                            </Button>
                            <input href="fileInput" type="file"
                            onChange={(event) => {
                                const files = event.target.files
                                if (files.length > 0) {
                                    this.onPickFile('iDPhotoURL', files[0])
                                }
                            }}/>
                        </div>
                        <div className='ico-wrapper'>
                            {iDPhotoURL && <label className="attached">attached</label>}
                            <i className="fa fa-paperclip ico-content"/>
                        </div>
                    </ItemGrid>}

                    {!hideCerts && <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <div className="file-input-wrapper">
                            <Button className="picker-button">
                                <label className='item-input placeholder'>Submitting copy photo of ID Back</label>
                            </Button>
                            <input href="fileInput" type="file"
                            onChange={(event) => {
                                const files = event.target.files
                                if (files.length > 0) {
                                    this.onPickFile('iDPhoto2URL', files[0])
                                }
                            }}/>
                        </div>
                        <div className='ico-wrapper'>
                            {iDPhoto2URL && <label className="attached">attached</label>}
                            <i className="fa fa-paperclip ico-content"/>
                        </div>
                    </ItemGrid>}

                    {!hideCerts && <ItemGrid xs={12} classes={{grid: 'relative-container item-container'}}>
                        <div className="file-input-wrapper">
                            <Button className="picker-button">
                                <label className='item-input placeholder'>Copy of practicing certificate</label>
                            </Button>
                            <input href="fileInput" type="file"
                            onChange={(event) => {
                                const files = event.target.files
                                if (files.length > 0) {
                                    this.onPickFile('certificatePhotoURL', files[0])
                                }
                            }}/>
                        </div>
                        <div className='ico-wrapper'>
                            {certificatePhotoURL && <label className="attached">attached</label>}
                            <i className="fa fa-paperclip ico-content"/>
                        </div>
                    </ItemGrid>}

                    {!hideCerts && <div className="sub-desc">(or equivalent)</div>}
                </Grid>
            </div>
        </div>
    }
}