import React from "react"
import { UserType, Images } from 'helper/const'
import { compressBase64 } from 'helper/global'
import { Base, ProfileCard, PatientInfo } from "components"
import { IconButton, Button } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"

import * as favoritesApi from 'api/favorites'
import * as imageApi from 'api/image'
import * as authApi from 'api/auth'

import * as actions from 'redux/actions/auth'
import * as noteActions from 'redux/actions/note'

export default class PatientProfile extends React.Component{
    constructor(props) {
        super(props)

        this.state = {
            favorite: props.userProfile ? true : false,
            illnessPickerOpen: false,
            languagePickerOpen: false,
            userProfile: props.userProfile
        }

        const { patientId } = props
        if (!props.userProfile && patientId) {
            authApi.getUserProfile(UserType.patient, patientId).then(rstUser => {
                if (rstUser.status === 0 && rstUser.data.length === 1) {
                    const userProfile = rstUser.data[0]
                    userProfile.userType = UserType.patient

                    console.log("Succeeded to load user profile:", userProfile)
                    this.setState({ userProfile })
                } else {
                    console.error("Invalid user profile:", rstUser)
                }
            }).catch(err => console.error("Failed to load user profile:", err))
        }

        this.props.dispatch(noteActions.setCurrentPatient(patientId))
    }

    componentWillReceiveProps(newProps) {
        if (newProps.userProfile) {
            this.setState({ userProfile: newProps.userProfile })
        }

        const { patientId } = newProps
        this.props.dispatch(noteActions.setCurrentPatient(patientId))
    }


    handleFavorite = async () => {
        const { patientId, doctorId } = this.props

        if (patientId && patientId !== 0) {
            const res = await favoritesApi.setFavorite(UserType.doctor, patientId, doctorId, !this.state.favorite)
            if (res.status === 0) {
                this.setState(prevState => {
                    return {
                        favorite: !prevState.favorite
                    }
                })
            }
        }
    }


    handleAvatarChange = (imgFile) => {
        if (imgFile) {
            let reader = new FileReader()
            reader.onload = (e) => {
                const filePath = e.target.result
                this.setState(prevState => {
                    let { userProfile } = prevState

                    if (userProfile) {
                        userProfile.photoProfileURL = filePath
                    }

                    return {
                        userProfile: userProfile
                    }
                })
            }

            reader.readAsDataURL(imgFile)
        }
    }


    handleUpdateProfile = async () => {
        const newUserProfile = this.state.userProfile
        const oldUserProfile = this.props.userProfile

        if (newUserProfile && oldUserProfile) {
            let data = {}
            let isUpdated = false
            let key

            for (key in newUserProfile) {
                if (newUserProfile.hasOwnProperty(key)) {
                    let newEl = newUserProfile[key]
                    let oldEl = oldUserProfile[key]

                    if (newEl !== oldEl) {
                        if (key === "photoProfileURL") {
                            newEl = newEl.split(",")[1]
                            newEl = compressBase64(newEl)

                            const rstImg = await imageApi.uploadImage(2, newEl)
                            console.log("Image upload: ", rstImg)

                            if (rstImg.status === 0) {
                                const imgUrl = rstImg.data
                                data["photoProfileURL"] = imgUrl
                            } else {
                                isUpdated = false
                                break
                            }
                        } else {
                            data[key] = newEl
                        }

                        isUpdated = true
                    }
                }
            }

            if (isUpdated) {
                const userType = newUserProfile.userType
                const userId = userType.toString() === UserType.patient.toString() ? newUserProfile.patientUserID : newUserProfile.doctorUserID
                this.props.dispatch(actions.updateProfile(userType, userId, data))
            } else {
                console.log("No updates to profile. Do nothing.")
            }
        }
    }


    render = () => {
        const { readOnly, showFavorite } = this.props
        const { userProfile } = this.state

        if (!userProfile) {
            return <div>Loading... Please wait</div>
        }

        const { username, patientUserID } = userProfile

        let avatar = null
        if (userProfile) {
            avatar = userProfile.photoProfileURL
            if (!avatar) {
                if (userProfile.userType && userProfile.userType.toString() === UserType.doctor.toString()) {
                    avatar =  Images.doctorAvatar
                } else {
                    avatar = Images.patientAvatar
                }
            }
        } else {
            avatar = Images.patientAvatar
        }


        let showTitleProfile = true
        if (window.location.href.includes("patient_detail")) {
            showTitleProfile = false
        }

        return (
            <div className="main-patient-profile">
                {showTitleProfile && <div className="patient-profile-title-wrapper">
                    <div className="title">Profile</div>
                    <Button
                        className="nav-filter-button mr-1"
                        href={`/#/dashboard/patient_notes/${patientUserID}`}>
                        Notes
                    </Button>
                </div>}

                {!showTitleProfile && <div className="title-wrapper">
                    <IconButton
                        className="nav-btn"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => window.history.back()}>
                        <ArrowBack />
                    </IconButton>

                    <div className="title">Patient</div>

                    <Button
                        className="nav-filter-button"
                        onClick={() => window.location.href = `/#/dashboard/patient_notes/${patientUserID}`}>
                        Notes
                    </Button>
                </div>}

                <Base className="patient-profile-container" model="auth">
                    <ProfileCard
                        avatar={avatar}
                        onAvatarChange={this.handleAvatarChange}
                        onHandleFavorite={this.handleFavorite}
                        showFavorite={showFavorite}
                        favorite={this.state.favorite}
                        readOnly={readOnly}
                        subtitle=""
                        title={username}
                        description={
                            <PatientInfo
                                userProfile={userProfile}
                                onChange={(data) => {
                                    this.setState({ userProfile: data })
                                }}
                                readOnly={readOnly} />
                        }
                        footer={!readOnly &&
                            <Button
                                onClick={this.handleUpdateProfile}>Update Profile</Button>
                        }/>
                </Base>
            </div>
        )
    }
}
