import * as questionApi from 'api/question'

export const QUESTIONS_REQUEST = 'QUESTIONS_REQUEST'
export const QUESTIONS_SUCCESS = 'QUESTIONS_SUCCESS'
export const QUESTIONS_FAILURE = 'QUESTIONS_FAILURE'

function _questionsRequest() {
    return {
        type: QUESTIONS_REQUEST,
        message: "Fetching questions..."
    }
}

function _questionsSuccess(questions) {
    return {
        type: QUESTIONS_SUCCESS,
        questions
    }
}

function _questionsFailure(message) {
    return {
        type: QUESTIONS_FAILURE,
        message
    }
}

export function getQuestions(userId, userType) {
  return async dispatch => {
    dispatch(_questionsRequest())
    // Get all questions
    const response = await questionApi.getQuestions(userId, userType)
    
    if (response.status === 0) {
        const questions = response.data
        dispatch(_questionsSuccess(questions))
    } else {
        dispatch(_questionsFailure(response.message))
    }
  }
}

export const CREATE_FEED_REQUEST = 'CREATE_FEED_REQUEST'
export const CREATE_FEED_SUCCESS = 'CREATE_FEED_SUCCESS'
export const CREATE_FEED_FAILURE = 'CREATE_FEED_FAILURE'

function _createFeedRequest() {
    return {
        type: CREATE_FEED_REQUEST,
        message: "Create feed..."
    }
}

function _createFeedSuccess(feed) {
    return {
        type: CREATE_FEED_SUCCESS,
        feed
    }
}

function _createFeedFailure(message) {
    return {
        type: CREATE_FEED_FAILURE,
        message
    }
}

export function createFeed(feed) {
    return async dispatch => {
        dispatch(_createFeedRequest())

        // Create new feed
        const response = await questionApi.createFeed(feed)

        if (response.status === 0) {
            dispatch(_createFeedSuccess(feed))
        } else {
            dispatch(_createFeedFailure(response.message))
        }
    }
}

export const UPDATE_FEED_REQUEST = 'UPDATE_FEED_REQUEST'
export const UPDATE_FEED_SUCCESS = 'UPDATE_FEED_SUCCESS'
export const UPDATE_FEED_FAILURE = 'UPDATE_FEED_FAILURE'

function _updateFeedRequest() {
    return {
        type: UPDATE_FEED_REQUEST,
        message: "Fetching update feed..."
    }
}

function _updateFeedSuccess(feed) {
    return {
        type: UPDATE_FEED_SUCCESS,
        feed
    }
}

function _updateFeedFailure(message) {
    return {
        type: UPDATE_FEED_FAILURE,
        message
    }
}

export function updateFeed(feedId, feed) {
  return async dispatch => {
    dispatch(_updateFeedRequest())
    // Get all updateFeed
    const response = await questionApi.updateFeed(feedId, feed)
    
    if (response.status === 0) {
        const updateFeed = response.data
        dispatch(_updateFeedSuccess(updateFeed))
    } else {
        dispatch(_updateFeedFailure(response.message))
    }
  }
}
