import * as bookingApi from 'api/booking'
import * as authApi from 'api/auth'
import * as notificationApi from 'api/notification'
import * as emailApi from 'api/email'
import { UserType } from 'helper/const'
import moment from 'moment'

const uuidv1 = require('uuid/v1')

export const GET_BOOKINGS_REQUEST = 'GET_BOOKINGS_REQUEST'
export const GET_BOOKINGS_SUCCESS = 'GET_BOOKINGS_SUCCESS'
export const GET_BOOKINGS_FAILURE = 'GET_BOOKINGS_FAILURE'

export const BOOK_REQUEST = 'BOOK_REQUEST'
export const BOOK_SUCCESS = 'BOOK_SUCCESS'
export const BOOK_FAILURE = 'BOOK_FAILURE'
export const BOOK_SEARCH = 'BOOK_SEARCH'

export const PREPARE_BOOKING = 'PREPARE_BOOKING'

function _getBookingsRequest() {
    return {
        type: GET_BOOKINGS_REQUEST,
        message: "Fetching bookings..."
    }
}

function _getBookingsSuccess(bookings) {
    return {
        type: GET_BOOKINGS_SUCCESS,
        bookings
    }
}

function _getBookingsFailure(message) {
    return {
        type: GET_BOOKINGS_FAILURE,
        message
    }
}


/**
* Get user's bookings
* 
* @param {user id} userId 
* @param {user type} userType 
*/
export function getBookingsAction(userId, userType) {
    return async dispatch => {
        dispatch(_getBookingsRequest())
        const response = await bookingApi.getBookings(userId, userType)

        if (response.status === 0) {
            dispatch(_getBookingsSuccess(response.data))
        } else {
            dispatch(_getBookingsFailure(response.message))
        }
    }
}

/**
* Cancel booking
*/
export const CANCEL_BOOKING_REQUEST = 'CANCEL_BOOKING_REQUEST'
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS'
export const CANCEL_BOOKING_FAILURE = 'CANCEL_BOOKING_FAILURE'

function _cancelBookingRequest() {
    return {
        type: CANCEL_BOOKING_REQUEST,
        message: "Cancel bookings..."
    }
}

function _cancelBookingSuccess(patientBookingID, cancelNote, userType) {
    return {
        type: CANCEL_BOOKING_SUCCESS,
        patientBookingID,
        cancelNote,
        userType
    }
}

function _cancelBookingFailure(message) {
    return {
        type: CANCEL_BOOKING_FAILURE,
        message
    }
}

/**
* Cancel booking
* 
* @param {object} history 
* @param {int} userType 
*/
export function cancelBookingAction(history, userType) {
    return async dispatch => {
        dispatch(_cancelBookingRequest())

        const { patientBookingID, doctor, patient, cancelNote, doctorServiceName, booking } = history

        // Cancel patient booking
        const response = await bookingApi.cancelBooking(patientBookingID, cancelNote, userType)

        if (response.status === 0) {
            // Release doctor service time slot
            const resService = await bookingApi.releaseDoctorServiceTimeSlot(booking.doctorServiceID)
            if (resService.status === 0) {
                // Compose notification
                let content = ''
                let oppositeEmailContent = ''
                let notificationParams
                let notification
                let toUserId
                let toUserType

                const bookingStartDateTime = moment.unix(booking.doctorStartTimeStamp)
                const bookingStartDate = bookingStartDateTime.format('DD/MM/YYYY')
                const bookingStartTime = bookingStartDateTime.format('hh:mm a')

                if (userType === UserType.patient) {
                    if (doctor) {
                        const doctorName = `${doctor.preFix} ${doctor.firstName} ${doctor.lastName}`

                        content = `${doctorServiceName} with ${doctorName} on ${bookingStartDate} at ${bookingStartTime} has been cancelled. Reason: ${cancelNote}`
                        oppositeEmailContent = `${doctorServiceName} with ${patient.username} on ${bookingStartDate} at ${bookingStartTime}`
                        toUserId = doctor.doctorUserID
                        toUserType = UserType.doctor

                        notificationParams = {
                            doctorId: toUserId,
                            content,
                            PushGuid: uuidv1()
                        }
                        notification = `Patient Cancelled: ${content}, Reason: ${cancelNote}`
                    }
                } else {
                    if (patient) {
                        content = `${doctorServiceName} with ${patient.username} on ${bookingStartDate} at ${bookingStartTime} has been cancelled. Reason: ${cancelNote}`
                        const doctorName = `${doctor.preFix} ${doctor.firstName} ${doctor.lastName}`

                        oppositeEmailContent = `${doctorServiceName} with ${doctorName} on ${bookingStartDate} at ${bookingStartTime}`
                        toUserId = patient.patientUserID
                        toUserType = UserType.patient

                        notificationParams = {
                            patientId: patient.patientUserID,
                            content: notification,
                            PushGuid: uuidv1()
                        }

                        notification = `Doctor Cancelled: ${content}, Reason: ${cancelNote}`
                    }
                }

                // Send notification
                const rstNotification = await notificationApi.createNotification(notificationParams)
                if (rstNotification.status === 0) {
                    const rstPushUsers = await notificationApi.getPushUsers(toUserId, toUserType)

                    if (rstPushUsers.status === 0) {
                        let targetUsers = []

                        for (let index = 0; index < rstPushUsers.data.length; index++) {
                            const element = rstPushUsers.data[index]
                            if (element.pushId && element.isLogged) {
                                targetUsers.push(element.pushId)
                            }
                        }

                        if (targetUsers.length > 0) {
                            notificationApi.sendPush(targetUsers, notification)
                        }
                    }
                }

                // Send email
                await emailApi.notifyBookingCanceled(toUserId, toUserType, oppositeEmailContent, cancelNote)

                dispatch(_cancelBookingSuccess(patientBookingID, cancelNote, userType))
            } else {
                dispatch(_cancelBookingFailure(resService.message))    
            }
        } else {
            dispatch(_cancelBookingFailure(response.message))
        }
    }
}


/**
* Start booked session
*/
export const START_SESSION_REQUEST = 'START_SESSION_REQUEST'
export const START_SESSION_SUCCESS = 'START_SESSION_SUCCESS'
export const START_SESSION_FAILURE = 'START_SESSION_FAILURE'

function _startSessionRequest() {
    return {
        type: START_SESSION_REQUEST,
        message: "Cancel bookings..."
    }
}

function _startSessionSuccess(patientBookingID) {
    return {
        type: START_SESSION_SUCCESS,
        patientBookingID
    }
}

function _startSessionFailure(message) {
    return {
        type: START_SESSION_FAILURE,
        message
    }
}


/**
* Start booked session
* 
* @param {int} patientBookingID 
*/
export function startSessionAction(patientBookingID) {
    return async dispatch => {
        dispatch(_startSessionRequest())

        const response = await bookingApi.startSession(patientBookingID)

        if (response.status === 0) {
            dispatch(_startSessionSuccess(patientBookingID))
        } else {
            dispatch(_startSessionFailure(response.message))
        }
    }
}

export function prepareBooking(bookingData) {
    return {
        type: PREPARE_BOOKING,
        bookingData
    }
}


function _bookRequest() {
    return {
        type: BOOK_REQUEST,
        message: "bookings..."
    }
}

export function searchBookings(searchText, illness) {
    return {
        type: BOOK_SEARCH,
        searchText: searchText,
        illness
    }
}

function _bookSuccess(extra) {
    return {
        type: BOOK_SUCCESS,
        pushExtra: extra
    }
}

function _bookFailure(message) {
    return {
        type: BOOK_FAILURE,
        message
    }
}


/**
* Book a doctor service 
*/
export function bookAction(bookingData, transactionId, username) {
    return async dispatch => {
        const { priceData } = bookingData

        dispatch(_bookRequest())

        try {
            const bookResponse = await bookingApi.createPatientTransaction(
                priceData.price,
                bookingData.note,
                parseInt(bookingData.patientId, 10),
                parseInt(bookingData.doctorId, 10),
                parseInt(bookingData.doctorServiceId, 10),
                priceData.name,
                bookingData.startTime,
                bookingData.endTime,
                bookingData.paidToDoctorAmount,
                bookingData.paidToPlatform,
                transactionId
            )

            console.log("Book Response:", bookResponse)

            if (bookResponse.data && bookResponse.data.Success) {
                // Send email first
                const timezone = parseInt(new Date().getTimezoneOffset() / -60, 10)
                const emailContents = `${priceData.name} with ${username} on ${bookingData.startTime.format('DD/MM/YYYY')} at ${bookingData.startTime.format('hh:mm a')} UTC ${timezone}`

                emailApi.notifyDoctorServiceBooking(bookingData.doctorId, emailContents, bookingData.note)

                // Now send notification to users using OneSignal
                const extraParts = bookResponse.data.Extra.split(":")
                const patientBookingId = parseInt(extraParts[extraParts.length - 1], 10)

                extraParts.pop()
                const extraWithoutBookingId = extraParts.join(":")

                dispatch(_bookSuccess(extraWithoutBookingId))

                console.log("Create notification for doctor/patient")

                // const doctorProfileResponse = await authApi.getUserProfile(UserType.doctor, bookingData.doctorId)
                const patientProfileResponse = await authApi.getUserProfile(UserType.patient, bookingData.patientId)

                if (/*doctorProfileResponse.status === 0 && */patientProfileResponse.status === 0) {
                    // const doctorProfile = doctorProfileResponse.data[0]
                    const patientProfile = patientProfileResponse.data[0]

                    const startDate = bookingData.startTime.format('DD/MM/YYYY')
                    const startTime = bookingData.startTime.format('hh:mm a')

                    const doctorNotification = `${priceData.name} with ${patientProfile.username} on ${startDate} at ${startTime}`
                    const doctorContent = `${priceData.name} with ${patientProfile.username} on ${startDate} at ${startTime}`

                    // Send notification
                    const rstNotification = await notificationApi.createNotification({
                        doctorId: bookingData.doctorId,
                        content: doctorNotification,
                        action: "Booking",
                        actionParam: `${patientBookingId}`,
                        PushGuid: uuidv1(),
                        doctorStartTimeStamp: `${parseInt(bookingData.startTime.toDate().getTime() / 1000, 10)}`,
                        doctorEndTimeStamp: `${parseInt(bookingData.endTime.toDate().getTime() / 1000, 10)}`,
                        doctorContent
                    })

                    console.log("Create Notification Result:")
                    console.log(rstNotification)
                }
            } else {
                console.error("Booking Error:", bookResponse.data.Error)
                dispatch(_bookFailure("Booking failed"))
            }
        } catch (error) {
            console.error("Error while integrating with Stripe API:", error)
            dispatch(_bookFailure(error.message))
        }
    }
}
