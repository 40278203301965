import React from 'react'
import { Search } from '@material-ui/icons'
import { Button } from "@material-ui/core"
import * as favoritesActions from 'redux/actions/favorites'
import { connect } from 'react-redux'

class NavFavourites extends React.Component {
    state = {
        searchText: null
    }

    handleChangeText = (searchText) => {
        this.setState({
            searchText
        })
    }

    handleSearch = () => {
        const { searchText } = this.state
        this.props.dispatch(favoritesActions.searchFavoriteAction(searchText))
    }


    onKeyUp = (e) => {
        if (e.keyCode === 13) {
            this.handleSearch()
        }
    }

    render() {
        return (
            <div className="nav-feed-container">
                <Button
                    className="search-button"
                    onClick={this.handleSearch}>
                    <Search style={{
                        width: '25px',
                        height: '25px'
                    }} className="search-ico" />
                </Button>

                <input
                    placeholder="Search"
                    onKeyUp={(e) => this.onKeyUp(e)}
                    onChange={(e) => {this.handleChangeText(e.target.value)}}
                    className='search'
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { favorites } = state
    return { favorites }
}

export default connect(mapStateToProps)(NavFavourites)

