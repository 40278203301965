import React from 'react'
import { Checkbox } from "@material-ui/core"
import {
    TermsUrl,
    PolicyUrl,
    UserType
} from 'helper/const'

export default class AgreeTerms extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            agreed: props.agreed
        }
    }

    handleAgreement = (e) => {
        const agreed = e.target.checked
        this.setState({agreed})
        
        const handleAgreement = this.props.onAgreement
        if (handleAgreement) {
            handleAgreement(agreed)
        }
    }
    render() {
        const { agreed } = this.state
        const { userType } = this.props
        const extClass = userType === UserType.patient ? 'patient-clr' : 'doctor-clr'
        const textClass = `plain-text`
        const linkClass = `link-text ${extClass}`
        
        return <div className = 'agree-terms-container'>
            <Checkbox
                checked={agreed}
                onChange={this.handleAgreement}
                value='agreed'
                color="primary"
                classes={{
                    checked: extClass
                }}
            />

            <label className={textClass}>I agree to the</label>
            <a href={TermsUrl} className={linkClass} target="_blank" rel="noopener noreferrer">Terms</a>
            <label className={textClass}>and</label>
            <a href={PolicyUrl} className={linkClass} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        </div>
    }
}