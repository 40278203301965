import React from 'react'
import AuthForm from './AuthForm.jsx'
import * as actions from 'redux/actions/auth'
import { connect } from 'react-redux'
import PatientSignup from './PatientSignup.jsx'
import DoctorSignup from './DoctorSignup.jsx'
import { UserType, ImageType } from 'helper/const'
import * as imageApi from 'api/image'
import * as emailApi from 'api/email'
import { Images } from 'helper/const'
import { compressBase64 } from 'helper/global'
import Storage from 'Storage/storage'
import { AlertDialog } from 'components'
import { Spinner } from 'components'


class Authentication extends React.Component {
    state = {
        enterProfile: 0,
        isLogin: true,

        alertDlgOpen: false,
        alertMessage: '',
        alertTitle: 'Authentication',

        showSpinner: false
    }

    componentWillMount() {
        const userTypeText = this.props.match.params.userType
        const authTypeText = this.props.match.params.authType
        const userType = userTypeText === 'patient' ? UserType.patient : UserType.doctor
        const isLogin = authTypeText === 'login'

        this.setState({
            userType,
            isLogin
        })

        localStorage.setItem("twitter_login_info", null)
    }


    setSigninFlag = () => {
        Storage.setValue("signin_flag", "1")
    }

    getSigninFlag = () => {
        return Storage.getValue("signin_flag", null)
    }

    notifyWelcomeIfNeeded = (userProfile) => {
        if (this.getSigninFlag()) {
            console.log("Signin flag found")
            return
        }

        // This is the very first time signin. Need to send welcome email
        let notifyName, notifyEmail

        if (userProfile.userType === UserType.patient) {
            // patient
            const { username, email } = userProfile

            notifyName = username
            notifyEmail = email
        } else if (userProfile.userType === UserType.doctor) {
            // doctor
            const { preFix, firstName, lastName, email } = userProfile

            notifyName = `${preFix || ''} ${firstName || ''} ${lastName || ''}`
            notifyEmail = email
        }

        emailApi.notifyWelcome(notifyName, notifyEmail)

        this.setSigninFlag()
    }

    componentWillReceiveProps(newProps) {
        // Log in
        if (newProps.isAuthenticating !== this.props.isAuthenticating && newProps.isAuthenticating === false) {
            this.setState({ showSpinner: false })

            if (newProps.isAuthed) {
                const profileData = newProps.userProfile
                this.notifyWelcomeIfNeeded(profileData)

                if (profileData.userType === UserType.patient) {   // patient
                    if (profileData.username) {
                        if (profileData.isActive) {
                            const { pushId } = newProps
                            const { patientUserID, userType } = profileData

                            this._updatePushInfo(pushId, patientUserID, userType)
                            this._remember()

                            this.props.history.replace('/dashboard')
                        } else {
                            this.showAlert(null, "Your account has been disabled. Please check email notification.")
                            newProps.dispatch(actions.logout())        
                        }
                    } else {
                        this.setState({ enterProfile: 1 })
                    }
                } else {                                                                // doctor
                    if (profileData.firstName) {
                        if (profileData.isActive) {
                            const { pushId } = newProps
                            const { doctorUserID, userType } = profileData

                            this._updatePushInfo(pushId, doctorUserID, userType)
                            this._remember()

                            this.props.history.replace('/dashboard')
                        } else {
                            this.showAlert(null, "Your application to register as a doctor is in review or your account has been banned. Please check email notification.")
                            newProps.dispatch(actions.logout())        
                        }
                    } else {
                        this.setState({ enterProfile: 2 })
                    }
                }
            }

            if (newProps.isAuthed === false && newProps.message) {
                this.showAlert(null, newProps.message)
            }
        }
        // Sign up
        else if (newProps.isUpdatingProfile !== this.props.isUpdatingProfile && newProps.isUpdatingProfile === false) {
            this.setState({ showSpinner: false })

            if (newProps.isUpdated === true) {
                const userProfile = newProps.userProfile
                if (userProfile.userType.toString() === UserType.patient.toString()) {  // Patient sign up
                    this.props.history.replace('/dashboard')
                    this._remember()
                } else {                                                                // Doctor sign up
                    this.showAlert(null, "You have signed up successfully. We'll notify you by email once your account is accepted. Thanks!")

                    // Notify admin for new doctor
                    emailApi.notifyAdminNewDoctor(userProfile.doctorUserID, `${userProfile.firstName} ${userProfile.middleName} ${userProfile.lastName}`)

                    // Log out
                    newProps.dispatch(actions.logout())
                    this.setState({enterProfile: 0})
                    this._remember()
                }
            } else {
                this.showAlert(null, newProps.message)
            }
        }
    }

    _remember() {
        if (this.isRememberMe) {
            Storage.setEmail(this.email)
            Storage.setPassword(this.password)
        } else {
            Storage.setEmail("")
            Storage.setPassword("")
        }

        Storage.setRememberMe(this.isRememberMe)
    }


    _updatePushInfo(pushId, userId, userType) {
        if (!pushId || !userId || !userType) {
            return
        }

        this.props.dispatch(actions.updatePushId(pushId, userId, userType))

        const tagValue = userType === UserType.patient ? 'Patient' : 'Doctor'
        const OneSignal = window.OneSignal || []

        OneSignal.sendTag('UserType', tagValue).then(function(tagsSent) {
            // Callback called when tags have finished sending
        })
    }


    handleAlert = () => {
        this.setState({
            alertDlgOpen: false
        })
    }


    showAlert = (title, message) => {
        this.setState(prevState => {
            const alertTitle = title ? title : prevState.alertTitle
            const alertMessage = message ? message : prevState.alertMessage

            return {
                alertTitle,
                alertMessage,
                alertDlgOpen: true
            }
        })
    }


    toggleLogin = () => {
        this.setState(prevState => {
            return {
                isLogin: !prevState.isLogin
            }
        })
    }


    onLogin(email, password, facebookId = null, twitterId = null, googleId = null, isRememberMe = false) {
        const userType = this.state.userType

        this.isRememberMe = isRememberMe
        this.email = email
        this.password = password

        this.props.dispatch(actions.login(userType, email, password, facebookId, twitterId, googleId))
    }


    onSignup(email, password, facebookId = null, twitterId = null, googleId = null, isRememberMe = false) {
        const userType = this.state.userType

        this.isRememberMe = isRememberMe
        this.email = email
        this.password = password

        this.props.dispatch(actions.signup(userType, email, password, facebookId, twitterId, googleId))
    }

    checkValidation(data) {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const element = data[key]
                if (!element) {
                    return false
                }
            }
        }

        return true
    }


    onUpdate = async (userProfile, agreed) => {
        const userType = userProfile.userType
        let data = null

        if (userType.toString() === UserType.patient.toString()) {
            // Patient sign up
            data = {
                email: userProfile.email,
                username: userProfile.username,
                sex: userProfile.sex,
                birthDate: userProfile.birthDate,
                illness: userProfile.illness,
                language: userProfile.language
            }

            // Check validation
            if (!this.checkValidation(data)) {
                this.showAlert(null, "Please complete all fields.")
                return
            }

            if (!agreed) {
                this.showAlert(null, "Please close eDocine app if you do not agree to Terms and Privacy Policy.")
                return
            }

            this.setState({ showSpinner: true })
            this.props.dispatch(actions.updateProfile(userType, userProfile.patientUserID, data))
        } else {
            // Doctor sign up
            data = {
                email: userProfile.email,
                preFix: userProfile.preFix,
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
                dateOfBirth: userProfile.dateOfBirth,
                language: userProfile.language,
                medicalSchool: userProfile.medicalSchool,
                yearOfGraduate: userProfile.yearOfGraduate,
                areaOfPractice: userProfile.areaOfPractice,
                licenseNumber: userProfile.licenseNumber,
                registrationBody: userProfile.registrationBody,
                qualification: userProfile.qualification,
                iDPhotoURL: userProfile.iDPhotoURL,
                iDPhoto2URL: userProfile.iDPhoto2URL,
                certificatePhotoURL: userProfile.certificatePhotoURL
            }

            // Check validation
            if (!this.checkValidation(data)) {
                this.showAlert(null, "Please complete all fields and attach ID & Practicing Certificate.")
                return
            }

            if (!agreed) {
                this.showAlert(null, "Please close eDocine app if you do not agree to Terms and Privacy Policy.")
                return
            }

            await this.setState({ showSpinner: true })

            data.middleName = userProfile.middleName

            // Upload images
            let rstImg = await imageApi.uploadImage(ImageType.DoctorRegistrationInfo, compressBase64(data.iDPhotoURL.split(",")[1]))
            if (rstImg.status === 0) {
                data.iDPhotoURL = rstImg.data
            } else {
                this.showAlert(null, "Failed to upload ID front image.")
                return
            }

            rstImg = await imageApi.uploadImage(ImageType.DoctorRegistrationInfo, compressBase64(data.iDPhoto2URL.split(",")[1]))
            if (rstImg.status === 0) {
                data.iDPhoto2URL = rstImg.data
            } else {
                this.showAlert(null, "Failed to upload ID back image.")
                return
            }

            rstImg = await imageApi.uploadImage(ImageType.DoctorRegistrationInfo, compressBase64(data.certificatePhotoURL.split(",")[1]))
            if (rstImg.status === 0) {
                data.certificatePhotoURL = rstImg.data
            } else {
                this.showAlert(null, "Failed to upload practicing certificate image.")
                return
            }

            this.props.dispatch(actions.updateProfile(userType, userProfile.doctorUserID, data))
        }
    }


    render() {
        const { isLogin, userType, enterProfile, alertDlgOpen, alertTitle, alertMessage } = this.state
        const { userProfile } = this.props

        let updatedUserProfile = {}
        if (userProfile && enterProfile > 0) {
            updatedUserProfile = JSON.parse(JSON.stringify(userProfile))

            const twitterInfo = localStorage.getItem("twitter_login_info")
            if (twitterInfo) {
                const twitterObj = JSON.parse(twitterInfo)
                if (twitterObj) {
                    const { username } = twitterObj

                    if (userType === UserType.patient) {
                        updatedUserProfile.username = username
                    } else {
                        updatedUserProfile.firstName = username
                    }
                }
            }
        }

        return <div className="authentication-container">
            {enterProfile === 0 && <div className="logo-view">
                <div className="logo"><img src={Images.logo} alt="Logo" /></div>
                <div className="logo-title">
                    <div>
                        <label className="e">e</label>
                        <label className="docine">Docine</label>
                    </div>
                </div>
                <div className="sub-title"><label>A Community that Connects Doctors and Patients</label></div>
            </div>}

            <div className="card">
                {enterProfile === 0 && <AuthForm
                    isLogin={isLogin}
                    userType={userType}
                    login={this.onLogin.bind(this)}
                    signup={this.onSignup.bind(this)}
                    toggleLogin={this.toggleLogin}
                />}

                {enterProfile === 0 && <button onClick={() => {this.props.history.goBack()}} className="back-link">Back</button>}

                {enterProfile === 1 &&
                    <PatientSignup
                        userProfile={updatedUserProfile}
                        onUpdate={this.onUpdate}
                        onBack={() => this.setState({ enterProfile: 0 })}
                    />
                }

                {enterProfile === 2 &&
                    <DoctorSignup
                        userProfile={updatedUserProfile}
                        onUpdate={this.onUpdate}
                        onBack={() => this.setState({ enterProfile: 0 })}
                    />
                }
            </div>

            <Spinner show={this.state.showSpinner} />

            <AlertDialog
                title={alertTitle}
                message={alertMessage}
                ok="OK"
                open={alertDlgOpen}
                onClose={this.handleAlert}
            />
        </div>
    }
}

function mapStateToProps(state) {
    const { auth } = state
    return auth
}

export default connect(mapStateToProps)(Authentication)
