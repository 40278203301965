import { connect } from 'react-redux'
import PatientProfile from './PatientProfile'

function mapStateToProps(state) {
    const { auth } = state

    const result = Object.assign(auth, {
        patientId: auth.userProfile.patientUserID
    })

    return result
}

export default connect(mapStateToProps)(PatientProfile)
