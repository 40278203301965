import React from 'react'

import * as global from 'helper/global'
import { UserType, Images, DISPLAY_MAX_LEN } from 'helper/const'
import { Button } from "@material-ui/core"


export default function FeedItem2({ ...props }) {
    // Question & Answer
    const {
        data,
        userType,
        handleLike,
        handleAgree,
        handleDoctorKnow,
        handleDetail,
        handleEditFeed,
        handleEditReply,
        userId,
        articleLink
    } = props

    const {
        Title,
        Body,
        ImageURL,
        replies,
        timeCreated,
        feedID,
        FeedType,
        DoctorId
    } = data


    const editable = parseInt(userType, 10) === UserType.doctor && parseInt(DoctorId, 10) === parseInt(userId, 10)

    // Patient avatar
    let avatar = Images.patientAvatar

    // Created time
    const diffTime = global.diffTime(global.string2Time(timeCreated), new Date())
    let questionOverflowed, BodyShow

    if (Body.length > DISPLAY_MAX_LEN) {
        questionOverflowed = true
        BodyShow = Body.slice(0, DISPLAY_MAX_LEN) + '...'
    } else {
        questionOverflowed = false
        BodyShow = Body
    }


    const articleAvailable = articleLink && articleLink.trim().length > 0
    const readmoreHtml = (feedID, className) => {
        if (articleAvailable) {
            return (
                <a href={articleLink} target="_blank" rel="noopener noreferrer">
                    <Button
                        className={className}>
                        Read More
                    </Button>
                </a>
            )
        } else {
            return (
                <Button
                    className={className}
                    onClick={() => handleDetail(feedID)}>
                    Read More
                </Button>
            )
        }
    }

    return (
        <div className="feeds-item">
            {ImageURL && <div className="post-image-wrapper">
                <img src={ImageURL} className='post-image' alt="..."/>
            </div>}

            <div className="feed2-title-wrapper"><label className="title">{Title}</label></div>

            <div className="profile-view">
                <img className="avatar" src={avatar} alt="..."/>

                <div className="info-wrapper">
                    <div className="info">
                        <label className="name">Patient</label>
                        <label className="time">{diffTime}</label>
                    </div>

                    {editable && <div className="info-edit">
                        <Button
                            className="edit-feed-button"
                            onClick={() => handleEditFeed(feedID)}>Edit</Button>
                    </div>}
                </div>
            </div>

            <label className="description">{BodyShow}</label>

            {questionOverflowed && readmoreHtml(feedID, "user-know-button read-more agree-button")}

            {replies && replies.map((reply, index) => {
                const { doctor, body, imageURL, feedReplyID} = reply
                if (!doctor) {
                    return null
                }

                let answerOverflowed, bodyShow
                if (body.length > DISPLAY_MAX_LEN) {
                    answerOverflowed = true
                    bodyShow = body.slice(0, DISPLAY_MAX_LEN) + '...'
                } else {
                    answerOverflowed = false
                    bodyShow = body
                }
                
                const {
                    doctorUserID,
                    preFix,
                    firstName,
                    lastName,
                    helped,
                    helpedTotal,
                    agreedCount,
                    agreed,
                    isLike,
                    known,
                    areaOfPractice,
                    knownCount
                } = doctor

                const replyEditable = parseInt(userType, 10) === UserType.doctor && parseInt(doctorUserID, 10) === parseInt(userId, 10)

                // Doctor avatar
                let doctorImg = Images.doctorAvatar
                if (doctor.profilePhotoURL && doctor.profilePhotoURL.length > 0) {
                    doctorImg = doctor.profilePhotoURL
                }

                // Doctor name
                let name = `${preFix || ''} ${firstName || ''} ${lastName || ''}`

                return (
                    <div key={feedReplyID} className="feeds-item bottom-border">
                        {imageURL && <div className="post-image-wrapper">
                            <img src={imageURL} className='post-image' alt="..."/>
                        </div>}

                        <div className="profile-view">
                            <a href={"/#/dashboard/service_detail/" + doctorUserID}>
                                <img className="avatar" src={doctorImg} alt="..."/>
                            </a>


                            <div className="info-wrapper">
                                <div className="info">
                                    <div>
                                        <a href={"/#/dashboard/service_detail/" + doctorUserID}>
                                            <label className="name">{name || ''}&nbsp;</label>
                                        </a>

                                        <label className="practice">{areaOfPractice || ''}</label>
                                    </div>

                                    <div className="sub-info">
                                        <label className="helped total bottom-pad">{helpedTotal || '0'}</label>
                                        <label className="caption">Patients Helped</label>
                                    </div>
                                </div>

                                {replyEditable && <div className="info-edit">
                                    <Button
                                        className="edit-feed-button"
                                        onClick={() => handleEditReply(feedReplyID)}>Edit</Button>
                                </div>}
                            </div>
                        </div>

                        <label className="description">{bodyShow}</label>

                        {answerOverflowed && readmoreHtml(feedID, "agree-button read-more")}

                        <div className="extra-info info">
                            <div className="sub-info">
                                <div className="sub-info pad">
                                    <label className="caption">Doctor{agreedCount > 1 ? 's' : ''} Agreed:</label>
                                    <label className="agreed-count">{agreedCount || '0'}</label>
                                </div>

                                <div className="sub-info pad">
                                    <label className="caption">Patient{helped > 1 ? 's' : ''} Helped:</label>
                                    <label className="helped">{helped || '0'}</label>
                                </div>

                                <div className="sub-info pad">
                                    <label className="caption">User{knownCount > 1 ? 's' : ''} Know:</label>
                                    <label className="helped">{knownCount || '0'}</label>
                                </div>
                            </div>
                        </div>

                        <div className="info">
                            <div className="sub-info buttons pt-0">
                                {userType === UserType.doctor &&
                                    <Button
                                        className="agree-button read-more"
                                        disabled={agreed}
                                        onClick={() => handleAgree(index, feedID, doctorUserID, FeedType, !agreed)}>
                                        {agreed ? 'Agreed' : 'I Agree'}
                                    </Button>
                                }

                                <Button
                                    className="user-know-button read-more"
                                    disabled={known}
                                    onClick={() => handleDoctorKnow(index, doctorUserID, !known)}>
                                    I Know Doctor
                                </Button>

                                <Button className="like-btn" onClick={() => handleLike(index, feedID, doctorUserID, FeedType, !isLike)}>
                                    <i className={`${isLike ? 'fas' : 'far'} fa-heart`}/>
                                </Button>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

