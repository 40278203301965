import React from "react";
import { NavLink } from "react-router-dom";
import {
    ListItem,
    ListItemText,
    Hidden,
    withStyles
} from "@material-ui/core";

import headerLinksStyle from "assets/jss/material-dashboard-react/sidebarStyle";
import {
    UserType,
    Images
} from 'helper/const'
import { connect } from 'react-redux'

class HeaderLinks extends React.Component {
    render() {
        const { classes, userName, avatar, path, onClose } = this.props;
        return (
            <div>
                <NavLink
                    to={path}
                    className={classes.item}
                    activeClassName="active"
                    onClick={onClose}>
                    <div>
                        <ListItem
                            button
                            className={classes.itemLink}>
                            <Hidden smDown>
                                <ListItemText
                                    primary={userName}
                                    className={classes.itemText}
                                    disableTypography={true} />

                                <img
                                    src={avatar}
                                    alt={path}
                                    className={classes.itemIconImg}
                                    style={{borderRadius: '50%', marginLeft: 8, marginRight: 8, marginBottom: 4}} />
                            </Hidden>

                            <Hidden mdUp>
                                <img
                                    src={avatar}
                                    alt={path}
                                    className={classes.itemIconImgMobile}
                                    style={{borderRadius: '50%', marginLeft: 8, marginRight: 8, marginBottom: 8}} />

                                <ListItemText
                                    primary={userName}
                                    className={classes.itemTextUserMobile}
                                    disableTypography={true} />
                            </Hidden>
                        </ListItem>
                    </div>
                </NavLink>
            </div>
        )
    }
}

const HeaderLinksWithStyle =  withStyles(headerLinksStyle)(HeaderLinks)

function mapStateToProps(state) {
    const { auth } = state
    const { isAuthed, userProfile } = auth

    var userName, avatar, path

    if (isAuthed) {
        const { userType } = userProfile

        if (userType === UserType.patient) {
            const { username, photoProfileURL } = userProfile

            userName = username
            avatar = (photoProfileURL && photoProfileURL.length > 0) ? photoProfileURL : Images.patientAvatar
            path = '/dashboard/user'
        }

        if (userType === UserType.doctor) {
            const { preFix, firstName, lastName, profilePhotoURL } = userProfile

            userName = `${preFix} ${firstName} ${lastName}`
            avatar = (profilePhotoURL && profilePhotoURL.length > 0) ? profilePhotoURL : Images.doctorAvatar
            path = '/dashboard/user'
        }
    }

    return {
        userName,
        avatar,
        path
    }
}

export default connect(mapStateToProps)(HeaderLinksWithStyle)
