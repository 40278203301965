import apiRequest, { apiRestRequest } from './apiRequest'
import { UserType, OneSignalAppId } from 'helper/const'

export function getNotifications(userId, userType) {
    if (userType === UserType.patient) {
        return apiRequest('notificationsGetByPatient', {patientId: userId})
    } else {
        return apiRequest('notificationsGetByDoctor', {doctorId: userId})
    }
}

export function delNotification(notificationID) {
    return apiRestRequest('notifications', 'delete', null, notificationID)
}

export function delAllNotifications(userId, userType) {
    if (userType === UserType.patient) {
        return apiRequest('notificationsDeleteByPatient', {patientId: userId})
    } else {
        return apiRequest('notificationsDeleteByDoctor', {doctorId: userId})
    }
}

export function createNotification(notification) {
    return apiRestRequest('notifications', 'post', notification)
}

/* Push users */
export function getAllDoctors() {
    return apiRequest('pushUsersGetAllDoctors', {
        tableField: "doctor",
        userId: "0"
    })
}

export function getAllDoctorsByDoctorId(doctorId) {
    return apiRequest('pushUsersGetAllDoctors', {
        tableField: "doctor",
        userId: doctorId
    })
}

export function getPushUsers(userId, userType) {
    switch (userType) {
        case UserType.patient:
            return apiRequest('pushUsersGetByPatient', { patient: userId })
        case UserType.doctor:
            return apiRequest('pushUsersGetByDoctor', { doctor: userId })
        case UserType.provider:
            return apiRequest('pushUsersGetByDoctor', { provider: userId })
        default:
            return null
    }
}

export function deletePushUsersByPushId(pushId) {
    return apiRequest('deletePushUsersByPushId', { pushId })
}

export function createPushUser(pushId, userId, userType, isLoggedIn) {
    var params = {
        pushId,
        isLogged: isLoggedIn ? 1 : 0
    }

    if (userType === UserType.patient) {
        params.patient = userId
    } else if (userType === UserType.doctor) {
        params.doctor = userId
    } else if (userType === UserType.provider) {
        params.provider = userId
    }

    return apiRestRequest('pushUsers', 'post', params)
}

export function updatePushUser(pushId, isLoggedin) {
    return apiRequest('updatePushLoginState', {
        isLogged: isLoggedin ? 1 : 0,
        pushId
    })
}

function sendNotification(data) {
    var headers = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Basic YmMzYWFmZDgtZTE0MS00YmViLWI0MDAtMmFmOTEwNDVlOTk4"
    }

    var options = {
        host: "onesignal.com",
        port: 443,
        path: "/api/v1/notifications",
        method: "POST",
        headers: headers
    }

    var https = require('https')
    var req = https.request(options, function(res) {  
        res.on('data', function(data) {
        })
    })

    req.on('error', function(e) {
        console.log("ERROR:", e)
    })

    req.write(JSON.stringify(data))
    req.end()
}

export function sendPush(targetPushUserIds, message, additional = null) {
    let notification = {
        app_id: OneSignalAppId,
        contents: { en: message },
        include_player_ids: targetPushUserIds,
        ios_badgeType: "Increase",
        ios_badgeCount: "1",
        web_url: 'https://www.edocineapp.com/#/dashboard/history'
    }

    if (additional) {
        notification.data = additional

        if (additional.action === 'PostArticle') {
            notification.web_url = 'https://www.edocineapp.com/#/dashboard/feed'
            notification = Object.assign(notification, {
                headings: {
                    en: additional.title
                }
            })

            if (additional.media) {
                notification = Object.assign(notification, {
                    big_picture: additional.media,
                    large_icon: additional.media,
                    chrome_web_image: additional.media,
                    adm_big_picture: additional.media,
                    chrome_big_picture: additional.media,
                    ios_attachments: {
                        image: additional.media
                    }
                })
            }
        }
    }

    sendNotification(notification)
}