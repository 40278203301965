import React from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { UserType, Images } from 'helper/const'
import * as actions from 'redux/actions/favorites'
import { connect } from 'react-redux'


function PatientFavoriteItem({...props}) {
    const { avatar, name, areaOfPractice, index } = props

    return (
        <ListItem button className="item" onClick={() => {props.onClick(index)}}>
            <img src={avatar} className="favourite-avatar" alt="..." style={{marginLeft: "10px"}}/>
            <ListItemText inset primary={name} secondary={areaOfPractice}  classes={{primary: "name", secondary: "practice"}} />
            <i className="fa fa-arrow-right arrow"  style={{marginRight: "10px"}}/>
        </ListItem>
    )
}


function DoctorFavoriteItem({...props}) {
    const {avatar, name, index} = props

    return (
        <ListItem button className="item" onClick={() => {props.onClick(index)}}>
        <img src={avatar} className="favourite-avatar" alt="..."/>
        <ListItemText inset primary={name} classes={{primary: "name"}}/>
            <i className="fa fa-arrow-right arrow"/>
        </ListItem>
    )
}


class Favorites extends React.Component {
    constructor(props) {
        super(props)
        const { userType, userId } = props

        if (userType && userId) {
            this.props.dispatch(actions.getFavorites(userType, userId))
        }
    }

    navToService = (index) => {
        this.props.history.push("/dashboard/service_detail/" + index)
    }

    navToPatient = (index) => {
        this.props.history.push("/dashboard/patient_detail/" + index)
    }

    favoriteItems = (userType, items) => {
        console.log("Draw:", userType, items)

        /* Patient */
        if (userType.toString() === UserType.patient.toString()) {
            return items.map((item, index) => {
                const { doctor } = item
                if (!doctor) {
                    return null
                }

                let avatar = (doctor.profilePhotoURL && doctor.profilePhotoURL.length > 0) ? doctor.profilePhotoURL : Images.doctorAvatar
                let name = ""

                if (doctor.firstName) {
                    name = doctor.firstName
                }

                if (doctor.lastName) {
                    name = name + " " + doctor.lastName
                }

                const areaOfPractice = doctor.areaOfPractice
                return (
                    <PatientFavoriteItem
                        avatar={avatar}
                        name={name}
                        areaOfPractice={areaOfPractice}
                        index={doctor.doctorUserID}
                        key={index}
                        onClick={this.navToService}
                        />
                )
            })
        } else {
            /* Doctor */
            return items.map((item, index) => {
                const patient = item.patient
                if (!patient) {
                    return null
                }

                let avatar = (patient.photoProfileURL && patient.photoProfileURL.length > 0) ? patient.photoProfileURL : Images.patientAvatar
                let name = patient.username

                return (
                    <DoctorFavoriteItem
                        avatar={avatar}
                        name={name}
                        index={item.patient.patientUserID}
                        key={index}
                        onClick={this.navToPatient} />
                )
            })
        }
    }

    render() {
        const { userType, favorites } = this.props
        let { data, searchText } = favorites ? favorites : {data: []}

        if (searchText && searchText.length > 0) {
            if (userType === UserType.doctor) {
                data = data.filter(x => {
                    const { username } = x.patient
                    const name = `${username}`

                    return name.toLowerCase().includes(searchText.toLowerCase())
                })
            } else {
                data = data.filter(x => {
                    const { firstName, middleName, lastName } = x.doctor
                    const name = `${firstName} ${middleName} ${lastName}`

                    return name.toLowerCase().includes(searchText.toLowerCase())
                })
            }
        }

        return (
            <div className="favorites-container">
                <div className="title">Favourites</div>

                <List component="nav">
                    {this.favoriteItems(userType, data)}
                </List>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, favorites } = state

    let userType = UserType.unknown
    let userId = 0

    if (auth && auth.userProfile) {
        userType = auth.userProfile.userType
        userId = userType.toString() === UserType.patient.toString() ? auth.userProfile.patientUserID : auth.userProfile.doctorUserID
    }

    return {
        userType,
        userId,
        favorites
    }
}

export default connect(mapStateToProps)(Favorites)
