import apiRequest, { apiRestRequest } from './apiRequest'
import { UserType, FeedType } from 'helper/const'

export function getQuestions(userId, userType) {
    if (userType === UserType.patient) {
        return apiRequest('questionListGetByPatientID', {patientId: userId, feedType: FeedType.patientPosted})
    } else {
        return apiRequest('questionListGet', {isReplied: 0, feedType: FeedType.patientPosted})
    }
}

export function createFeed(model) {
    return apiRestRequest('feeds', 'post', model)
}

export function updateFeed(feedId, model) {
    return apiRestRequest('feeds', 'put', model, feedId)
}

export function isQuestionReplied(feedId, doctorId) {
    return apiRequest('feedRepliesCheckIsReplied', {feedId, doctorId})
}

export function createFeedReply(model) {
    return apiRestRequest('feedReplies', 'post', model)
}

export function updateFeedReply(feedReplyId, model) {
    return apiRestRequest('feedReplies', 'put', model, feedReplyId)
}
