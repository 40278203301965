import React from 'react'
import { connect } from 'react-redux'
import { IconButton, Button, Grid } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import { ItemGrid } from "components"
import { compressBase64 } from 'helper/global'
import { ImageType } from 'helper/const'

import * as noteActions from 'redux/actions/note'
import * as imageApi from 'api/image'

class NoteDetail extends React.Component {
    constructor(props) {
        super(props)

        if (!props.patientNote) {
            props.history.goBack()
        }
    }

    componentWillReceiveProps(newProps) {
        const props = this.props

        if (newProps.noteStatus !== props.noteStatus) {
            if (newProps.noteStatus === 0) {
                this.props.history.goBack()
            } else if(newProps.noteStatus === 2) {
                console.log(newProps.message)
            }
        }
    }

    handleTextChange = (e) => {
        let patientNote = {...this.props.patientNote, noteText: e.target.value}
        this.props.dispatch(noteActions.setCurrentNoteAction(patientNote))
    }

    handleImageDetail = (url) => {
        this.props.history.push({
            pathname: '/dashboard/image_detail',
            state: {
                imageUrl: url
            }
        })
    }

    onPickFile = (imgFile) => {
        if (imgFile) {
            let reader = new FileReader()

            reader.onload = (e) => {
                const filePath = e.target.result

                let patientNote = {...this.props.patientNote, noteDataURL: filePath}
                this.props.dispatch(noteActions.setCurrentNoteAction(patientNote))
            }

            reader.readAsDataURL(imgFile)
        }
    }

    handleSave = async () => {
        const props = this.props
        if (!props.patientNote) {
            return
        }

        const patientNote = {...props.patientNote}
        
        if (patientNote.noteDataURL) {
            const splitData = patientNote.noteDataURL.split(",")
            if (splitData.length === 2) {
                const uploadData = splitData[1]
                // Upload images
                var rstImg = await imageApi.uploadImage(ImageType.DoctorNotes, compressBase64(uploadData))
                if (rstImg.status === 0) {
                    patientNote.noteDataURL = rstImg.data
                } else {
                    console.log("Failed to upload attach image.")
                    return
                }
            }
        }

        const { patientNoteID, noteText, noteDataURL, patientId, doctorId } = patientNote
        this.props.dispatch(noteActions.setNoteAction(patientNoteID, noteText, noteDataURL, patientId, doctorId))
    }

    render() {
        const { patientNote } = this.props
        if (!patientNote) {
            return null
        }

        const { noteDataURL, noteText } = patientNote

        return (
            <div className="note-detail-top-container">
                <div className="horz-container title-view">
                    <div className="title-wrapper">
                        <IconButton
                            className="nav-btn"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => window.history.back()}>
                            <ArrowBack />
                        </IconButton>
                        <div className="title">Note</div>
                    </div>

                    <Button
                        className="nav-filter-button"
                        onClick={this.handleSave.bind(this)}>Save</Button>
                </div>

                <Grid container className="note-detail-container" justify='center'>
                    <ItemGrid xs={12} sm={12} md={12}>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start">
                            <label className="note-caption">Note:</label>
                            <textarea className="note-content" defaultValue={noteText} onChange={this.handleTextChange}/>
                            <img src={noteDataURL} alt="" className="note-img" onClick={this.handleImageDetail.bind(this, noteDataURL)}/>
                            <div className='relative-container item-container'>
                                <div className="file-input-wrapper">
                                    <Button className="picker-button">
                                        <label className='item-input placeholder'>Attach a file&nbsp;<i className="fas fa-paperclip ico-content"/></label>
                                    </Button>

                                    <input
                                        href="fileInput"
                                        type="file"
                                        onChange={(event) => {
                                            const files = event.target.files
                                            if (files.length > 0) {
                                                this.onPickFile(files[0])
                                            }
                                        }}/>
                                </div>
                            </div>
                        </Grid>
                    </ItemGrid>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { note } = state
    const {currentNote: patientNote, noteStatus, message} = note

    return {
        patientNote,
        noteStatus,
        message
    }
}

export default connect(mapStateToProps)(NoteDetail)
