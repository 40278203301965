import React from 'react'
import { connect } from 'react-redux'
import { IconButton, Button } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import {
    AlertDialog,
    DropDown,
    Spinner
} from 'components'
import {
    UserType,
    PaymentMethods
} from 'helper/const'

import * as paymentAction from 'redux/actions/payment'
import * as emailApi from 'api/email'

class DoctorPaymentSetting extends React.Component {
    state = {
        alertDlgOpen: false,
        alertMessage: '',
        alertTitle: 'Payment And Withdrawal',

        paymentSetting: {
            paypalEmail: '',
            checkMailingAddress: '',
            bankAccountNumber: '',
            bankName: '',
            bankBranch: '',
            bankBeneficiary: '',
            bankAccountType: '',
            bankBeneficiaryEmail: ''
        },
        balance: 0,
        withdrawMoney: '',
        showSpinner: false
    }

    constructor(props) {
        super(props)
        const {userId} = props
        if (userId) {
            this.props.dispatch(paymentAction.getDoctorPaymentSettingAction(userId))
        }
    }

    showAlert = (title, message) => {
        this.setState(prevState => {
            const alertTitle = title ? title : prevState.alertTitle
            const alertMessage = message ? message : prevState.alertMessage
            return {
                alertTitle,
                alertMessage,
                alertDlgOpen: true
            }
        })
    }

    componentWillReceiveProps(newProps) {
        this._initSetting(newProps)

        const { withdrawStatus } = newProps

        if (withdrawStatus === null || typeof withdrawStatus === 'undefined') {
            // Do nothing
        } else if (withdrawStatus === 1) {
            // Withdraw started
            this.withdrawStarted = true
            this.setState({ showSpinner: true })
        } else if (withdrawStatus === 2) {
            // Withdraw failed
            this.withdrawStarted = false
            this.setState({ showSpinner: false })
            alert("Failed to withdraw. Please try again later")
        } else if (withdrawStatus === 0) {
            // Withdraw succeeded
            this.withdrawStarted = false
            alert("Withdrawal was successful")

            const { withdrawMoney } = this.state
            const { userId } = this.props

            emailApi.notifyWithdrawal(withdrawMoney, userId)
        }
    }

    _initSetting(props) {
        const { paymentSetting, balance } = props
        this.setState({
            paymentSetting,
            balance
        })
    }

    handleChangeText = (key, value) => {
        this.setState(prevState => {
            var { paymentSetting } = prevState
            paymentSetting[key] = value
            
            return {
                paymentSetting
            }
        })
    }

    handleChangeWithdrawMoney = (withdrawMoney) => {
        this.setState({ withdrawMoney })
    }

    handleSave = () => {
        const { paymentSetting, userId } = this.state
        const {
            paymentMethod,
            paypalEmail,
            checkMailingAddress,
            bankAccountNumber,
            bankName,
            bankBranch,
            bankBeneficiary,
            bankAccountType,
            bankBeneficiaryEmail
        } = paymentSetting

        // Check validation
        if (!paymentMethod) {
            this.showAlert(null, "Please select a payment method.")
            return
        }

        switch (paymentMethod) {
            case "Paypal":
                if (!paypalEmail) {
                    this.showAlert(null, "Please fill in Paypal email.")
                    return
                }
                break
            case "Cheque":
                if (!checkMailingAddress) {
                    this.showAlert(null, "Please fill in Cheque Mailing Address.")
                    return
                }
                break
            default:
                if (!bankAccountNumber || !bankName || !bankBranch || !bankBeneficiary || !bankAccountType || !bankBeneficiaryEmail) {
                    this.showAlert(null, "Please fill in Bank Transfer information.")
                    return
                }
                break
        }

        if (!paymentSetting.doctor) {
            paymentSetting.doctor = userId
        }

        this.props.dispatch(paymentAction.saveDoctorPaymentSettingAction(paymentSetting.doctorPaymentMethodID, paymentSetting))
    }

    handleWithdraw = () => {
        const { withdrawMoney, balance } = this.state
        const { userId } = this.props

        if (!withdrawMoney) {
            this.showAlert(null, "Please input withdraw amount.")
            return
        }

        if (withdrawMoney > balance) {
            this.showAlert(null, "Withdrawal amount must be euqal to or less than in-app balance.")
            return
        }

        this.props.dispatch(paymentAction.doctorWithdrawAction(userId, withdrawMoney, balance))
    }

    render() {
        const { paymentSetting, balance, withdrawMoney } = this.state
        const {
            paymentMethod,
            paypalEmail,
            checkMailingAddress,
            bankAccountNumber,
            bankName,
            bankBranch,
            bankBeneficiary,
            bankAccountType,
            bankBeneficiaryEmail
        } = paymentSetting

        const balanceDisplay = `Balance $${balance.toFixed(2)} USD`

        return (
            <div className='doctor-payment-setting-container'>
                <div className="title-wrapper">
                    <IconButton
                        className="nav-btn"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => window.history.back()}>
                        <ArrowBack />
                    </IconButton>
                    <div className='title'>Payment And Withdrawal</div>
                </div>

                <DropDown
                    classes={{
                        root: "dropdown-container"
                    }}
                    value={ paymentMethod }
                    options={ PaymentMethods }
                    onChange={ (val) => {this.handleChangeText('paymentMethod', val)} }
                    containerClass="item"
                    itemClass={!paymentMethod ? "item-input placeholder" : 'item-input'}
                    menuItemClass={'item-input menu-item'}
                    IconComponent={<i className="fa fa-angle-down ico-content"/>}
                />

                <div className="divider"/>

                <input
                    className="item-input"
                    placeholder='Paypal Email'
                    value={paypalEmail}
                    onChange={(e) => {this.handleChangeText('paypalEmail', e.target.value)}}
                />

                <div className="divider"/>

                <input
                    className="item-input"
                    placeholder='Cheque Mailing Address'
                    value={checkMailingAddress}
                    onChange={(e) => {this.handleChangeText('checkMailingAddress', e.target.value)}}
                />

                <div className="divider"/>

                <input
                    className="item-input"
                    placeholder='Bank Account Number'
                    value={bankAccountNumber}
                    onChange={(e) => {this.handleChangeText('bankAccountNumber', e.target.value)}}
                />
                <input
                    className="item-input"
                    placeholder='Bank Name'
                    value={bankName}
                    onChange={(e) => {this.handleChangeText('bankName', e.target.value)}}
                />
                <input
                    className="item-input"
                    placeholder='Bank Branch'
                    value={bankBranch}
                    onChange={(e) => {this.handleChangeText('bankBranch', e.target.value)}}
                />
                <input
                    className="item-input"
                    placeholder='Bank Account Type'
                    value={bankAccountType}
                    onChange={(e) => {this.handleChangeText('bankAccountType', e.target.value)}}
                />
                <input
                    className="item-input"
                    placeholder='Bank Beneficiary'
                    value={bankBeneficiary}
                    onChange={(e) => {this.handleChangeText('bankBeneficiary', e.target.value)}}
                />
                <input
                    className="item-input"
                    placeholder='Bank Beneficiary Email'
                    value={bankBeneficiaryEmail}
                    onChange={(e) => {this.handleChangeText('bankBeneficiaryEmail', e.target.value)}}
                />

                <div className="divider"/>

                <Button className="doctor-btn" onClick={this.handleSave}>Save</Button>

                <div className="divider"/>

                <label className="balance">{balanceDisplay}</label>
                <input
                    className="item-input"
                    placeholder='Withdraw Money'
                    value={withdrawMoney}
                    onChange={(e) => {this.handleChangeWithdrawMoney(e.target.value)}}
                />
                <Button className="doctor-btn" onClick={this.handleWithdraw}>Send</Button>

                <Spinner show={this.state.showSpinner} />

                <AlertDialog
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    ok="OK"
                    open={this.state.alertDlgOpen}
                    onClose={() => {this.setState({
                        alertDlgOpen: false
                    })}}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, payment } = state
    const { userProfile } = auth
    if (!userProfile) {
        return null
    }
    const { userType, patientUserID, doctorUserID } = userProfile
    const userId = (userType === UserType.patient) ? patientUserID : doctorUserID

    var paymentSetting, balance
    if (payment) {
        paymentSetting = payment.paymentSetting
        balance = payment.balance
    }
    return {
        userId,
        userType,
        paymentSetting,
        balance
    }
}

export default connect(mapStateToProps)(DoctorPaymentSetting)
