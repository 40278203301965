import React from 'react'
import { IconButton } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import moment from 'moment'

export default class FeedQuestionDetail extends React.Component {
    handleImageDetail = (url) => {
        const {handleImageDetail} = this.props
        if (handleImageDetail) {
            handleImageDetail(url)
        } else {
            window.open(url)
        }
    }

    render() {
        const { feed } = this.props
        const { Title, timeCreated, Body, ImageURL, replies } = feed
        const reply = (replies && replies.length > 0) ? replies[0] : {}
        const { body, imageURL} = reply

        const mTime = moment(timeCreated)
        const dispTime = mTime.format('hh:mm a DD/MM/YYYY')

        return (
            <div className="feed-question-detail-container">
                <div className="title-wrapper padding">
                    <IconButton
                        className="nav-btn"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => window.history.back()}>
                        <ArrowBack />
                    </IconButton>
                    <label className="question">{Title}</label>
                </div>

                <label className="when">{dispTime}</label>

                <label className="question-body">{Body}</label>

                {ImageURL && <img className="tile-img" onClick={() => {this.handleImageDetail(ImageURL)}} src={ImageURL} alt="..."/>}

                <div className="divider"/>

                <label className="answer">Answer</label>

                {body && <label className="answer-body">{body}</label>}

                {imageURL && <img className="tile-img" onClick={() => {this.handleImageDetail(imageURL)}} src={imageURL} alt="..."/>}
            </div>
        )
    }
}