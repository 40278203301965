import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'

import * as notificationApi from 'api/notification'
import * as actions from 'redux/actions/auth'

/**
 * Dialog with action buttons. The actions are passed in as an array of React objects,
 * in this example [FlatButtons](/#/components/flat-button).
 *
 * You can also close this dialog by clicking outside the dialog, or with the 'Esc' key.
 */
class Logout extends React.Component {
    constructor(props) {
        super(props)

        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)

        this.state = { open: true }
    }

    handleLogout = () => {
        const { auth, dispatch, history } = this.props

        console.log("Push ID For Logout:", auth.pushId)
        dispatch(actions.logout())
        notificationApi.updatePushUser(auth.pushId, false)
        history.replace("/")
    }

    handleClose = () => {
        this.setState({ open: false })
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="logout-container">
                <Modal show={this.state.open} onHide={this.handleClose} enforceFocus={false} className="modal-center">
                    <Modal.Header closeButton>
                        <Modal.Title>Log out</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p>Do you really want to log out?</p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={this.handleClose}>No</Button>
                        <Button bsStyle="primary" onClick={this.handleLogout}>Yes</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth } = state
    return {
        auth
    }
}

export default connect(mapStateToProps)(Logout)
