import fetch from 'cross-fetch'
import { Api } from 'helper/const'

const authToken = 'Basic ' + btoa(Api.apiKey + ':' + Api.secretKey)
const authRestToken = 'Basic ' + btoa(Api.restToken)

export default function apiRequest(relativeUrl, params) {
    var url = Api.baseUrl + Api.apiKey + "/" + relativeUrl + ".json"

    var body = []
    var key;
    for (key in params) {
        if (params.hasOwnProperty(key)) {
            const element = params[key];
            body.push('params[' + key + ']=' + element + '&')
        }
    }
    body = body.join('&')
    
    return new Promise(resolve => {
        fetch(url, {
            headers: {
                'Authorization': authToken,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'POST',
            body
        }).then(response => {
            if (response.status !== 200) {
                resolve({
                    status: 1,
                    message: response.statusText
                })
            } else {
                return response.json()
                .then( result => {
                    if (result.responseCode === 1) {
                        resolve({
                            status: 0,
                            data: result.payload
                        })
                    } else {
                        resolve({
                            status: 2,
                            message: result.responseMessage
                        })
                    }    
                })
            }
        })
    })
}

export function apiRestRequest(relativeUrl, method, data, id = null) {
    var url = Api.restBaseUrl + relativeUrl
    if (id) {
        url = url + "/" + id
    }
    const body = data === null || method === 'get' ? null : JSON.stringify(data)
    
    return new Promise(resolve => {
        fetch(url, {
            headers: {
                'Authorization': authRestToken,
                'Content-Type': 'application/json',
            },
            method,
            body
        }).then(response => {
            if (response.ok === true) {
                if (response.status === 200) {
                    return response.json()
                    .then( result => {
                        resolve({
                            status: 0,
                            data: result
                        })
                    })
                    .catch( () => {
                        resolve({
                            status: 0
                        })
                    })
                } else if (response.status === 201 || response.status === 204) {
                    resolve({
                        status: 0,
                        data: response.headers.get("Location")
                    })
                }
            } else {
                return response.json()
                .then (result => {
                    resolve({
                        status: 1,
                        message: result.error.message
                    })
                })
            }
        }).catch(err => {
            resolve({
                status: 2,
                message: err
            })
        })
    })
}

export function sendGenRequest(url, method = 'get', data = null) {
    const body = data === null || method === 'get' ? null : JSON.stringify(data)

    return new Promise(resolve => {
        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
            },
            method,
            body
        }).then(response => {
            if (response.ok === true) {
                if (response.status === 200) {
                    return response.json()
                    .then( result => {
                        resolve({
                            status: 0,
                            data: result
                        })
                    })
                    .catch( () => {
                        resolve({
                            status: 0
                        })
                    })
                } else if (response.status === 201 || response.status === 204) {
                    resolve({
                        status: 0,
                        data: response.headers.get("Location")
                    })
                }
            } else {
                return response.json()
                .then (result => {
                    resolve({
                        status: 1,
                        message: result.error ? result.error.message : result.Message
                    })
                })
            }
        }).catch(err => {
            resolve({
                status: 2,
                message: err
            })
        })
    })
}