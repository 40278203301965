import React from "react"
import { Button } from "@material-ui/core"
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Typography,
    withStyles
} from "@material-ui/core"
import PropTypes from "prop-types"
import profileCardStyle from "assets/jss/material-dashboard-react/profileCardStyle"

function ProfileCard({ ...props }) {
    const {
        classes,
        subtitle,
        title,
        description,
        footer,
        avatar,
        onAvatarChange,
        readOnly,
        showFavorite,
        favorite,
        onHandleFavorite
    } = props

    return (
        <Card className={classes.card}>
            <CardHeader
                classes={{
                    root: classes.cardHeader,
                    avatar: classes.cardAvatar
                }}
                avatar={
                    <div className="file-input-wrapper-main-cont">
                        <div className="file-input-wrapper">
                            <input href="fileInput" type="file"
                                disabled={readOnly ? true : false}
                                onChange={(event) => {
                                    const files = event.target.files
                                    if (files.length > 0 && onAvatarChange) {
                                        onAvatarChange(files[0])
                                    }
                                }}/>
                            <img src={avatar} alt="..." className={classes.img} />
                        </div>

                        {showFavorite && <Button onClick={() => onHandleFavorite()} className="fav-btn patient-favorite">
                            <i className={favorite ? "fas fa-star fa-0.5x star" : "far fa-star star"}></i>
                        </Button>}
                    </div>
                } />

            <label className={classes.textAlign + " " + classes.cardTitle}>{title}</label>

            <CardContent className={classes.textAlign}>
                {subtitle !== undefined ? (
                    <Typography component="h6" className={classes.cardSubtitle}>
                        {subtitle}
                    </Typography>
                ) : null}
                {description}
            </CardContent>

            <CardActions className={classes.textAlign + " " + classes.cardActions}>
                {footer}
            </CardActions>
        </Card>
    )
}

ProfileCard.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    description: PropTypes.node,
    footer: PropTypes.node,
    avatar: PropTypes.string
}

export default withStyles(profileCardStyle)(ProfileCard)

