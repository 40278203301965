import React from 'react'
import { connect } from 'react-redux'
import * as servicesAction from 'redux/actions/services'

import { List, ListItem } from "@material-ui/core"
import Rating from 'react-rating'
import { Images } from 'helper/const'
import { UserType } from 'helper/const'
import { Button } from "@material-ui/core"

class ServiceList extends React.Component {
    componentWillMount() {
        this.props.dispatch(servicesAction.getServices())
    }

    handleNavService(doctorId) {
        this.props.history.push(`/dashboard/service_detail/${doctorId}`)
    }

    render() {
        const { services } = this.props
        const { all } = services
        const data = all

        const containerClass = "normal-button nav-filter-button " + (this.props.userType === UserType.patient ? "patient" : "doctor")

        return (
            <div className="service-list-container">
                <div className="title-wrapper">
                    <div className="title">Services</div>

                    <Button
                        className={containerClass}
                        href="/#/dashboard/services_filter"
                        style={{
                            marginRight: '10px'
                        }} >Filter</Button>

                </div>

                {data.length > 0 && <List component="nav">
                    {data.map((item, index) => {
                        const { doctorUserID, preFix, firstName, lastName, areaOfPractice, profilePhotoURL, averageRating, userCount } = item
                        const name = `${preFix} ${firstName} ${lastName}`
                        const avatar = (profilePhotoURL && profilePhotoURL.length > 0) ? profilePhotoURL : Images.doctorAvatar
                        const rating = averageRating ? parseFloat(averageRating) : 0
                        const ratingDesp = `Ratings By ${userCount} User${userCount > 1 ? 's' : ''}`

                        return <ListItem button className="item-container" key={index} onClick={this.handleNavService.bind(this, doctorUserID)}>
                            <div className="left-view">
                                <img className="avatar" src={avatar} alt="..."/>
                                <div className="info-view">
                                    <label className="name">{name}</label>
                                    <label className="area-of-practice">{areaOfPractice}</label>
                                </div>
                            </div>
                            <div className="right-view">
                                <Rating
                                    fractions={2}
                                    emptySymbol={['far fa-star star']}
                                    fullSymbol={['fas fa-star star']}
                                    initialRating={rating}
                                    readonly={true}
                                />
                                <label className="rating-desp">{ratingDesp}</label>
                            </div>
                        </ListItem>
                    })}
                </List>}

                {data.length === 0 && <div className="no-results-container">No results found...</div>}
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const services = state.services

    return {
        services
    }
}

export default connect(mapStateToProps)(ServiceList)
