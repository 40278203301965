import React from "react"
import {
    IconButton,
    List,
    ListItem,
    ListItemText,
    Button
} from "@material-ui/core"
import { Delete as DeleteIcon } from "@material-ui/icons"
import { Modal } from 'react-bootstrap'

import { UserType, ServiceSKU } from 'helper/const'
import { connect } from 'react-redux'
import * as notificationsAction from 'redux/actions/notifications'
import * as bookingsAction from 'redux/actions/bookings'

class Notifications extends React.Component {
    state = {
        openClearAllModal: false
    }

    componentWillMount() {
        const { userId, userType } = this.props

        this.props.dispatch(notificationsAction.getNotificationsAction(userId, userType))
        this.props.dispatch(bookingsAction.getBookingsAction(userId, userType))
    }

    handleDeleteNotification(notificationID) {
        this.props.dispatch(notificationsAction.delNotificationAction(notificationID))
    }

    handleNotificationDetail(notification) {
        console.log(notification)

        const { bookings, userType } = this.props

        let nextPage
        const { action, actionParam } = notification
        if (action === 'Booking' || action === 'Accepted') {
            console.log("Bookings:", bookings)
            const bookingData = bookings.find(item => parseInt(item.patientBookingID, 10) === parseInt(actionParam, 10))
            if (!bookingData) {
                console.log("No booking data found with id:", actionParam)
                return
            }

            const { status, isPatientCanceled, isDoctorCanceled, doctorResponse, booking } = bookingData
            const { doctorServiceSKU } = booking

            if (userType === UserType.patient) {
                if (status === 0 && !isPatientCanceled && !isDoctorCanceled) {
                    nextPage = 'booking_cancel_start'
                }
            } else if (!isPatientCanceled) {
                if (doctorResponse === 0) { // doctor not accepted
                    nextPage = 'booking_confirm'
                } else if (doctorResponse === 1 && status === 0) { // doctor accepted but session not started
                    nextPage = 'booking_cancel_start'
                }
            }
    
            if (!nextPage) {
                if (doctorServiceSKU === ServiceSKU.GENERAL_IM ||
                    doctorServiceSKU === ServiceSKU.SPECIAL_IM
                ) {
                    nextPage = 'instant_message'
                } else {
                    if (status === 2) {
                        if (userType === UserType.patient) {
                            nextPage ='patient_rating'
                        }
                    } else if (status === 1) {
                        switch (doctorServiceSKU) {
                            case ServiceSKU.GENERAL_AUDIO_CALL:
                            case ServiceSKU.SPECIAL_AUDIO_CALL:
                                nextPage = 'audio_chat'
                                break
                            case ServiceSKU.GENERAL_VIDEO_CALL:
                            case ServiceSKU.SPECIAL_VIDEO_CALL:
                                nextPage = 'video_chat'
                                break
                            default:
                                break
                        }
                    }
                }
            }

            if (nextPage) {
                nextPage += '/' + actionParam
            }
        } else if (action === 'IM') {
        } else if (action === 'Answer') {
        }

        console.log('NextPage:', nextPage)

        if (nextPage) {
            this.props.history.push(`/dashboard/${nextPage}`)
        }
    }


    handleOpenClearAllModal = () => {
        this.setState({ openClearAllModal: true })
    }

    handleCloseOpenClearAllModal = () => {
        this.setState({ openClearAllModal: false })
    }

    handleClearAll = () => {
        const { userId, userType } = this.props

        this.props.dispatch(notificationsAction.delAllNotificationsAction(userId, userType))
        this.handleCloseOpenClearAllModal()
    }


    render() {
        let { notifications } = this.props
        if (!notifications) {
            return null
        }

        const { searchText = '' } = this.state
        if (searchText && searchText.trim().length > 0) {
            notifications = notifications.filter(item => {
                const { content } = item
                return content.toUpperCase().includes(searchText.toUpperCase())
            })
        }

        return (
            <div className="notifications-container">
                <div className="horz-container title-view">
                    <div className="title">Notifications</div>
                    <Button
                        className="nav-filter-button"
                        onClick={this.handleOpenClearAllModal}
                        style={{
                            marginRight: '10px'
                        }}>
                        Clear All
                    </Button>

                    <Modal show={this.state.openClearAllModal} onHide={this.handleCloseOpenClearAllModal} enforceFocus={false} className="modal-center">
                        <Modal.Header closeButton>
                            <Modal.Title>Clear All</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <p>Are you sure you want to clear all notifications?</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button onClick={this.handleCloseOpenClearAllModal}>No</Button>
                            <Button onClick={this.handleClearAll}>Yes</Button>
                        </Modal.Footer>
                    </Modal>
                </div>

                <List>
                    {notifications.map((item, index) => {
                        const { content, timeCreated, notificationID } = item
                        const timezoneOffset = new Date().getTimezoneOffset()
                        const dtCreated = new Date(timeCreated)
                        dtCreated.setTime(dtCreated.getTime() + timezoneOffset * 60 * 1000)

                        const year = dtCreated.getFullYear()
                        const month = dtCreated.getMonth() + 1
                        const date = dtCreated.getDate()

                        let dt = ''
                        if (date < 10) {
                            dt += '0'
                        }
                        dt += date
                        dt += '/'

                        if (month < 10) {
                            dt += '0'
                        }
                        dt += month
                        dt += '/'

                        dt += year

                        return (
                            <ListItem button key={notificationID} onClick={this.handleNotificationDetail.bind(this, item)} className="notification-item">
                                <ListItemText primary={content} secondary={dt}  classes={{ primary: "name", secondary: "when" }} />

                                <IconButton aria-label="delete" onClick = {this.handleDeleteNotification.bind(this, notificationID)}>
                                    <DeleteIcon fontSize="large" />
                                </IconButton>
                            </ListItem>
                        )
                    })}
                </List>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { auth, notifications, bookings } = state
    const { searchText } = notifications

    const { userProfile } = auth
    const userType = userProfile.userType
    const userId = userType === UserType.patient ? userProfile.patientUserID : userProfile.doctorUserID
    const notificationList = notifications.data

    const { data } = bookings

    return {
        userId,
        userType,
        bookings: data,
        searchText: searchText || '',
        notifications: notificationList
    }
}

export default connect(mapStateToProps)(Notifications)
