import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { amber, green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
}

const useStyles1 = makeStyles(theme => {
    return {
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: theme.palette.error.dark,
        },
        info: {
            backgroundColor: theme.palette.primary.main,
        },
        warning: {
            backgroundColor: amber[700],
        },
        icon: {
            fontSize: 20,
        },
        iconVariant: {
            opacity: 0.9,
            marginRight: theme.spacing(1),
        },
        message: {
            display: 'flex',
            alignItems: 'center',
        }
    }
})

function MySnackbarContentWrapper(props) {
    const classes = useStyles1()
    const { message, className, onClose, variant, ...other } = props
    const Icon = variantIcon[variant]

    return (
        <SnackbarContent
            className={clsx(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other} />
    )
}

MySnackbarContentWrapper.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
}

class Base extends React.Component {
    static defaultDuration = 3000
    state = {
        toastMessage: '',
        toastVariant: 'info'
    }

    static getDerivedStateFromProps(props, state) {
        if (props.toastMessage !== state.toastMessage) {
            let toastMessage = ''
            if (typeof props.toastMessage === 'string') {
                toastMessage = props.toastMessage
            }

            return {
                toastMessage,
                toastVariant: props.toastVariant
            }
        }

        return null
    }

    showToast = (toastMessage) => {
        this.setState({toastMessage})
    }

    hideToast = () => {
        if (this.props.hideToast) {
            this.props.hideToast()
        } else {
            this.setState({toastMessage: null})
        }
    }

    render = () => {
        let { className, children } = this.props

        if (!className) {
            className = ""
        }

        const { toastMessage, toastVariant } = this.state
        const openToast = toastMessage ? true : false
        const toastDuration = Base.defaultDuration

        return (
            <div className={className}>
                {children}

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    open={openToast}
                    autoHideDuration={toastDuration}
                    onClose={this.hideToast} >

                <MySnackbarContentWrapper
                    onClose={this.hideToast}
                    variant={toastVariant}
                    message={toastMessage} />
                </Snackbar>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { model: modelKey } = ownProps

    const rst = {
        toastMessage: '',
        toastVariant: 'info'
    }

    if (modelKey) {
        const model = state[modelKey]
        if (model) {
            if (model.toastMessage !== undefined) {
                rst.toastMessage = model.toastMessage
            }

            if (model.toastVariant !== undefined) {
                rst.toastVariant = model.toastVariant
            }
        }
    }

    return rst
}

function mapDispatchToProps(dispatch, ownProps) {
    const { model } = ownProps

    return {
        hideToast: () => dispatch({ type: 'HIDE-TOAST', model })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Base)
