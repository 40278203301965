import React from 'react'

import * as global from 'helper/global'
import { UserType, Images, DISPLAY_MAX_LEN } from 'helper/const'
import { Button } from "@material-ui/core"

export default function FeedItem1({ ...props }) {
    // Article
    const {
        data,
        userType,
        handleLike,
        handleAgree,
        handleDoctorKnow,
        handleDetail,
        handleEditFeed,
        userId,
        articleLink
    } = props

    const {
        ArticleLink,
        doctor,
        Title,
        Body,
        ImageURL,
        timeCreated,
        feedID,
        FeedType
    } = data

    const {
        doctorUserID,
        preFix,
        firstName,
        lastName,
        helped,
        agreedCount,
        agreed,
        isLike,
        known,
        areaOfPractice
    } = doctor

    const editable = parseInt(userType, 10) === UserType.doctor && parseInt(userId, 10) === parseInt(doctorUserID, 10)

    // Created time
    const diffTime = global.diffTime(global.string2Time(timeCreated), new Date())

    // Doctor avatar
    let doctorImg = Images.doctorAvatar
    if (doctor.profilePhotoURL && doctor.profilePhotoURL.length > 0) {
        doctorImg = doctor.profilePhotoURL
    }

    // Doctor name
    let name = `${preFix || ''} ${firstName || ''} ${lastName || ''}`

    let answerOverflowed, bodyShow
    if (Body.length > DISPLAY_MAX_LEN) {
        answerOverflowed = true
        bodyShow = Body.slice(0, DISPLAY_MAX_LEN) + '...'
    } else {
        answerOverflowed = false
        bodyShow = Body
    }

    if (ArticleLink) {
        answerOverflowed = true
    }

    const articleAvailable = articleLink && articleLink.trim().length > 0
    const readmoreHtml = (feedID) => {
        if (articleAvailable) {
            return (
                <a href={articleLink} target="_blank" rel="noopener noreferrer">
                    <Button
                        className="agree-button read-more">
                        Read More
                    </Button>
                </a>
            )
        } else {
            return (
                <Button
                    className="agree-button read-more"
                    onClick={() => handleDetail(feedID)}>
                    Read More
                </Button>
            )
        }
    }

    return (
        <div className="feeds-item doctor-view bottom-border">
            {ImageURL && <div className="post-image-wrapper">
                <img className="post-image" src={ImageURL} alt="..."/>
            </div>}

            <label className="title">{Title}</label>

            <div className="profile-view">
                <a href={"/#/dashboard/service_detail/" + doctorUserID}>
                    <img className="avatar" src={doctorImg} alt="..."/>
                </a>

                <div className="info-wrapper">
                    <div className="info">
                        <div>
                            <a href={"/#/dashboard/service_detail/" + doctorUserID}>
                                <label className="name">{name}&nbsp;</label>
                            </a>
                            <label className="practice">{areaOfPractice}</label>
                        </div>

                        <label className="time">{diffTime}</label>
                    </div>

                    {editable && <div className="info-edit">
                        <Button
                            className="edit-feed-button"
                            onClick={() => handleEditFeed(feedID)}>Edit</Button>
                    </div>}
                </div>
            </div>

            <label className="description">{bodyShow}</label>

            {answerOverflowed && readmoreHtml(feedID)}

            <div className="extra-info info">
                <div className="sub-info">
                    <div className="sub-info pad">
                        <label className="caption">Doctor{agreedCount > 1 ? 's' : ''} Agreed:</label>
                        <label className="agreed-count">{agreedCount}</label>
                    </div>

                    <div className="sub-info pad">
                        <label className="caption">Patient{helped > 1 ? 's' : ''} Helped:</label>
                        <label className="helped">{helped}</label>
                    </div>
                </div>
            </div>

            <div className="info">
                <div className="sub-info buttons pt-0">
                    {userType === UserType.doctor &&
                        <Button
                            className="agree-button read-more"
                            disabled={agreed}
                            onClick={() => handleAgree(0, feedID, doctorUserID, FeedType, !agreed)}>
                            {agreed ? 'Agreed' : 'I Agree'}
                        </Button>
                    }

                    <Button
                        className="user-know-button read-more"
                        disabled={known}
                        onClick={() => handleDoctorKnow(-1, doctorUserID, !known)}>
                        I Know Doctor
                    </Button>

                    <Button className="like-btn" onClick={() => handleLike(0, feedID, doctorUserID, FeedType, !isLike)}>
                        <i className={`${isLike ? 'fas' : 'far'} fa-heart`}/>
                    </Button>
                </div>
            </div>
        </div>
    )
}
