import apiRequest, { apiRestRequest, sendGenRequest } from './apiRequest'
import { Api, UserType } from 'helper/const'

export function getBookings(userId, userType) {
    if (userType === UserType.patient) {
        return apiRequest('patientBookingsGetByPatient', { patient: userId })
    } else {
        return apiRequest('patientBookingsGetByDoctor', { doctor: userId })
    }
}

export function getBookingDetails(patientBookingID) {
    return apiRequest('patientBookingsGetById', { patientBookingID })
}

export function cancelBooking(patientBookingId, cancelNote, userType) {
    if (userType === UserType.patient) {
        return apiRestRequest('patientBookings', 'put', {isPatientCanceled: 1, cancelNote}, patientBookingId)
    } else {
        return apiRestRequest('patientBookings', 'put', {isDoctorCanceled: 1, cancelNote}, patientBookingId)
    }
}

export function captureDoctorServiceTimeSlot(doctorServiceId) {
    return apiRestRequest('doctorServices', 'put', {isBooked: 1}, doctorServiceId)
}

export function releaseDoctorServiceTimeSlot(doctorServiceId) {
    return apiRestRequest('doctorServices', 'put', {isBooked: 0}, doctorServiceId)
}

export function startSession(patientBookingId) {
    return apiRestRequest('patientBookings', 'put', {status: 1}, patientBookingId)
}

export function endSession(patientBookingId) {
    return apiRestRequest('patientBookings', 'put', {status: 2}, patientBookingId)
}

export function payService(amount, currency, customerID, paymentMethodId) {
    return sendGenRequest(Api.cardApiBaseUrl + '/MakePayment', 'post', {
        amount,
        currency,
        customerID,
        paymentMethodId
    })
}

export function acceptDeclineBooking(bookingId, response, declineReason) {
    return apiRestRequest(`patientBookings`, 'put', {
        doctorResponse: response,
        doctorDeclineReason: declineReason
    }, bookingId)
}

export function updateSessionID(bookingId, sessionId) {
    return apiRestRequest(`patientBookings`, 'put', { sessionId: sessionId }, bookingId)
}

export function createRate(doctorId, patientId, patientBookingId, ratingStar) {
    return apiRequest('doctorRatingsAdd', {
        doctorId,
        patientId,
        patientBookingId,
        ratingStar
    })
}

export function updateRate(doctorRatingID, ratingStar) {
    return apiRequest('doctorRatingsUpdateById', {
        doctorRatingID,
        ratingStar
    })
}

export function getRatingByBookingId(patientBookingId) {
    return apiRequest('doctorRatingsGetByBookingId', {
        patientBookingId
    })
}


export function createPatientTransaction(
    transactionAmount,   // float
    note,
    patient,             // Patient ID
    paidFor,             // Doctor ID
    doctorServiceId,     // Service ID
    serviceName,         // Service Name
    startDateTime,       // Book Start Date Time
    endDateTime,         // Book End Date Time
    amountToDoctor,      // Price Amount to Doctor
    amountToPlatform,    // Price Amount to Platform
    payPalPaymentKey     // Transaction ID created by Stripe
) {
    return sendGenRequest(Api.cardApiBaseUrl + '/CreatePatientTransaction', 'post', {
        transactionAmount,
        note,
        patient,
        paidFor,
        doctorServiceId,
        serviceName,
        startDateTime,
        endDateTime,
        payPalPaymentKey,
        amountToDoctor,
        amountToPlatform
    })
}
