import React from 'react'
import moment from 'moment'

export default class FeedQuestionDetail extends React.Component {
    handleImageDetail = (url) => {
        const {handleImageDetail} = this.props
        if (handleImageDetail) {
            handleImageDetail(url)
        } else {
            window.open(url)
        }
    }

    render() {
        const {feed} = this.props
        const {ArticleLink, Title, timeCreated, Body, ImageURL} = feed
        const mTime = moment(timeCreated)
        const dispTime = mTime.format('hh:mm a DD/MM/YYYY')

        if (ArticleLink) {
            return (
                <iframe src={ArticleLink}
                    className="feed-article-link"
                    title="article"
                />
            )
        } else {
            return (<div className="feed-article-detail-container">
                <label className="title">{Title}</label>
                <label className="when">{dispTime}</label>
                <label className="article-body">{Body}</label>
                {ImageURL && <img className="tile-img" onClick={() => {this.handleImageDetail(ImageURL)}} src={ImageURL} alt="..."/>}
            </div>)
        }
    }
}