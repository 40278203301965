import apiRequest, { apiRestRequest } from './apiRequest'

export function getPrescription(email) {
    return apiRequest('ePrescriptionsGetByEmail', {email: email})
}


export function savePrescription(prescription) {
    const { ePrescriptionID } = prescription
    if (ePrescriptionID) {
        // Update
        return apiRestRequest('ePrescriptions/' + ePrescriptionID, 'put', prescription)
    } else {
        // Create
        return apiRestRequest('ePrescriptions', 'post', prescription)
    }
}

export function getServiceProviders() {
    return apiRequest('providerGetAllByType', { providerType: 1 })
}
