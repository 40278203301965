import apiRequest, { apiRestRequest } from './apiRequest'
import { UserType } from 'helper/const'

export function getFavorites(userType, userId) {
    if (userType.toString() === UserType.patient.toString()) {
        return apiRequest('patientFavoriteGetbyPatintId', {patient: userId})
    } else {
        return apiRequest('doctorFavoritesGetByDoctorId', {doctor: userId})
    }
}

export function setFavorite(userType, patientId, doctorId, isFavorite) {
    const data = {patient: patientId, doctor: doctorId}
    if (isFavorite === false) {
        if (userType.toString() === UserType.patient.toString()) {
            return apiRequest('patientFavoriteDelete', data)
        } else {
            return apiRequest('doctorFavoritesDeleteByDoctorPatient', {patient: patientId, doctor: doctorId})
        }
    } else {
        if (userType.toString() === UserType.patient.toString()) {
            return apiRestRequest('patientFavorites', 'post', data)
        } else {
            return apiRestRequest('doctorFavorites', 'post', data)
        }
    }
}
