import React from 'react'
import { MessageBox } from 'react-chat-elements'
import { STextInput } from "components"

import * as authActions from 'redux/actions/auth'
import { connect } from 'react-redux'
import * as notificationApi from 'api/notification'
import * as chatApi from 'api/chat'
import * as providersApi from 'api/providers'
import * as authApi from 'api/auth'
import {
    Images,
    SvgIconData,
    UserType
} from 'helper/const'
import { SvgIcon } from "components"

const uuidv1 = require('uuid/v1')

class ChatRoom extends React.Component {
    state = {
        msgs: [],
        newMessage: "",
        statusMsg: "Creating chat room...",
        session: null,
        canSend: true,
        partnerConnection: null,
        user: null,
        provider: null
    }

    async componentDidMount() {
        if (this.props.match && this.props.match.params) {
            // Get user type, user id and provider id from url parameters.
            let { userType, userId, providerId } = this.props.match.params
            if (!userType || !userId || !providerId) {
                console.log("Invalid parameters are provided.")
                return
            }
            userType = parseInt(userType)

            // Get user info
            const rstUser = await authApi.getUserProfile(userType, userId)
            if (rstUser.status !== 0 && rstUser.data.length !== 1) {
                console.log("Failed to get doctor info.")
                return
            }

            let user = rstUser.data[0]
            user.userType = userType
            
            // Get provider
            const rstProvider = await providersApi.getServiceProviderById(providerId)
            if (rstProvider.status !== 0) {
                console.log("Faild to get provider data.")
                return
            }

            const provider = rstProvider.data

            // Update provider's OneSignal push id
            if (this.props.auth && this.props.auth.pushId) {
                const {pushId} = this.props.auth
                this._updatePushInfo(pushId, provider.servicesProviderID, UserType.provider)
            }

            // Initialize session
            const sessionInfo = await this.initSession()
            if (sessionInfo && sessionInfo.session && sessionInfo.sessionData) {
                this.setState({
                    session: sessionInfo.session,
                    provider,
                    user,
                    statusMsg: `Waiting for the ${userType === UserType.doctor ? 'doctor' : 'patient'}...`
                })

                if (!this.props.match.params.apiKey) {
                    // Send push notification to the doctor if succeed to create new session
                    this.sendNotificationToUser(userType, userId, provider, sessionInfo.sessionData)
                }
            }
        }
    }

    componentWillReceiveProps(newProps) {
        if (!newProps.auth || !newProps.auth.pushId) {
            return
        }
        const {pushId} = newProps.auth
        if (this.props.auth && this.props.auth.pushId === pushId) {
            return
        }
        const {provider} = this.state
        if (provider) {
            this._updatePushInfo(pushId, provider.servicesProviderID, UserType.provider)
        }
    }

    /**
     * Update onesignal push id to the up-to-date on the PushUsers table
     * @param {string} pushId OneSignal push id 
     * @param {int} userId Current user id 
     * @param {int} userType User type 
     */
    _updatePushInfo(pushId, userId, userType) {
        if (!pushId || !userId || !userType) {
            return
        }

        this.props.dispatch(authActions.updatePushId(pushId, userId, userType))

        let tagValue = 'unknown'
        switch (userType) {
            case UserType.patient:
                tagValue = 'Patient'
                break;
            case UserType.doctor:
                tagValue = 'Doctor'
                break;
            case UserType.provider:
                tagValue = 'Provider'
                break;
            default:
                break;
        }

        let OneSignal = window.OneSignal || [];
        OneSignal.sendTag('UserType', tagValue).then(function(tagsSent) {
            // Callback called when tags have finished sending
        });
    }

    /**
     * Send push notification to the user who sent email
     * @param {int} userType user type. Can be doctor or patient
     * @param {int} userId user id who should receive push notification
     * @param {Object} provider service provider who is sending push notification. 
     * @param {Object} session Session object {ApiKey, SessionId, Token} 
     */
    async sendNotificationToUser(userType, userId, provider, session) {
        const content = `New instant message chat request from ${provider.name}.`
        const actionParam = {
            buddyUserId: provider.servicesProviderID,
            buddyName: provider.name,
            bookingId: 0,
            sessionType: "IM",
            sessionId: session.SessionId,
            isBuddyDoctor: false,
            isBuddyService: true,
            isHistoryOnly: false
        }

        let notificationParams = {
            content,
            action: "IM",
            actionParam: JSON.stringify(actionParam),
            PushGuid: uuidv1()
        }

        if (userType === UserType.patient) {
            notificationParams.patientId = userId
        } else if (userType === UserType.doctor) {
            notificationParams.doctorId = userId
        } else {
            console.log("Invalid user type is provided.")
            return
        }

        const rstNotification = await notificationApi.createNotification(notificationParams)

        if (rstNotification.status === 0) {
            const rstPushUsers = await notificationApi.getPushUsers(userId, userType)
            if (rstPushUsers.status === 0) {
                let targetUsers = []
                for (let index = 0; index < rstPushUsers.data.length; index++) {
                    const element = rstPushUsers.data[index];
                    if (element.pushId && element.isLogged) {
                        targetUsers.push(element.pushId)
                    }
                }

                if (targetUsers.length > 0) {
                    notificationApi.sendPush(targetUsers, content, actionParam)
                }
            }
        }
    }

    /**
     * Create new tokbox text message session
     */
    async initSession() {
        let rstSession = null

        if (this.props.match && this.props.match.params
            && this.props.match.params.apiKey
            && this.props.match.params.sessionId
        ) {
            rstSession = await chatApi.joinSession(this.props.match.params.sessionId)
        } else {
            rstSession = await chatApi.createSession()
        }

        if (rstSession.status !== 0) {
            return null
        }

        const sessionData = rstSession.data
        let session = await chatApi.initSession(sessionData.ApiKey, sessionData.SessionId)

        if (!session) {
            return null
        }

        session = await session.connect(sessionData.Token)
        session.on("signal", this.onReceivedSignal)
        session.on("connectionCreated", this.onConnectionCreated)
        session.on("connectionDestroyed", this.onConnectionDestroyed)

        return {session, sessionData}
    }


    onConnectionCreated = event => {
        if (this.state.session.connection.connectionId !== event.connection.connectionId) {
            this.setState({
                partnerConnection: event.connection,
                statusMsg: "Type a message here..."
            })
        }
    }


    onConnectionDestroyed = (e) => {
        const {partnerConnection, user} = this.state
        if (partnerConnection && partnerConnection.connectionId === e.connection.connectionId && partnerConnection.creationTime === e.connection.creationTime) {
            this.setState({
                partnerConnection: null,
                statusMsg: `Waiting for the ${user.userType === UserType.doctor ? 'doctor' : 'patient'}...`
            })
        }
    }


    onReceivedSignal = event => {
        if (event.type === "signal:chat") {
            this.setState(prevState => {
                const msgs = prevState.msgs
                msgs.push({
                    isMine: false,
                    text: event.data,
                    when: new Date()
                })

                return {
                    msgs
                }
            })

            this.scrollToBottom()
        }
    }


    handleChangeText = (key, value) => {
        this.setState({
                [key]: value
        })
    }


    handleSendMessage = () => {
        const {newMessage, session, canSend, partnerConnection} = this.state

        if (session && newMessage && canSend && partnerConnection) {
            this.setState({ canSend: false })

            session.signal({
                type: "chat",
                to: partnerConnection,
                data: newMessage
            }, error => {
                if (error) {
                    console.error("signal error: " + error)
                } else {
                    console.log("signal sent")
                }

                this.setState(prevState => {
                    const msgs = prevState.msgs
                    msgs.push({
                        isMine: true,
                        text: newMessage,
                        when: new Date()
                    })

                    return {
                        msgs,
                        canSend: true,
                        newMessage: ""
                    }
                })

                this.scrollToBottom()
            })
        } 
    }


    scrollToBottom = () => {
        this.messageEnd.scrollIntoView({ behavior: "smooth" })
    }


    render() {
        const { msgs, newMessage, canSend, statusMsg, provider, user } = this.state
        const myName = provider ? provider.name : ""
        const myAvatar = Images.doctorAvatar

        let userName = null
        let userAvatar = null

        if (user) {
            if (user.userType === UserType.doctor) {
                userName = `${user.preFix} ${user.firstName} ${user.lastName}`
                userAvatar = (user.profilePhotoURL && user.profilePhotoURL.length > 0) ? user.profilePhotoURL : Images.doctorAvatar
            } else if (user.userType === UserType.patient) {
                userName = user.username
                userAvatar = (user.photoProfileURL && user.photoProfileURL.length > 0) ? user.photoProfileURL : Images.patientAvatar
            }
        }

        const msgViews = msgs.map((x, i) => {
            return <MessageBox
                position={ x.isMine ? 'right' : 'left' }
                type={'text'}
                avatar={ x.isMine ? myAvatar : userAvatar }
                title= { x.isMine ? myName : userName }
                text={x.text}
                date={x.when}
                key = {i}
            />
        })

        return (
            <div className="chat-room-container container">
                <div className="chat-area">
                    {msgViews}
                    <div style={{ float: "left", clear: "both"}}
                        ref={(el) => {this.messageEnd = el;}}>
                    </div>
                </div>

                <div className="tool-area">
                    <STextInput
                        caption={statusMsg}
                        value={newMessage}
                        handleChange={(e) => this.handleChangeText('newMessage', e.target.value)}
                        handleEnter={this.handleSendMessage} />

                    <button className="send-button mb-8" onClick={this.handleSendMessage} disable={canSend ? "" : "disabled"}>
                        <SvgIcon data={SvgIconData.messageSend} className="svg-fill"/>
                    </button>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { auth } = state
    return { auth }
}


export default connect(mapStateToProps)(ChatRoom)
