import React from 'react'
import { connect } from 'react-redux'
import { UserType } from 'helper/const'

import InfiniteScroll from 'react-infinite-scroll-component'
import FeedItem1 from './FeedItem1'
import FeedItem2 from './FeedItem2'
import FeedFloat from './FeedFloat'

import * as feedActions from 'redux/actions/feeds'

class Feed extends React.Component {
    constructor(props) {
        super(props)

        const { userId, userType, feeds } = props
        const { needRefresh, filter } = feeds

        if (userId && needRefresh) {
            props.dispatch(feedActions.getFeeds(userId, userType, 0, filter))
        }
    }


    handleLike = (index, replyIndex, feedId, feedDoctorId, feedType, isLike) => {
        const { userId, userType } = this.props

        this.props.dispatch(feedActions.setLikeFeed(
            index,
            replyIndex,
            feedId,
            feedDoctorId,
            userId,
            userType,
            feedType,
            isLike
        ))
    }

    handleAgree = (index, replyIndex, feedId, feedDoctorId, feedType, isAgree) => {
        this.props.dispatch(feedActions.setAgreeFeed(index, replyIndex, feedId, feedDoctorId, this.props.userId, feedType, isAgree))
    }

    handleDoctorKnow = (index, replyIndex, doctorId, isKnow) => {
        this.props.dispatch(feedActions.setDoctorKnow(index, replyIndex, doctorId, this.props.userType, this.props.userId, isKnow))
    }

    handleDetail = (feedID) => {
        this.props.history.push(`/dashboard/feed_detail/${feedID}`)
    }

    handleEditFeed = (feedID) => {
        this.props.history.push(`/dashboard/edit_article/${feedID}`)
    }

    handleEditReply = (feedReplyId) => {
        this.props.history.push(`/dashboard/edit_feedreply/${feedReplyId}`)
    }

    refresh = () => {
        const { userId, userType, feeds } = this.props
        const { isFeeding, filter } = feeds
        if (isFeeding === false) {
            this.props.dispatch(feedActions.getFeeds(userId, userType, 0, filter))
        }
    }

    fetchData = () => {
        const { userId, userType, feeds } = this.props
        const { rowIndex, isFeeding, filter } = feeds

        if (isFeeding === false) {
            this.props.dispatch(feedActions.getFeeds(userId, userType, rowIndex + 1, filter))
        }
    }

    handleScroll = (e) => {
        const scrollY = e.target.scrollTop
        this.props.dispatch(feedActions.feedScrollAction(scrollY))
    }

    render() {
        const { feeds, userType, history, userId } = this.props
        const { data, scrollY, isFeeding } = feeds

        const feedItems = data.map((item, index) => {
            if (!item) {
                return null
            }

            const { ArticleLink } = item

            if (item.FeedType === 2) {
                return <FeedItem2
                    userId={userId}
                    data={item}
                    userType={userType}
                    articleLink={ArticleLink}
                    handleLike={this.handleLike.bind(this, index)}
                    handleAgree={this.handleAgree.bind(this, index)}
                    handleDoctorKnow={this.handleDoctorKnow.bind(this, index)}
                    handleDetail={this.handleDetail}
                    handleEditFeed={this.handleEditFeed}
                    handleEditReply={this.handleEditReply}
                    key={item.feedID}/>
            } else {
                return <FeedItem1
                    userId={userId}
                    data={item}
                    userType={userType}
                    articleLink={ArticleLink}
                    handleLike={this.handleLike.bind(this, index)}
                    handleAgree={this.handleAgree.bind(this, index)}
                    handleDoctorKnow={this.handleDoctorKnow.bind(this, index)}
                    handleDetail={this.handleDetail}
                    handleEditFeed={this.handleEditFeed}
                    key={item.feedID}/>
            }
        })

        return (
            <div className="feeds-container">
                <InfiniteScroll
                    dataLength={data.length} //This is important field to render the next data
                    next={this.fetchData.bind(this)}
                    hasMore={true}
                    height={'80vh'}
                    loader={<h4 style={{marginLeft: '10px', marginTop: '10px'}}>{isFeeding ? "Loading..." : "No results found"}</h4>}
                    endMessage={
                        <p style={{textAlign: 'center'}}>
                            <b>No more</b>
                        </p>
                    }
                    // below props only if you need pull down functionality
                    refreshFunction={this.refresh}
                    pullDownToRefresh
                    pullDownToRefreshContent={
                        <h3 style={{textAlign: 'center'}}>&#8595; Pull down to refresh</h3>
                    }
                    releaseToRefreshContent={
                        <h3 style={{textAlign: 'center'}}>&#8593; Release to refresh</h3>
                    }
                    onScroll={this.handleScroll}
                    initialScrollY={scrollY}>{feedItems}</InfiniteScroll>

                <FeedFloat
                    history={history}
                    userType={userType}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { feeds, auth } = state

    const { userProfile } = auth
    let userType = UserType.unknown
    let userId = 0

    if (userProfile) {
        userType = userProfile.userType
        if (userType.toString() === UserType.patient.toString()) {
            userId = userProfile.patientUserID
        }

        if (userType.toString() === UserType.doctor.toString()) {
            userId = userProfile.doctorUserID
        }
    }

    return { feeds, userType, userId }
}

export default connect(mapStateToProps)(Feed)
