import React from 'react'
import FeedQuestionDetail from './FeedQuestionDetail'
import FeedArticleDetail from './FeedArticleDetail'
import {FeedType} from 'helper/const'
import { connect } from 'react-redux'

class FeedDetail extends React.Component {
    handleImageDetail = (url) => {
        this.props.history.push({
            pathname: '/dashboard/image_detail',
            state: {
                imageUrl: url
            }
        })
    }

    render() {
        const {feed} = this.props
        if (!feed) {
            return null
        }

        return (
            <div className="feed-detail-container">
                { feed.FeedType === FeedType.patientPosted &&
                    <FeedQuestionDetail feed={feed} handleImageDetail={this.handleImageDetail.bind(this)}/>}

                { feed.FeedType === FeedType.doctorPosted &&
                    <FeedArticleDetail feed={feed} handleImageDetail={this.handleImageDetail.bind(this)}/>}
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { feeds } = state
    const { data } = feeds

    if (!data || data.length <= 0) {
        ownProps.history.replace('/dashboard/feed')
        return {}
    }

    const feedID = parseInt(ownProps.match.params.feedID, 10)
    const feed = data.find(x => x && x.feedID === feedID)

    return { feed }
}

export default connect(mapStateToProps)(FeedDetail)

