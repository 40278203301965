import apiRequest, { apiRestRequest } from './apiRequest'
import { UserType } from 'helper/const'

export function login(userType, email, password, facebookId = null, twitterId = null, googleId = null) {
    if (email) {    // Email login
        if (userType.toString() === UserType.patient.toString()) {
            return apiRequest('patientLoginByEmail', {email, password})
        } else {
            return apiRequest('doctorLoginByEmail', {email, password})
        }
    } else {        // Social login
        if (userType.toString() === UserType.patient.toString()) {
            return apiRequest('patientLoginBySocial', {facebookId, twitterId, googleId})
        } else {
            return apiRequest('doctorLoginBySocial', {facebookId, twitterId, googleId})
        }
    }
}

export function signup(userType, email, password, facebookId = null, twitterId = null, googleId = null) {
    var tableName = null
    const params = {
        email,
        password,
        facebookId,
        googleId,
        twitterId
    }
    if (userType.toString() === UserType.patient.toString()) {
        tableName = 'patientUsers'
        params.isActive = "1"
    } else {
        tableName = 'doctorUsers'
    }
    return apiRestRequest(tableName, 'post', params)
}

export function getUserProfile(userType, userId) {
    if (userType.toString() === UserType.patient.toString()) {
        return apiRequest('patientGetById', {patientUserID: userId})
    } else {
        return apiRequest('doctorGetById', {doctorUserID: userId})
    }
}

export function getUserByEmail(userType, email) {
    if (userType.toString() === UserType.patient.toString()) {
        return apiRequest('patientGetByEmail', {email})
    } else {
        return apiRequest('doctorGetByEmail', {email})
    }
}

export function updateUserProfile(userType, userId, data) {
    if (userType.toString() === UserType.patient.toString()) {
        return apiRestRequest('patientUsers', 'put', data, userId)
    } else {
        return apiRestRequest('doctorUsers', 'put', data, userId)
    }
}

export function isUserExist(userType, email, facebookId = null, twitterId = null, googleId = null) {
    const params = {
        email: email ? email : 'N/A',
        facebookId: facebookId ? facebookId : 'N/A',
        twitterId: twitterId ? twitterId : 'N/A',
        googleId: googleId ? googleId : 'N/A'
    }
    const endpoint = userType.toString() === UserType.patient.toString() ? 'patientCheckUnique' : 'doctorCheckUnique'
    return apiRequest(endpoint, params)
}