import React from 'react'
import { Button } from "@material-ui/core"
import { Search } from '@material-ui/icons'

import { UserType } from 'helper/const'
import { connect } from 'react-redux'
import * as notificationsAction from 'redux/actions/notifications'

class NavNotifications extends React.Component {
    state = {
        searchText: null
    }

    handleChangeText = (searchText) => {
        this.setState({
            searchText
        })
    }

    handleSearch = () => {
        const { searchText } = this.state
        this.props.dispatch(notificationsAction.searchNotificationsAction(searchText || ''))
    }

    
    render() {
        return (
            <div className="nav-feed-container">
                <Button
                    className="search-button"
                    onClick={this.handleSearch}>
                    <Search style={{
                        width: '25px',
                        height: '25px'
                    }} className="search-ico" />
                </Button>

                <input
                    placeholder="Search"
                    onChange={(e) => {this.handleChangeText(e.target.value)}}
                    className='search'
                />
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { auth } = state
    const { userProfile } = auth

    const userType = userProfile.userType
    const userId = userType === UserType.patient ? userProfile.patientUserID : userProfile.doctorUserID
    
    return {
        userId,
        userType
    }
}
  
export default connect(mapStateToProps)(NavNotifications)
