import React from 'react'
import Loader from 'react-loader-spinner'

export default class Spinner extends React.Component {
    render() {
        const { show } = this.props

        const display = show === true ? 'flex' : 'none'

        return (
            <div style={{
                backgroundColor: 'rgba(0, 0, 0, .1)',
                position: 'fixed',
                left: '0',
                right: '0',
                top: '0',
                bottom: '0',
                display: display,
                justifyContent: 'center',
                alignItems: 'center'
            }} onClick={(e) => e.stopPropagation()}>
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={80}
                    width={80}
                />
            </div>
        )
    }
}
