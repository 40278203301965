import * as servicesApi from 'api/service'

export const GET_SERVICES_REQUEST = 'GET_SERVICES_REQUEST'
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS'
export const GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE'

export const FILTER_SERVICES_REQUEST = 'FILTER_SERVICES_REQUEST'
export const FILTER_SERVICES_SUCCESS = 'FILTER_SERVICES_SUCCESS'
export const FILTER_SERVICES_FAILURE = 'FILTER_SERVICES_FAILURE'

function _getServicesRequest() {
    return {
        type: GET_SERVICES_REQUEST,
        message: "Fetching doctors..."
    }
}

function _getServicesSuccess(services) {
    return {
        type: GET_SERVICES_SUCCESS,
        services
    }
}

function _getServicesFailure(message) {
    return {
        type: GET_SERVICES_FAILURE,
        message
    }
}

/**
 * Get all the services
 * 
 */
export function getServices() {
  return async dispatch => {
    dispatch(_getServicesRequest())
    const response = await servicesApi.getDoctors()
    if (response.status === 0) {
        dispatch(_getServicesSuccess(response.data))
    } else {
        dispatch(_getServicesFailure(response.message))
    }
  }
}

function _filterServicesRequest() {
    return {
        type: FILTER_SERVICES_REQUEST,
        message: "Filtering doctors..."
    }
}

function _filterServicesSuccess(services) {
    return {
        type: FILTER_SERVICES_SUCCESS,
        services
    }
}

function _filterServicesFailure(message) {
    return {
        type: FILTER_SERVICES_FAILURE,
        message
    }
}

/**
 * Filter services with variant options
 * 
 * @param {Filter options} params 
 */
export function filterServices(params) {
    return async dispatch => {
        dispatch(_filterServicesRequest())

        // Check if any filter option is fullfilled or not.
        let isFiltered = false
        if (params) {
            let key
            for (key in params) {
                if (params.hasOwnProperty(key)) {
                    const element = params[key]
                    if (element) {
                        isFiltered = true
                    }
                }
            }
        }

        if (isFiltered === false) {
            dispatch(_filterServicesSuccess([]))
            return
        }

        const response = await servicesApi.getFilteredDoctors(params)
        if (response.status === 0) {
            let filtered = []
            for (let index = 0; index < response.data.length; index++) {
                const element = response.data[index]
                const doctorRst = await servicesApi.getDoctorAverageRating(element.doctorUserID)

                if (doctorRst.status === 0) {
                    filtered.push(doctorRst.data[0])
                    dispatch(_filterServicesSuccess(filtered))
                }
            }

            dispatch(_filterServicesSuccess(filtered))
        } else {
            dispatch(_filterServicesFailure(response.message))
        }
    }
}
