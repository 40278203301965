import React from 'react'
import PropTypes from 'prop-types'
import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core'
import SignatureCanvas from 'react-signature-canvas'


class SignatureDialog extends React.Component {
    componentWillReceiveProps(nextProps) {
        if (this.sigCanvas && this.validSignature(nextProps.signature)) {
            this.drawCanvas(nextProps.signature)
        }
    }

    componentDidMount() {
        const { signature } = this.props
        if (this.sigCanvas && this.validSignature(signature)) {
            this.drawCanvas(signature)
        }
    }

    drawCanvas = (signature) => {
        const ctx = this.sigCanvas.getCanvas().getContext('2d')

        const img = new Image()
        img.onload = function() {
            console.log("Drawing image:")
            ctx.drawImage(img, 0, 0)
        }

        img.src = signature
    }


    validSignature = (data) => {
        if (!data.includes(';')) {
            return false
        }

        if (!data.includes('data')) {
            return false
        }

        return true
    }

    handleCancel = () => {
        this.props.onCancel()
    }

    handleOk = () => {
        if (this.sigCanvas) {
            const { onOK } = this.props
            if (onOK) {
                onOK(this.sigCanvas.toDataURL())
            }
        }
    }

    handleClear = () => {
        if (this.sigCanvas) {
            this.sigCanvas.clear()
        }
    }


    render() {
        const { open } = this.props

        return (
            <div className="signature-root-container" style={{
                visibility: open ? 'visible' : 'hidden'
            }}>
                <div className="signature-main-contents">
                    <DialogTitle id="signature-dialog-title">Signature</DialogTitle>

                    <DialogContent className="signature-content">
                        <SignatureCanvas
                            penColor='black'
                            ref={ref => this.sigCanvas = ref}
                            canvasProps={{
                                width: 500,
                                height: 350,
                                className: 'sigCanvas'
                            }} />
                    </DialogContent>

                    <DialogActions>
                        <Button className="btn black-green-button" onClick={() => this.handleClear()}>Clear</Button>
                        <Button className="btn black-green-button" onClick={() => this.handleCancel()}>Cancel</Button>
                        <Button className="btn black-green-button" onClick={() => this.handleOk()}>OK</Button>
                    </DialogActions>
                </div>
            </div>
        )
    }
}

SignatureDialog.propTypes = {
    onCancel: PropTypes.func,
    onOK: PropTypes.func,
    signature: PropTypes.string,
}


export default SignatureDialog
