import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

export default class AlertDialog extends React.Component {
  handleCancel = () => {
      this.props.onClose(false)
  };

  handleOk = () => {
    this.props.onClose(true)
  };

  render() {
    const { title, message, ok, cancel, options, ...other } = this.props

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        className="alert-dialog-container"
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <label className="message">{message}</label>
        </DialogContent>
        <DialogActions>
          {cancel && <Button className="btn" onClick={this.handleCancel} color="primary">
            {cancel}
          </Button>}
          {ok && <Button className="btn" onClick={this.handleOk} color="primary">
            {ok}
          </Button>}
        </DialogActions>
      </Dialog>
    );
  }
}
