import React from 'react'
import { Button } from '@material-ui/core'
import { connect } from 'react-redux'

import { UserType, ImageType } from 'helper/const'

import * as imageApi from 'api/image'
import * as questionApi from 'api/question'
import * as notificationApi from 'api/notification'
import * as emailApi from 'api/email'
import * as feedsAction from 'redux/actions/feeds'

const uuidv1 = require('uuid/v1')


class FeedReplyEdit extends React.Component {
    constructor(props) {
        super(props)

        let value
        if (props.feedReply) {
            value = props.feedReply.body
        }

        this.state = {
            value
        }
    }


    componentWillReceiveProps(nextProps) {
        let value
        if (nextProps.feedReply) {
            value = nextProps.feedReply.body
        }

        this.setState({
            value
        })
    }


    onPickFile = (imgFile) => {
        if (imgFile) {
            let reader = new FileReader()

            reader.onload = (e) => {
                const photo = e.target.result
                const photoSize = parseInt(photo.length * 3 / 4, 10)   // Bytes

                this.setState({
                    photo,
                    photoSize: this.getFileSizeDesc(photoSize)
                })
            }
            reader.readAsDataURL(imgFile)
        }
    }

    getFileSizeDesc = (size) => {
        let result = parseInt(size, 10)

        if (isNaN(result)) {
            return size
        }

        if (result < 1024) {
            result = result + " Bytes"
        } else if (result < 1024 * 1024) {
            result = result / 1024  // KB
            result = parseInt(result * 10, 10) / 10

            result = result + " KB"
        } else {
            result = result / 1024 / 1024  // KB
            result = parseInt(result * 10, 10) / 10

            result = result + " MB"
        }

        return result
    }


    handleChange = (value) => {
        this.setState({
            value
        })
    }


    handleSave = async () => {
        const answer = this.state

        // Check if can send answer
        if (!answer || !answer.value || answer.value.trim().length === 0) {
            this.setState({
                alertMessage: 'Answer is required.',
                alertDlgOpen: true
            })
            return
        }

        const { question, userId, feedReply } = this.props
        const { photo, value } = answer

        let data = {
            feedId: question.feedID,
            doctorId: userId,
            body: value
        }

        if (photo) {
            const purePhoto = photo.split(",")[1];
            const base64Photo = global.compressBase64(purePhoto)
            const rstImg = await imageApi.uploadImage(ImageType.Article, base64Photo)

            console.log("Image upload: ", rstImg)

            if (rstImg.status === 0) {
                const imgUrl = rstImg.data
                data["imageURL"] = imgUrl
            }
        }

        const rstReply = await questionApi.updateFeedReply(feedReply.feedReplyID, data)

        if (rstReply.status === 0) {
            let notification = ''
            if (question && question.title) {
                notification = `Answer to the question ${question.title} was updated.`
            } else {
                console.error("No field \"title\" found in question object:", question)
                notification = `Answer to your question was updated.`
            }

            const rstNotification = await notificationApi.createNotification({
                patientId: question.patientId,
                content: notification,
                action: "Answer",
                actionParam: question.feedID,
                PushGuid: uuidv1()
            })


            if (rstNotification.status === 0) {
                const rstPushUsers = await notificationApi.getPushUsers(question.patientId, UserType.patient)
                if (rstPushUsers.status === 0) {
                    let targetUsers = []

                    for (let index = 0; index < rstPushUsers.data.length; index++) {
                        const element = rstPushUsers.data[index];
                        if (element.pushId && element.isLogged) {
                            targetUsers.push(element.pushId)
                        }
                    }

                    if (targetUsers.length > 0) {
                        notificationApi.sendPush(targetUsers, notification)
                    }
                }
            } else {
                this.setState({
                    alertMessage: 'Failed to save notification.',
                    alertDlgOpen: true
                })
            }

            // Send email
            const rstEmail = await emailApi.notifyQuestionAnswered(question.title, value, question.feedID, question.patientId, userId)
            console.log('send email: ', rstEmail)

            this.props.dispatch(feedsAction.feedNeedRefreshAction())
            this.props.history.replace('/#/dashboard/feed')
        } else {
            this.setState({
                alertMessage: "Failed to reply",
                alertDlgOpen: true
            })
        }
    }


    render() {
        const { photoSize } = this.state

        return (
            <div className="alert-dialog-container">
                <div className="title">Update Answer</div>

                <textarea onChange={(e) => {this.handleChange(e.target.value)}} value={this.state.value}/>

                <div className="file-input-wrapper">
                    <Button className="picker-button">
                        <label className='item-input placeholder' style={{fontSize: '11pt'}}>{photoSize ? "Attach new photo" : "Attach a photo"}</label>
                        {photoSize && <label className="photo-size">{photoSize}</label>}
                    </Button>

                    <input
                        href="fileInput"
                        type="file"
                        onChange={(event) => {
                            const files = event.target.files
                            if (files.length > 0) {
                                this.onPickFile(files[0])
                            }
                        }}/>
                </div>

                <div className="save-button-wrapper">
                    <Button className='update-button save-btn full-width' onClick={this.handleSave.bind(this)}>Save</Button>
                </div>
            </div>
        )
    }
}



function mapStateToProps(state, ownProps) {
    const { feeds, auth } = state
    const { userProfile } = auth
    const { data } = feeds

    let userType = UserType.unknown
    let userId = 0

    if (userProfile) {
        userType = userProfile.userType
        if (userType === UserType.patient) {
            userId = userProfile.patientUserID
        }

        if (userType === UserType.doctor) {
            userId = userProfile.doctorUserID
        }
    }

    let result = { userType, userId }

    if (ownProps.match.params.feedReplyID) {
        const feedReplyID = parseInt(ownProps.match.params.feedReplyID, 10)

        let feedReply
        let question

        if (feedReplyID) {
            for (let i = 0; i < data.length; i++) {
                const feed = data[i]
                const { replies } = feed

                if (!replies) {
                    continue
                }

                feedReply = replies.find(item => parseInt(item.feedReplyID, 10) === feedReplyID)
                if (feedReply) {
                    question = feed
                    break
                }
            }
        }

        result.feedReply = feedReply
        result.question = question
    }

    return result
}

export default connect(mapStateToProps)(FeedReplyEdit)
