import React from 'react'
import PropTypes from 'prop-types'
import {
    Checkbox,
    FormControlLabel,
    List,
    ListItem,
    Menu,
    MenuItem,
    withStyles
} from '@material-ui/core'

const styles = theme => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    icon: {
        height: '100%',
        backgroundColor: 'transparent',
        position: 'absolute',
        right: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
})

class MultiDropDown extends React.Component {
    button = undefined

    constructor(props) {
        super(props)

        let values = props.value ? props.value.split(",") : []
        this.state = {
            anchorEl: null,
            value: values.map(item => item.trim())
        }
    }


    componentWillReceiveProps(nextProps) {
        const { value } = nextProps

        let { options } = nextProps
        if (!options) {
            options = this.props.options
        }

        if (value !== null && typeof value !== 'undefined' && value.length > 0) {
            let newValues = value.split(",")
            const indices = []
            for (let i = 0; i < newValues.length; i++) {
                const valueItem = newValues[i]
                const index = (options || []).indexOf(valueItem)
                if (index >= 0) {
                    indices.push(index)
                }
            }

            newValues = newValues.map(item => item.trim())

            this.setState({
                value: newValues,
                selectedIndex: indices
            })
        }
    }

    handleClickListItem = event => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleChange = (value) => {
        if (value.length === 0) {
            return
        }

        const { options, onlyIndex } = this.props
        const onlyItem = Number.isInteger(onlyIndex) ? options[onlyIndex] : null
        const isOnlyItem = onlyItem === value

        this.setState(prevState => {
            let newValue = [...prevState.value]
            let newIndices = prevState.selectedIndex || []

            const index = newValue.indexOf(value)
            const optionsIndex = (options || []).indexOf(value)

            if (isOnlyItem) {
                newValue = [value]
                if (optionsIndex >= 0) {
                    newIndices = [optionsIndex]
                }
            } else {
                newValue = newValue.filter(item => item !== onlyItem)

                if (index < 0) {
                    newValue.push(value)

                    if (optionsIndex >= 0) {
                        newIndices.push(optionsIndex)
                    }
                } else {
                    newValue.splice(index, 1)
                    newIndices = newIndices.filter(item => item !== optionsIndex)
                }
            }

            newValue = newValue.map(item => item.trim())

            return {
                value: newValue,
                selectedIndex: newIndices
            }
        })
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
        if (this.props.onChange) {
            this.props.onChange(this.state.value.join(', '))
        }
    }

    render() {
        const { classes, containerClass, itemClass, menuItemClass, options, IconComponent, placeholder, disabled, unselectableIndices, showValue } = this.props
        const { anchorEl, value } = this.state
        const menuItemClasses = { root: containerClass }

        const display = showValue || (value && value.length > 0 ? value.join(', ') : placeholder)

        return (
            <div className={classes.root}>
                <List component="nav">
                    <ListItem
                        button
                        aria-haspopup="true"
                        aria-controls="lock-menu"
                        aria-label="When device is locked"
                        onClick={disabled ? null : this.handleClickListItem}
                        classes={menuItemClasses} >

                        <label className={itemClass}>{display}</label>
                        <div className={classes.icon}>{IconComponent}</div>
                    </ListItem>
                </List>

                <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose} >

                    {!unselectableIndices && <MenuItem
                        disabled={true}
                        selected={true}>
                        <label className={menuItemClass}>{placeholder}</label>
                    </MenuItem>}

                    {options.map((option, index) => {
                        const isUnselectable = unselectableIndices && Array.isArray(unselectableIndices) && unselectableIndices.includes(index)

                        if (isUnselectable) {
                            return (
                                <MenuItem
                                    key={index}
                                    disabled={true}
                                    selected={true}>
                                    <label className={menuItemClass}>{option}</label>
                                </MenuItem>
                            )
                        } else {
                            return (
                                <MenuItem
                                    key={index}
                                    selected={(this.state.selectedIndex || []).includes(index)} >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value.includes(option)}
                                                onChange={() => this.handleChange(option)} />
                                        }
                                        label={option}
                                        classes={{
                                            label: menuItemClass
                                        }}
                                    />
                                </MenuItem>
                            )
                        }
                    })}
                </Menu>
            </div>
        )
    }
}

MultiDropDown.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(MultiDropDown)
