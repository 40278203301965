import React from 'react'
import { Search } from '@material-ui/icons'
import { Button } from "@material-ui/core"
import * as feedsActions from 'redux/actions/feeds'
import { connect } from 'react-redux'
import { UserType } from 'helper/const'

class NavFeed extends React.Component {
    state = {
        searchText: null
    }

    handleChangeText = (searchText) => {
        this.setState({
            searchText
        })
    }

    handleSearch = () => {
        const { searchText } = this.state
        const { feeds, userId, userType } = this.props
        const { isFeeding, filter} = feeds

        if (filter !== searchText && !isFeeding) {
            this.props.dispatch(feedsActions.getFeeds(userId, userType, 0, searchText))
        }
    }

    processKeyUp = (e) => {
        if (e.keyCode === 13) {
            // End key pressed. Going to search
            this.handleSearch()
        }
    }

    render() {
        const { feeds } = this.props
        const { isFeeding } = feeds

        return (
            <div className="nav-feed-container">
                <Button
                    className="search-button"
                    onClick={this.handleSearch}>
                    <Search style={{
                        width: '25px',
                        height: '25px'
                    }} className="search-ico" />
                </Button>

                <input
                    placeholder="Search"
                    onKeyUp={(e) => this.processKeyUp(e)}
                    onChange={(e) => {this.handleChangeText(e.target.value)}}
                    disabled={isFeeding}
                    className='search'
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { feeds, auth } = state

    let userProfile = auth.userProfile
    let userType = UserType.unknown
    let userId = 0

    if (userProfile) {
        userType = userProfile.userType
        if (userType.toString() === UserType.patient.toString()) {
            userId = userProfile.patientUserID
        }
        if (userType.toString() === UserType.doctor.toString()) {
            userId = userProfile.doctorUserID
        }
    }

    return { feeds, userType, userId }
}

export default connect(mapStateToProps)(NavFeed)
