import React, { Component } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Favicon from 'react-favicon'
import Splash from 'views/Splash/Splash.jsx'
import Authentication from 'views/Authentication/Authentication.jsx'
import AuthSelect from 'views/Authentication/AuthSelect'
import Dashboard from "layouts/Dashboard/Dashboard.jsx"
import ChatRoom from "views/ChatRoom/ChatRoom.jsx"
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from 'redux/store/configureStore'
import { OneSignalAppId, UserType, StripePublicKey, ServiceSKU, SERVICE_TIME_INTERVAL } from 'helper/const'
import Storage from 'Storage/storage.js'

import * as authAction from 'redux/actions/auth'
import * as scheduleApi from 'api/schedule'

import moment from 'moment'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"


const stripePromise = loadStripe(StripePublicKey)

class App extends Component {
    state = {
    }

    componentDidMount() {
        this.updateUserProfile()
        this.addDefaultBookingsIfNeeded()

        let OneSignal = window.OneSignal || []
        OneSignal.push(function() {
            OneSignal.init({
                appId: OneSignalAppId
            })

            OneSignal.registerForPushNotifications()

            OneSignal.isPushNotificationsEnabled(function(isEnabled) {
                if (isEnabled) {
                    console.log("Push notifications are enabled!")

                    OneSignal.getUserId((pushId) => {
                        let OneSignal = window.OneSignal || []

                        console.log("OneSignal User ID:", pushId)

                        // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316    
                        const { auth } = store.getState()
                        if (auth.isAuthed) {
                            const { patientUserID, doctorUserID, userType } = auth.userProfile
                            const userId = userType === UserType.patient ? patientUserID: doctorUserID
                            store.dispatch(authAction.updatePushId(pushId, userId, userType))

                            const tagValue = userType === UserType.patient ? 'Patient' : 'Doctor'
                            OneSignal.sendTag('UserType', tagValue).then(function(tagsSent) {
                                // Callback called when tags have finished sending
                            })
                        } else {
                            // If chat room save pushId as the provider's
                            var providerId = null
                            if (window.location && window.location.href && window.location.href.indexOf('/chat-room') > -1) {
                                const charRoomIndex = window.location.href.indexOf('chat-room/')
                                if (charRoomIndex > -1) {
                                    const params = window.location.href.substring(charRoomIndex).split('/')
                                    if (params && params.length >= 4) {
                                        providerId = params[3]
                                    }
                                }
                            }

                            if (providerId) {
                                store.dispatch(authAction.updatePushId(pushId, providerId, UserType.provider))
                            } else {
                                store.dispatch(authAction.updatePushId(pushId, null, null))
                            }
                        }
                    })

                    const notificationHandler = function(data) {
                        console.log("Received NotificationOpened:")
                        console.log(data)
                        OneSignal.addListenerForNotificationOpened(notificationHandler)
                    }

                    OneSignal.addListenerForNotificationOpened(notificationHandler)
                } else {
                    console.log("Push notifications are not enabled yet.")
                }
            })
        })
    }


    addDefaultBookingsIfNeeded() {
        const { auth } = store.getState()
        if (!auth || !auth.userProfile) {
            return
        }

        const { doctorUserID, userType } = auth.userProfile
        const userId = doctorUserID

        if (userType !== UserType.doctor) {
            return
        }

        const instantMessageSetting = Storage.getInstantMessageSetting(userId, userType)
        const audioCallSetting = Storage.getAudioCallSetting(userId, userType)
        const videoCallSetting = Storage.getVideoCallSetting(userId, userType)

        const doctorStartTimeStamp = moment().unix()

        // Instant Messaging Schedules
        let imSchedules = []
        scheduleApi.getSchedule(userId, ServiceSKU.GENERAL_IM, doctorStartTimeStamp).then(scheduleResponse => {
            if (scheduleResponse.status === 0) {
                imSchedules = imSchedules.concat(scheduleResponse.data)

                scheduleApi.getSchedule(userId, ServiceSKU.SPECIAL_IM, doctorStartTimeStamp).then(scheduleResponse => {
                    if (scheduleResponse.status === 0) {
                        imSchedules = imSchedules.concat(scheduleResponse.data)

                        const timestamp = this.addDefaultBooking(imSchedules, "IM", Storage.getInstantMessageMaxDefaultTimestamp(), instantMessageSetting)
                        Storage.setInstantMessageMaxDefaultTimestamp(timestamp)
                    } else {
                        console.error("Error while loading special im schedule:", scheduleResponse.status, scheduleResponse.data)
                    }
                }).catch(err => console.error("Failed to load special im schedule:", err))
            } else {
                console.error("Error while loading general im schedule:", scheduleResponse.status, scheduleResponse.data)
            }
        }).catch(err => console.error("Failed to load general im schedule:", err))

        // Audio Call Schedules
        let acSchedules = []
        scheduleApi.getSchedule(userId, ServiceSKU.GENERAL_AUDIO_CALL, doctorStartTimeStamp).then(scheduleResponse => {
            if (scheduleResponse.status === 0) {
                acSchedules = acSchedules.concat(scheduleResponse.data)

                scheduleApi.getSchedule(userId, ServiceSKU.SPECIAL_AUDIO_CALL, doctorStartTimeStamp).then(scheduleResponse => {
                    if (scheduleResponse.status === 0) {
                        acSchedules = acSchedules.concat(scheduleResponse.data)

                        const timestamp = this.addDefaultBooking(acSchedules, "Audio", Storage.getAudioCallMaxDefaultTimestamp(), audioCallSetting)
                        Storage.setAudioCallMaxDefaultTimestamp(timestamp)
                    } else {
                        console.error("Error while loading special audio schedule:", scheduleResponse.status, scheduleResponse.data)
                    }
                }).catch(err => console.error("Failed to load special audio schedule:", err))
            } else {
                console.error("Error while loading general audio schedule:", scheduleResponse.status, scheduleResponse.data)
            }
        }).catch(err => console.error("Failed to load general audio schedule:", err))

        // Video Call Schedules
        let vcSchedules = []
        scheduleApi.getSchedule(userId, ServiceSKU.GENERAL_VIDEO_CALL, doctorStartTimeStamp).then(scheduleResponse => {
            if (scheduleResponse.status === 0) {
                vcSchedules = vcSchedules.concat(scheduleResponse.data)

                scheduleApi.getSchedule(userId, ServiceSKU.SPECIAL_VIDEO_CALL, doctorStartTimeStamp).then(scheduleResponse => {
                    if (scheduleResponse.status === 0) {
                        vcSchedules = vcSchedules.concat(scheduleResponse.data)

                        const timestamp = this.addDefaultBooking(vcSchedules, "Video", Storage.getVideoCallMaxDefaultTimestamp(), videoCallSetting)
                        Storage.setVideoCallMaxDefaultTimestamp(timestamp)
                    } else {
                        console.error("Error while loading special video schedule:", scheduleResponse.status, scheduleResponse.data)
                    }
                }).catch(err => console.error("Failed to load special video schedule:", err))
            } else {
                console.error("Error while loading general video schedule:", scheduleResponse.status, scheduleResponse.data)
            }
        }).catch(err => console.error("Failed to load general video schedule:", err))
    }


    addDefaultBooking(schedule, type, lastUpdateDate, isEnabled) {
        if (!isEnabled) {
            return lastUpdateDate
        }

        if (!lastUpdateDate) {
            lastUpdateDate = 0
        }

        const { auth } = store.getState()
        const { userProfile } = auth
        const { doctorUserID, areaOfPractice } = userProfile

        const todayStart = new Date()
        todayStart.setHours(0)
        todayStart.setMinutes(0)
        todayStart.setSeconds(0)

        const startTimestamp = schedule.length > 0 ? Math.max.apply(Math, schedule.map(item => item.doctorEndTimeStamp)) : parseInt(todayStart.getTime() / 1000, 10)
        const endTimestamp = moment().unix() + 90 * 24 * 60 * 60


        let sku = ''

        if (schedule.length > 0) {
            sku = schedule[0].doctorServiceSKU
        } else {
            if (type === "IM") {
                sku = areaOfPractice === 'General Practice' ? ServiceSKU.GENERAL_IM : ServiceSKU.SPECIAL_IM
            } else if (type === "Audio") {
                sku = areaOfPractice === 'General Practice' ? ServiceSKU.GENERAL_AUDIO_CALL : ServiceSKU.SPECIAL_AUDIO_CALL
            } else if (type === "Video") {
                sku = areaOfPractice === 'General Practice' ? ServiceSKU.GENERAL_VIDEO_CALL : ServiceSKU.SPECIAL_VIDEO_CALL
            }
        }

        let newSchedules = []
        let lastTimestamp
        let currentTimeslot = startTimestamp + 24 * 60 * 60

        while (currentTimeslot < endTimestamp) {
            const currentDate = new Date()
            currentDate.setTime(currentTimeslot * 1000)

            let thisDayStartTime
            let thisDayEndTime

            if (currentDate.getDay() === 6 || currentDate.getDay() === 0) {
                thisDayStartTime = currentTimeslot + 10 * 60 * 60
                thisDayEndTime = thisDayStartTime + 4 * 60 * 60
            } else {
                thisDayStartTime = currentTimeslot + 9 * 60 * 60
                thisDayEndTime = thisDayStartTime + 8 * 60 * 60
            }

            const slotsAmount = (thisDayEndTime - thisDayStartTime) / (SERVICE_TIME_INTERVAL * 60)
            for (let i = 0; i < slotsAmount; i++) {
                const scheduleItem = {
                    DoctorUserId: doctorUserID,
                    DoctorServiceSKU: sku,
                    DoctorStartTimeStamp: thisDayStartTime + SERVICE_TIME_INTERVAL * i * 60,
                    DoctorEndTimeStamp: thisDayStartTime + SERVICE_TIME_INTERVAL * (i + 1) * 60
                }
                newSchedules.push(scheduleItem)

                lastTimestamp = scheduleItem.DoctorEndTimeStamp
            }

            currentTimeslot += 24 * 60 * 60
        }

        if (newSchedules.length > 0) {
            // Add services
            this.addServices(newSchedules)
            return lastTimestamp
        }

        return lastUpdateDate
    }


    async addServices(newSchedules) {
        for (let i = 0; i < newSchedules.length; i++) {
            const scheduleItem = newSchedules[i]
            await scheduleApi.createSchedule(scheduleItem.DoctorUserId, scheduleItem.DoctorServiceSKU, scheduleItem.DoctorStartTimeStamp, scheduleItem.DoctorEndTimeStamp)
        }
    }


    updateUserProfile() {
        const { auth } = store.getState()

        if (auth && auth.userProfile) {
            const { email, password, userType } = auth.userProfile
            store.dispatch(authAction.login(userType, email, password))
        }
    }

    render() {
        let icoUrl = `${window.location.origin}/logo.ico`

        return (
            <div>
                <Favicon url={icoUrl}/>

                <HashRouter>
                    <Switch>
                        <Route path="/chat-room/:userType/:userId/:providerId/:apiKey?/:sessionId?" component={ChatRoom}/>
                        <Route path="/splash" component={Splash}/>
                        <Route path="/auth/:userType" component={AuthSelect}/>
                        <Route path="/authentication/:userType/:authType" component={Authentication}/>
                        <Route path="/dashboard" component={Dashboard}/>
                        <Redirect from='/' to='/splash' />
                    </Switch>
                </HashRouter>
            </div>
        )
    }
}


const { store, persistor } = configureStore()

export const ReduxStore = store

export default class Root extends Component {
    render() {
        return (
            <Elements stripe={stripePromise}>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <App />
                        </MuiPickersUtilsProvider>
                    </PersistGate>
                </Provider>
            </Elements>
        )
    }
}
