import React from "react"
import PropTypes from "prop-types"
import { Menu } from "@material-ui/icons"
import {
    AppBar,
    Grid,
    Toolbar,
    IconButton,
    Hidden,
    withStyles
} from "@material-ui/core"

import cx from "classnames"
import headerStyle from "assets/jss/material-dashboard-react/headerStyle.jsx"
import HeaderLinks from "./HeaderLinks"

function Header({ ...props }) {
    function makeBrand() {
        let name

        props.routes.map((prop, key) => {
            let path = prop.path
            const escapedPath = path.replace(/(:.+)/g, ".+")
            const regPath = new RegExp(escapedPath)

            if (props.location.pathname.match(regPath)) {
                name = prop.navbarName
            }

            return null
        })

        return name
    }


    let navComponent
    let isSubRoute = false
    for (let index = 0; index < props.routes.length; index++) {
        const element = props.routes[index]

        let path = element.path
        const escapedPath = path.replace(/(:.+)/g, ".+")
        const regPath = new RegExp(escapedPath)

        if (props.location.pathname.match(regPath)) {
            navComponent = element.navComponent
            isSubRoute = element.subRoute ? element.subRoute : false
        }
    }

    if (!navComponent) {
        navComponent = <div style={{width: '48px'}}></div>
    }

    const { classes, color } = props

    const appBarClasses = cx({
        [" " + classes[color]]: color
    })

    const brand = makeBrand()

    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container} style={{ marginLeft: '20px' }}>
                <Grid container className="header-container">
                    <Grid item className="horz-container full-width">
                        <div className="nav-title-wrapper">
                            {!isSubRoute && <Hidden mdUp>
                                <IconButton
                                    className={classes.appResponsive}
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={props.handleDrawerToggle}>
                                    <Menu />
                                </IconButton>
                            </Hidden>}

                            { brand && <label className={classes.title}>{brand}</label> }
                        </div>

                        {navComponent}
                    </Grid>
                </Grid>

                <Hidden smDown implementation="css">
                    <HeaderLinks />
                </Hidden>
            </Toolbar>
        </AppBar>
    )
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
}

export default withStyles(headerStyle)(Header)
