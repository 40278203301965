import React from 'react'
import { connect } from 'react-redux'
import { MessageBox } from 'react-chat-elements'
import { Button } from "@material-ui/core"
import { SvgIcon, AlertDialog, STextInput } from "components"
import Storage from 'Storage/storage'

import * as authActions from 'redux/actions/auth'
import * as bookActions from 'redux/actions/bookings'

import * as notificationApi from 'api/notification'
import * as chatApi from 'api/chat'
import * as authApi from 'api/auth'
import * as bookingApi from 'api/booking'

import {
    Images,
    SvgIconData,
    UserType,
    CALL_DURATION_MAX
} from 'helper/const'

const uuidv1 = require('uuid/v1')

class InstantMessage extends React.Component {
    initialized = false
    state = {
        msgs: [],
        newMessage: "",
        statusMsg: "Creating chat room...",
        session: null,
        canSend: true,
        partnerConnection: null,
        user: null,
        showFeedbackPrompt: false,
        isEndedByUser: false,
        connectionCreated: false
    }

    async componentDidMount() {
        if (this.initialized || !this.props.historyData) {
            return
        }

        let { historyData } = this.props
        const historyDataResponse = await bookingApi.getBookingDetails(historyData.patientBookingID)
        if (historyDataResponse.status === 0) {
            historyData = historyDataResponse.data[0]
            await this.setState({ historyData })

            // Get user type, user id from url parameters.
            let { userType, userId } = this.props
            userType = parseInt(userType)

            // Get user info
            const rstUser = await authApi.getUserProfile(userType, userId)
            if (rstUser.status !== 0 && rstUser.data.length !== 1) {
                console.log("Failed to get current user info.")
                return
            }

            const user = rstUser.data[0]
            user.userType = userType

            // Get opponent user
            const rstOpponent = await this.getOpponentUserProfile()
            if (rstOpponent.status !== 0 && rstOpponent.data.length !== 1) {
                console.log("Failed to get opponent user info.")
                return
            }

            const oppoUser = rstOpponent.data[0]
            oppoUser.userType = this.getOpponentUserType()

            console.log("Current User:", user)
            console.log("Opponent User:", oppoUser)

            // Update opponent user's OneSignal push id
            if (this.props.auth && this.props.auth.pushId) {
                const { pushId } = this.props.auth
                this._updatePushInfo(pushId, this.getOpponentUserId(), this.getOpponentUserType())
            }

            // Initialize session
            const sessionInfo = await this.initSession()
            if (sessionInfo && sessionInfo.session && sessionInfo.sessionData) {
                this.setState({
                    oppoUser,
                    user,
                    statusMsg: `Waiting for the ${userType === UserType.doctor ? 'patient' : 'doctor'}...`
                })

                if (sessionInfo.isNewSessionCreated) {
                    // Send push notification to the opponent user if succeed to create new session
                    this.sendNotificationToUser(userType, userId, user, oppoUser, sessionInfo.sessionData)
                }
            }

            console.log("Initialized instant message page")
            this.initialized = true
        } else {
            console.error("Failed to load booking details:", historyDataResponse.data)
        }
    }

    componentWillReceiveProps(newProps) {
        if (!newProps.auth || !newProps.auth.pushId) {
            return
        }

        const { pushId } = newProps.auth
        if (this.props.auth && this.props.auth.pushId === pushId) {
            return
        }

        this._updatePushInfo(pushId, this.getOpponentUserId(), this.getOpponentUserType())
    }

    /**
     * Update onesignal push id to the up-to-date on the PushUsers table
     * @param {string} pushId OneSignal push id 
     * @param {int} userId Current user id 
     * @param {int} userType User type 
     */
    _updatePushInfo(pushId, userId, userType) {
        if (!pushId || !userId || !userType) {
            return
        }

        this.props.dispatch(authActions.updatePushId(pushId, userId, userType))

        let tagValue = 'unknown'
        switch (userType) {
            case UserType.patient:
                tagValue = 'Patient'
                break
            case UserType.doctor:
                tagValue = 'Doctor'
                break
            default:
                break
        }

        let OneSignal = window.OneSignal || []
        OneSignal.sendTag('UserType', tagValue).then(function(tagsSent) {
            // Callback called when tags have finished sending
        })
    }


    /**
     * Send push notification to the opponent user
     */
    async sendNotificationToUser(userType, userId, user, rstOpponent, session) {
        const { historyData } = this.state

        if (userType !== UserType.doctor) {
            return
        }

        const content = `New instant message chat request from Dr. ${user.firstName} ${user.lastName}.`
        const oppoUserName = rstOpponent.username
        const oppoUserId = rstOpponent.patientUserID

        const actionParam = {
            buddyUserId: oppoUserId,
            buddyName: oppoUserName,
            bookingId: historyData.patientBookingID,
            sessionType: "IM",
            sessionId: session.SessionId,
            isBuddyDoctor: userType === UserType.patient,
            isBuddyService: false,
            isHistoryOnly: false
        }

        const notificationParams = {
            content,
            action: "IM",
            actionParam: JSON.stringify(actionParam),
            PushGuid: uuidv1(),
            doctorId: userId
        }

        const rstNotification = await notificationApi.createNotification(notificationParams)

        if (rstNotification.status === 0) {
            const rstPushUsers = await notificationApi.getPushUsers(oppoUserId, UserType.patient)
            if (rstPushUsers.status === 0) {
                let targetUsers = []
                for (let index = 0; index < rstPushUsers.data.length; index++) {
                    const element = rstPushUsers.data[index]
                    if (element.pushId && element.isLogged) {
                        targetUsers.push(element.pushId)
                    }
                }

                if (targetUsers.length > 0) {
                    notificationApi.sendPush(targetUsers, content, actionParam)
                }
            }
        }
    }

    /**
     * Create new tokbox text message session
     */
    async initSession() {
        const { userId, userType } = this.props
        const { historyData } = this.state
        const { sessionId, patientBookingID } = historyData

        let rstSession = null
        let isNewSessionCreated = false

        console.log("initialize session:", historyData)

        if (this.isSessionStarted()) {
            console.log("Session already started. Try join...", sessionId)
            rstSession = await chatApi.joinSession(sessionId)
            if (rstSession.status !== 0) {
                console.log("Failed to join session. Creating...", rstSession.data)
                rstSession = await chatApi.createSession()
                isNewSessionCreated = true
            }
        } else {
            console.log("No session started. Try to create...", sessionId)
            rstSession = await chatApi.createSession()
            isNewSessionCreated = true
        }

        if (isNewSessionCreated) {
            // Update session id of the booking item information
            const sessionId = rstSession.data.SessionId
            const updateSessionResult = await bookingApi.updateSessionID(patientBookingID, sessionId)
            bookActions.getBookingsAction(userId, userType)

            console.log(`New session created ${sessionId}. Update session result:`, updateSessionResult)
        } else {
            console.log("Session already created. Joined successfully")
        }

        if (rstSession.status !== 0) {
            console.log(`Failed to create or join session. SessionID: ${sessionId}. Result:`, rstSession)
            return null
        }

        const sessionData = rstSession.data
        let session = await chatApi.initSession(sessionData.ApiKey, sessionData.SessionId)
        if (!session) {
            return null
        }

        await this.setState({ session })

        session = await session.connect(sessionData.Token)
        session.on("signal", this.onReceivedSignal)
        session.on("connectionCreated", this.onConnectionCreated)
        session.on("connectionDestroyed", this.onConnectionDestroyed)

        return { session, sessionData, isNewSessionCreated }
    }


    setRemainingTimeToStorage = (time) => {
        const { historyData } = this.state
        const { sessionId, patientBookingID } = historyData

        const saveData = {
            sessionId,
            time
        }

        const key = `instant_message_info_${patientBookingID}`
        const value = JSON.stringify(saveData)

        Storage.setValue(key, value)
    }

    getRemainingTimeFromStorage = () => {
        const { historyData } = this.state
        const { patientBookingID } = historyData

        const key = `instant_message_info_${patientBookingID}`
        const value = Storage.getValue(key)

        if (!value || value.length === 0) {
            return null
        }

        return JSON.parse(value)
    }

    calculateDuration = async () => {
        let result = ''

        if (!this.startTimestamp) {
            result = ''
        }

        const durationMilliseconds = new Date().getTime() - this.startTimestamp
        const durationSeconds = parseInt(durationMilliseconds / 1000, 10)
        const remaining = CALL_DURATION_MAX - durationSeconds

        if (remaining <= 0) {
            // Timeout occurred
            this.state.session.disconnect()

            if (this.durationTimer) {
                clearInterval(this.durationTimer)
                this.durationTimer = null
            }

            // End session now
            const { userId, userType } = this.props
            const { historyData } = this.state
            const { patientBookingID } = historyData

            const endSessionResult = await bookingApi.endSession(patientBookingID)
            bookActions.getBookingsAction(userId, userType)

            console.log("End Instant Message Session Result:", endSessionResult)

            if (!this.state.showFeedbackPrompt && userType === UserType.patient) {
                await this.setState({ showFeedbackPrompt: true })
            }

            return
        }

        const mins = parseInt(remaining / 60, 10)
        const secs = remaining - mins * 60

        result += ('0' + mins).slice(-2)
        result += ':'
        result += ('0' + secs).slice(-2)

        this.setRemainingTimeToStorage(result)
        this.setState({ remainingTime: result })
    }

    onConnectionCreated = event => {
        console.log("Instant Message Connection Created")

        if (this.state.session.connection.connectionId !== event.connection.connectionId) {
            this.setState({
                partnerConnection: event.connection,
                connectionCreated: true,
                statusMsg: "Type a message here..."
            })

            const previousInfo = this.getRemainingTimeFromStorage()
            if (!previousInfo) {
                this.startTimestamp = new Date().getTime()
            } else {
                const remainingTime = previousInfo.time
                console.log("Found Previous Info:", previousInfo)

                // Parse remaining time
                let [ min, sec ] = remainingTime.split(":")

                min = parseInt(min, 10)
                sec = parseInt(sec, 10)

                const remainingMilliseconds = (min * 60 + sec) * 1000
                const totalMilliseconds = CALL_DURATION_MAX * 1000
                const passedMilliseconds = totalMilliseconds - remainingMilliseconds

                this.startTimestamp = new Date().getTime() - passedMilliseconds
            }

            this.durationTimer = setInterval(this.calculateDuration, 200)
        }
    }


    onConnectionDestroyed = (e) => {
        console.log("Instant Message Connection Destoryed")

        if (this.durationTimer) {
            clearInterval(this.durationTimer)
            this.durationTimer = null
        }

        const { partnerConnection, user } = this.state
        if (partnerConnection &&
            partnerConnection.connectionId === e.connection.connectionId &&
            partnerConnection.creationTime === e.connection.creationTime
        ) {
            this.setState({
                partnerConnection: null,
                statusMsg: `Waiting for the ${user.userType === UserType.doctor ? 'doctor' : 'patient'}...`
            })

            if (user.userType === UserType.patient && !this.state.showFeedbackPrompt) {
                this.setState({ showFeedbackPrompt: true })
            }
        }
    }


    onReceivedSignal = event => {
        if (event.type === "signal:chat") {
            this.setState(prevState => {
                const msgs = prevState.msgs
                msgs.push({
                    isMine: false,
                    text: event.data,
                    when: new Date()
                })

                return { msgs }
            })

            this.scrollToBottom()
        }
    }


    handleChangeText = (key, value) => {
        this.setState({
            [key]: value
        })
    }


    handleSendMessage = () => {
        const { newMessage, session, canSend, partnerConnection } = this.state

        if (session && newMessage && canSend && partnerConnection) {
            this.setState({ canSend: false })

            session.signal({
                type: "chat",
                to: partnerConnection,
                data: newMessage
            }, error => {
                if (error) {
                    console.error("signal error: " + error)
                } else {
                    console.log("signal sent")
                }

                this.setState(prevState => {
                    const msgs = prevState.msgs
                    msgs.push({
                        isMine: true,
                        text: newMessage,
                        when: new Date()
                    })

                    return {
                        msgs,
                        canSend: true,
                        newMessage: ""
                    }
                })

                this.scrollToBottom()
            })
        } 
    }

    isSessionStarted = () => {
        const { historyData } = this.state
        const { status, sessionId = '' } = historyData

        if (status === 2) {
            return false
        }

        if (!sessionId || sessionId.length === 0) {
            return false
        }

        return true
    }

    getOpponentUserId = () => {
        const { userType } = this.props
        const { historyData } = this.state

        if (parseInt(userType, 10) === UserType.patient) {
            return historyData.doctor.doctorUserID
        } else if (parseInt(userType, 10) === UserType.doctor) {
            return historyData.patient.patientUserID
        } else {
            return -1
        }
    }

    getOpponentUserType = () => {
        const { userType } = this.props

        if (userType === UserType.doctor) {
            return UserType.patient
        } else {
            return UserType.doctor
        }
    }


    getOpponentUserProfile = async () => {
        const opponentId = this.getOpponentUserId()
        const { userType } = this.props

        let rstOpponent
        if (userType === UserType.doctor) {
            rstOpponent = await authApi.getUserProfile(UserType.patient, opponentId)
        } else if (userType === UserType.patient) {
            rstOpponent = await authApi.getUserProfile(UserType.doctor, opponentId)
        }

        return rstOpponent
    }

    scrollToBottom = () => {
        this.messageEnd.scrollIntoView({ behavior: "smooth" })
    }

    handleFeedback = (rst) => {
        this.setState({ showFeedbackPrompt: false })

        if (rst) {
            // Move to patient rating page
            const { historyData } = this.state
            this.props.history.push(`/dashboard/patient_rating/${historyData.patientBookingID}`)
        }
    }


    disconnectSession = () => {
        try {
            this.state.session.disconnect()
        } catch (err) {
            console.log("Failed to disconnect session.")
        }
    }

    handleEndCall = (event) => {
        event.stopPropagation()

        this.disconnectSession()
        this.setState({ isEndedByUser: true })

        if (this.durationTimer) {
            clearInterval(this.durationTimer)
            this.durationTimer = null
        }
    }

    render() {
        const { userType } = this.props
        const {
            msgs,
            newMessage = '',
            canSend,
            statusMsg,
            user,
            oppoUser,
            partnerConnection,
            remainingTime,
            showFeedbackPrompt,
            isEndedByUser,
            connectionCreated
        } = this.state

        if (!user || !oppoUser) {
            return <div>Loading... Please wait</div>
        }

        let myName = ''
        if (userType === UserType.doctor) {
            myName = user.firstName + ' ' + user.lastName
        } else {
            myName = user.userName
        }

        let userName = null
        let userAvatar = null
        let myAvatar = null

        if (userType === UserType.doctor) {
            userName = oppoUser.username
            userAvatar = (oppoUser.photoProfileURL && oppoUser.photoProfileURL.length > 0) ? oppoUser.photoProfileURL : Images.patientAvatar
            myAvatar = (user.profilePhotoURL && user.profilePhotoURL.length > 0) ? user.profilePhotoURL : Images.doctorAvatar
        } else if (userType === UserType.patient) {
            userName = `${oppoUser.preFix} ${oppoUser.firstName} ${oppoUser.lastName}`
            userAvatar = (oppoUser.profilePhotoURL && oppoUser.profilePhotoURL.length > 0) ? oppoUser.profilePhotoURL : Images.doctorAvatar
            myAvatar = (user.photoProfileURL && user.photoProfileURL.length > 0) ? user.photoProfileURL : Images.patientAvatar
        }

        const msgViews = msgs.map((x, i) => {
            return <MessageBox
                position={ x.isMine ? 'right' : 'left' }
                type={'text'}
                avatar={ x.isMine ? myAvatar : userAvatar }
                title= { x.isMine ? myName : userName }
                text={x.text}
                date={x.when}
                key = {i}
            />
        })


        let extra = ''
        if (isEndedByUser) {
            extra = 'Consult ended'
        } else if (partnerConnection) {
            extra = `${userName} has joined the chat. (${remainingTime})`
        } else if (connectionCreated) {
            extra = 'Consult ended'
        } else {
            extra = statusMsg
        }

        return (
            <div className="instant-chat-room-container container">
                <div className="extra-description-container">
                    <div className="message-contents">{extra}</div>
                    <Button className="btn end-chat-button" onClick={this.handleEndCall}>End</Button>
                </div>

                <div className="chat-area">
                    { msgViews }
                    <div style={{ float: "left", clear: "both"}}
                        ref={(el) => this.messageEnd = el}>
                    </div>
                </div>

                <div className="tool-area">
                    <STextInput
                        caption={statusMsg}
                        value={newMessage}
                        handleChange={(e) => this.handleChangeText('newMessage', e.target.value)}
                        handleEnter={this.handleSendMessage} />

                    <button className="send-button mb-8" onClick={this.handleSendMessage} disable={canSend ? "" : "disabled"}>
                        <SvgIcon data={SvgIconData.messageSend} className="svg-fill"/>
                    </button>
                </div>

                <AlertDialog
                    title="eDocine"
                    message={userName + ' has left the chat. Do you want to give a feedback for this service?'}
                    ok="YES"
                    cancel="NOT NOW"
                    open={showFeedbackPrompt}
                    onClose={this.handleFeedback} />
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    const { auth, bookings } = state
    const { userProfile } = auth
    const userType = userProfile.userType
    const userId = userType === UserType.patient ? userProfile.patientUserID : userProfile.doctorUserID
    const { data } = bookings

    let historyData
    if (data && ownProps.match.params) {
        let { patientBookingID } = ownProps.match.params
        patientBookingID = parseInt(patientBookingID, 10)
        historyData = data.find(x => parseInt(x.patientBookingID, 10) === patientBookingID)
    }

    return {
        auth,
        userId,
        userType,
        historyData
    }
}

export default connect(mapStateToProps)(InstantMessage)

