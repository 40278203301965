import React from 'react'
import { connect } from 'react-redux'
import { IconButton, Button, ListItem } from '@material-ui/core'
import { ArrowBack } from "@material-ui/icons"
import { UserType } from 'helper/const'

import * as global from 'helper/global'
import * as actions from 'redux/actions/questions'

class Questions extends React.Component {
    componentWillMount() {
        const { questions, userId, userType } = this.props
        const { questionsStatus } = questions

        if (questionsStatus !== 1) {
            this.props.dispatch(actions.getQuestions(userId, userType))
        }
    }

    handleQuestion = (question) => {
        const { userType } = this.props

        if (userType === UserType.patient) {
            this.props.history.push({
                pathname: '/dashboard/post_question',
                state: {question}
            })
        } else {
            this.props.history.push({
                pathname: '/dashboard/question_detail',
                state: {question}
            })
        }
    }

    render() {
        const { questions, userType } = this.props
        const { data } = questions
        if (!data) {
            return null
        }

        return (
            <div className="questions-container">
                <div className="title-wrapper">
                    <IconButton
                        className="nav-btn"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => window.history.back()}>
                        <ArrowBack />
                    </IconButton>

                    <div className="title">Questions</div>

                    {userType === UserType.patient && <Button
                        className="nav-filter-button"
                        href="/#/dashboard/post_question"
                        style={{ marginRight: '10px' }}>New</Button>}
                </div>

                {data.map(question => {
                    const { title, timeCreated, feedID } = question

                    let diffTime
                    if (timeCreated) {
                        diffTime = global.diffTime(new Date(timeCreated), new Date())
                    } else {
                        diffTime = 'A few minutes ago'
                    }

                    return (
                        <ListItem button className="item even" onClick={() => this.handleQuestion(question)} key={feedID.toString()}>
                            <label className="name">{title}</label>
                            <label className="when">{diffTime}</label>
                        </ListItem>
                    )
                })}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { questions, auth } = state
    const { userProfile } = auth

    var userType = UserType.unknown
    var userId = 0

    if (userProfile) {
        userType = userProfile.userType
        if (userType === UserType.patient) {
            userId = userProfile.patientUserID
        }
        if (userType === UserType.doctor) {
            userId = userProfile.doctorUserID
        }
    }

    return { questions, userType, userId }
}

export default connect(mapStateToProps)(Questions)
