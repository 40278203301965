import { sendGenRequest } from './apiRequest'
import { Api, UserType } from 'helper/const'

export function notifyAdminNewDoctor(doctorId, doctorInfo) {
    const params = {
        doctorId,
        doctorInfo
    }

    return sendGenRequest(Api.emailApiBaseUrl + '/NotifyNewDoctor', 'post', params)
}

export function notifyWelcome(name, email) {
    const params = {
        name,
        email
    }

    return sendGenRequest(Api.emailApiBaseUrl + '/welcome', 'post', params)
}

export function notifyQuestionAnswered(question, answer, feedId, patientId, doctorId) {
    const params = {
        feedId,
        question,
        answer,
        doctorId,
        patientId
    }

    return sendGenRequest(Api.emailApiBaseUrl + '/NotifyAnswer', 'post', params)
}

export function notifyBookingCanceled(userId, userType, service, cancelReason) {
    const params = {
        isToDoctor: userType === UserType.doctor,
        userId,
        service,
        cancelReason
    }

    return sendGenRequest(Api.emailApiBaseUrl + '/NotifyBookingCanceled', 'post', params)
}

export function notifyWithdrawal(amount, doctorId) {
    const params = {
        amount,
        doctorId
    }

    return sendGenRequest(Api.emailApiBaseUrl + '/NotifyWithdrawal', 'post', params)
}

export function notifyDoctorServiceBooking(doctorId, service, additionalInfo) {
    const params = {
        doctorId,
        service,
        additionalInfo
    }

    return sendGenRequest(Api.emailApiBaseUrl + '/NotifyDoctorServiceBooking', 'post', params)
}

export function notifyDoctorEService(providerType, fileBase64, model) {
    const params = {
        baseStr: fileBase64,
        providerType: providerType,
        list: model
    }

    return sendGenRequest(Api.emailApiBaseUrl + '/DoctorServiceEPrescription', 'post', params)
}

export function notifyBookingConfirm(patientId, service, isDeclined, declineReason) {
    const params = {
        patientId,
        service,
        isDeclined,
        declineReason
    }

    return sendGenRequest(Api.emailApiBaseUrl + '/NotifyPatientServiceBooking', 'post', params)
}
