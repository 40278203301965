import {
    LOAD_ELABORATORIES_REQUEST,
    LOAD_ELABORATORIES_SUCCESS,
    LOAD_ELABORATORIES_FAILURE,

    UPDATE_LABORATORY_REQUEST,
    UPDATE_LABORATORY_SUCCESS,
    UPDATE_LABORATORY_FAILURE,

    LOAD_LABORATORY_TESTS_REQUEST,
    LOAD_LABORATORY_TESTS_SUCCESS,
    LOAD_LABORATORY_TESTS_FAILURE,

    LOAD_LABORATORY_PROVIDERS_REQUEST,
    LOAD_LABORATORY_PROVIDERS_SUCCESS,
    LOAD_LABORATORY_PROVIDERS_FAILURE
} from 'redux/actions/elaboratories'



export function elaboratory (
    state = {
        status: 0,
        message: null,
        data: [],
    },
    action
) {
    switch (action.type) {
        case LOAD_ELABORATORIES_REQUEST:
        case UPDATE_LABORATORY_REQUEST:
            return {
                ...state,
                status: 1,
                message: null
            }
        case LOAD_LABORATORY_PROVIDERS_REQUEST:
            return {
                ...state,
                status: 6,
                message: null
            }
        case LOAD_LABORATORY_TESTS_REQUEST:
            return {
                ...state,
                status: 3,
                message: null
            }
        case LOAD_ELABORATORIES_FAILURE:
        case UPDATE_LABORATORY_FAILURE:
            return {
                ...state,
                status: 2,
                message: action.message
            }
        case LOAD_LABORATORY_PROVIDERS_FAILURE:
            return {
                ...state,
                status: 7,
                message: action.message
            }
        case LOAD_LABORATORY_TESTS_FAILURE:
            return {
                ...state,
                status: 4,
                message: action.message
            }
        case LOAD_ELABORATORIES_SUCCESS:
            return {
                ...state,
                status: 0,
                data: action.data
            }
        case LOAD_LABORATORY_PROVIDERS_SUCCESS:
            return {
                ...state,
                status: 8,
                serviceProviders: action.data
            }
        case LOAD_LABORATORY_TESTS_SUCCESS:
            return {
                ...state,
                status: 5,
                tests: action.data
            }
        case UPDATE_LABORATORY_SUCCESS:
            let newData = []

            if (state.data) {
                newData = JSON.parse(JSON.stringify(state.data))
            }

            if (action.data) {
                newData = newData.filter(item => item.eLaboratoryID !== action.data.eLaboratoryID)
                newData.push(action.data)
            }

            return {
                ...state,
                status: 0,
                data: newData
            }
        default:
            return state
    }
}

