import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from 'redux/reducers'

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['feeds', 'questions', 'schedule', 'bookings', 'note']
}

const loggerMiddleware = createLogger()

export default function configureStore(preloadedState) {
    const persistedReducer = persistReducer(persistConfig, rootReducer)

    const store = createStore(
        persistedReducer,
        preloadedState,
        applyMiddleware(
            thunkMiddleware,
            loggerMiddleware
        )
    )

    let persistor = persistStore(store)
    return { store, persistor }
}