import * as cardApi from 'api/card'

export const LOAD_CARDS_REQUEST      = 'LOAD_CARDS_REQUEST'
export const LOAD_CARDS_SUCCESS      = 'LOAD_CARDS_SUCCESS'
export const LOAD_CARDS_FAILURE      = 'LOAD_CARDS_FAILURE'

function _loadCardsRequest() {
    return {
        type: LOAD_CARDS_REQUEST
    }
}

function _loadCardsSuccess(cards) {
    return {
        type: LOAD_CARDS_SUCCESS,
        cards
    }
}

function _loadCardsFailure(message) {
    return {
        type: LOAD_CARDS_FAILURE,
        message
    }
}

export function loadCards(customerID) {
    return async dispatch => {
        dispatch(_loadCardsRequest())

        const response = await cardApi.loadCards(customerID)

        if (response.status === 0 && !response.data.Error) {
            dispatch(_loadCardsSuccess(response.data.PaymentMethods))
        } else {
            dispatch(_loadCardsFailure(response.data ? response.data.Error : "Failed to load cards"))
        }
    }
}
