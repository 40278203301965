import React from 'react'

// For the productions, all of following variables should be false
const isStaging = false
const paymentIsStaging = true
const bookMaxDurationTest = false
export const startBookingAnytime = false
// End of staging variables

export const UserType = {
    unknown: 0,
    patient: 1,
    doctor: 2,
    admin: 3,
    provider: 4
}

export const FeedType = {
    doctorPosted: 1,
    patientPosted: 2
}

export const eServiceType = {
    Prescription: 1,
    Radiology: 2,
    Laboratory: 3
}

export const ImageType = {
    DoctorProfile: 0,
    DoctorRegistrationInfo: 1,
    PatientProfile: 2,
    Article: 3,
    Answer: 4,
    DoctorNotes: 5,
    PatientNotes: 6,
    Labs: 7
}

export const Sex = {
    none: "Sex",
    male: "Male",
    female: "Female"
}

export const ServiceSKU = {
    GENERAL_IM:         'GeneralIM',
    GENERAL_AUDIO_CALL: 'GeneralAC',
    GENERAL_VIDEO_CALL: 'GeneralVC',
    SPECIAL_IM:         'SpecialIM',
    SPECIAL_AUDIO_CALL: 'SpecialAC',
    SPECIAL_VIDEO_CALL: 'SpecialVC',
    HOUSE_CALL:         'HouseCall',
    OFFICE_APPOINTMENT: 'OfficeAppointment'
}

export const Api = {
    baseUrl:            process.env.REACT_APP_BASE_URL,

    // Constants below should be re-checked before push to `master` branch
    apiKey:             isStaging ? process.env.REACT_APP_API_KEY : process.env.REACT_APP_PROD_API_KEY,
    secretKey:          isStaging ? process.env.REACT_APP_SECRET_KEY : process.env.REACT_APP_PROD_SECRET_KEY,
    restBaseUrl:        isStaging ? process.env.REACT_APP_REST_BASE_URL : process.env.REACT_APP_PROD_REST_BASE_URL,
    restToken:          isStaging ? process.env.REACT_APP_REST_TOKEN : process.env.REACT_APP_PROD_REST_TOKEN,
    baseAdminUrl:       isStaging ? process.env.REACT_APP_BASE_ADMIN_URL : process.env.REACT_APP_PROD_BASE_ADMIN_URL,
    // End of 'Constants below ...'

    get imageApiBaseUrl() { return `${this.baseAdminUrl}api/images`},
    get emailApiBaseUrl() { return `${this.baseAdminUrl}api/email`},
    get cardApiBaseUrl() { return `${this.baseAdminUrl}api/UserStripeCard`},
    get feedApiBaseUrl() { return `${this.baseAdminUrl}api/feedcontents`},
    get chatUrl() { return `${this.baseAdminUrl}api/TokBox`}
}

export const OneSignalAppId = '874572c0-46b6-4c80-a8b3-3a8ce0884a0a'
export const StripePublicKey = paymentIsStaging ? process.env.REACT_APP_STRIPE_PUBLIC_KEY : process.env.REACT_APP_PROD_STRIPE_PUBLIC_KEY
export const GoogleApiKey = "AIzaSyC2ZiD599V93Ts7eNl0LCBRUJ2yakvTbg4"

export const ServerEmail = "info@edocineapp.com"
export const TermsUrl = "http://edocine.azurewebsites.net/Home/Term"
export const PolicyUrl = "http://edocine.azurewebsites.net/Home/Policy"

export const SocialInfo = {
    facebookAppId: '283672495356525',

    twitter: {
        consumerKey: "228GuLsDPpHjbhShujak3u75A",
        consumerSecret: "FGCvQgRrB6hTZhHKVMNjy212wNR53S5hKSJ7XmjRgLlKJfLnKk",
        accessToken: "745317423310643200-2PLft74VweqfoGFhPnfBbyceU6AGNqP",
        accessTokenSecret: "wijGUbhhe7eZfiyWnP0qDxAkLYxIm0fkiCPjJQmMpt4hm",
        callBackUrl: "https://google.com"
    }
}

export const DISPLAY_MAX_LEN = 250
export const FeedRowCount = 4
export const NoteRowCount = 10
export const SERVICE_TIME_INTERVAL = 30
export const CALL_DURATION_MAX = bookMaxDurationTest ? 1 * 60 : 30 * 60

export const Illnesses = [
    'None',
    'Asthma',
    'Benign Prostatic Hyperplasia',
    'Bipolar',
    'Bladder Cancer',
    'Bone Cancer',
    'Bowel Cancer',
    'Brain Cancer',
    'Breast Cancer',
    'Cervical Cancer',
    'Chronic Infection',
    'Chronic Obstructive Pulmonary Disease',
    'Cirrhosis',
    'Congestive Cardiac Failure',
    'Depression',
    'Diabetes Mellitus',
    'Endometrial Cancer',
    'Gall Bladder Stone',
    'Glaucoma',
    'Glucose-6-phosphate dehydrogenase deficiency',
    'Head & Neck Cancer',
    'Hypercholesterolaemia',
    'Hypertension',
    'Immunocompromised',
    'Inflammatory Bowel Disease',
    'Kidney Stone',
    'Leukaemia',
    'Liver Cancer',
    'Lung Cancer',
    'Metastasis',
    'Multiple Myeloma',
    'Myocardial Infarction',
    'Obesity',
    'Ovarian Cancer',
    'Penile Cancer',
    'Prostate Cancer',
    'Pulmonary Embolism',
    'Schizophrenia',
    'Sickle Cell Disease',
    'Sinusitis',
    'Skin Cancer',
    'Stroke',
    'Tuberculosis',
    'Uterine Fibroid'
]

export const Languages = [
    'Arabic',
    'Chinese',
    'English',
    'French',
    'German',
    'Hindi',
    'Italian',
    'Japanese',
    'Portuguese',
    'Russian',
    'Romanian',
    'Spanish'
]

export const Qualifications = ["ACOP", "ACOG", "ACOOG", "ACP", "ACR", "ACS", "BSc", "BMEDSCI", "CPSO", "CCFP", "CFPC", "DDS", "DM", "DMD", "DO", "DPM", "EdD", "FRCA", "FRCGP", "FRCOG", "FRCOphth", "FRCPath", "FRCPsych", "FRCP", "FRCPS", "FRCR", "FRCS", "FRCSC", "FRCPC", "MBA", "MBBS", "MBChB", "MD", "MDCM", "MRCGP", "MRCOG", "MRCP", "MRCPath", "MRCPsych", "MRCS", "Msc.", "PharmD", "PhD", "PsyD", "RCPSC"]

export const PrefixDoctor = ["Prefix", "Dr.", "Mr.", "Ms.", "Mrs.", "Prof."]

const _AreaOfPractices = ["Anaesthesia","Pain Medicine", "Intensive Care Medicine", "General Practice", "General Dentistry","Dermatology", "Emergency Medicine", "Sport and Exercise Medicine", "Oral and Maxillofacial Surgery", "Medical Administration", "General Medicine","General Paediatrics","Cardiology", "Clinical Genetics", "Clinical Pharmacology", "Community Child Health","Endocrinology", "Gastroenterology and Hepatology", "Geriatric Medicine", "Immunology and Allergy", "Infectious Diseases", "Medical Oncology", "Neonatal-Perinatal Medicine","Nephrology","Neurology", "Nuclear Medicine", "Paediatric Emergency Medicine", "Palliative Medicine","Respiratory and Sleep Medicine", "Rheumatology", "Occupational and Environmental Medicine", "Addiction Medicine", "Public Health Medicine","Rehabilitation Medicine", "Sexual Health Medicine","Cardio-thoracic Surgery", "General Surgery", "Neurosurgery", "Orthopaedic Surgery", "Otolaryngology","Head and Neck Surgery", "Paediatric Surgery","Plastic and Reconstructive Surgery", "Urology", "Vascular Surgery","Obstetrics and Gynaecology", "Gynaecological Oncology", "Maternal-Fetal Medicine", "Obstetrics and Gynaecological Ultrasound", "Reproductive Endocrinology and Infertility", "Urogynaecology","Ophthalmology","General Pathology", "Anatomical Pathology","Chemical Pathology", "Forensic Pathology", "Haematology","Immunology", "Microbiology", "Psychiatry", "Diagnostic Radiology", "Diagnostic Ultrasound","Radiation Oncology","ADHD & Autism", "Aesthetic Medicine", "Bariatrics", "Breast Surgery", "Plastic surgery","Lipidology", "Clinical Psychology", "Cosmetic Surgery","Dental Public Health", "Dentistry-Endodontics", "Dentistry-Orthodontics and Dentofacial", "Paediatric Dentistry","Dentistry-Periodontics", "Dentistry-Prosthodontics", "Hair Restoration","Podiatrist"]
export const AreaOfPractices = ["Area of practice", ..._AreaOfPractices.sort()]

export const PaymentMethods = ["Payment Method", "Paypal", "Cheque", "Bank Transfer"]
export const CardTypes = ['Visa', 'Master Card', 'Discover', 'Amex', 'Switch', 'Solo']

export const Images = {
    agreed:             require('assets/img/icons/agreed.png'),
    checked:            require('assets/img/icons/checkbox-marked-circle.svg'),
    doctorAvatar:       require('assets/img/icons/doctor_avatar.svg'),
    facebook:           require('assets/img/icons/facebook-box.svg'),
    floatMore:          require('assets/img/icons/more.png'),
    floatPost:          require('assets/img/icons/post.png'),
    floatQuestion:      require('assets/img/icons/question.png'),
    floatTalk:          require('assets/img/icons/talk.png'),
    likes:              require('assets/img/icons/heart-outline.svg'),
    logo:               require('assets/img/logo.png'),
    patientAvatar:      require('assets/img/icons/patient_avatar.svg'),
    patientsHelped:     require('assets/img/icons/patients_helped.png'),
    twitter:            require('assets/img/icons/twitter.svg'),
    usersKnow:          require('assets/img/icons/users_know.png'),

    icon_emenu:         require('assets/img/icons/icon_emenu.svg'),
    icon_elab:          require('assets/img/icons/e-lab_icon.svg'),
    icon_eradio:        require('assets/img/icons/e-radio_icon.svg'),
    icon_eprescription: require('assets/img/icons/e-press_icon.svg'),
}

export const SvgIconData = {
    accountCircle:      <path d="M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />,
    bellOutline:        <path d="M16,17H7V10.5C7,8 9,6 11.5,6C14,6 16,8 16,10.5M18,16V10.5C18,7.43 15.86,4.86 13,4.18V3.5A1.5,1.5 0 0,0 11.5,2A1.5,1.5 0 0,0 10,3.5V4.18C7.13,4.86 5,7.43 5,10.5V16L3,18V19H20V18M11.5,22A2,2 0 0,0 13.5,20H9.5A2,2 0 0,0 11.5,22Z" />,
    building:           <path d="M5,3V21H11V17.5H13V21H19V3H5M7,5H9V7H7V5M11,5H13V7H11V5M15,5H17V7H15V5M7,9H9V11H7V9M11,9H13V11H11V9M15,9H17V11H15V9M7,13H9V15H7V13M11,13H13V15H11V13M15,13H17V15H15V13M7,17H9V19H7V17M15,17H17V19H15V17Z" />,
    checkboxMarked:     <path d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />,
    commentQuestion:    <path d="M4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2M12.19,5.5C11.3,5.5 10.59,5.68 10.05,6.04C9.5,6.4 9.22,7 9.27,7.69H11.24C11.24,7.41 11.34,7.2 11.5,7.06C11.7,6.92 11.92,6.85 12.19,6.85C12.5,6.85 12.77,6.93 12.95,7.11C13.13,7.28 13.22,7.5 13.22,7.8C13.22,8.08 13.14,8.33 13,8.54C12.83,8.76 12.62,8.94 12.36,9.08C11.84,9.4 11.5,9.68 11.29,9.92C11.1,10.16 11,10.5 11,11H13C13,10.72 13.05,10.5 13.14,10.32C13.23,10.15 13.4,10 13.66,9.85C14.12,9.64 14.5,9.36 14.79,9C15.08,8.63 15.23,8.24 15.23,7.8C15.23,7.1 14.96,6.54 14.42,6.12C13.88,5.71 13.13,5.5 12.19,5.5M11,12V14H13V12H11Z" />,
    doctorAvatar:       (<svg viewBox="0 0 96 96" style={{"enable-background": "new 0 0 96 96"}}>
                            <path class="st0" d="M36.1,63.8L48,96l11.9-32.2c9.5,2.9,17.5,9.3,22.5,17.7C90.8,72.9,96,61,96,48C96,21.5,74.5,0,48,0S0,21.5,0,48
                            c0,13,5.2,24.9,13.6,33.5C18.7,73.1,26.6,66.7,36.1,63.8z"/>
                            <path class="st1" d="M29.7,34H28v8c0,11,9,20,20,20s20-9,20-20v-8h-1.7H29.7z"/>
                            <g>
                                <path class="st1" d="M68,32H28V20c0-4.4,3.6-8,8-8h24c4.4,0,8,3.6,8,8V32z"/>
                                <rect x="46" y="16" class="st2" width="4" height="12"/>
                                <rect x="46" y="16" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 70 -26)" class="st2" width="4" height="12"/>
                            </g>
                            <polygon class="st1" points="59.9,63.8 59.9,63.8 59.9,63.8 "/>
                            <polygon class="st1" points="36.1,63.8 36.1,63.8 36.1,63.8 "/>
                            <path class="st3" d="M59.9,63.8L48,96c13.5,0,25.6-5.6,34.4-14.5C77.3,73.1,69.4,66.7,59.9,63.8z"/>
                            <path class="st3" d="M48,96L36.1,63.8c-9.5,2.9-17.5,9.3-22.5,17.7C22.4,90.4,34.5,96,48,96z"/>
                            <g>
                                <path class="st1" d="M59.9,63.8L48,96c13.5,0,25.6-5.6,34.4-14.5C77.3,73.1,69.4,66.7,59.9,63.8z"/>
                                <path class="st1" d="M48,96L36.1,63.8c-9.5,2.9-17.5,9.3-22.5,17.7C22.4,90.4,34.5,96,48,96z"/>
                            </g>
                        </svg>),
    fileDocument:       <path d="M14,17H7V15H14M17,13H7V11H17M17,9H7V7H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />,
    heart:              <path d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z" />,
    heartOutline:       <path d="M12.1,18.55L12,18.65L11.89,18.55C7.14,14.24 4,11.39 4,8.5C4,6.5 5.5,5 7.5,5C9.04,5 10.54,6 11.07,7.36H12.93C13.46,6 14.96,5 16.5,5C18.5,5 20,6.5 20,8.5C20,11.39 16.86,14.24 12.1,18.55M16.5,3C14.76,3 13.09,3.81 12,5.08C10.91,3.81 9.24,3 7.5,3C4.42,3 2,5.41 2,8.5C2,12.27 5.4,15.36 10.55,20.03L12,21.35L13.45,20.03C18.6,15.36 22,12.27 22,8.5C22,5.41 19.58,3 16.5,3Z" />,
    history:            <path d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3" />,
    home:               <path d="M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z" />,
    homeOutline:        <path d="M9,19V13H11L13,13H15V19H18V10.91L12,4.91L6,10.91V19H9M12,2.09L21.91,12H20V21H13V15H11V21H4V12H2.09L12,2.09Z" />,
    message:            <path d="M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4A2,2 0 0,0 20,2M6,9H18V11H6M14,14H6V12H14M18,8H6V6H18" />,
    messageSend:        (<svg viewBox="0 0 535.5 535.5">
                            <g>
                                <g id="send">
                                    <polygon points="0,497.25 535.5,267.75 0,38.25 0,216.75 382.5,267.75 0,318.75   " fill="darkgray"/>
                                </g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                            <g>
                            </g>
                        </svg>),
    multiUser:          (<svg>
                            <path d="M11.99 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm3.61 6.34c1.07 0 1.93.86 1.93 1.93 0 1.07-.86 1.93-1.93 1.93-1.07 0-1.93-.86-1.93-1.93-.01-1.07.86-1.93 1.93-1.93zm-6-1.58c1.3 0 2.36 1.06 2.36 2.36 0 1.3-1.06 2.36-2.36 2.36s-2.36-1.06-2.36-2.36c0-1.31 1.05-2.36 2.36-2.36zm0 9.13v3.75c-2.4-.75-4.3-2.6-5.14-4.96 1.05-1.12 3.67-1.69 5.14-1.69.53 0 1.2.08 1.9.22-1.64.87-1.9 2.02-1.9 2.68zM11.99 20c-.27 0-.53-.01-.79-.04v-4.07c0-1.42 2.94-2.13 4.4-2.13 1.07 0 2.92.39 3.84 1.15-1.17 2.97-4.06 5.09-7.45 5.09z"/>
                            <path fill="none" d="M0 0h24v24H0z"/>
                        </svg>),
    phone:              <path d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z" />,
    plusOutline:        <path d="M4,9H9V4H15V9H20V15H15V20H9V15H4V9M11,13V18H13V13H18V11H13V6H11V11H6V13H11Z" />,
    powerStandBy:       <path d="M13,3H11V13H13V3M17.83,5.17L16.41,6.59C18.05,7.91 19,9.9 19,12A7,7 0 0,1 12,19C8.14,19 5,15.88 5,12C5,9.91 5.95,7.91 7.58,6.58L6.17,5.17C2.38,8.39 1.92,14.07 5.14,17.86C8.36,21.64 14.04,22.1 17.83,18.88C19.85,17.17 21,14.65 21,12C21,9.37 19.84,6.87 17.83,5.17Z" />,
    settingsOutline:    <path d="M19.43,12.97L21.54,14.63C21.73,14.78 21.78,15.05 21.66,15.27L19.66,18.73C19.54,18.95 19.27,19.03 19.05,18.95L16.56,17.94C16.04,18.34 15.5,18.67 14.87,18.93L14.5,21.58C14.46,21.82 14.25,22 14,22H10C9.75,22 9.54,21.82 9.5,21.58L9.13,18.93C8.5,18.68 7.96,18.34 7.44,17.94L4.95,18.95C4.73,19.03 4.46,18.95 4.34,18.73L2.34,15.27C2.21,15.05 2.27,14.78 2.46,14.63L4.57,12.97L4.5,12L4.57,11L2.46,9.37C2.27,9.22 2.21,8.95 2.34,8.73L4.34,5.27C4.46,5.05 4.73,4.96 4.95,5.05L7.44,6.05C7.96,5.66 8.5,5.32 9.13,5.07L9.5,2.42C9.54,2.18 9.75,2 10,2H14C14.25,2 14.46,2.18 14.5,2.42L14.87,5.07C15.5,5.32 16.04,5.66 16.56,6.05L19.05,5.05C19.27,4.96 19.54,5.05 19.66,5.27L21.66,8.73C21.78,8.95 21.73,9.22 21.54,9.37L19.43,11L19.5,12L19.43,12.97M6.5,12C6.5,12.58 6.59,13.13 6.75,13.66L4.68,15.36L5.43,16.66L7.95,15.72C8.69,16.53 9.68,17.12 10.8,17.37L11.24,20H12.74L13.18,17.37C14.3,17.13 15.3,16.54 16.05,15.73L18.56,16.67L19.31,15.37L17.24,13.67C17.41,13.14 17.5,12.58 17.5,12C17.5,11.43 17.41,10.87 17.25,10.35L19.31,8.66L18.56,7.36L16.06,8.29C15.31,7.47 14.31,6.88 13.19,6.63L12.75,4H11.25L10.81,6.63C9.69,6.88 8.69,7.47 7.94,8.29L5.44,7.35L4.69,8.65L6.75,10.35C6.59,10.87 6.5,11.43 6.5,12M12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5M12,10.5A1.5,1.5 0 0,0 10.5,12A1.5,1.5 0 0,0 12,13.5A1.5,1.5 0 0,0 13.5,12A1.5,1.5 0 0,0 12,10.5Z" />,
    video:              <path d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />,
    eprescription:      <path d="M6,2A2,2,0,0,0,4,4V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V8L14,2H6M6,4h7V9h5V20H6V4m2,8v2h8V12H8m0,4v2h5V16Z" transform="translate(-4 -2)" />,
    elaboratory:        <path d="M9.46,6.28,11.05,9a5.01,5.01,0,1,0,5.03,7H13.5V14h8v2H19.25a8.048,8.048,0,0,1-2.46,4H19.5v2H3.5V20H6.21A7.986,7.986,0,0,1,9.46,6.28m3.28-4.21.76,1.3.86-.5,3.5,6.06-3.47,2-3.5-6.06.87-.5L11,3.07Z" transform="translate(-3.5 -2.07)" />,
    eradiology:         <path d="M10,13a7.173,7.173,0,0,0-.81,1.93C6.5,15.16,3.9,16.42,3.9,17v1.1H9.2A6.959,6.959,0,0,0,10,20H2V17c0-2.66,5.33-4,8-4m0-9a4,4,0,0,1,4,4,4.041,4.041,0,0,1-.82,2.43,6.353,6.353,0,0,0-2.27,1.47L10,12a4,4,0,1,1,0-8m0,1.9A2.1,2.1,0,1,0,12.1,8,2.1,2.1,0,0,0,10,5.9M15.5,12a4.5,4.5,0,0,1,3.81,6.9L22.39,22,21,23.39l-3.12-3.07A4.5,4.5,0,1,1,15.5,12m0,2A2.5,2.5,0,1,0,18,16.5,2.5,2.5,0,0,0,15.5,14Z" transform="translate(-2 -4)" />,
    emenu:              <path d="M6.494-12.426a4.079,4.079,0,0,1,4.215-3.716c2.912,0,4.138,1.839,4.215,3.716Zm8.927,4.9A4.163,4.163,0,0,1,11.13-4.648,4.591,4.591,0,0,1,6.379-8.939H19.866c0-.077.077-.843.077-1.571,0-6.054-3.487-9.77-9.31-9.77-4.828,0-9.272,3.908-9.272,9.923C1.36-4,5.919-.28,11.092-.28c4.636,0,7.625-2.72,8.582-5.977Z" transform="translate(-1.36 20.28)" fill="#37c977"/>
}

export const Strings = {
    confirmCancelBooking: 'Are you sure you want to cancel this appointment?',
    confirmStartBooking: 'Are you sure you want to start?'
}
