import React from 'react'
import { Button } from "@material-ui/core"
import { connect } from 'react-redux'
import * as noteActions from 'redux/actions/note'
import {UserType} from 'helper/const'

class NavNoteList extends React.Component {
    handleAdd = () => {
        const {doctorId, patientId} = this.props
        this.props.dispatch(noteActions.setCurrentNoteAction({
            doctorId,
            patientId,
            noteText: '',
            noteDataURL: ''
        }))
        window.location.href = '/#/dashboard/note_detail'
    }

    render() {
        return (
            <Button
                className="nav-filter-button"
                onClick={this.handleAdd.bind(this)}
                style={{
                    marginRight: '10px'
                }}
            >
                Add
            </Button>
        )
    }
}

function mapStateToProps(state) {
    const { auth, note } = state
    const { userProfile } = auth
    const { userType } = userProfile
    var doctorId
    if (userType === UserType.patient) {
        doctorId = 0
    } else {
        doctorId = userProfile.doctorUserID
    }

    const {currentPatientId} = note
    return {
      userType,
      doctorId,
      patientId: currentPatientId
    }
}
  
export default connect(mapStateToProps)(NavNoteList)