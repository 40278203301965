import * as ePrescriptionApi from 'api/eprescription'

export const LOAD_EPRESCRIPTION_REQUEST =    'LOAD_EPRESCRIPTION_REQUEST'
export const LOAD_EPRESCRIPTION_SUCCESS =    'LOAD_EPRESCRIPTION_SUCCESS'
export const LOAD_EPRESCRIPTION_FAILURE =    'LOAD_EPRESCRIPTION_FAILURE'

export const LOAD_EPRESCRIPTION_PROVIDERS_REQUEST =    'LOAD_EPRESCRIPTION_PROVIDERS_REQUEST'
export const LOAD_EPRESCRIPTION_PROVIDERS_SUCCESS =    'LOAD_EPRESCRIPTION_PROVIDERS_SUCCESS'
export const LOAD_EPRESCRIPTION_PROVIDERS_FAILURE =    'LOAD_EPRESCRIPTION_PROVIDERS_FAILURE'

export const UPDATE_EPRESCRIPTION_REQUEST  =    'UPDATE_EPRESCRIPTION_REQUEST'
export const UPDATE_EPRESCRIPTION_SUCCESS  =    'UPDATE_EPRESCRIPTION_SUCCESS'
export const UPDATE_EPRESCRIPTION_FAILURE  =    'UPDATE_EPRESCRIPTION_FAILURE'

function loadPrescriptionRequest(email) {
    return {
        type: LOAD_EPRESCRIPTION_REQUEST,
        email,
    }
}

function loadPrescriptionSuccess(data) {
    return {
        type: LOAD_EPRESCRIPTION_SUCCESS,
        data,
    }
}

function loadPrescriptionFailure(message) {
    return {
        type: LOAD_EPRESCRIPTION_FAILURE,
        message,
    }
}


export function loadPrescriptionAction(email) {
    return async dispatch => {
        dispatch(loadPrescriptionRequest())

        const response = await ePrescriptionApi.getPrescription(email)
        if (response.status === 0) {
            dispatch(loadPrescriptionSuccess(response.data))
        } else {
            dispatch(loadPrescriptionFailure(response.message))
        }
    }
}


function loadPrescriptionProvidersRequest() {
    return {
        type: LOAD_EPRESCRIPTION_PROVIDERS_REQUEST,
    }
}

function loadPrescriptionProvidersSuccess(data) {
    return {
        type: LOAD_EPRESCRIPTION_PROVIDERS_SUCCESS,
        data,
    }
}

function loadPrescriptionProvidersFailure(message) {
    return {
        type: LOAD_EPRESCRIPTION_PROVIDERS_FAILURE,
        message,
    }
}


export function loadPrescriptionProvidersAction() {
    return async dispatch => {
        dispatch(loadPrescriptionProvidersRequest())

        const response = await ePrescriptionApi.getServiceProviders()
        if (response.status === 0) {
            dispatch(loadPrescriptionProvidersSuccess(response.data))
        } else {
            dispatch(loadPrescriptionProvidersFailure(response.message))
        }
    }
}



function updatePrescriptionRequest() {
    return {
        type: UPDATE_EPRESCRIPTION_REQUEST,
        message: 'Adding card...'
    }
}

function updatePrescriptionSuccess(prescription) {
    return {
        type: UPDATE_EPRESCRIPTION_SUCCESS,
        prescription
    }
}

function updatePrescriptionFailure(message) {
    return {
        type: UPDATE_EPRESCRIPTION_FAILURE,
        message
    }
}

export function updatePrescriptionAction(prescription) {
    return async dispatch => {
        dispatch(updatePrescriptionRequest())

        const res = await ePrescriptionApi.savePrescription(prescription)

        if (res.status === 0) {
            dispatch(updatePrescriptionSuccess(res.data))
        } else {
            dispatch(updatePrescriptionFailure(res.message))
        }
    }
}
