import React from 'react'
import { connect } from 'react-redux'
import { Grid, IconButton } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import { Spinner, ItemGrid, AlertDialog } from 'components'
import { UserType, ServiceSKU } from 'helper/const'
import moment from 'moment'

import * as authApi from 'api/auth'
import * as paymentApi from 'api/payment'

import * as scheduleAction from 'redux/actions/schedule'
import * as bookingAction from 'redux/actions/bookings'
import * as cardActions from 'redux/actions/card'

class BookInstantMessage extends React.Component {
    bookingData = {}

    constructor(props) {
        super(props)

        const {doctorId, sku} = props
        
        this.state = {
            alertDlgOpen: false,
            alertMessage: '',
            alertTitle: 'Booking',

            answerDlgOpen: false,
            answerMessage: '',
            answerTitle: 'Booking',

            showSpinner: false,

            note: ''
        }

        if (!sku) {
            this.props.history.goBack()
            return
        }

        props.dispatch(scheduleAction.getScheduleAction(doctorId, sku))
    }

    showAlert = (title, message) => {
        this.setState(prevState => {
            const alertTitle = title ? title : prevState.alertTitle
            const alertMessage = message ? message : prevState.alertMessage

            return {
                alertTitle,
                alertMessage,
                alertDlgOpen: true
            }
        })
    }

    askQuestion = (title, message) => {
        this.setState(prevState => {
            const answerTitle = title ? title : prevState.answerTitle
            const answerMessage = message ? message : prevState.answerMessage

            return {
                answerTitle,
                answerMessage,
                answerDlgOpen: true
            }
        })
    }

    loadCards = () => {
        const { dispatch, userProfile } = this.props
        const { customerID = "" } = userProfile

        if (customerID && customerID.length > 0) {
            dispatch(cardActions.loadCards(customerID))
        }
    }

    handleAnswer = (answer) => {
        this.setState({
            answerDlgOpen: false
        })

        if (answer) {
            this.loadCards()
            this.props.dispatch(bookingAction.prepareBooking(this.bookingData))
            this.props.history.push('/dashboard/patient_payment_setting')
        }
    }

    handleNoteChange = (note) => {
        this.setState({note})
    }


    handleBooking = async (doctorUserId, doctorServiceID, startTime, endTime) => {
        const { note } = this.state
        const { userId, doctorId, sku } = this.props

        // Check validation
        if (!note) {
            this.showAlert(null, "Please fill in Note to doctor.")
            return
        }

        if (note.length > 300) {
            this.showAlert(null, "Note to doctor must be less than 300 characters.")
            return
        }

        await this.setState({ showSpinner: true })

        // Get price info
        let priceData

        if (sku === ServiceSKU.OFFICE_APPOINTMENT) {
            priceData = {
                sKU: ServiceSKU.OFFICE_APPOINTMENT,
                name: "Office Appointment",
                price: 0,
                currency: 'USD',
                isFreeService: 1
            }
        } else if (sku === ServiceSKU.HOUSE_CALL) {
            const resDoctor = await authApi.getUserProfile(UserType.doctor, doctorId)

            if (resDoctor.status === 0) {
                const doctor = resDoctor.data[0]
                priceData = {
                    sKU: ServiceSKU.HOUSE_CALL,
                    name: 'House Call',
                    price: doctor.doctorHousePrice,
                    currency: 'USD',
                    tax: 0,
                    isFreeService: 0
                }
            }
        } else {
            const resPrices = await paymentApi.getAllServicePrices()

            if (resPrices.status === 0) {
                const prices = resPrices.data
                priceData = prices.find(x => x.sKU === sku)
            }
        }


        if (!priceData) {
            this.showAlert(null, "Server error: Service price is unavailable.")
            this.setState({ showSpinner: false })
            return
        }


        let paidToDoctorAmount, paidToPlatform

        // Get doctor payment setting
        const resDoctorPaymentSetting = await paymentApi.getDoctorPaymentSetting(doctorId)
        if (resDoctorPaymentSetting.status !== 0) {
            this.showAlert(null, "Server error: Failed to get doctor payment method.")
            this.setState({ showSpinner: false })
            return
        }

        if (!resDoctorPaymentSetting.data || resDoctorPaymentSetting.data.length <= 0) {
            this.showAlert(null, "User is unable to accept payment at the moment, please try again later.")
            this.setState({ showSpinner: false })
            return
        }

        const doctorPaymentSetting = resDoctorPaymentSetting.data[0]

        // Get doctor payment income percent
        const resDoctorPaymentPercent = await paymentApi.getDoctorPaymentPercent('DoctorIncomePercent')
        if (!resDoctorPaymentPercent.data || resDoctorPaymentPercent.data.length <= 0) {
            this.showAlert(null, "Settings of eDocine isn't ready to accept payment. Please contact us for this issue.")
            this.setState({ showSpinner: false })
            return
        }

        // Calculate transaction amount
        const doctorPaymentPercent = resDoctorPaymentPercent.data[0]

        if (doctorPaymentSetting.paymentMethod.toLowerCase() === "paypal" && doctorPaymentSetting.paypalEmail) {
            paidToDoctorAmount = priceData.price * doctorPaymentPercent.value / 100
            paidToPlatform = priceData.price - paidToDoctorAmount
        } else {
            paidToPlatform = priceData.price // Doctor don't have paypal, pay all to platform then doctor can send withdrawal request later.
            paidToDoctorAmount = priceData.price * doctorPaymentPercent.value / 100
        }

        this.setState({ showSpinner: false })

        if (!priceData.isFreeService) {
            this.bookingData.priceData = priceData
            this.bookingData.paidToDoctorAmount = paidToDoctorAmount
            this.bookingData.paidToPlatform = paidToPlatform
            this.bookingData.sku = priceData.sKU
            this.bookingData.doctorId = doctorId
            this.bookingData.patientId = userId
            this.bookingData.doctorServiceId = doctorServiceID
            this.bookingData.startTime = startTime
            this.bookingData.endTime = endTime
            this.bookingData.note = note

            this.askQuestion("Consultation cost", `${priceData.name} - ${priceData.price.toFixed(2)} ${priceData.currency}.\n Would you like to continue booking?`)
        }
    }


    render() {
        const {
            note,
            alertTitle,
            alertMessage,
            alertDlgOpen,
            answerTitle,
            answerMessage,
            answerDlgOpen,
            showSpinner
        } = this.state

        let { scheduleData } = this.props
        if (scheduleData) {
            scheduleData = scheduleData.filter(item => !item.isBooked)
        }

        return (
            <div className="book-instant-message-main-cont">
                <div className="title-wrapper">
                    <IconButton
                        className="nav-btn"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => {
                            window.history.back()
                        }}>
                        <ArrowBack />
                    </IconButton>
                    <div className="title">Book Service</div>
                </div>

                <Grid container direction="column" alignItems="center" className="book-instant-message-container" style={{padding: '1rem'}}>
                    <ItemGrid xs={12} md={12} lg={12} classes={{grid: "sub-container"}}>
                        <label className="caption">Note to doctor:</label>
                        <textarea className="note" value={note} onChange={(e) => {this.handleNoteChange(e.target.value)}}/>
                        <label className="caption">Select a time slot to book with the doctor:</label>

                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                </tr>
                            </thead>

                            {scheduleData &&
                                <tbody>
                                    {scheduleData.map((slot, index) => {
                                        const { doctorServiceID, doctorStartTimeStamp, doctorEndTimeStamp } = slot
                                        const startTime = moment.unix(doctorStartTimeStamp)
                                        const endTime = moment.unix(doctorEndTimeStamp)

                                        return (
                                            <tr key={index} onClick={() => {
                                                const { doctorUserId } = slot
                                                this.handleBooking(doctorUserId, doctorServiceID, startTime, endTime)
                                            }}>
                                                <td>{startTime.format('DD/MM/YYYY')}</td>
                                                <td>{startTime.format('hh : mm a')}</td>
                                                <td>{endTime.format('hh : mm a')}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>}
                        </table>
                    </ItemGrid>

                    <AlertDialog
                        title={alertTitle}
                        message={alertMessage}
                        ok="OK"
                        open={alertDlgOpen}
                        onClose={() => {this.setState({
                            alertDlgOpen: false
                        })}}
                    />

                    <AlertDialog
                        title={answerTitle}
                        message={answerMessage}
                        ok="Yes"
                        cancel="No"
                        open={answerDlgOpen}
                        onClose={this.handleAnswer}
                    />
                </Grid>

                <Spinner show={showSpinner} />
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { auth, schedule } = state
    const { userProfile } = auth || {}

    let userType = UserType.unknown
    let userId = 0
    if (userProfile) {
        userType = userProfile.userType
        userId = userType.toString() === UserType.patient.toString() ? userProfile.patientUserID : userProfile.doctorUserID
    }

    // Service SKU
    let doctorId, sku
    if (ownProps.location.state) {
        doctorId = ownProps.location.state.doctorId
        sku = ownProps.location.state.sku
    }

    const { schedules, scheduleStatus } = schedule
    const scheduleData = schedules[sku]
    
    return {
        userProfile,
        userType,
        userId,
        doctorId,
        scheduleData,
        scheduleStatus,
        sku
    }
}
  
export default connect(mapStateToProps)(BookInstantMessage)
