import React from "react"
import {
    Button,
    Grid,
    List,
    ListItem,
    IconButton,
} from "@material-ui/core"
import { ServiceInfoItem, SvgIcon } from "components"
import { ArrowBack } from "@material-ui/icons"
import Rating from 'react-rating'
import {
    UserType,
    Images,
    ServiceSKU,
    SvgIconData
} from 'helper/const'

import * as favoritesApi from 'api/favorites'
import * as serviceApi from 'api/service'
import * as authApi from 'api/auth'

function handleServiceItem (handler, index, areaOfPractice, doctorId) {
    if (!handler) {
        return
    }

    const isGeneralArea = (areaOfPractice === 'General Practice' || areaOfPractice === 'General Dentistry')

    let sku

    switch (index) {
        case 0:
            sku = isGeneralArea ? ServiceSKU.GENERAL_IM : ServiceSKU.SPECIAL_IM
            break
        case 1:
            sku = isGeneralArea ? ServiceSKU.GENERAL_VIDEO_CALL : ServiceSKU.SPECIAL_VIDEO_CALL
            break
        case 2:
            sku = isGeneralArea ? ServiceSKU.GENERAL_AUDIO_CALL : ServiceSKU.SPECIAL_AUDIO_CALL
            break
        case 3:
            sku = ServiceSKU.HOUSE_CALL
            break
        case 4:
            sku = ServiceSKU.OFFICE_APPOINTMENT
            break
        default:
            break
    }

    handler(sku, doctorId)
}

function ServiceItem ({...props}) {
    const { ico, label, index, handler, areaOfPractice, doctorId } = props

    let className = 'service-item'
    if (index === 0) {
        className = className + " first"
    }

    if ((index % 2) === 0) {
        className = className + " even"
    }

    return (
        <ListItem button className={className} onClick={() => {handleServiceItem(handler, index, areaOfPractice, doctorId)}}>
            <SvgIcon data={ico} className="service-ico"/>
            <label>{label}</label>
        </ListItem>
    )
}


export default class Service extends React.Component {
    serviceList = [{
        ico: SvgIconData.message,
        label: "Instant Message A Doctor"
    }, {
        ico: SvgIconData.video,
        label: "Video Call A Doctor"
    }, {
        ico: SvgIconData.phone,
        label: "Voice Call A Doctor"
    }, {
        ico: SvgIconData.home,
        label: "Schedule A House Call"
    }, {
        ico: SvgIconData.building,
        label: "Schedule Office Appointment"
    }]

    constructor(props) {
        super(props)

        this.state = {
            rating: 0,
            favorite: false,
            likes: 0,
            agreed: 0,
            knowns: 0,
            helped: 0,
            doctor: null
        }

        this.initialize()
    }

    initialize = async () => {
        let doctor = this.props.doctor

        if (!doctor) {
            this.getExtraInfos(this.props.doctorId)

            const doctorRst = await authApi.getUserProfile(UserType.doctor, this.props.doctorId)
            if (doctorRst.status === 0) {
                doctor = doctorRst.data[0]
                this.setState({ doctor })
            }
        } else {
            this.getExtraInfos(doctor.doctorUserID)
        }

        setInterval(() => this.getExtraInfos(doctor.doctorUserID), 5000)
    }

    async getExtraInfos(userId) {
        this.getAgreed(userId)
        this.getFavorites(userId)
        this.getKnowns(userId)
        this.getHelped(userId)
        this.getAverageRating(userId)

        if (this.props.patientId) {
            this.isFavorite(userId, this.props.patientId)
        }
    }

    async getAgreed(doctorId) {
        const res = await serviceApi.getAgreed(doctorId)
        if (res.status === 0) {
            this.setState({ agreed: res.data })
        }
    }

    async getFavorites(doctorId) {
        const res = await serviceApi.feedLikeDoctorTotal(doctorId)
        if (res.status === 0) {
            let likes = parseInt(res.data, 10)
            if (isNaN(likes)) {
                likes = 0
            }

            this.setState({ likes })
        }
    }

    async getKnowns(doctorId) {
        const res = await serviceApi.getKnowns(doctorId)
        if (res.status === 0) {
            const knowns = res.data ? res.data : 0
            this.setState({ knowns })
        }
    }

    async getHelped(doctorId) {
        const res = await serviceApi.getHelped(doctorId)
        if (res.status === 0) {
            const helped = res.data ? res.data : 0
            this.setState({ helped })
        }
    }

    async getAverageRating(doctorId) {
        const res = await serviceApi.getDoctorAverageRating(doctorId)
        if (res.status === 0) {
            const rating = res.data ? parseFloat(res.data[0].averageRating) : 0
            this.setState({ rating })
        }
    }

    async isFavorite(doctorId, patientId) {
        const res = await serviceApi.isPatientLikeDoctor(patientId, doctorId)
        if (res.status === 0) {
            const favorite = res.data === "1" ? true : false
            this.setState({ favorite })
        }
    }

    handleFavorite = async () => {
        const { patientId } = this.props
        const { doctor } = this.state

        if (patientId && patientId !== 0) {
            if (doctor) {
                const doctorId = doctor.doctorUserID
                const res = await favoritesApi.setFavorite(UserType.patient, patientId, doctorId, !this.state.favorite)
                if (res.status === 0) {
                    this.setState(prevState => {
                        return { favorite: !prevState.favorite }
                    })
                }
            }
        }
    }

    nav2DoctorProfie = () => {
        if (!this.props.patientId) {
            return
        }

        const {patientId, doctorId} = this.props
        this.props.history.push({
            pathname: `/dashboard/doctor_detail/${doctorId}`,
            state: {
                patientId
            }
        })
    }

    render() {
        const doctor = this.state.doctor
        if (!doctor) {
            return <div>Loading... Please wait</div>
        }

        const { handleSKU } = this.props
        const { rating, likes, favorite, agreed, knowns, helped } = this.state
        const { areaOfPractice, preFix, firstName, lastName, profilePhotoURL } = doctor

        const avatar = (profilePhotoURL && profilePhotoURL.length > 0) ? profilePhotoURL : Images.doctorAvatar
        const name = `${preFix || ''} ${firstName || ''} ${lastName || ''}`

        let showTitle = true
        if (window.location.href.includes("service_detail")) {
            showTitle = false
        }

        const { patientId } = this.props

        let showFavoriteButton = true
        if (!patientId) {
            showFavoriteButton = false
        }

        return (
            <div>
                {showTitle && <div className="title">Services</div>}
                {!showTitle && <div className="title-wrapper">
                    <IconButton
                        className="nav-btn"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => window.history.back()}>
                        <ArrowBack />
                    </IconButton>
                    <div className="title">Services</div>
                </div>}

                <div className="service-container">
                    <Grid container justify="center" alignItems="center" direction="column">
                        <div className="avatar-container">
                            <img src={avatar} className="avatar" onClick={this.nav2DoctorProfie} alt="..."/>
                            {showFavoriteButton && <Button
                                variant='text'
                                onClick={this.handleFavorite}
                                className="fav-btn">
                                <i className={favorite ? "fas fa-star fa-0.5x star" : "far fa-star star"}></i>
                            </Button>}
                        </div>
                        <label className="name">{name}</label>
                        <label className="area-of-practice">{areaOfPractice}</label>
                    </Grid>
                
                    <Grid container justify="center" alignItems="center" direction="column" className="star-container">
                        <Rating
                            fractions={2}
                            emptySymbol={['far fa-star fa-2x star']}
                            fullSymbol={['fas fa-star fa-2x star']}
                            initialRating={rating}
                            onChange={(rating)=>{this.setState({rating})}}
                            readonly={true} />
                    </Grid>
                    
                    <Grid container justify="center" alignItems="center" direction="column">
                        <ServiceInfoItem
                            label="Likes"
                            ico={SvgIconData.heart}
                            value={likes} />
                        <ServiceInfoItem
                            label="Agreed"
                            ico={SvgIconData.checkboxMarked}
                            value={agreed} />
                        <ServiceInfoItem
                            label="Users Know"
                            ico={SvgIconData.multiUser}
                            value={knowns} />
                        <ServiceInfoItem
                            label="Patients Helped"
                            ico={SvgIconData.accountCircle}
                            value={helped} />
                    </Grid>
                
                    <List component="nav" style={{ marginTop: '1rem' }}>
                        {this.serviceList.map((service, index) => {
                            return <ServiceItem
                                index={index}
                                key= {index}
                                ico={service.ico}
                                label={service.label}
                                handler={handleSKU}
                                areaOfPractice={areaOfPractice}
                                doctorId={doctor.doctorUserID} />
                        })}
                    </List>
                </div>
            </div>
        )
    }
}
