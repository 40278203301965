import {encode, decode} from 'base64-arraybuffer'
var zlib = require('zlib')

// Convert 'YYYY-MM-DD HH:MM:SS' string to date
export function string2Time(value) {
    const parts = value.split(' ')
    if (parts.length !== 2) {
        return null
    }

    const datePart = parts[0]
    const timePart = parts[1]

    const dateParts = datePart.split('-')
    const timeParts = timePart.split(':')

    const year = parseInt(dateParts[0], 10)
    const month = parseInt(dateParts[1], 10)
    const date = parseInt(dateParts[2], 10)

    const hour = parseInt(timeParts[0], 10)
    const minute = parseInt(timeParts[1], 10)

    return new Date(year, month - 1, date, hour, minute)
}
    
export function diffTime(t1, t2) {
    if (!t1 || !t2) {
        return 'A long time ago'
    }

    const timeDiff = Math.abs(t2.getTime() - t1.getTime());

    const hours = Math.ceil(timeDiff / (1000 * 3600))
    const days = Math.ceil(hours / 24)
    const months = Math.ceil(days / 30)
    const years = Math.ceil(days / 365)

    if (hours < 24) {
        if (hours < 2) {
            return '1 hour ago'
        } else {
            return `${hours} hours ago`
        }
    }

    if (days < 30) {
        if (days < 2) {
            return 'A day ago'
        } else {
            return `${days} days ago`
        }
    }

    if (months < 12) {
        if ( months < 2) {
            return 'A month ago'
        } else {
            return `${months} months ago`
        }
    }

    if (years < 2) {
        return 'A year ago'
    } else if (!isNaN(years)) {
        return `${years} years ago`
    } else {
        // invalid date
        return 'A long time ago'
    }
}

/**
 * Decompress base64 string, gzip and compress to base64 again
 * @param {source string} base64String 
 */
export function compressBase64(base64String) {
    const decoded = decode(base64String)
    const buffer = Buffer.from(decoded)
    const zipped = zlib.gzipSync(buffer)
    const encoded = encode(zipped)
    return encoded
}