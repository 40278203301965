import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core'
import { AgreeTerms } from 'components'

export default class TwitterLoginDialog extends React.Component {
    state = {
        pin: '',
        agreed: false
    }

    handleCancel = () => {
        this.props.onClose(null)
    }

    handleOk = () => {
        const { pin, agreed } = this.state

        if (!agreed) {
            alert("Please agree to Terms and Privacy Policy first.")
            return
        }

        this.props.onClose(pin)
    }

    handleAgreement = (agreed) => {
        this.setState({
            agreed
        })
    }

    handleChange = (value) => {
        this.setState({
            pin: value
        })
    }

    render() {
        const { title, isLogin, userType, ...other } = this.props

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                className="twitter-login-dialog"
                {...other}>

                <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>

                <DialogContent>
                    <label className="message">PIN:</label>
                    <input type="text" onChange={(e) => {this.handleChange(e.target.value)}} />
                </DialogContent>

                {!isLogin && <AgreeTerms
                    className="agree-terms"
                    onAgreement={this.handleAgreement}
                    userType={userType}/>}

                <DialogActions>
                    <Button className="btn" onClick={this.handleCancel} color="primary">Cancel</Button>
                    <Button className="btn" onClick={this.handleOk} color="primary">OK</Button>
                </DialogActions>
            </Dialog>
        )
    }
}
