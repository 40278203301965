import apiRequest, { apiRestRequest } from './apiRequest'

export function getRadiologies(email) {
    return apiRequest('eRadiologiesGetByEmail', {email: email})
}


export function saveRadiology(radiology) {
    const { eRadiologyID } = radiology
    if (eRadiologyID) {
        // Update
        return apiRestRequest('eRadiologies/' + eRadiologyID, 'put', radiology)
    } else {
        // Create
        return apiRestRequest('eRadiologies', 'post', radiology)
    }
}

export function getRadiologyTests() {
    return apiRequest('radiologyTestListsGetAll', {})
}

export function getServiceProviders() {
    return apiRequest('providerGetAllByType', { providerType: 2 })
}
