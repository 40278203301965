import apiRequest, { apiRestRequest } from './apiRequest'

export function getLaboratories(email) {
    return apiRequest('eLaboratoriesGetByEmail', {email: email})
}


export function saveLaboratory(laboratory) {
    const { eLaboratoryID } = laboratory
    if (eLaboratoryID) {
        // Update
        return apiRestRequest('eLaboratories/' + eLaboratoryID, 'put', laboratory)
    } else {
        // Create
        return apiRestRequest('eLaboratories', 'post', laboratory)
    }
}

export function getLaboratoryTests() {
    return apiRequest('laboratoryTestsGetAll', {})
}

export function getLaboratoryTestGroups() {
    return apiRestRequest('laboratoryTestsGroups', 'get')
}

export function getServiceProviders() {
    return apiRequest('providerGetAllByType', { providerType: 3 })
}
