import * as eRadiologyApi from 'api/eradiology'

export const LOAD_ERADIOLOGIES_REQUEST     = 'LOAD_ERADIOLOGIES_REQUEST'
export const LOAD_ERADIOLOGIES_SUCCESS     = 'LOAD_ERADIOLOGIES_SUCCESS'
export const LOAD_ERADIOLOGIES_FAILURE     = 'LOAD_ERADIOLOGIES_FAILURE'

export const UPDATE_RADIOLOGY_REQUEST      = 'UPDATE_RADIOLOGY_REQUEST'
export const UPDATE_RADIOLOGY_SUCCESS      = 'UPDATE_RADIOLOGY_SUCCESS'
export const UPDATE_RADIOLOGY_FAILURE      = 'UPDATE_RADIOLOGY_FAILURE'

export const LOAD_RADIOLOGY_TESTS_REQUEST  = 'LOAD_RADIOLOGY_TESTS_REQUEST'
export const LOAD_RADIOLOGY_TESTS_SUCCESS  = 'LOAD_RADIOLOGY_TESTS_SUCCESS'
export const LOAD_RADIOLOGY_TESTS_FAILURE  = 'LOAD_RADIOLOGY_TESTS_FAILURE'

export const LOAD_RADIOLOGY_PROVIDERS_REQUEST  = 'LOAD_RADIOLOGY_PROVIDERS_REQUEST'
export const LOAD_RADIOLOGY_PROVIDERS_SUCCESS  = 'LOAD_RADIOLOGY_PROVIDERS_SUCCESS'
export const LOAD_RADIOLOGY_PROVIDERS_FAILURE  = 'LOAD_RADIOLOGY_PROVIDERS_FAILURE'

function loadRadiologiesRequest(email) {
    return {
        type: LOAD_ERADIOLOGIES_REQUEST,
        email,
    }
}

function loadRadiologiesSuccess(data) {
    return {
        type: LOAD_ERADIOLOGIES_SUCCESS,
        data,
    }
}

function loadRadiologiesFailure(message) {
    return {
        type: LOAD_ERADIOLOGIES_FAILURE,
        message,
    }
}


export function loadRadiologiesAction(email) {
    return async dispatch => {
        dispatch(loadRadiologiesRequest())

        const response = await eRadiologyApi.getRadiologies(email)
        if (response.status === 0) {
            dispatch(loadRadiologiesSuccess(response.data))
        } else {
            dispatch(loadRadiologiesFailure(response.message))
        }
    }
}


function loadRadiologyTestsRequest() {
    return {
        type: LOAD_RADIOLOGY_TESTS_REQUEST,
    }
}

function loadRadiologyTestsSuccess(data) {
    return {
        type: LOAD_RADIOLOGY_TESTS_SUCCESS,
        data,
    }
}

function loadRadiologyTestsFailure(message) {
    return {
        type: LOAD_RADIOLOGY_TESTS_FAILURE,
        message,
    }
}


export function loadRadiologyTestsAction() {
    return async dispatch => {
        dispatch(loadRadiologyTestsRequest())

        const response = await eRadiologyApi.getRadiologyTests()
        if (response.status === 0) {
            dispatch(loadRadiologyTestsSuccess(response.data))
        } else {
            dispatch(loadRadiologyTestsFailure(response.message))
        }
    }
}


function loadRadiologyProvidersRequest() {
    return {
        type: LOAD_RADIOLOGY_PROVIDERS_REQUEST,
    }
}

function loadRadiologyProvidersSuccess(data) {
    return {
        type: LOAD_RADIOLOGY_PROVIDERS_SUCCESS,
        data,
    }
}

function loadRadiologyProvidersFailure(message) {
    return {
        type: LOAD_RADIOLOGY_PROVIDERS_FAILURE,
        message,
    }
}


export function loadRadiologyProvidersAction() {
    return async dispatch => {
        dispatch(loadRadiologyProvidersRequest())

        const response = await eRadiologyApi.getServiceProviders()
        if (response.status === 0) {
            dispatch(loadRadiologyProvidersSuccess(response.data))
        } else {
            dispatch(loadRadiologyProvidersFailure(response.message))
        }
    }
}


function updateRadiologyRequest() {
    return {
        type: UPDATE_RADIOLOGY_REQUEST,
        message: 'Adding card...'
    }
}

function updateRadiologySuccess(radiology) {
    return {
        type: UPDATE_RADIOLOGY_SUCCESS,
        radiology
    }
}

function updateRadiologyFailure(message) {
    return {
        type: UPDATE_RADIOLOGY_FAILURE,
        message
    }
}

export function updateRadiologyAction(radiology) {
    return async dispatch => {
        dispatch(updateRadiologyRequest())

        const res = await eRadiologyApi.saveRadiology(radiology)

        if (res.status === 0) {
            dispatch(updateRadiologySuccess(res.data))
        } else {
            dispatch(updateRadiologyFailure(res.message))
        }
    }
}
