import { sendGenRequest } from './apiRequest'
import { Api } from 'helper/const'

export function createCustomerObject(userId, userType) {
    return sendGenRequest(Api.cardApiBaseUrl + '/CreateCustomerObject', 'post', {
        userId,
        userType
    })
}

export function createSetupIntent(customerID) {
    return sendGenRequest(Api.cardApiBaseUrl + '/CreateSetupIntent', 'post', { customerID })
}

export function loadCards(customerID) {
    return sendGenRequest(Api.cardApiBaseUrl + '/LoadPaymentMethods', 'post', { customerID })
}

export function deleteCard(paymentMethodId) {
    return sendGenRequest(Api.cardApiBaseUrl + '/DeletePaymentMethod', 'post', {
        paymentMethodId
    })
}
