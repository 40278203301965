import React, { Component } from 'react'
import { ArrowBack } from "@material-ui/icons"
import { IconButton } from "@material-ui/core"
import {
    FacebookShareCount,
    GooglePlusShareCount,
    LinkedinShareCount,
    PinterestShareCount,
    VKShareCount,
    OKShareCount,
    RedditShareCount,
    TumblrShareCount,

    FacebookShareButton,
    GooglePlusShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    PinterestShareButton,
    VKShareButton,
    OKShareButton,
    TelegramShareButton,
    WhatsappShareButton,
    RedditShareButton,
    EmailShareButton,
    TumblrShareButton,
    LivejournalShareButton,
    MailruShareButton,
    ViberShareButton,

    FacebookIcon,
    TwitterIcon,
    GooglePlusIcon,
    LinkedinIcon,
    PinterestIcon,
    VKIcon,
    OKIcon,
    TelegramIcon,
    WhatsappIcon,
    RedditIcon,
    TumblrIcon,
    MailruIcon,
    EmailIcon,
    LivejournalIcon,
    ViberIcon,
} from 'react-share'

import { Images } from 'helper/const'

class Share extends Component {
    render() {
        const shareUrl = 'www.edocineapp.com'
        const title = 'Visit www.edocine.com and download the amazing eDocine Mobile App...A digital community built to connect doctors and patients!'
        const subject = 'Visit www.edocine.com and download the amazing eDocine Mobile App'

        return (
            <div className="demo-main-container">
                <div className="title-wrapper">
                    <IconButton
                        className="nav-btn"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => window.history.back()}>
                        <ArrowBack />
                    </IconButton>
                    <div className="title">Share</div>
                </div>

                <div className="Demo__container">
                    <div className="Demo__some-network">
                        <FacebookShareButton
                            url={shareUrl}
                            quote={title}
                            className="Demo__some-network__share-button">
                            <FacebookIcon
                            size={32}
                            round /></FacebookShareButton>

                        <FacebookShareCount
                            url={shareUrl}
                            className="Demo__some-network__share-count">
                            {count => count}
                        </FacebookShareCount>
                    </div>

                    <div className="Demo__some-network">
                        <TwitterShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button">
                            <TwitterIcon
                            size={32}
                            round />
                        </TwitterShareButton>

                        <div className="Demo__some-network__share-count">&nbsp;</div>
                    </div>

                    <div className="Demo__some-network">
                        <TelegramShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button">
                                <TelegramIcon size={32} round />
                        </TelegramShareButton>

                        <div className="Demo__some-network__share-count">&nbsp;</div>
                    </div>

                    <div className="Demo__some-network">
                        <WhatsappShareButton
                            url={shareUrl}
                            title={title}
                            separator=":: "
                            className="Demo__some-network__share-button">
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>

                        <div className="Demo__some-network__share-count">&nbsp;</div>
                    </div>

                    <div className="Demo__some-network">
                        <GooglePlusShareButton
                            url={shareUrl}
                            className="Demo__some-network__share-button">
                            <GooglePlusIcon
                            size={32}
                            round />
                        </GooglePlusShareButton>

                        <GooglePlusShareCount
                            url={shareUrl}
                            className="Demo__some-network__share-count">
                            {count => count}
                        </GooglePlusShareCount>
                    </div>

                    <div className="Demo__some-network">
                        <LinkedinShareButton
                            url={shareUrl}
                            title={title}
                            windowWidth={750}
                            windowHeight={600}
                            className="Demo__some-network__share-button">
                            <LinkedinIcon
                            size={32}
                            round />
                        </LinkedinShareButton>

                        <LinkedinShareCount
                            url={shareUrl}
                            className="Demo__some-network__share-count">
                            {count => count}
                        </LinkedinShareCount>
                    </div>

                    <div className="Demo__some-network">
                        <PinterestShareButton
                            url={String(window.location)}
                            media={`${String(window.location)}/${Images.logo}`}
                            windowWidth={1000}
                            windowHeight={730}
                            className="Demo__some-network__share-button">
                            <PinterestIcon size={32} round />
                        </PinterestShareButton>

                        <PinterestShareCount
                            url={shareUrl}
                            className="Demo__some-network__share-count" />
                    </div>

                    <div className="Demo__some-network">
                        <VKShareButton
                            url={shareUrl}
                            image={`${String(window.location)}/${Images.logo}`}
                            windowWidth={660}
                            windowHeight={460}
                            className="Demo__some-network__share-button">
                            <VKIcon
                                size={32}
                                round />
                        </VKShareButton>

                        <VKShareCount
                            url={shareUrl}
                            className="Demo__some-network__share-count" />
                    </div>

                    <div className="Demo__some-network">
                        <OKShareButton
                            url={shareUrl}
                            image={`${String(window.location)}/${Images.logo}`}
                            windowWidth={660}
                            windowHeight={460}
                            className="Demo__some-network__share-button">

                            <OKIcon
                                size={32}
                                round />
                        </OKShareButton>

                        <OKShareCount
                            url={shareUrl}
                            className="Demo__some-network__share-count" />
                    </div>

                    <div className="Demo__some-network">
                        <RedditShareButton
                            url={shareUrl}
                            title={title}
                            windowWidth={660}
                            windowHeight={460}
                            className="Demo__some-network__share-button">
                            <RedditIcon
                                size={32}
                                round />
                        </RedditShareButton>

                        <RedditShareCount
                            url={shareUrl}
                            className="Demo__some-network__share-count" />
                    </div>

                    <div className="Demo__some-network">
                        <TumblrShareButton
                            url={shareUrl}
                            title={title}
                            windowWidth={660}
                            windowHeight={460}
                            className="Demo__some-network__share-button">
                            <TumblrIcon
                                size={32}
                                round />
                        </TumblrShareButton>

                        <TumblrShareCount
                            url={shareUrl}
                            className="Demo__some-network__share-count" />
                    </div>

                    <div className="Demo__some-network">
                        <LivejournalShareButton
                            url={shareUrl}
                            title={title}
                            description={shareUrl}
                            className="Demo__some-network__share-button">
                            <LivejournalIcon size={32} round />
                        </LivejournalShareButton>
                    </div>

                    <div className="Demo__some-network">
                        <MailruShareButton
                            url={shareUrl}
                            title={title}
                            className="Demo__some-network__share-button">
                            <MailruIcon
                                size={32}
                                round />
                        </MailruShareButton>
                    </div>

                    <div className="Demo__some-network">
                        <EmailShareButton
                            url={shareUrl}
                            subject={subject}
                            body={title}
                            className="Demo__some-network__share-button">
                            <EmailIcon
                                size={32}
                                round />
                        </EmailShareButton>
                    </div>

                    <div className="Demo__some-network">
                        <ViberShareButton
                            url={shareUrl}
                            title={subject}
                            body={title}
                            className="Demo__some-network__share-button">
                            <ViberIcon
                                size={32}
                                round />
                        </ViberShareButton>
                    </div>
                </div>
            </div>
        )
    }
}

export default Share