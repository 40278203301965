import React from 'react'
import { connect } from 'react-redux'
import * as bookingsAction from 'redux/actions/bookings'
import {
    ListItem,
    ListItemText
} from "@material-ui/core"
import {
    UserType,
    Images,
    ServiceSKU
} from 'helper/const'
import moment from 'moment'

class History extends React.Component {
    componentWillMount() {
        const { userId, userType } = this.props
        this.props.dispatch(bookingsAction.getBookingsAction(userId, userType))
    }


    navPatientProfile = (history) => {
        const { userType } = this.props
        const { patient, doctor } = history

        let newUrl = '/dashboard/'

        if (userType === UserType.patient) {
            // Jump to doctor
            const {doctorUserID} = doctor
            newUrl += ('service_detail/' + doctorUserID)
        } else if (userType === UserType.doctor) {
            // Jumpt to patient
            const {patientUserID} = patient
            newUrl += ('patient_detail/' + patientUserID)
        }

        this.props.history.push(newUrl)
    }


    navHistoryDetail = (history) => {
        const { patientBookingID, status, isPatientCanceled, isDoctorCanceled, doctorResponse, booking } = history
        const { doctorServiceSKU } = booking
        const { userType } = this.props

        let nextPage
        if (userType === UserType.patient) {
            if (status === 0 && !isPatientCanceled && !isDoctorCanceled) {
                nextPage = 'booking_cancel_start'
            }
        } else if (!isPatientCanceled) {
            if (doctorResponse === 0) { // doctor not accepted
                nextPage = 'booking_confirm'
            } else if (doctorResponse === 1 && status === 0) { // doctor accepted but session not started
                nextPage = 'booking_cancel_start'
            }
        }

        if (!nextPage) {
            if (doctorServiceSKU === ServiceSKU.GENERAL_IM ||
                doctorServiceSKU === ServiceSKU.SPECIAL_IM
            ) {
                nextPage = 'instant_message'
            } else {
                if (status === 2) {
                    if (userType === UserType.patient) {
                        nextPage ='patient_rating'
                    }
                } else if (status === 1) {
                    switch (doctorServiceSKU) {
                        case ServiceSKU.GENERAL_AUDIO_CALL:
                        case ServiceSKU.SPECIAL_AUDIO_CALL:
                            nextPage = 'audio_chat'
                            break
                        case ServiceSKU.GENERAL_VIDEO_CALL:
                        case ServiceSKU.SPECIAL_VIDEO_CALL:
                            nextPage = 'video_chat'
                            break
                        default:
                            break
                    }
                }
            }
        }

        console.log("Next Page:", nextPage)

        if (nextPage) {
            this.props.history.push(`/dashboard/${nextPage}/${patientBookingID}`)
        }
    }


    render() {
        const { bookingList, userType, searchText, illness } = this.props
        if (!bookingList) {
            return null
        }

        const containsIllness = (illness, patientProfile) => {
            if (!illness || illness.length === 0 || illness === 'None') {
                return true
            }

            const illnesses = illness.split(',').map(item => item.trim())
            let contains = false

            for (let i = 0; i < illnesses.length; i++) {
                const illnessItem = illnesses[i]

                if (patientProfile.illness.indexOf(illnessItem) >= 0) {
                    contains = true
                    break
                }
            }

            return contains
        }

        return (
            <div className="bookings-container">
                <div className="title">History</div>

                {bookingList.map((item) => {
                    const { patientBookingID, doctorServiceName, doctorResponse, isDoctorCanceled, isPatientCanceled, doctorDeclineReason, cancelNote } = item

                    let status = ""
                    let statusClass = ""
                    let extraMsg = ""

                    if (isDoctorCanceled) {
                        status = "Cancelled by Doctor"
                        statusClass = "red"
                        extraMsg = `Reason: ${cancelNote}`
                    } else if(isPatientCanceled) {
                        status = "Cancelled by Patient"
                        statusClass = "red"
                        extraMsg = `Reason: ${cancelNote}`
                    } else {
                        switch (doctorResponse) {
                            case 0:
                                status = "Awaiting Doctor's Response"
                                break
                            case 1:
                                status = "Accepted by Doctor"
                                statusClass = "green"
                                break
                            case -1:
                                status = "Declined by Doctor"
                                statusClass = "red"
                                extraMsg = `Reason: ${doctorDeclineReason}`
                                break
                            default:
                                break
                        }
                    }

                    let content, avatar
                    const { doctor, booking, patient } = item

                    if (userType === UserType.patient) {
                        if (!doctor) {
                            return null
                        }

                        avatar = (doctor.profilePhotoURL && doctor.profilePhotoURL.length > 0) ? doctor.profilePhotoURL : Images.doctorAvatar

                        const doctorName = `${doctor.preFix} ${doctor.firstName} ${doctor.lastName}`

                        if (booking) {
                            const startDateTime = moment.unix(booking.doctorStartTimeStamp)
                            const startDate = startDateTime.format('DD/MM/YYYY')
                            const startTime = startDateTime.format('hh:mm a')

                            content = `${doctorServiceName} with ${doctorName} on ${startDate} at ${startTime}.`
                        } else {
                            content = `${doctorServiceName} with ${doctorName}.`
                        }
                    } else {
                        if (!patient) {
                            return null
                        }

                        avatar = (patient.photoProfileURL && patient.photoProfileURL.length > 0) ? patient.photoProfileURL : Images.patientAvatar

                        if (booking) {
                            const startDateTime = moment.unix(booking.doctorStartTimeStamp)
                            const startDate = startDateTime.format('DD/MM/YYYY')
                            const startTime = startDateTime.format('hh:mm a')

                            content = `${doctorServiceName} with ${patient.username} on ${startDate} at ${startTime}.`
                        } else {
                            content = `${doctorServiceName} with ${patient.username}.`
                        }
                    }

                    let passFilter = true
                    if (searchText && searchText.trim().length > 0) {
                        if (content.toUpperCase().indexOf(searchText.trim().toUpperCase()) < 0 &&
                            status.toUpperCase().indexOf(searchText.trim().toUpperCase()) < 0
                        ) {
                            passFilter = false
                        }
                    }

                    if (userType === UserType.doctor) {
                        if (!containsIllness(illness, patient)) {
                            passFilter = false
                        }
                    }

                    if (!passFilter) {
                        return null
                    }

                    content += '\n'
                    content += extraMsg

                    return (
                        <ListItem button className="item" onClick={() => {this.navHistoryDetail(item)}} key={patientBookingID}>
                            <img src={avatar} className="avatar" alt="..." onClick={(e) => {
                                e.stopPropagation()
                                this.navPatientProfile(item)
                            }} />
                            <ListItemText primary={content} secondary={status} classes={{
                                primary: "desp ",
                                secondary: "status " + statusClass
                            }} />
                            <i className="fa fa-arrow-right arrow"/>
                        </ListItem>
                    )
                })}
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth, bookings } = state
    const { userProfile } = auth

    const userType = userProfile.userType
    const userId = userType === UserType.patient ? userProfile.patientUserID : userProfile.doctorUserID
    const { bookingsStatus, message, data, searchText = '', illness } = bookings

    return {
        userId,
        userType,
        bookingsStatus,
        message,
        bookingList: data,
        searchText: searchText || '',
        illness: illness || ''
    }
}

export default connect(mapStateToProps)(History)
