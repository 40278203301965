import React from 'react'
import { connect } from 'react-redux'

import { UserType, Images, CALL_DURATION_MAX } from 'helper/const'
import { Grid, Button } from "@material-ui/core"
import { Spinner, AlertDialog } from 'components'
import Storage from 'Storage/storage'

import * as authActions from 'redux/actions/auth'
import * as bookActions from 'redux/actions/bookings'

import * as notificationApi from 'api/notification'
import * as chatApi from 'api/chat'
import * as authApi from 'api/auth'
import * as bookingApi from 'api/booking'

const OT = require('@opentok/client')
const uuidv1 = require('uuid/v1')

class AudioVideoChat extends React.Component {
    publisherId = 'id_publisher_video'
    subscriberId = 'id_subscriber_video'

    state = {
        statusMsg: "Creating chat room...",
        session: null,
        user: null,
        oppoUser: null,
        isEndedByUser: false,
        streamCreated: false,
        streamPublished: false,
        showFeedbackPrompt: false
    }


    isVideoCall = () => {
        return window.location.href.toUpperCase().includes("VIDEO")
    }


    async componentDidMount() {
        const { historyData } = this.props
        await this.setState({ historyData })

        // Get user type, user id from url parameters.
        const { userType, userId } = this.props
       
        // Get user info
        const rstUser = await authApi.getUserProfile(userType, userId)
        if (rstUser.status !== 0 && rstUser.data.length !== 1) {
            console.log("Failed to get current user info.")
            return
        }

        const user = rstUser.data[0]
        user.userType = userType

        // Get opponent user
        const rstOpponent = await this.getOpponentUserProfile()
        if (rstOpponent.status !== 0 && rstOpponent.data.length !== 1) {
            console.log("Failed to get opponent user info.")
            return
        }

        const oppoUser = rstOpponent.data[0]
        oppoUser.userType = this.getOpponentUserType()

        this.setState({
            oppoUser,
            user,
            statusMsg: "Click or Tap on the button to start"
        })

        // Update opponent user's OneSignal push id
        if (this.props.auth && this.props.auth.pushId) {
            const { pushId } = this.props.auth
            this._updatePushInfo(pushId, this.getOpponentUserId(), this.getOpponentUserType())
        }
    }

    componentWillReceiveProps(newProps) {
        if (!newProps.auth || !newProps.auth.pushId) {
            return
        }

        const { pushId } = newProps.auth
        if (this.props.auth && this.props.auth.pushId === pushId) {
            return
        }

        this._updatePushInfo(pushId, this.getOpponentUserId(), this.getOpponentUserType())
    }

    /**
     * Update onesignal push id to the up-to-date on the PushUsers table
     * @param {string} pushId OneSignal push id 
     * @param {int} userId Current user id 
     * @param {int} userType User type 
     */
    _updatePushInfo(pushId, userId, userType) {
        if (!pushId || !userId || !userType) {
            return
        }

        this.props.dispatch(authActions.updatePushId(pushId, userId, userType))

        let tagValue = 'unknown'
        switch (userType) {
            case UserType.patient:
                tagValue = 'Patient'
                break
            case UserType.doctor:
                tagValue = 'Doctor'
                break
            default:
                break
        }

        let OneSignal = window.OneSignal || []
        OneSignal.sendTag('UserType', tagValue).then(function(tagsSent) {
            // Callback called when tags have finished sending
        })
    }


    /**
     * Send push notification to the opponent user
     */
    async sendNotificationToUser(userType, userId, user, rstOpponent, session) {
        if (userType !== UserType.doctor) {
            console.log("Only doctor is allowed to send notification")
            return
        }

        const { historyData } = this.state
        const pageDetail = this.isVideoCall() ? 'video call' : 'audio call'
        const content = `New ${pageDetail} request from Dr. ${user.firstName} ${user.lastName}.`
        const oppoUserName = rstOpponent.username
        const patientUserID = rstOpponent.patientUserID

        const actionParam = {
            buddyUserId: patientUserID,
            buddyName: oppoUserName,
            bookingId: historyData.patientBookingID,
            sessionType: this.isVideoCall() ? "VC" : "AC",
            sessionId: session.SessionId,
            isBuddyDoctor: false,
            isBuddyService: false,
            isHistoryOnly: false
        }

        let notificationParams = {
            content,
            action: "IM",
            actionParam: JSON.stringify(actionParam),
            PushGuid: uuidv1()
        }

        if (userType === UserType.doctor) {
            notificationParams.doctorId = userId
        } else {
            console.error("Invalid user type is provided:", userType)
            return
        }

        const rstNotification = await notificationApi.createNotification(notificationParams)

        if (rstNotification.status === 0) {
            const rstPushUsers = await notificationApi.getPushUsers(patientUserID, UserType.patient)
            if (rstPushUsers.status === 0) {
                let targetUsers = []
                for (let index = 0; index < rstPushUsers.data.length; index++) {
                    const element = rstPushUsers.data[index]
                    if (element.pushId && element.isLogged) {
                        targetUsers.push(element.pushId)
                    }
                }

                if (targetUsers.length > 0) {
                    notificationApi.sendPush(targetUsers, content, actionParam)
                }
            }
        }
    }

    /**
     * Create new tokbox text message session
     */
    async initSession() {
        let { historyData } = this.state

        const { userId, userType } = this.props
        const historyDataResponse = await bookingApi.getBookingDetails(historyData.patientBookingID)
        if (historyDataResponse.status === 0) {
            historyData = historyDataResponse.data[0]

            await this.setState({ historyData })

            const { sessionId, patientBookingID } = historyData

            let rstSession = null
            let isNewSessionCreated = false

            if (this.isSessionStarted()) {
                rstSession = await chatApi.joinSession(sessionId)
                if (rstSession.status !== 0) {
                    rstSession = await chatApi.createSession()
                    isNewSessionCreated = true
                }
            } else {
                rstSession = await chatApi.createSession()
                isNewSessionCreated = true
            }

            if (isNewSessionCreated) {
                // Update session id of the booking item information
                const sessionId = rstSession.data.SessionId
                const updateSessionResult = await bookingApi.updateSessionID(patientBookingID, sessionId)
                bookActions.getBookingsAction(userId, userType)

                console.log(`New session created ${sessionId}. Update session result:`, updateSessionResult)
            }

            if (rstSession.status !== 0) {
                console.log(`Failed to create or join session. SessionID: ${sessionId}. Result:`, rstSession)
                return null
            }

            const sessionData = rstSession.data
            let session = await chatApi.initSession(sessionData.ApiKey, sessionData.SessionId)

            if (!session) {
                console.error("Failed to create session")
                return null
            }

            await this.setState({ session })

            const publishOptions = {
                insertMode: 'append',
                width: '100%',
                height: '100%',
                publishVideo: this.isVideoCall(),
                publishAudio: true
            }

            if (!this.isVideoCall()) {
                publishOptions.videoSource = false
            }

            const publisher = OT.initPublisher(this.publisherId, publishOptions, (error) => {
                if (error) {
                    console.error("Failed to init publisher:", error)
                } else {
                    console.log("Succeeded to init publisher")
                }
            })

            return new Promise((resolve, reject) => {
                session.on("streamCreated", this.onStreamCreated)
                session.on("streamDestroyed", this.onStreamDestroyed)

                session.connect(sessionData.Token, (error) => {
                    if (error) {
                        console.error("Failed to connect:", error)
                        reject(error)
                    } else {
                        session.publish(publisher, {
                            insertMode: 'append',
                            width: '100%',
                            height: '100%',
                        }, (error) => {
                            if (error) {
                                console.error("Failed to publish:", error)
                            } else {
                                console.log("Succeeded to publish")
                                this.setState({ streamPublished: true })
                            }
                        })

                        resolve({ session, sessionData, isNewSessionCreated })
                    }
                })
            })
        } else {
            return new Promise((resolve, reject) => reject("Failed to load historyData"))
        }
    }

    setRemainingTimeToStorage = (time) => {
        const { historyData } = this.state
        const { sessionId, patientBookingID } = historyData

        const saveData = {
            sessionId,
            time
        }

        const prefix = this.isVideoCall() ? 'video_call_info_' : 'audio_call_info_'
        const key = `${prefix}${patientBookingID}`
        const value = JSON.stringify(saveData)

        Storage.setValue(key, value)
    }

    getRemainingTimeFromStorage = () => {
        const { historyData } = this.state
        const { patientBookingID } = historyData

        const prefix = this.isVideoCall() ? 'video_call_info_' : 'audio_call_info_'
        const key = `${prefix}${patientBookingID}`
        const value = Storage.getValue(key)

        if (!value || value.length === 0) {
            return null
        }

        return JSON.parse(value)
    }

    calculateDuration = async () => {
        let result = ''

        if (!this.startTimestamp) {
            result = ''
        }

        const durationMilliseconds = new Date().getTime() - this.startTimestamp
        const durationSeconds = parseInt(durationMilliseconds / 1000, 10)
        const remaining = CALL_DURATION_MAX - durationSeconds

        if (remaining <= 0) {
            // Timeout occurred
            this.disconnectSession()

            if (this.durationTimer) {
                clearInterval(this.durationTimer)
                this.durationTimer = null
            }

            // End session now
            const { userId, userType } = this.props
            const { historyData } = this.state
            const { patientBookingID } = historyData

            const endSessionResult = await bookingApi.endSession(patientBookingID)
            bookActions.getBookingsAction(userId, userType)

            console.log("End Audio/Video Call Session Result:", endSessionResult)

            if (!this.state.showFeedbackPrompt && userType === UserType.patient) {
                await this.setState({ showFeedbackPrompt: true })
            }

            return
        }

        const mins = parseInt(remaining / 60, 10)
        const secs = remaining - mins * 60

        result += ('0' + mins).slice(-2)
        result += ':'
        result += ('0' + secs).slice(-2)

        this.setRemainingTimeToStorage(result)
        this.setState({ remainingTime: result })
    }

    onStreamCreated = event => {
        this.state.session.subscribe(event.stream, this.subscriberId, {
            insertMode: 'append',
            width: '100%',
            height: '100%'
        }, (error) => {
            if (error) {
                console.error("Failed to subscribe:", error)
            } else {
                console.log("Succeded to subscribe")

                const previousInfo = this.getRemainingTimeFromStorage()
                if (!previousInfo) {
                    this.startTimestamp = new Date().getTime()
                } else {
                    const remainingTime = previousInfo.time
                    console.log("Found Previous Info:", previousInfo)

                    // Parse remaining time
                    let [ min, sec ] = remainingTime.split(":")

                    min = parseInt(min, 10)
                    sec = parseInt(sec, 10)

                    const remainingMilliseconds = (min * 60 + sec) * 1000
                    const totalMilliseconds = CALL_DURATION_MAX * 1000
                    const passedMilliseconds = totalMilliseconds - remainingMilliseconds

                    this.startTimestamp = new Date().getTime() - passedMilliseconds
                }

                this.durationTimer = setInterval(this.calculateDuration, 200)
                this.setState({ streamCreated: true })
            }
        })
    }

    onStreamDestroyed = async (event) => {
        console.log("Video/Audio Stream Destroyed")
        if (this.durationTimer) {
            clearInterval(this.durationTimer)
            this.durationTimer = null
        }

        this.setState({
            statusMsg: "Call ended",
            streamCreated: false,
            streamPublished: true
        })

        const { userType } = this.props
        if (userType === UserType.patient && !this.state.showFeedbackPrompt) {
            this.setState({ showFeedbackPrompt: true })
        }
    }


    isSessionStarted = () => {
        const { historyData } = this.state
        const { status, sessionId = '' } = historyData

        if (status === 2) {
            return false
        }

        if (!sessionId || sessionId.length === 0) {
            return false
        }

        return true
    }

    getOpponentUserId = () => {
        const { userType } = this.props
        const { historyData } = this.state

        if (userType === UserType.patient) {
            return historyData.doctor.doctorUserID
        } else if (userType === UserType.doctor) {
            return historyData.patient.patientUserID
        } else {
            return -1
        }
    }

    getOpponentUserType = () => {
        const { userType } = this.props

        if (userType === UserType.doctor) {
            return UserType.patient
        } else {
            return UserType.doctor
        }
    }

    getOpponentUserProfile = async () => {
        const opponentId = this.getOpponentUserId()
        const { userType } = this.props

        let rstOpponent
        if (userType === UserType.doctor) {
            rstOpponent = await authApi.getUserProfile(UserType.patient, opponentId)
        } else if (userType === UserType.patient) {
            rstOpponent = await authApi.getUserProfile(UserType.doctor, opponentId)
        }

        return rstOpponent
    }


    disconnectSession = () => {
        try {
            this.state.session.disconnect()
        } catch (err) {
            console.log("Failed to disconnect session.")
        }
    }


    handleEndCall = async () => {
        this.disconnectSession()
        this.setState({
            isEndedByUser: true,
            statusMsg: 'Call ended'
        })

        if (this.durationTimer) {
            clearInterval(this.durationTimer)
            this.durationTimer = null
        }
    }

    handleStartCall = async () => {
        const { user, oppoUser } = this.state
        const { userId, userType } = this.props

        this.setState({ showSpinner: true })

        // Initialize session
        this.initSession().then(sessionInfo => {
            const { session, sessionData, isNewSessionCreated } = sessionInfo || {}
            if (sessionInfo && session && sessionData) {
                this.setState({
                    statusMsg: `Waiting for the ${userType === UserType.doctor ? 'patient' : 'doctor'}...`
                })

                if (isNewSessionCreated) {
                    // Send push notification to the opponent user if succeed to create new session
                    this.sendNotificationToUser(userType, userId, user, oppoUser, sessionData)
                }
            }

            this.setState({ showSpinner: false })
        }).catch(err => {
            this.setState({ showSpinner: false })
            alert("Failed to establish video connection. Please try again.")
            console.error(err)
        })
    }


    handleFeedback = (rst) => {
        this.setState({ showFeedbackPrompt: false })

        if (rst) {
            // Move to patient rating page
            const { historyData } = this.state
            this.props.history.push(`/dashboard/patient_rating/${historyData.patientBookingID}`)
        }
    }


    render() {
        const { statusMsg, oppoUser, streamCreated, streamPublished, remainingTime, showFeedbackPrompt } = this.state
        const { userType } = this.props

        if (!oppoUser) {
            return <div>Loading... Please wait</div>
        }

        let oppoUserName = null
        let oppoUserAvatar = null

        if (userType === UserType.doctor) {
            oppoUserName = oppoUser.username
            oppoUserAvatar = (oppoUser.photoProfileURL && oppoUser.photoProfileURL.length > 0) ? oppoUser.photoProfileURL : Images.patientAvatar
        } else if (userType === UserType.patient) {
            oppoUserName = `${oppoUser.preFix} ${oppoUser.firstName} ${oppoUser.lastName}`
            oppoUserAvatar = (oppoUser.profilePhotoURL && oppoUser.profilePhotoURL.length > 0) ? oppoUser.profilePhotoURL : Images.doctorAvatar
        }

        return (
            <div>
                {(!streamCreated || this.state.isEndedByUser) && <div className="extra-description-container">{statusMsg}</div>}
                {!this.state.isEndedByUser && streamCreated && <div className="extra-description-container">{'Call started ' + remainingTime}</div>}

                <div className={"audiovideo-chat-room-container container " + (this.isVideoCall() ? "" : "audio")}>
                    <div className={"subscriber-video " + (this.isVideoCall() ? "" : "audio")} id={this.subscriberId}>
                        {!this.isVideoCall() && <div className="oppo-avatar">
                            <img src={oppoUserAvatar} alt="" />
                            <div className="oppo-username">{oppoUserName}</div>
                        </div>}
                    </div>
                    <div className={"publisher-video " + (this.isVideoCall() ? "" : "audio")} id={this.publisherId} />
                </div>

                {!streamPublished && <Grid container direction='column'>
                    <Button className="btn operation-item first" onClick={this.handleStartCall}>Start</Button>
                </Grid>}

                {!this.state.isEndedByUser && streamPublished && streamCreated && <Grid container direction='column'>
                    <Button className="btn btn-end operation-item first" onClick={this.handleEndCall}>End</Button>
                </Grid>}

                <AlertDialog
                    title="eDocine"
                    message={oppoUserName + ' has left the chat. Do you want to give a feedback for this service?'}
                    ok="YES"
                    cancel="NOT NOW"
                    open={showFeedbackPrompt}
                    onClose={this.handleFeedback} />

                <Spinner show={this.state.showSpinner} />
            </div>
        )
    }
}


function mapStateToProps(state, ownProps) {
    const { auth, bookings } = state
    const { userProfile } = auth
    const userType = userProfile.userType
    const userId = userType === UserType.patient ? userProfile.patientUserID : userProfile.doctorUserID
    const { data } = bookings

    let historyData
    if (data && ownProps.match.params) {
        let { patientBookingID } = ownProps.match.params
        patientBookingID = parseInt(patientBookingID, 10)
        historyData = data.find(x => parseInt(x.patientBookingID, 10) === patientBookingID)
    }

    return {
        auth,
        userId,
        userType,
        historyData
    }
}

export default connect(mapStateToProps)(AudioVideoChat)

