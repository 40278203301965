import React from 'react';
import { CSSTransition } from 'react-transition-group';

export default class Toggle extends React.Component {
    render() {
        const className = this.props.className
        return <div className={className + ' centered-container'}>
            <CSSTransition
                in={this.props.show === true}
                timeout={600}
                classNames="toggle"
                unmountOnExit
                onEntered = {this.props.onEntered}
            >
                {this.props.children}
            </CSSTransition>
       </div>
    }
}