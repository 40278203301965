import React from 'react'
import { connect } from 'react-redux'
import { Button, Grid } from "@material-ui/core"
import { AlertDialog } from 'components'
import DatePicker from 'react-datepicker'
import { Spinner } from 'components'
import { UserType, SERVICE_TIME_INTERVAL } from 'helper/const'
import * as scheduleAction from 'redux/actions/schedule'
import * as authAction from 'redux/actions/auth'
import * as authApi from 'api/auth'
import moment from 'moment'

class ServiceSchedule extends React.Component {
    constructor(props) {
        super(props)

        const { userId, sku } = props
        
        const date = moment()
        const curMin = date.minute()
        const remainMin = curMin % SERVICE_TIME_INTERVAL

        let startTime = moment(date)
        if (remainMin !== 0) {
            startTime = startTime.add(SERVICE_TIME_INTERVAL - remainMin, 'm')
        }

        let endTime = moment(startTime)
        endTime = endTime.add(SERVICE_TIME_INTERVAL, 'm')
        
        this.state = {
            alertDlgOpen: false,
            alertMessage: '',
            alertTitle: 'Schedule',

            houseCallPrice: -1,
            date,
            startTime,
            endTime,
            bookingNumber: 1,
            weekly: 0,
            showSpinner: false
        }

        if (!sku) {
            this.props.history.goBack()
            return
        }

        props.dispatch(scheduleAction.getScheduleAction(userId, sku))
    }

    componentWillReceiveProps(nextProps) {
        const { scheduleStatus, dispatch, userProfile } = nextProps
        if (scheduleStatus === 0) {
            this.setState({ showSpinner: false })
        } else if (scheduleStatus === 5) {
            // Cleared schedule
            const { userId, sku } = this.props
            dispatch(scheduleAction.getScheduleAction(userId, sku))
        } else if (scheduleStatus === 6) {
            this.setState({ showSpinner: false })
        }

        if (userProfile) {
            const { houseCallPrice } = this.state
            if (houseCallPrice < 0) {
                this.setState({ houseCallPrice: userProfile.doctorHousePrice })
            }
        }
    }

    showAlert = (title, message) => {
        this.setState(prevState => {
            const alertTitle = title ? title : prevState.alertTitle
            const alertMessage = message ? message : prevState.alertMessage

            return {
                alertTitle,
                alertMessage,
                alertDlgOpen: true
            }
        })
    }

    handleDateChange = (date) => {
        this.setState(prevState => {
            const { startTime, endTime } = prevState

            const year = date.year()
            const month = date.month()
            const day = date.date()

            startTime.year(year)
            startTime.month(month)
            startTime.date(day)
            endTime.year(year)
            endTime.month(month)
            endTime.date(day)

            return {
                date,
                startTime,
                endTime,
                weekly: 0
            }
        })
    }

    handleStartTimeChange = (startTime) => {
        this.setState(prevState => {
            const { bookingNumber } = prevState

            let endTime = moment(startTime)
            endTime.add(SERVICE_TIME_INTERVAL * bookingNumber, 'm')

            return {
                startTime,
                endTime,
                weekly: 0
            }
        })
    }

    handleEndTimeChange = (endTime) => {
        this.setState(prevState => {
            const { bookingNumber } = prevState
            let startTime = moment(endTime)

            startTime.subtract(SERVICE_TIME_INTERVAL * bookingNumber, 'm')

            return {
                startTime,
                endTime,
                weekly: 0
            }
        })
    }

    handleChangeBookingNumber = (bookingNumber) => {
        this.setState(prevState => {
            const {startTime} = prevState
            const endTime = moment(startTime)
            endTime.add(SERVICE_TIME_INTERVAL * bookingNumber, 'm')

            return {
                bookingNumber,
                endTime,
                weekly: 0
            }
        })
    }

    handleChangeHouseCallPrice = (price) => {
        this.setState({ houseCallPrice: price })
    }

    createSchedule = (startTime, endTime) => {
        const { scheduleStatus, scheduleData, userId, sku } = this.props

        if (scheduleStatus === 1) { // Pending
            this.showAlert(null, "Pending... Try again later!")
            return
        } 

        let now = moment().unix()
        let iterTime = moment(startTime)

        if (now > iterTime.unix()) {
            this.showAlert(null, "Can't add as the time slot is passed.")
            return false
        }

        const scheduleList = []

        while (iterTime < endTime) {
            const iterStartTime = iterTime.unix()
            iterTime.add(SERVICE_TIME_INTERVAL, 'm')

            const iterEndTime = iterTime.unix()
            const existSlot = scheduleData.find(x => (x.doctorStartTimeStamp <= iterStartTime && iterStartTime < x.doctorEndTimeStamp) ||
                (x.doctorStartTimeStamp < iterEndTime && iterEndTime <= x.doctorEndTimeStamp))

            if (existSlot) {
                this.showAlert(null, "Can't add as there is at least one existing time slot conflicting with the new one.")
                return false
            }

            scheduleList.push({
                doctorUserId: userId,
                doctorServiceSKU: sku,
                doctorStartTimeStamp: iterStartTime,
                doctorEndTimeStamp: iterEndTime
            })
        }

        this.setState({ showSpinner: true })
        this.props.dispatch(scheduleAction.addSchedulesAction(userId, sku, scheduleList))

        return true
    }

    handleAdd = () => {
        const { startTime, endTime, houseCallPrice } = this.state
        const { userProfile, userId } = this.props

        if (userProfile.doctorHousePrice !== houseCallPrice) {
            // Should update doctor Houser Call Price
            authApi.updateUserProfile(UserType.doctor, userId, {
                doctorHousePrice: houseCallPrice
            })

            userProfile.doctorHousePrice = houseCallPrice

            authAction.notifyUpdateProfile(userProfile)
        }

        if (this.createSchedule(startTime, endTime)) {
            this.setState({ weekly: 1 })
        }
    }

    handleRepeatWeekly = () => {
        let {startTime, endTime, weekly} = this.state
        if (weekly <= 0) {
            return
        }

        startTime = moment(startTime).add(weekly, 'w')
        endTime = moment(endTime).add(weekly, 'w')

        if (this.createSchedule(startTime, endTime)) {
            this.setState({
                weekly: weekly + 1
            })
        }
    }

    handleDelete = (doctorServiceID) => {
        const { scheduleStatus, sku } = this.props

        if (scheduleStatus === 1) { // Pending
            this.showAlert(null, "Pending... Try again later!")
            return
        }

        this.props.dispatch(scheduleAction.deleteScheduleAction(doctorServiceID, sku))
    }

    handleDeleteAll = () => {
        const { userId, userType, sku, scheduleData } = this.props
        const filteredScheduleData = scheduleData ? scheduleData : []

        if (userType !== UserType.doctor) {
            console.warn("This is not doctor user. Cannot delete any schedules")
            return
        }

        if (filteredScheduleData.length === 0) {
            console.warn("No schedule data to delete now")
            return
        }

        const startTimestamp = filteredScheduleData[0].doctorStartTimeStamp
        this.props.dispatch(scheduleAction.clearScheduleAction(userId, sku, startTimestamp - 1))

        this.setState({ showSpinner: true })
    }


    isHouseCall = () => {
        const { sku } = this.props
        return sku === 'HouseCall'
    }


    render() {
        const { sku } = this.props
        const { date, startTime, endTime, bookingNumber, houseCallPrice } = this.state
        const { scheduleData } = this.props
        const filteredScheduleData = scheduleData ? scheduleData : []
        const isHouseCall = this.isHouseCall()

        let title = 'Schedule'
        if (sku === 'GeneralIM') {
            title = 'Instant Message Schedule'
        } else if (sku === 'GeneralVC') {
            title = 'Video Call Schedule'
        } else if (sku === 'GeneralAC') {
            title = 'Audio Call Schedule'
        } else if (sku === 'HouseCall') {
            title = 'House Call Schedule'
        } else if (sku === 'OfficeAppointment') {
            title = 'Office Visit Schedule'
        }

        return (
            <div className="service-schedule-container">
                <div className="service-schedule-profile-title-wrapper">
                    <div className="title">{title}</div>
                    <Button
                        className="nav-filter-button mr-1"
                        onClick={(e) => this.handleDeleteAll()}>Delete All Time Slots</Button>
                </div>

                <div className="sub-container">
                    {isHouseCall && <Grid container direction="row" alignItems="center" className="item-wrapper">
                        <label className='caption'>House Call Price: $</label>
                        <input
                            type="number"
                            className="item-input house-call-price"
                            placeholder='House Call Price'
                            value={houseCallPrice}
                            onChange={(e) => this.handleChangeHouseCallPrice(e.target.value)}/>
                    </Grid>}
                    <Grid container direction="row" alignItems="center" className="item-wrapper">
                        <label className='caption'>Date:</label>
                        <DatePicker
                            dateFormat="DD/MM/YYYY"
                            selected={date}
                            onChange={(val) => {this.handleDateChange(val)}}
                            placeholderText="Date"
                            className="item-input datepicker-item"
                            showYearDropdown
                            scrollableYearDropdown
                            minDate={moment()}
                            dropdownMode="select"
                        />
                    </Grid>
                    <Grid container direction="row" alignItems="center" className="item-wrapper">
                        <label className='caption'>Start Time:</label>
                        <DatePicker
                            selected={startTime}
                            onChange={this.handleStartTimeChange}
                            placeholderText="hh:mm a"
                            className="item-input"
                            minDate={moment()}
                            dropdownMode="select"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={SERVICE_TIME_INTERVAL}
                            dateFormat="LT"
                            timeCaption="Time"
                        />
                    </Grid>
                    <Grid container direction="row" alignItems="center" className="item-wrapper">
                        <label className='caption'>End Time:</label>
                        <DatePicker
                            selected={endTime}
                            onChange={this.handleEndTimeChange}
                            placeholderText="hh:mm a"
                            className="item-input"
                            minDate={moment()}
                            dropdownMode="select"
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={SERVICE_TIME_INTERVAL}
                            dateFormat="LT"
                            timeCaption="Time"
                        />
                    </Grid>
                    <Grid container direction="row" alignItems="center" className="item-wrapper">
                        <label className='caption'>Booking Number:</label>
                        <input
                            type="number"
                            className="item-input booking-number"
                            placeholder='Max booking number'
                            value={bookingNumber}
                            onChange={(e) => {this.handleChangeBookingNumber(e.target.value)}}/>
                    </Grid>

                    <Button className='doctor-btn' onClick={this.handleAdd}>Add</Button>

                    <div className="schedule-table-wrapper">
                        <table className="schedule-table">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {filteredScheduleData.map((slot, index) => {
                                    const { doctorServiceID, doctorStartTimeStamp, doctorEndTimeStamp } = slot
                                    const startTime = moment.unix(doctorStartTimeStamp)
                                    const endTime = moment.unix(doctorEndTimeStamp)
                                    return (
                                        <tr key={index}>
                                            <td>{startTime.format('DD/MM/YYYY')}</td>
                                            <td>{startTime.format('hh : mm a')}</td>
                                            <td>{endTime.format('hh : mm a')}</td>
                                            <td>
                                                <Button className="close"
                                                    onClick={(e) => this.handleDelete(doctorServiceID)}>
                                                    <i className="fas fa-trash ico-close"/>
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="repeat-button-wrapper">
                    <Button className='doctor-btn' onClick={this.handleRepeatWeekly}>Repeat weekly</Button>
                </div>

                <AlertDialog
                    title={this.state.alertTitle}
                    message={this.state.alertMessage}
                    ok="OK"
                    open={this.state.alertDlgOpen}
                    onClose={() => {this.setState({
                        alertDlgOpen: false
                    })}}
                />

                <Spinner show={this.state.showSpinner} />
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { auth, schedule } = state
    const { userProfile } = auth
    let userType = UserType.unknown
    let userId = 0

    if (auth && userProfile) {
        userType = parseInt(userProfile.userType, 10)
        userId = userType.toString() === UserType.patient.toString() ? userProfile.patientUserID : userProfile.doctorUserID
    }

    // Service SKU
    let sku
    if (ownProps.location.state) {
        sku = ownProps.location.state.sku
    }

    const { schedules, scheduleStatus } = schedule
    const scheduleData = schedules[sku]

    return {
        userType,
        userId,
        userProfile,
        scheduleData,
        scheduleStatus,
        sku
    }
}
  
export default connect(mapStateToProps)(ServiceSchedule)
