import React from 'react'
import { Button } from "@material-ui/core"
import { connect } from 'react-redux'
import { UserType } from 'helper/const'

function NavQuestions(props) {
    const { userType } = props

    if (userType === UserType.patient) {
        return (
            <Button
                className="nav-filter-button"
                href="/#/dashboard/post_question"
                style={{ marginRight: '10px' }}>New</Button>
        )
    } else {
        return null
    }
}

function mapStateToProps(state) {
    const { auth } = state
    const { userProfile } = auth

    let userType = UserType.unknown
    let userId = 0

    if (userProfile) {
        userType = userProfile.userType

        if (userType === UserType.patient) {
            userId = userProfile.patientUserID
        }

        if (userType === UserType.doctor) {
            userId = userProfile.doctorUserID
        }
    }

    return { userType, userId }
}

export default connect(mapStateToProps)(NavQuestions)