import {
    GET_NOTES_REQUEST,
    GET_NOTES_SUCCESS,
    GET_NOTES_FAILURE,

    SET_NOTE_REQUEST,
    SET_NOTE_SUCCESS,
    SET_NOTE_FAILURE,

    SET_CURRENT_PATIENT,
    SET_CURRENT_NOTE,
} from 'redux/actions/note'

import { NoteRowCount } from 'helper/const'

export function note (
    state = {
        currentPatientId: 0,
        currentNoteId: null,
        noteStatus: 0,
        data: {},
        message: null
    },
    action
  ) {
    switch (action.type) {
        case GET_NOTES_REQUEST:
        case SET_NOTE_REQUEST:
            return {
                ...state,
                noteStatus: 1,
                message: null
            }
        case GET_NOTES_FAILURE:
        case SET_NOTE_FAILURE:
            return {
                ...state,
                noteStatus: 2,
                message: action.message
            }
        case GET_NOTES_SUCCESS:
        case SET_NOTE_SUCCESS:
            var newData = []
            const patientNotes = state.data[action.patientId]
            if (!patientNotes || action.rowIndex === 0) { // Refresh
                newData = [...action.notes]
            } else {
                if (action.rowIndex > patientNotes.rowIndex) {
                    newData = [...patientNotes.data, ...action.notes]
                } else {
                    newData = [...patientNotes.data]
                    newData.splice(action.rowIndex * NoteRowCount, action.notes.length, ...action.notes)
                }
            }

            var newPatientNotes = {
                rowIndex: action.rowIndex,
                data: newData
            }

            var newNotesData = {
                ...state.data,
                [action.patientId]: newPatientNotes
            }
            
            return {
                ...state,
                noteStatus: 0,
                data: newNotesData,
                message: null
            }
        case SET_CURRENT_PATIENT:
            return {
                ...state,
                currentPatientId: action.patientId
            }
        case SET_CURRENT_NOTE:
            return {
                ...state,
                currentNote: {...action.patientNote}
            }
        default:
            return state
    }
}