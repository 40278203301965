import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from "@material-ui/core"
import { UserType } from 'helper/const'
import { Spinner } from 'components'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import * as authActions from 'redux/actions/auth'
import * as cardActions from 'redux/actions/card'
import * as cardApi from 'api/card'

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4"
            }
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
        }
    }
}

const PatientCardDetail = (props) => {
    const [ showSpinner, setShowSpinner ] = useState(false)

    const stripe = useStripe()
    const elements = useElements()

    const loadCards = () => {
        const { dispatch, userProfile } = props
        const { customerID = "" } = userProfile

        if (customerID && customerID.length > 0) {
            dispatch(cardActions.loadCards(customerID))
        }
    }

    const handleSaveCard = async () => {
        await setShowSpinner(true)

        const { userProfile, userId, userType } = props

        let { customerID } = userProfile
        if (!customerID || customerID.length === 0) {
            console.log("No customer object found. Create new")

            const createCustomerObjectResponse = await cardApi.createCustomerObject(userId, userType)
            if (createCustomerObjectResponse.status === 0 && !createCustomerObjectResponse.data.Error) {
                customerID = createCustomerObjectResponse.data.CustomerID

                userProfile.customerID = customerID

                props.dispatch(authActions.notifyUpdateProfile(userProfile))
            } else {
                console.error("Failed to create customer object", createCustomerObjectResponse.data)
            }
        } else {
            console.log("Customer object found")
        }

        let clientSecret

        if (customerID) {
            // Now setup intent
            console.log("Started to setup intent")
            const setupIntentResponse = await cardApi.createSetupIntent(customerID)
            if (setupIntentResponse.status === 0 && !setupIntentResponse.data.Error) {
                clientSecret = setupIntentResponse.data.ClientSecret
            } else {
                console.error("Failed to setup intent", setupIntentResponse.data)
            }
        }

        if (clientSecret) {
            let userName
            if (userType === UserType.patient) {
                userName = userProfile.username
            } else {
                userName = `${userProfile.preFix || ''} ${userProfile.firstName || ''} ${userProfile.lastName || ''}`
            }

            if (userName.trim().length === 0) {
                userName = "eDocine"
            }

            console.log("Payment username:", userName)

            // Confirm card setup
            stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: userName,
                    }
                }
            }).then(function(result) {
                setShowSpinner(false)
                if (result.error) {
                    console.error("Failed to confirm card setup.", result.error)
                } else {
                    // The setup has succeeded. Display a success message.
                    console.log("Succeeded to confirm card setup.")

                    loadCards()

                    window.history.back()
                }
            }).catch(err => {
                console.error("Failed to confirm card setup:", err)
                setShowSpinner(false)
            })
        } else {
            setShowSpinner(false)
        }
    }

    return (
        <div className="patient-card-detail-container">
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <Button className="btn" onClick={handleSaveCard}>Save</Button>
            <Spinner show={showSpinner} />
        </div>
    )
}

function mapStateToProps(state) {
    const { auth, card } = state
    const { userProfile } = auth

    let userType = UserType.unknown
    let userId = 0

    if (auth && userProfile) {
        userType = userProfile.userType
        userId = userType.toString() === UserType.patient.toString() ? userProfile.patientUserID : userProfile.doctorUserID
    }


    const { cardList } = card

    return {
        userProfile,
        userType,
        userId,
        cardList
    }
}

export default connect(mapStateToProps)(PatientCardDetail)
