import apiRequest, {apiRestRequest} from './apiRequest'


export function getClientStripeSecret() {
    return apiRequest('patientCreateClientStripeSecret', {})
}

export function getDoctorPaymentSetting(doctorId) {
    return apiRequest('doctorPaymentMethodGetByDoctorId', {doctor: doctorId})
}

export function createDoctorPaymentSetting(model) {
    return apiRestRequest('doctorPaymentMethods', 'post', model)
}

export function updateDoctorPaymentSetting(doctorPaymentMethodID, model) {
    return apiRestRequest('doctorPaymentMethods', 'put', model, doctorPaymentMethodID)
}

export function doctorTransactionAmountByDoctor(doctorId) {
    return apiRequest('doctorTransactionAmoutByDoctor', {doctor: doctorId})
}

export function doctorWithdrawalsByDoctor(doctorId) {
    return apiRequest('doctorWitharawalsByDoctor', {doctor: doctorId, status: -1})
}

export function doctorWithdrawCheckUnique(doctorId) {
    return apiRequest('doctorWitharawalsCheckUnique', {doctor: doctorId, status: 0})
}

export function createDoctorWithdrawals(model) {
    return apiRestRequest('doctorWithdrawals', 'post', model)
}

export function updateDoctorWithdrawals(doctorWithdrawalID, model) {
    return apiRestRequest('doctorWithdrawals', 'post', model, doctorWithdrawalID)
}

export function getAllServicePrices() {
    return apiRequest('serivcePricesGetAll')
}

export function getDoctorPaymentPercent(key) {
    return apiRequest('settingGetValueByKey', {key})
} 