import React from 'react'
import { Link } from 'react-router-dom'
import { Toggle } from 'components'
import { Images } from 'helper/const'
import { connect } from 'react-redux'

class Splash extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            curAnimation: -1
        }

        this.goDashboard(props)
    }

    componentWillReceiveProps(newProps) {
        this.goDashboard(newProps)
    }

    componentDidMount() {
        this.setState({curAnimation: 0})
    }

    goDashboard(auth) {
        if (auth.isAuthed) {
            const {userProfile} = auth
            const {username, firstName} = userProfile
            if (username || firstName) {
                this.props.history.replace('/dashboard')
            }
        }
    }

    nextAnimation() {
        this.setState(prevState => {
            return {
                curAnimation: prevState.curAnimation + 1
            }
        })
    }
  
    render() {
        let doctorUrl = "/auth/doctor"
        let patientUrl = "/auth/patient"

        return <div className="splash-container">
            <div className="logo-view">
                <Toggle show={this.state.curAnimation >= 0} onEntered={this.nextAnimation.bind(this)} className="logo"><img src={Images.logo} alt="Logo" /></Toggle>
                <Toggle show={this.state.curAnimation >= 1} onEntered={this.nextAnimation.bind(this)} className="logo-title">
                    <div>
                        <label className="e">e</label>
                        <label className="docine">Docine</label>
                    </div>
                </Toggle>
                <Toggle show={this.state.curAnimation >= 2} onEntered={this.nextAnimation.bind(this)} className="sub-title"><label>A Community that Connects Doctors and Patients</label></Toggle>
            </div>

            <div className="buttons-view">
                <Toggle show={this.state.curAnimation >= 3} onEntered={this.nextAnimation.bind(this)} className="button-item">
                    <Link to={doctorUrl}>
                        <img src={Images.doctorAvatar} alt="I am a doctor"/>
                        <div className="bottom">
                            <label className="iam">I am&nbsp;</label>
                            <label className="doctor-caption">a Doctor</label>
                        </div>
                    </Link>
                </Toggle>
                <Toggle show={this.state.curAnimation >= 4} onEntered={this.nextAnimation.bind(this)} className="button-item">
                    <Link to={patientUrl}>
                        <img src={Images.patientAvatar} alt="I am a doctor"/>
                        <div className="bottom">
                            <label className="iam">I am&nbsp;</label>
                            <label className="patient-caption">a Patient</label>
                        </div>
                    </Link>
                </Toggle>
            </div>
        </div>
    }
}

function mapStateToProps(state) {
    const { auth } = state
    return auth
}

export default connect(mapStateToProps)(Splash)
