import React from 'react'
import {Button} from "@material-ui/core"

export default class NavPatientCardList extends React.Component {
    render() {
        return (
            <div className="nav-container button-wrapper">
                <Button
                    className="nav-btn"
                    href="/#/dashboard/patient_card"><i className='fas fa-plus'/></Button>
            </div>
        )
    }
}