import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@material-ui/core'


export default class AnswerDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                value: nextProps.value
            })
        }
    }

    onPickFile = (imgFile) => {
        if (imgFile) {
            let reader = new FileReader()

            reader.onload = (e) => {
                const photo = e.target.result
                const photoSize = parseInt(photo.length * 3 / 4, 10)   // Bytes

                this.setState({
                    photo,
                    photoSize: this.getFileSizeDesc(photoSize)
                })
            }
            reader.readAsDataURL(imgFile)
        }
    }

    getFileSizeDesc = (size) => {
        let result = parseInt(size, 10)

        if (isNaN(result)) {
            return size
        }

        if (result < 1024) {
            result = result + " Bytes"
        } else if (result < 1024 * 1024) {
            result = result / 1024  // KB
            result = parseInt(result * 10, 10) / 10

            result = result + " KB"
        } else {
            result = result / 1024 / 1024  // KB
            result = parseInt(result * 10, 10) / 10

            result = result + " MB"
        }

        return result
    }


    handleCancel = () => {
        this.props.onClose(false)
    }

    handleOk = () => {
        this.props.onClose(this.state)
    }

    handleChange = (value) => {
        this.setState({
            value
        })
    }

    render() {
        const { title, options, ...other } = this.props
        const { photoSize } = this.state

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                onEntering={this.handleEntering}
                aria-labelledby="confirmation-dialog-title"
                className="alert-dialog-container"
                {...other} >

                <DialogTitle id="confirmation-dialog-title">Answer</DialogTitle>

                <DialogContent className="answer-question-content">
                    <textarea onChange={(e) => {this.handleChange(e.target.value)}} />

                    <div className="file-input-wrapper">
                        <Button className="picker-button">
                            <label className='item-input placeholder' style={{fontSize: '11pt'}}>{photoSize ? "Attach new photo" : "Attach a photo"}</label>
                            {photoSize && <label className="photo-size">{photoSize}</label>}
                        </Button>

                        <input
                            href="fileInput"
                            type="file"
                            onChange={(event) => {
                                const files = event.target.files
                                if (files.length > 0) {
                                    this.onPickFile(files[0])
                                }
                            }}/>
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button className="btn black-green-button" onClick={this.handleCancel}>Cancel</Button>
                    <Button className="btn black-green-button" onClick={this.handleOk}>OK</Button>
                </DialogActions>
            </Dialog>
        )
    }
}
