import * as eLaboratoryApi from 'api/elaboratory'

export const LOAD_ELABORATORIES_REQUEST =    'LOAD_ELABORATORIES_REQUEST'
export const LOAD_ELABORATORIES_SUCCESS =    'LOAD_ELABORATORIES_SUCCESS'
export const LOAD_ELABORATORIES_FAILURE =    'LOAD_ELABORATORIES_FAILURE'

export const UPDATE_LABORATORY_REQUEST  =    'UPDATE_LABORATORY_REQUEST'
export const UPDATE_LABORATORY_SUCCESS  =    'UPDATE_LABORATORY_SUCCESS'
export const UPDATE_LABORATORY_FAILURE  =    'UPDATE_LABORATORY_FAILURE'

export const LOAD_LABORATORY_TESTS_REQUEST = 'LOAD_LABORATORY_TESTS_REQUEST'
export const LOAD_LABORATORY_TESTS_SUCCESS = 'LOAD_LABORATORY_TESTS_SUCCESS'
export const LOAD_LABORATORY_TESTS_FAILURE = 'LOAD_LABORATORY_TESTS_FAILURE'

export const LOAD_LABORATORY_PROVIDERS_REQUEST =    'LOAD_LABORATORY_PROVIDERS_REQUEST'
export const LOAD_LABORATORY_PROVIDERS_SUCCESS =    'LOAD_LABORATORY_PROVIDERS_SUCCESS'
export const LOAD_LABORATORY_PROVIDERS_FAILURE =    'LOAD_LABORATORY_PROVIDERS_FAILURE'

function loadLaboratoriesRequest(email) {
    return {
        type: LOAD_ELABORATORIES_REQUEST,
        email,
    }
}

function loadLaboratoriesSuccess(data) {
    return {
        type: LOAD_ELABORATORIES_SUCCESS,
        data,
    }
}

function loadLaboratoriesFailure(message) {
    return {
        type: LOAD_ELABORATORIES_FAILURE,
        message,
    }
}


export function loadLaboratoriesAction(email) {
    return async dispatch => {
        dispatch(loadLaboratoriesRequest())

        const response = await eLaboratoryApi.getLaboratories(email)
        if (response.status === 0) {
            dispatch(loadLaboratoriesSuccess(response.data))
        } else {
            dispatch(loadLaboratoriesFailure(response.message))
        }
    }
}


function loadLaboratoriesProvidersRequest() {
    return {
        type: LOAD_LABORATORY_PROVIDERS_REQUEST
    }
}

function loadLaboratoriesProvidersSuccess(data) {
    return {
        type: LOAD_LABORATORY_PROVIDERS_SUCCESS,
        data,
    }
}

function loadLaboratoriesProvidersFailure(message) {
    return {
        type: LOAD_LABORATORY_PROVIDERS_FAILURE,
        message,
    }
}


export function loadLaboratoriesProvidersAction() {
    return async dispatch => {
        dispatch(loadLaboratoriesProvidersRequest())

        const response = await eLaboratoryApi.getServiceProviders()
        if (response.status === 0) {
            dispatch(loadLaboratoriesProvidersSuccess(response.data))
        } else {
            dispatch(loadLaboratoriesProvidersFailure(response.message))
        }
    }
}


function updateLaboratoryRequest() {
    return {
        type: UPDATE_LABORATORY_REQUEST,
        message: 'Adding card...'
    }
}

function updateLaboratorySuccess(laboratory) {
    return {
        type: UPDATE_LABORATORY_SUCCESS,
        laboratory
    }
}

function updateLaboratoryFailure(message) {
    return {
        type: UPDATE_LABORATORY_FAILURE,
        message
    }
}

export function updateLaboratoryAction(laboratory) {
    return async dispatch => {
        dispatch(updateLaboratoryRequest())

        const res = await eLaboratoryApi.saveLaboratory(laboratory)

        if (res.status === 0) {
            dispatch(updateLaboratorySuccess(res.data))
        } else {
            dispatch(updateLaboratoryFailure(res.message))
        }
    }
}


function loadLaboratoryTestsRequest() {
    return {
        type: LOAD_LABORATORY_TESTS_REQUEST,
    }
}

function loadLaboratoryTestsSuccess(data) {
    return {
        type: LOAD_LABORATORY_TESTS_SUCCESS,
        data,
    }
}

function loadLaboratoryTestsFailure(message) {
    return {
        type: LOAD_LABORATORY_TESTS_FAILURE,
        message,
    }
}


export function loadLaboratoryTestsAction() {
    return async dispatch => {
        dispatch(loadLaboratoryTestsRequest())

        const response = await eLaboratoryApi.getLaboratoryTests()
        if (response.status === 0) {
            dispatch(loadLaboratoryTestsSuccess(response.data))
        } else {
            dispatch(loadLaboratoryTestsFailure(response.message))
        }
    }
}
