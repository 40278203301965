import Feed from "views/Feed/Feed.jsx"
import CurrentPatientProfile from "views/UserProfile/CurrentPatientProfile.jsx"
import FavouritePatientProfile from "views/UserProfile/FavouritePatientProfile.jsx"
import CurrentDoctorProfile from "views/UserProfile/CurrentDoctorProfile.jsx"
import Favorites from "views/Favorites/Favorites.jsx"
import ServiceList from 'views/Service/ServiceList.jsx'
import FilteredServiceList from 'views/Service/FilteredServiceList.jsx'
import DoctorDetail from 'views/Service/DoctorDetail.jsx'
import Notifications from "views/Notifications/Notifications.jsx"
import History from "views/History/History.jsx"
import Settings from "views/Settings/Settings.jsx"
import Logout from "views/Logout/Logout.jsx"

import ServiceDoctorProfile from 'views/UserProfile/ServiceDoctorProfile.jsx'
import ServiceDetail from 'views/Service/ServiceDetail.jsx'
import FilterService from 'views/Service/FilterService.jsx'
import Share from "views/Settings/Share.jsx"
import Questions from 'views/Questions/Questions.jsx'
import PostQuestion from 'views/Questions/PostQuestion.jsx'
import PostArticle from 'views/Questions/PostArticle.jsx'
import QuestionDetail from 'views/Questions/QuestionDetail.jsx'

import FeedDetail from 'views/Feed/FeedDetail.jsx'
import FeedReplyEdit from 'views/Feed/FeedReplyEdit.jsx'

import ImageDetail from 'views/ImageDetail/ImageDetail.jsx'
import BookingCancelStart from 'views/History/BookingCancelStart.jsx'
import BookingConfirm from 'views/History/BookingConfirm.jsx'
import DoctorPaymentSetting from 'views/Settings/DoctorPaymentSetting.jsx'
import PatientCardList from 'views/Settings/PatientCardList.jsx'
import PatientCardDetail from 'views/Settings/PatientCardDetail.jsx'
import DefaultServicesBooking from 'views/Settings/DefaultServicesBooking.jsx'
import ServiceSchedule from 'views/Service/ServiceSchedule.jsx'
import BookInstantMessage from 'views/Service/BookInstantMessage.jsx'
import InstantMessage from 'views/ChatRoom/InstantMessage.jsx'
import AudioVideoChat from 'views/ChatRoom/AudioVideoChat.jsx'
import RatingPage from 'views/ChatRoom/RatingPage.jsx'
import NoteList from 'views/Note/NoteList.jsx'
import NoteDetail from 'views/Note/NoteDetail.jsx'
import Maps from 'views/Maps/Maps.jsx'

import ERadiology from "views/EMenu/ERadiology.jsx"
import EPrescription from "views/EMenu/EPrescription.jsx"
import ELaboratory from "views/EMenu/ELaboratory.jsx"

import React from 'react'
import { 
    NavFeed,
    NavFavourites,
    NavHistory
} from 'components'

import { UserType, SvgIconData } from 'helper/const'

const dashboardRoutes = [{
    path: "/user",
    sidebarName: "Profile",
    navbarName: "",
    component: CurrentPatientProfile,
    userType: UserType.patient,
    subRoute: true
}, {
    path: "/user",
    sidebarName: "Profile",
    navbarName: "",
    component: CurrentDoctorProfile,
    userType: UserType.doctor,
    subRoute: true
}, {
    path: "/feed",
    sidebarName: "Feed",
    navbarName: "",
    navComponent: <NavFeed/>,
    icon: SvgIconData.homeOutline,
    component: Feed
}, {
    path: "/favourites",
    sidebarName: "Favourites",
    navbarName: "",
    navComponent: <NavFavourites/>,
    icon: SvgIconData.heartOutline,
    component: Favorites
}, {
    path: "/services",
    sidebarName: "Services",
    navbarName: "",
    icon: SvgIconData.plusOutline,
    component: ServiceList,
    userType: UserType.patient
}, {
    path: "/filtered_services",
    sidebarName: "Services",
    navbarName: "",
    icon: SvgIconData.plusOutline,
    component: FilteredServiceList,
    userType: UserType.patient,
    subRoute: true
}, {
    path: "/services",
    sidebarName: "Services",
    navbarName: "",
    icon: SvgIconData.plusOutline,
    component: DoctorDetail,
    userType: UserType.doctor
}, {
    path: "/notifications",
    sidebarName: "Notifications",
    navbarName: "",
    icon: SvgIconData.bellOutline,
    component: Notifications
}, {
    path: "/history",
    sidebarName: "History",
    navbarName: "",
    navComponent: <NavHistory />,
    icon: SvgIconData.history,
    component: History
}, {
    path: "/settings",
    sidebarName: "Settings",
    navbarName: "",
    icon: SvgIconData.settingsOutline,
    component: Settings
}, {
    path: "/logout",
    sidebarName: "Logout",
    navbarName: "",
    icon: SvgIconData.powerStandBy,
    component: Logout
}, {
    path: "/services_search",
    sidebarName: "Services",
    navbarName: "",
    component: ServiceList,
    subRoute: true
}, {
    path: "/patient_detail/:patientId",
    sidebarName: "Patient",
    navbarName: "",
    component: FavouritePatientProfile,
    subRoute: true
}, {
    path: "/doctor_detail/:doctorId",
    sidebarName: "Doctor",
    navbarName: "",
    component: ServiceDoctorProfile,
    subRoute: true
}, {
    path: "/service_detail/:doctorId",
    sidebarName: "Services",
    navbarName: "",
    component: ServiceDetail,
    subRoute: true
}, {
    path: "/services_filter",
    sidebarName: "Filter",
    navbarName: "",
    component: FilterService,
    subRoute: true
}, {
    path: "/share",
    sidebarName: "Share",
    navbarName: "",
    component: Share,
    subRoute: true
}, {
    path: "/questions",
    sidebarName: "Questions",
    navbarName: "",
    component: Questions,
    subRoute: true
}, {
    path: "/post_question",
    sidebarName: "Post a question",
    navbarName: "",
    component: PostQuestion,
    subRoute: true
}, {
    path: "/question_detail",
    sidebarName: "Answer",
    navbarName: "",
    component: QuestionDetail,
    subRoute: true
}, {
    path: "/edit_feedreply/:feedReplyID",
    sidebarName: "FeedReply",
    navbarName: "",
    component: FeedReplyEdit,
    subRoute: true
}, {
    path: "/post_article",
    sidebarName: "Post an article",
    navbarName: "",
    component: PostArticle,
    subRoute: true
}, {
    path: "/edit_article/:feedID",
    sidebarName: "Edit an article",
    navbarName: "",
    component: PostArticle,
    subRoute: true
}, {
    path: "/feed_detail/:feedID",
    navbarName: "",
    component: FeedDetail,
    subRoute: true
}, {
    path: "/booking_cancel_start/:patientBookingID",
    navbarName: "",
    component: BookingCancelStart,
    subRoute: true
}, {
    path: "/booking_confirm/:patientBookingID",
    navbarName: "",
    component: BookingConfirm,
    subRoute: true
}, {
    path: "/image_detail",
    navbarName: "",
    component: ImageDetail,
    subRoute: true
}, {
    path: "/doctor_payment_setting",
    navbarName: "",
    component: DoctorPaymentSetting,
    subRoute: true
}, {
    path: "/patient_payment_setting",
    navbarName: "",
    component: PatientCardList,
    subRoute: true
}, {
    path: "/patient_card",
    navbarName: "",
    component: PatientCardDetail,
    subRoute: true
}, {
    path: "/default_services_booking",
    navbarName: "",
    component: DefaultServicesBooking,
    subRoute: true
}, {
    path: "/service_schedule",
    navbarName: "",
    component: ServiceSchedule,
    subRoute: true,
    userType: UserType.doctor
}, {
    path: "/booking",
    navbarName: "",
    component: BookInstantMessage,
    subRoute: true,
    userType: UserType.patient
}, {
    path: "/instant_message/:patientBookingID",
    navbarName: "",
    component: InstantMessage,
    subRoute: true
}, {
    path: "/audio_chat/:patientBookingID",
    navbarName: "",
    component: AudioVideoChat,
    subRoute: true
}, {
    path: "/video_chat/:patientBookingID",
    navbarName: "",
    component: AudioVideoChat,
    subRoute: true
}, {
    path: "/patient_rating/:patientBookingID",
    navbarName: "",
    component: RatingPage,
    subRoute: true
}, {
    path: "/patient_notes/:patientId",
    navbarName: "",
    component: NoteList,
    subRoute: true
}, {
    path: "/note_detail",
    navbarName: "",
    component: NoteDetail,
    subRoute: true
}, {
    path: "/map_view",
    navbarName: "",
    component: Maps,
    subRoute: true
}, {
    path: "/e-prescription",
    sidebarName: "e-Prescription",
    navbarName: "",
    component: EPrescription,
    userType: UserType.patient,
    icon: SvgIconData.eprescription,
    emenu: true,
}, {
    path: "/e-prescription",
    sidebarName: "e-Prescription",
    navbarName: "",
    component: EPrescription,
    userType: UserType.doctor,
    icon: SvgIconData.eprescription,
    emenu: true,
}, {
    path: "/e-radiology",
    sidebarName: "e-Radiology",
    navbarName: "",
    component: ERadiology,
    userType: UserType.doctor,
    icon: SvgIconData.eradiology,
    emenu: true,
}, {
    path: "/e-laboratory",
    sidebarName: "e-Laboratory",
    navbarName: "",
    component: ELaboratory,
    userType: UserType.doctor,
    icon: SvgIconData.elaboratory,
    emenu: true,
}, {
    redirect: true,
    path: "/dashboard",
    to: "/dashboard/feed",
    navbarName: ""
}]

export default dashboardRoutes
