import React from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import cx from "classnames"
import {
    Drawer,
    Hidden,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    withStyles
} from "@material-ui/core"

import { SvgIconData } from 'helper/const'

import { HeaderLinks, SvgIcon } from "components"
import sidebarStyle from "assets/jss/material-dashboard-react/sidebarStyle.jsx"

class Links extends React.Component {
    // verifies if routeName is the one active (in browser input)
    state = {
        hoverPath: null
    }

    activeRoute(routeName) {
        if (this.state.hoverPath === routeName) {
            return true
        }

        return this.props.pathname.indexOf(routeName) > -1 ? true : false
    }

    render() {
        const routes = this.props.data
        const color = this.props.color
        const classes = this.props.classes

        return (
            <div className="sidebar-wrapper">
                <List className={classes.list} style={{ flex: '1', overflow: 'auto' }}>
                    {routes.map((prop, key) => {
                        const {icon} = prop

                        if (prop.redirect || prop.subRoute || prop.emenu) {
                            return null
                        }

                        const isSel = this.activeRoute(prop.path)
                        const listItemClasses = " " + color + cx({
                            " sel": isSel
                        })

                        const icoClass = isSel ? classes.itemIconImgActive : classes.itemIconImg
                        const whiteFontClasses = cx({
                            [" " + classes.whiteFont]: this.activeRoute(prop.path)
                        })

                        return (
                            <NavLink
                                to={prop.path}
                                className={classes.item}
                                activeClassName="active"
                                key={key}>

                                <div onClick={this.props.handleDrawerToggle}>
                                    <ListItem
                                        button
                                        className={classes.itemLink + listItemClasses}
                                        onMouseOver={(e) => {
                                            this.setState({
                                                hoverPath: prop.path
                                            })
                                        }}
                                        onMouseOut={() => {
                                            this.setState({
                                                hoverPath: null
                                            })
                                        }}>
                                        <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                                            <SvgIcon
                                                data={icon}
                                                className={icoClass}/>
                                        </ListItemIcon>

                                        <ListItemText
                                            primary={prop.sidebarName}
                                            className={classes.itemText + whiteFontClasses}
                                            disableTypography={true} />
                                    </ListItem>
                                </div>
                            </NavLink>
                        )
                    })}
                </List>

                { this.renderEMenu() }
            </div>
        )
    }


    renderEMenu() {
        const routes = this.props.data
        const color = this.props.color
        const classes = this.props.classes

        return (
            <div className="e-menu-wrapper">
                <div className="e-menu-logo-wrapper">
                    <SvgIcon
                        data={SvgIconData.emenu}
                        className={classes.itemIconImg} /> - Menu
                </div>

                <List className={classes.list} style={{ marginBottom: '0px' }}>
                    {routes.map((prop, key) => {
                        if (prop.redirect || prop.subRoute || prop.emenu !== true) {
                            return null
                        }

                        const { icon } = prop
                        const isSel = this.activeRoute(prop.path)
                        const listItemClasses = " " + color + cx({
                            " sel": isSel
                        })

                        const icoClass = isSel ? classes.itemIconImgActive : classes.itemIconImg
                        const whiteFontClasses = cx({
                            [" " + classes.whiteFont]: this.activeRoute(prop.path)
                        })

                        return (
                            <NavLink
                                to={prop.path}
                                className={classes.item}
                                activeClassName="active"
                                key={key}>

                                <div onClick={this.props.handleDrawerToggle}>
                                    <ListItem
                                        button
                                        className={classes.itemLink + listItemClasses}
                                        onMouseOver={(e) => this.setState({ hoverPath: prop.path })}
                                        onMouseOut={() => this.setState({ hoverPath: null })}>

                                        <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                                            <SvgIcon
                                                data={icon}
                                                className={icoClass} />
                                        </ListItemIcon>

                                        <ListItemText
                                            primary={prop.sidebarName}
                                            className={classes.itemText + whiteFontClasses}
                                            disableTypography={true} />
                                    </ListItem>
                                </div>
                            </NavLink>
                        )
                    })}
                </List>
            </div>
        )
    }
}

class Sidebar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: props.open
        }
    }


    render() {
        const { classes, color, logo, image, logoText, routes } = this.props

        var brand = (
            <div className={classes.logo}>
                <img src={logo} alt="logo" className={classes.logoImage} />
                <label>{logoText}</label>
            </div>
        )

        return (
            <div>
                <Hidden mdUp>
                    <Drawer
                        variant="temporary"
                        anchor="left"
                        open={this.props.open}
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        style={{
                            position: 'static'
                        }}
                        onClose={this.props.handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}>

                        {brand}

                        <div className={classes.sidebarWrapper}>
                            <HeaderLinks onClose={this.props.handleDrawerToggle}/>

                            <Links
                                data={routes}
                                color={color}
                                classes={classes}
                                pathname={this.props.location.pathname}
                                handleDrawerToggle={this.props.handleDrawerToggle}/>
                        </div>

                        {image !== undefined ? (<div
                            className={classes.background}
                            style={{ backgroundImage: "url(" + image + ")" }} />
                        ) : null}
                    </Drawer>
                </Hidden>

                <Hidden smDown>
                    <Drawer
                        anchor="left"
                        variant="permanent"
                        open
                        classes={{
                        paper: classes.drawerPaper
                        }} >

                        {brand}

                        <div className={classes.sidebarWrapper}>
                            <Links
                                data={routes}
                                color={color}
                                classes={classes}
                                pathname={this.props.location.pathname}
                                handleDrawerToggle={this.props.handleDrawerToggle} />
                        </div>

                        {image !== undefined ? (
                            <div
                                className={classes.background}
                                style={{ backgroundImage: "url(" + image + ")" }} />
                        ) : null}
                    </Drawer>
                </Hidden>
            </div>
        )
    }
}

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired
}

export default withStyles(sidebarStyle)(Sidebar)

