import React from 'react'
import { connect } from 'react-redux'

import { ArrowBack } from "@material-ui/icons"
import { IconButton, Grid, Button } from "@material-ui/core"
import { AreaOfPractices } from 'helper/const'
import {
    FloatSelect,
    IOSSwitch,
    ItemGrid,
    SDatePicker,
    STextInput
} from "components"

import * as servicesAction from 'redux/actions/services'

class FilterService extends React.Component {
    years = ['0000']

    constructor(props) {
        super(props)
        
        // Calc years
        const today = new Date()
        const thisYear = today.getFullYear()
        const endYear = 1799

        for (let i = thisYear; i > endYear; i--) {
            this.years.push(i)
        }

        this.state = {
            userProfile: {
                availabilityDate: today,
                useDate: false
            }
        }  
    }

    handleChangeText = (key, value) => {
        this.setState(prevState => {
            const { userProfile } = prevState
            userProfile[key] = value

            if (this.props.onChange) {
                this.props.onChange(userProfile)
            }

            return { userProfile }
        })
    }

    handleAvailabilityDateChange = (date) => {
        this.setState(prevState => {
            const { userProfile } = prevState
            userProfile.availabilityDate = date

            if (this.props.onChange) {
                this.props.onChange(userProfile)
            }

            return { userProfile }
        })
    }

    handleOK = () => {
        const userProfile = {...this.state.userProfile}
        if (userProfile.useDate) {
            userProfile.startDateTime = userProfile.availabilityDate
            userProfile.endDateTime = userProfile.availabilityDate
        }

        delete userProfile.availabilityDate
        delete userProfile.useDate

        this.props.dispatch(servicesAction.filterServices(userProfile))
        this.props.history.replace("/dashboard/filtered_services")
    }
    
    render() {
        const { userProfile } = this.state
        const {
            firstName,
            middleName,
            lastName,
            availabilityDate,
            location,
            medicalSchool,
            yearOfGraduate,
            areaOfPractice,
            useDate
        } = userProfile

        console.log("Available:", availabilityDate)

        // let availDate = availabilityDate || moment().format()

        return (
            <div className="filter-main-cont">
                <div className="title-wrapper">
                    <IconButton
                        className="nav-btn"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => window.history.back()}>
                        <ArrowBack />
                    </IconButton>

                    <div className="title">Filter</div>
                </div>

                <div className="user-info">
                    <div className="sub-body">
                        <STextInput
                            caption="First Name"
                            value={firstName}
                            handleChange={(e) => this.handleChangeText('firstName', e.target.value)}
                        />
                        <STextInput
                            caption="Middle Name"
                            value={middleName}
                            handleChange={(e) => this.handleChangeText('middleName', e.target.value)}
                        />
                        <STextInput
                            caption="Last Name"
                            value={lastName}
                            handleChange={(e) => this.handleChangeText('lastName', e.target.value)}
                        />
                        <FloatSelect
                            caption="Area Of Practice"
                            value={areaOfPractice || ''}
                            options={AreaOfPractices}
                            handleChange={e => {this.handleChangeText('areaOfPractice', e.target.value)}}
                        />
                        <STextInput
                            caption="Medical School"
                            value={medicalSchool}
                            handleChange={(e) => this.handleChangeText('medicalSchool', e.target.value)}
                        />
                        <FloatSelect
                            caption="Year Of Graduation"
                            value={yearOfGraduate || ''}
                            options={ this.years }
                            handleChange={e => {this.handleChangeText('yearOfGraduate', e.target.value)}}
                        />
                        <STextInput
                            caption="Location"
                            value={location}
                            handleChange={(e) => this.handleChangeText('location', e.target.value)}
                        />
                        <SDatePicker
                            caption="Availability Date"
                            format="DD/MM/YYYY"
                            value={availabilityDate}
                            handleChange={this.handleAvailabilityDateChange}
                        />
                        <Grid container alignItems="center" className="use-date-container">
                            <ItemGrid xs={4} sm={4} md={8} lg={8}>
                                <label className="sex-label">Use Date</label>
                            </ItemGrid>

                            <ItemGrid xs={8} sm={8} md={4} lg={4}>
                                <IOSSwitch
                                    checked={useDate}
                                    onChange={(e) => {this.handleChangeText('useDate', e.target.checked)}}
                                    value="useDate"
                                    classes={{
                                        root: 'switchBase',
                                        checked: 'checked',
                                    }}
                                />
                            </ItemGrid>
                        </Grid>
                    </div>

                    <Button
                        className="update-button full-width doctor"
                        variant="text"
                        onClick={this.handleOK}>OK</Button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const services = state.services
    const { isGettingServices, message } = services

    return {
        isGettingServices,
        message
    }
}

export default connect(mapStateToProps)(FilterService)
