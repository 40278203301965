import * as notificationApi from 'api/notification'

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST'
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE'

function _getNotificationsRequest() {
    return {
        type: GET_NOTIFICATIONS_REQUEST,
        message: "Fetching notifications..."
    }
}

function _getNotificationsSuccess(notifications) {
    return {
        type: GET_NOTIFICATIONS_SUCCESS,
        notifications
    }
}

function _getNotificationsFailure(message) {
    return {
        type: GET_NOTIFICATIONS_FAILURE,
        message
    }
}

/**
 * Get user's notifications
 * 
 * @param {user id} userId 
 * @param {user type} userType 
 */
export function getNotificationsAction(userId, userType) {
    return async dispatch => {
        dispatch(_getNotificationsRequest())
        const response = await notificationApi.getNotifications(userId, userType)

        if (response.status === 0) {
            dispatch(_getNotificationsSuccess(response.data))
        } else {
            dispatch(_getNotificationsFailure(response.message))
        }
    }
}

export const DEL_NOTIFICATION_REQUEST = 'DEL_NOTIFICATION_REQUEST'
export const DEL_NOTIFICATION_SUCCESS = 'DEL_NOTIFICATION_SUCCESS'
export const DEL_NOTIFICATION_FAILURE = 'DEL_NOTIFICATION_FAILURE'

function _delNotificationRequest() {
    return {
        type: DEL_NOTIFICATION_REQUEST,
        message: "Delete notification..."
    }
}

function _delNotificationSuccess(notificationID) {
    return {
        type: DEL_NOTIFICATION_SUCCESS,
        notificationID
    }
}

function _delNotificationFailure(message) {
    return {
        type: DEL_NOTIFICATION_FAILURE,
        message
    }
}

/**
 * Delete user's notification
 * 
 * @param {notification id} notificationID 
 */
export function delNotificationAction(notificationID) {
    return async dispatch => {
        dispatch(_delNotificationRequest())
        const response = await notificationApi.delNotification(notificationID)

        if (response.status === 0) {
            dispatch(_delNotificationSuccess(notificationID))
        } else {
            dispatch(_delNotificationFailure(response.message))
        }
    }
}


export const DEL_ALL_NOTIFICATIONS_REQUEST = 'DEL_ALL_NOTIFICATIONS_REQUEST'
export const DEL_ALL_NOTIFICATIONS_SUCCESS = 'DEL_ALL_NOTIFICATIONS_SUCCESS'
export const DEL_ALL_NOTIFICATIONS_FAILURE = 'DEL_ALL_NOTIFICATIONS_FAILURE'
export const SEARCH_NOTIFICATIONS = 'SEARCH_NOTIFICATIONS'

function _delALLNotificationsRequest() {
    return {
        type: DEL_ALL_NOTIFICATIONS_REQUEST,
        message: "Delete all notifications..."
    }
}

function _delAllNotificationsSuccess() {
    return {
        type: DEL_ALL_NOTIFICATIONS_SUCCESS
    }
}

export function searchNotificationsAction(searchText) {
    return {
        type: SEARCH_NOTIFICATIONS,
        searchText
    }
}


function _delAllNotificationsFailure(message) {
    return {
        type: DEL_ALL_NOTIFICATIONS_FAILURE,
        message
    }
}

/**
 * Delete all notifications
 * 
 * @param {user id} userId 
 * @param {user type} userType 
 */
export function delAllNotificationsAction(userId, userType) {
    return async dispatch => {
        dispatch(_delALLNotificationsRequest())
        const response = await notificationApi.delAllNotifications(userId, userType)

        if (response.status === 0) {
            dispatch(_delAllNotificationsSuccess())
        } else {
            dispatch(_delAllNotificationsFailure(response.message))
        }
    }
}
