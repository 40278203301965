import * as authApi from 'api/auth'
import * as notificationApi from 'api/notification'

export const LOGIN_REQUEST  = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS  = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE  = 'LOGIN_FAILURE'
export const LOGOUT         = 'LOGOUT'

export const UPDATE_PROFILE_REQUEST  = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS  = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE  = 'UPDATE_PROFILE_FAILURE'

export const UPDATE_PUSH_ID = 'UPDATE_PUSH_ID'

function _loginRequest() {
    return {
        type: LOGIN_REQUEST,
        message: "Log in..."
    }
}

function _loginSuccess(userProfile) {
    return {
        type: LOGIN_SUCCESS,
        userProfile
    }
}

function _loginFailure(message) {
    return {
        type: LOGIN_FAILURE,
        message
    }
}

export function login(userType, email, password, facebookId = null, twitterId = null, googleId = null) {
    return dispatch => {
        dispatch(_loginRequest())

        authApi.login(userType, email, password, facebookId, twitterId, googleId)
            .then(response => {
                if (response.status === 0) {
                    if (response.data.length === 0) {
                        dispatch(_loginFailure("Invalid user."))
                    } else {
                        const userProfile = response.data[0]

                        userProfile.userType = userType
                        dispatch(_loginSuccess(response.data[0]))
                    }
                } else {
                    dispatch(_loginFailure(response.message))
                }
            })
    }
}

export function signup(userType, email, password, facebookId = null, twitterId = null, googleId = null) {
    return async dispatch => {
        dispatch(_loginRequest())

        // Check if user already exist
        const rstExist = await authApi.isUserExist(userType, email, facebookId, twitterId, googleId)
        if (rstExist.status === 0) {
            if (rstExist.data.length > 0) {
                dispatch(_loginFailure("User already exists."))
            } else {
                // Sign up
                const rstSignup = await authApi.signup(userType, email, password, facebookId, twitterId, googleId)
                if (rstSignup.status === 0) {
                    var rstUser = null
                    if (email) {
                        rstUser = await authApi.getUserByEmail(userType, email)
                    } else {
                        rstUser = await authApi.login(userType, email, password, facebookId, twitterId, googleId)
                    }
                    if (rstUser && rstUser.status === 0 && rstUser.data.length === 1) {
                        var userProfile = rstUser.data[0]
                        userProfile.userType = userType
                        dispatch(_loginSuccess(userProfile))
                    } else {
                        dispatch(_loginFailure("Failed to get user profile."))
                    }
                } else {
                    dispatch(_loginFailure(rstSignup.message))
                }
            }
        } else {
            dispatch(_loginFailure(rstExist.message))
        }
    }
}

export function logout() {
    return {
        type: LOGOUT
    }
}

function _updateProfileRequest() {
    return {
        type: UPDATE_PROFILE_REQUEST,
        message: "Updating..."
    }
}

function _updateProfileSuccess(userProfile) {
    return {
        type: UPDATE_PROFILE_SUCCESS,
        userProfile
    }
}

function _updateProfileFailure(message) {
    return {
        type: UPDATE_PROFILE_FAILURE,
        message
    }
}

export function notifyUpdateProfile(userProfile) {
    return dispatch => {
        dispatch(_updateProfileSuccess(userProfile))
    }
}

export function updateProfile(userType, userId, data) {
    return dispatch => {
        dispatch(_updateProfileRequest())

        authApi.updateUserProfile(userType, userId, data).then(response => {
            if (response.status === 0) {
                const userProfile = response.data
                userProfile.userType = userType
                dispatch(_updateProfileSuccess(userProfile))
            } else {
                console.error("Failed to update user profile:", response)
                dispatch(_updateProfileFailure("Failed to update user profile"))
            }
        }).catch(err => console.error(err))
    }
}

function _updatePushId(pushId) {
    return {
        type: UPDATE_PUSH_ID,
        pushId
    }
}

async function updatePushInfo(pushId, userId, userType) {
    // Delete previous push users
    await notificationApi.deletePushUsersByPushId(pushId)
    await notificationApi.createPushUser(pushId, userId, userType, true)
}

export function updatePushId(pushId, userId, userType) {
    return async dispatch => {
        dispatch(_updatePushId(pushId))

        if (pushId && userId && userType) {
            await updatePushInfo(pushId, userId, userType)
        }
    }
}
