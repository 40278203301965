import {
    GET_NOTIFICATIONS_REQUEST,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_FAILURE,

    DEL_NOTIFICATION_REQUEST,
    DEL_NOTIFICATION_SUCCESS,
    DEL_NOTIFICATION_FAILURE,

    DEL_ALL_NOTIFICATIONS_REQUEST,
    DEL_ALL_NOTIFICATIONS_SUCCESS,
    DEL_ALL_NOTIFICATIONS_FAILURE,

    SEARCH_NOTIFICATIONS
} from 'redux/actions/notifications'

export function notifications (
    state = {
        notificationsStatus: 0,
        message: null,
        data: [],
        searchText: ""
    },
    action
) {
    switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
    case DEL_NOTIFICATION_REQUEST:
    case DEL_ALL_NOTIFICATIONS_REQUEST:
        return {
            ...state,
            notificationsStatus: 1,
            message: null
        }
    case GET_NOTIFICATIONS_FAILURE:
    case DEL_NOTIFICATION_FAILURE:
    case DEL_ALL_NOTIFICATIONS_FAILURE:
        return {
            ...state,
            notificationsStatus: 2,
            message: action.error
        }
    case GET_NOTIFICATIONS_SUCCESS:
        return {
            ...state,
            notificationsStatus: 0,
            data: action.notifications
        }
    case DEL_NOTIFICATION_SUCCESS:
        let notifications = [...state.data]
        const removeIndex = notifications.map(function(item) { return item.notificationID}).indexOf(action.notificationID)

        if (removeIndex >= 0) {
            notifications.splice(removeIndex, 1)
        }

        return {
            ...state,
            notificationsStatus: 0,
            data: notifications
        }

    case DEL_ALL_NOTIFICATIONS_SUCCESS:
        return {
            ...state,
            notificationsStatus: 0,
            data: []
        }

    case SEARCH_NOTIFICATIONS:
        return {
            ...state,
            searchText: action.searchText
        }
    default:
        return state
    }
}