import React, { useState, useEffect } from "react"
import { connect } from 'react-redux'
import { UserType } from 'helper/const'
import { IconButton, Button, List, ListItem } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"

import { Spinner } from 'components'
import { useStripe } from '@stripe/react-stripe-js'

import * as cardActions from 'redux/actions/card'
import * as bookingAction from 'redux/actions/bookings'

import * as cardApi from 'api/card'
import * as notificationApi from 'api/notification'
import * as bookingApi from 'api/booking'


const PatientCardList = (props) => {
    const [ showSpinner, setShowSpinner ] = useState(false)
    const [ startedBooking, setStartedBooking ] = useState(false)

    const stripe = useStripe()

    useEffect(() => {
        const sendNotificationToUser = async (message, pushGuid) => {
            console.log("Send Notification To User:", message, pushGuid)
    
            const { bookingData } = props
            const rstPushUsers = await notificationApi.getPushUsers(bookingData.doctorId, UserType.doctor)
    
            if (rstPushUsers.status === 0) {
                let targetUsers = []
                for (let index = 0; index < rstPushUsers.data.length; index++) {
                    const element = rstPushUsers.data[index];
                    if (element.pushId && element.isLogged) {
                        targetUsers.push(element.pushId)
                    }
                }
    
                if (targetUsers.length > 0) {
                    console.log("Target users:", targetUsers)
                    notificationApi.sendPush(targetUsers, message)
                }
            }
        }

        const { bookingsStatus } = props

        if (!startedBooking && bookingsStatus === 5) {
            // Started Booking
            setShowSpinner(true)
            setStartedBooking(true)
        } else if (startedBooking) {
            if (bookingsStatus === 6) {
                // Booking succeeded
                setShowSpinner(false)
                setStartedBooking(false)

                const { bookingData, userProfile } = props
                const { priceData } = bookingData

                const bookingStartDate = bookingData.startTime.format('DD/MM/YYYY')
                const bookingStartTime = bookingData.startTime.format('hh:mm a')
                const timezone = `UTC ${parseInt(new Date().getTimezoneOffset() / -60, 10)}`

                let msg = `${priceData.name} with ${userProfile.username} on ${bookingStartDate} at ${bookingStartTime} ${timezone}`
                sendNotificationToUser(msg)

                alert("Your appointment booking was successful")

                props.history.push("/dashboard/history")
            } else if (bookingsStatus === 7) {
                // Booking failed
                setShowSpinner(false)
                setStartedBooking(false)

                alert("Your appointment booking has failed")
            }
        }
    }, [props, startedBooking]);

    const loadCards = () => {
        const { dispatch, userProfile } = props
        const { customerID = "" } = userProfile

        if (customerID && customerID.length > 0) {
            dispatch(cardActions.loadCards(customerID))
        }
    }

    const deleteCard = async (paymentMethodId) => {
        await setShowSpinner(true)
        await cardApi.deleteCard(paymentMethodId)
        await setShowSpinner(false)

        loadCards()
    }

    const continueBooking = (bookingData, paymentIntentId) => {
        const { dispatch, userProfile, userType } = props

        let username
        if (userType === UserType.patient) {
            username = userProfile.username
        } else {
            username = `${userProfile.preFix} ${userProfile.firstName} ${userProfile.lastName}`
        }

        dispatch(bookingAction.bookAction(bookingData, paymentIntentId, username))
    }

    const onSelectedPaymentMethod = async (paymentMethodId) => {
        const { bookingData, userProfile } = props
        if (bookingData) {
            await setShowSpinner(true)

            const { customerID = "" } = userProfile
            const { priceData } = bookingData
            const { price, currency } = priceData
            const amount = parseInt(price * 100, 10)

            const paymentIntentResponse = await bookingApi.payService(
                amount,
                currency,
                customerID,
                paymentMethodId
            )

            if (!paymentIntentResponse.data.Error) {
                const paymentResponse = paymentIntentResponse.data
                const PaymentIntentId = paymentResponse.PaymentIntentId

                continueBooking(bookingData, PaymentIntentId)
            } else {
                console.log("Failed to create payment intent:", paymentIntentResponse.data)

                if (paymentIntentResponse.data.PaymentMethodId) {
                    const { ClientSecret, PaymentMethodId } = paymentIntentResponse.data
                    stripe.confirmCardPayment(ClientSecret, {
                        payment_method: PaymentMethodId
                    }).then(function(result) {
                        if (result.error) {
                            // Show error to your customer
                            console.error("Failed to confirmCardPayment:", result.error)
                            alert("Failed to book service. Please try again later.")
                        } else {
                            console.log("Payment intent created:", result.paymentIntent)
                            if (result.paymentIntent.status === 'succeeded') {
                                // The payment is complete!
                                console.log("Succeeded finally")
                                continueBooking(bookingData, result.paymentIntent.id)
                            } else {
                                console.error("confirm card payment status not succeeded!")
                                alert("Failed to book service. Please try again later.")
                            }
                        }

                        setShowSpinner(false)
                    }).catch(err => {
                        console.error("payment failed")
                        console.error(err)
                        setShowSpinner(false)
                    })
                } else {
                    alert("Failed to book service. Please try again later.")
                }
            }
        } else {
            // Do nothing
            console.log("No booking data found")
        }
    }

    if (props.cardLoadingStatus === 1) {
        return (
            <div className="patient-card-list-main-cont">
                <div className="patient-card-list-container">
                    Loading... Please wait
                </div>
            </div>
        )
    }

    return (
        <div className="patient-card-list-main-cont">
            <div className="patient-card-list-container">
                <div className="title-wrapper">
                    <IconButton
                        className="nav-btn"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => window.history.back()}>
                        <ArrowBack />
                    </IconButton>
                    <div className="title">Cards</div>
                    <Button
                        className="nav-btn"
                        href="/#/dashboard/patient_card"><i className='fas fa-plus'/></Button>
                </div>

                <List component="nav" className="item-container">
                    {(props.cardList || []).map(item => {
                        const { Id, Brand, Last4, ExpMonth, ExpYear } = item
                        const expireDate = `Expires in ${('0' + ExpMonth).slice(-2)}/${ExpYear}`

                        return (
                            <ListItem button className="item" key={Id} onClick={() => onSelectedPaymentMethod(Id)}>
                                <div className="left card-info">
                                    <label className="number">{`${Last4} (${Brand})`}</label>
                                    <label className="expire-date">{expireDate}</label>
                                </div>

                                <Button className="close"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        deleteCard(Id)
                                    }} >
                                    <i className="fas fa-trash ico-close"/>
                                </Button>
                            </ListItem>
                        )
                    })}
                </List>
            </div>

            <Spinner show={showSpinner} />
        </div>
    )
}

function mapStateToProps(state) {
    const { auth, card, bookings } = state
    const { userProfile } = auth
    const { cardList, status } = card

    let userType = UserType.unknown
    let userId = 0

    if (userProfile) {
        userType = userProfile.userType
        userId = userType.toString() === UserType.patient.toString() ? userProfile.patientUserID : userProfile.doctorUserID
    }

    const { bookingData, bookingsStatus, pushExtra } = bookings

    return {
        cardLoadingStatus: status,
        cardList,
        userProfile,
        userType,
        userId,
        bookingData,
        bookingsStatus,
        pushExtra
    }
}

export default connect(mapStateToProps)(PatientCardList)
