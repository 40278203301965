import React from 'react'
import { Images } from 'helper/const'
import { Button } from "@material-ui/core"

class AuthSelect extends React.Component {
    render() {
        const userType = this.props.match.params.userType
        return (<div className="auth-select-container">
            <a
                className='back-btn'
                href='/#/splash'
            >
                <i className='fa fa-arrow-left'/>
            </a>
            <div className='content'>
                <div className="top-space" />

                <div className="logo-space">
                    <img className='logo' src={Images.logo} alt="Logo" />
                    <label className='app-name'>eDocine</label>
                    <label className='desp'>A Community that Connects Doctors and Patients</label>
                </div>

                <div className="buttons-space">
                    <Button className='sign-in btn' href={`/#/authentication/${userType}/login`}>Sign in</Button>
                    <Button className='sign-up btn' href={`/#/authentication/${userType}/signup`}>Sign up</Button>
                </div>
            </div>
        </div>)
    }
}

export default AuthSelect