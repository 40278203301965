import { connect } from 'react-redux'
import PatientProfile from './PatientProfile'

function mapStateToProps(state, ownProps) {
    let doctorId, patient

    const { auth, favorites } = state
    const { userProfile } = auth
    const patientId = parseInt(ownProps.match.params.patientId, 10)
    const { data } = favorites
    const item = data.find(x => x.patient.patientUserID === patientId)

    if (userProfile) {
        doctorId = userProfile.doctorUserID
    }

    if (item) {
        patient = item.patient
    }

    return {
        userProfile: patient,
        patientId,
        doctorId,
        readOnly: true,
        showFavorite: true,
    }
}

export default connect(mapStateToProps)(PatientProfile)
