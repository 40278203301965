import React from 'react'
import { Button, ListItem } from "@material-ui/core"
import { SvgIconData, UserType } from 'helper/const'
import { SvgIcon } from "components"

class FeedFloat extends React.Component {
    state = {
        toggle: false
    }
    toggleFloat = () => {
        this.setState((prevState) => {
            return {
                toggle: !prevState.toggle
            }
        })
    }

    handleTalk = () => {
        this.props.history.push('/dashboard/services_search')
    }

    handlePost = () => {
        this.props.history.push('/dashboard/post_article')
    }

    handleQuestion = () => {
        this.props.history.push('/dashboard/questions')
    }

    render() {
        const {userType} = this.props
        const {toggle} = this.state
        const questionTitle = userType === UserType.doctor ? 'Answer a question' : 'Post a question'

        return <div className="feed-float-container">
            {toggle && <div className="items-view">
                <ListItem button className="item talk" onClick={this.handleTalk}>
                    <SvgIcon data={SvgIconData.message} className="icon-talk"/>
                    <label>Talk to doctor</label>
                </ListItem>

                {userType === UserType.doctor && <ListItem button className="item post" onClick={this.handlePost}>
                    <SvgIcon data={SvgIconData.fileDocument} className="icon-post"/>
                    <label>Post an article</label>
                </ListItem>}

                <ListItem button className="item question" onClick={this.handleQuestion}>
                    <SvgIcon data={SvgIconData.commentQuestion} className="icon-question"/>
                    <label>{questionTitle}</label>
                </ListItem>
            </div>}
            <Button
                variant='text'
                className='more'
                onClick={this.toggleFloat}
            >
                <i className="fa fa-ellipsis-v"></i>
            </Button>
        </div>
    }
}

export default FeedFloat