import * as scheduleApi from 'api/schedule'
import moment from 'moment'

export const GET_SCHEDULE_REQUEST = 'GET_SCHEDULE_REQUEST'
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS'
export const GET_SCHEDULE_FAILURE = 'GET_SCHEDULE_FAILURE'

export const ADD_SCHEDULE_REQUEST = 'ADD_SCHEDULE_REQUEST'
export const ADD_SCHEDULE_SUCCESS = 'ADD_SCHEDULE_SUCCESS'
export const ADD_SCHEDULE_FAILURE = 'ADD_SCHEDULE_FAILURE'

export const DELETE_SCHEDULE_REQUEST = 'DELETE_SCHEDULE_REQUEST'
export const DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS'
export const DELETE_SCHEDULE_FAILURE = 'DELETE_SCHEDULE_FAILURE'

export const CLEAR_SCHEDULE_REQUEST = 'CLEAR_SCHEDULE_REQUEST'
export const CLEAR_SCHEDULE_SUCCESS = 'CLEAR_SCHEDULE_SUCCESS'
export const CLEAR_SCHEDULE_FAILURE = 'CLEAR_SCHEDULE_FAILURE'

function _getScheduleRequest() {
    return {
        type: GET_SCHEDULE_REQUEST,
        message: "Fetching schedule..."
    }
}

function _getScheduleSuccess(sku, schedule) {
    return {
        type: GET_SCHEDULE_SUCCESS,
        sku,
        schedule
    }
}

function _getScheduleFailure(message) {
    return {
        type: GET_SCHEDULE_FAILURE,
        message
    }
}

export function getScheduleAction(doctorUserId, doctorServiceSKU) {
    return async dispatch => {
        dispatch(_getScheduleRequest())

        const doctorStartTimeStamp = moment().unix()

        const response = await scheduleApi.getSchedule(doctorUserId, doctorServiceSKU, doctorStartTimeStamp)
        if (response.status === 0) {
            dispatch(_getScheduleSuccess(doctorServiceSKU, response.data))
        } else {
            dispatch(_getScheduleFailure(response.message))
        }
    }
}

function _addScheduleRequest() {
    return {
        type: ADD_SCHEDULE_REQUEST,
        message: "Fetching schedule..."
    }
}

function _addScheduleSuccess(sku, schedule) {
    return {
        type: ADD_SCHEDULE_SUCCESS,
        sku,
        schedule
    }
}

function _addScheduleFailure(message) {
    return {
        type: ADD_SCHEDULE_FAILURE,
        message
    }
}

export function addSchedulesAction(doctorId, sku, scheduleList) {
    return async dispatch => {
        dispatch(_addScheduleRequest())
        
        // Add schedule
        for (let index = 0; index < scheduleList.length; index++) {
            const element = scheduleList[index];
            const {doctorUserId, doctorServiceSKU, doctorStartTimeStamp, doctorEndTimeStamp} = element
            const response = await scheduleApi.createSchedule(doctorUserId, doctorServiceSKU, doctorStartTimeStamp, doctorEndTimeStamp)
            if (response.status !== 0) {
                dispatch(_addScheduleFailure(response.message))
                return
            }
        }

        const curTimeStamp = moment().unix()
        const resGet = await scheduleApi.getSchedule(doctorId, sku, curTimeStamp)

        if (resGet.status === 0) {
            dispatch(_addScheduleSuccess(sku, resGet.data))
        } else {
            dispatch(_addScheduleFailure(resGet.message))
        }
    }
}  

function _deleteScheduleRequest() {
    return {
        type: DELETE_SCHEDULE_REQUEST,
        message: "Fetching schedule..."
    }
}

function _deleteScheduleSuccess(doctorServiceID, sku) {
    return {
        type: DELETE_SCHEDULE_SUCCESS,
        sku,
        doctorServiceID
    }
}

function _deleteScheduleFailure(message) {
    return {
        type: DELETE_SCHEDULE_FAILURE,
        message
    }
}

export function deleteScheduleAction(doctorServiceID, doctorServiceSKU) {
    return async dispatch => {
        dispatch(_deleteScheduleRequest())
        
        const response = await scheduleApi.deleteSchedule(doctorServiceID)
        if (response.status === 0) {
            dispatch(_deleteScheduleSuccess(doctorServiceID, doctorServiceSKU))
        } else {
            dispatch(_deleteScheduleFailure(response.message))
        }
    }
}

function _clearScheduleRequest() {
    return {
        type: CLEAR_SCHEDULE_REQUEST,
        message: "Clearing schedule time slots..."
    }
}

function _clearScheduleSuccess(doctorId, sku) {
    return {
        type: CLEAR_SCHEDULE_SUCCESS,
        sku,
        doctorId
    }
}

function _clearScheduleFailure(message) {
    return {
        type: CLEAR_SCHEDULE_FAILURE,
        message
    }
}

export function clearScheduleAction(doctorId, doctorServiceSKU, startTimestamp) {
    return async dispatch => {
        dispatch(_clearScheduleRequest())

        const response = await scheduleApi.clearSchedule(doctorId, doctorServiceSKU, startTimestamp)
        if (response.status === 0) {
            dispatch(_clearScheduleSuccess(doctorId, doctorServiceSKU))
        } else {
            dispatch(_clearScheduleFailure(response.message))
        }
    }
}