import React from 'react'
import { connect } from 'react-redux'

import { UserType } from 'helper/const'
import { IconButton, ListItem, Button } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"

import IOSSwitch from 'components/CustomInput/IOSSwitch'
import Storage from 'Storage/storage.js'

class DefaultServicesBooking extends React.Component {
    state = {
        instantMessageSetting: false,
        audioCallSetting: false,
        videoCallSetting: false
    }

    componentDidMount() {
        this._initSetting(this.props)
    }


    _initSetting(props) {
        const { userId, userType } = props
        if (!userId || !userType) {
            return
        }

        const instantMessageSetting = Storage.getInstantMessageSetting(userId, userType)
        const audioCallSetting = Storage.getAudioCallSetting(userId, userType)
        const videoCallSetting = Storage.getVideoCallSetting(userId, userType)

        this.setState({
            instantMessageSetting,
            audioCallSetting,
            videoCallSetting
        })
    }

    _handleChange = name => event => {
        this.setState({[name]: event.target.checked})
    }

    _handleSave = () => {
        const { userId, userType } = this.props
        if (!userId || !userType) {
            return
        }

        const { instantMessageSetting, audioCallSetting, videoCallSetting } = this.state

        Storage.setInstantMessageSetting(userId, userType, instantMessageSetting)
        Storage.setAudioCallSetting(userId, userType, audioCallSetting)
        Storage.setVideoCallSetting(userId, userType, videoCallSetting)
    }


    render() {
        const { instantMessageSetting, audioCallSetting, videoCallSetting } = this.state

        return (
            <div className="settings-container">
                <div className="title-wrapper">
                    <IconButton
                        className="nav-btn"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => window.history.back()}>
                        <ArrowBack />
                    </IconButton>
                    <div className="title">Default Services Booking</div>
                </div>

                <ListItem className="item top bottom">
                    <label>Instant Message A Doctor</label>
                    <IOSSwitch
                        checked={instantMessageSetting}
                        onChange={this._handleChange('instantMessageSetting')}
                        value='instantMessageSetting'
                    />
                </ListItem>
                <ListItem className="item top bottom">
                    <label>Audio Call A Doctor</label>
                    <IOSSwitch
                        checked={audioCallSetting}
                        onChange={this._handleChange('audioCallSetting')}
                        value='audioCallSetting'
                    />
                </ListItem>
                <ListItem className="item top bottom">
                    <label>Video Call A Doctor</label>
                    <IOSSwitch
                        checked={videoCallSetting}
                        onChange={this._handleChange('videoCallSetting')}
                        value='videoCallSetting'
                    />
                </ListItem>
                <Button className="save" onClick={this._handleSave}>
                    Save Changes
                </Button>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { auth } = state
    const { userProfile } = auth
    if (!userProfile) {
        return null
    }

    const { userType, patientUserID, doctorUserID } = userProfile
    const userId = (userType === UserType.patient) ? patientUserID : doctorUserID

    return {
        userId,
        userType
    }
}

export default connect(mapStateToProps)(DefaultServicesBooking)
