import {
    GET_BOOKINGS_REQUEST,
    GET_BOOKINGS_SUCCESS,
    GET_BOOKINGS_FAILURE,

    CANCEL_BOOKING_REQUEST,
    CANCEL_BOOKING_SUCCESS,
    CANCEL_BOOKING_FAILURE,

    START_SESSION_REQUEST,
    START_SESSION_SUCCESS,
    START_SESSION_FAILURE,

    PREPARE_BOOKING,
    BOOK_SEARCH,
    BOOK_REQUEST,
    BOOK_SUCCESS,
    BOOK_FAILURE
} from 'redux/actions/bookings'

import {
    UserType
} from 'helper/const'

export function bookings (
    state = {
        bookingsStatus: 0,
        message: null,
        data: [],
        bookingData: null,
        searchText: ''
    },
    action
) {
    switch (action.type) {
        case GET_BOOKINGS_REQUEST:
        case CANCEL_BOOKING_REQUEST:
        case START_SESSION_REQUEST:
            return {
                ...state,
                bookingsStatus: 1,
                message: null
            }
        case GET_BOOKINGS_FAILURE:
        case CANCEL_BOOKING_FAILURE:
        case START_SESSION_FAILURE:
            return {
                ...state,
                bookingsStatus: 2,
                message: action.message
            }
        case GET_BOOKINGS_SUCCESS:
            return {
                ...state,
                bookingsStatus: 0,
                data: action.bookings
            }
        case CANCEL_BOOKING_SUCCESS:
            let data = [...state.data]
            let history = data.find(x => x.patientBookingID === action.patientBookingID)

            if (history) {
                if (action.userType === UserType.patient) {
                    history.isPatientCanceled = 1
                } else {
                    history.isDoctorCanceled = 1
                }
                history.cancelNote = action.cancelNote
            }

            return {
                ...state,
                bookingsStatus: 0,
                data
            }
        case START_SESSION_SUCCESS:
            data = [...state.data]
            history = data.find(x => x.patientBookingID === action.patientBookingID)

            if (history) {
                history.status = 0
            }

            return {
                ...state,
                bookingsStatus: 0,
                data
            }
        case PREPARE_BOOKING:
            return {
                ...state,
                bookingData: action.bookingData
            }
        case BOOK_REQUEST:
            return {
                ...state,
                bookingsStatus: 5
            }
        case BOOK_SUCCESS:
            return {
                ...state,
                bookingsStatus: 6,
                pushExtra: action.pushExtra
            }
        case BOOK_FAILURE:
            return {
                ...state,
                bookingsStatus: 7
            }
        case BOOK_SEARCH:
            return {
                ...state,
                searchText: action.searchText,
                illness: action.illness || ''
            }
        default:
            return state
    }
}