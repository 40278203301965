import React from 'react'
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

export default function FloatSelect(props) {
    const {id, caption, value, options, handleChange, multiple, readOnly} = props
    const inputClass = "value-input" + (readOnly ? " read-only": "")
    return (
        <FormControl className={"control-container"}>
            <InputLabel
                classes={{
                    root: "caption",
                    focused: "focused",
                }}
                htmlFor={id}
            >
                {caption}
            </InputLabel>
            <Select
                multiple={multiple}
                value={value}
                onChange={handleChange}
                inputProps={{
                    name: id,
                    id: id,
                }}
                disableUnderline={true}
                classes={{
                    root: inputClass
                }}
                readOnly={readOnly}
            >
                {options.map(item => {
                    return <MenuItem classes={{root:'menu-item'}} key={item} value={item}>{item}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}