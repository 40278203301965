import * as paymentApi from 'api/payment'

export const DOCTOR_PAYMENT_SETTING_REQUEST = 'DOCTOR_PAYMENT_SETTING_REQUEST'
export const DOCTOR_PAYMENT_SETTING_SUCCESS = 'DOCTOR_PAYMENT_SETTING_SUCCESS'
export const DOCTOR_PAYMENT_SETTING_FAILURE = 'DOCTOR_PAYMENT_SETTING_FAILURE'

function _doctorPaymentSettingRequest() {
    return {
        type: DOCTOR_PAYMENT_SETTING_REQUEST,
        message: "Fetching doctor payment setting..."
    }
}

function _doctorPaymentSettingSuccess(paymentSetting, balance) {
    return {
        type: DOCTOR_PAYMENT_SETTING_SUCCESS,
        paymentSetting,
        balance
    }
}

function _doctorPaymentSettingFailure(message) {
    return {
        type: DOCTOR_PAYMENT_SETTING_FAILURE,
        message
    }
}

export function getDoctorPaymentSettingAction(doctorId) {
    return async dispatch => {
        dispatch(_doctorPaymentSettingRequest())

        // Get payment settings
        const response = await paymentApi.getDoctorPaymentSetting(doctorId)

        if (response.status === 0) {
            var paymentSetting
            if (response.data && response.data.length > 0) {
                paymentSetting = response.data[0]
            }

            // Get current doctor balance
            const resTransaction = await paymentApi.doctorTransactionAmountByDoctor(doctorId)
            if (resTransaction.status === 0) {
                const transactionAmount = resTransaction.data

                const resWithdrawal = await paymentApi.doctorWithdrawalsByDoctor(doctorId)
                if (resWithdrawal.status === 0) {
                    const withdrawalAmount = resWithdrawal.data
                    const balance = transactionAmount - withdrawalAmount
                    dispatch(_doctorPaymentSettingSuccess(paymentSetting, balance))
                } else {
                    dispatch(_doctorPaymentSettingFailure("Failed to get withdrawal amount: " + resWithdrawal.message))
                }
            } else {
                dispatch(_doctorPaymentSettingFailure("Failed to get transaction amount: " + resTransaction.message))
            }
        } else {
            dispatch(_doctorPaymentSettingFailure(response.message))
        }
    }
}

export const DOCTOR_PAYMENT_SETTING_SAVE_REQUEST = 'DOCTOR_PAYMENT_SETTING_SAVE_REQUEST'
export const DOCTOR_PAYMENT_SETTING_SAVE_SUCCESS = 'DOCTOR_PAYMENT_SETTING_SAVE_SUCCESS'
export const DOCTOR_PAYMENT_SETTING_SAVE_FAILURE = 'DOCTOR_PAYMENT_SETTING_SAVE_FAILURE'

function _doctorPaymentSettingSaveRequest() {
    return {
        type: DOCTOR_PAYMENT_SETTING_SAVE_REQUEST,
        message: "Saving doctor payment setting..."
    }
}

function _doctorPaymentSettingSaveSuccess(paymentSetting) {
    return {
        type: DOCTOR_PAYMENT_SETTING_SUCCESS,
        paymentSetting
    }
}

function _doctorPaymentSettingSaveFailure(message) {
    return {
        type: DOCTOR_PAYMENT_SETTING_SAVE_FAILURE,
        message
    }
}

export function saveDoctorPaymentSettingAction(doctorPaymentMethodID, model) {
    return async dispatch => {
        dispatch(_doctorPaymentSettingSaveRequest())

        // Get payment settings
        var response
        if (doctorPaymentMethodID) {
            response = await paymentApi.updateDoctorPaymentSetting(doctorPaymentMethodID, model)
        } else {
            response = await paymentApi.createDoctorPaymentSetting(model)
        }

        if (response.status === 0) {
            const resPayment = await paymentApi.getDoctorPaymentSetting(model.doctor)

            if (resPayment.status === 0) {
                const paymentSetting = resPayment.data[0]
                dispatch(_doctorPaymentSettingSaveSuccess(paymentSetting))
            } else {
                dispatch(_doctorPaymentSettingSaveFailure(resPayment.message))
            }
        } else {
            dispatch(_doctorPaymentSettingSaveFailure(response.message))
        }
    }
}

export const DOCTOR_WITHDRAW_REQUEST = 'DOCTOR_WITHDRAW_REQUEST'
export const DOCTOR_WITHDRAW_SUCCESS = 'DOCTOR_WITHDRAW_SUCCESS'
export const DOCTOR_WITHDRAW_FAILURE = 'DOCTOR_WITHDRAW_FAILURE'

function _doctorWithdrawRequest() {
    return {
        type: DOCTOR_WITHDRAW_REQUEST,
        message: "Withdraw..."
    }
}

function _doctorWithdrawSuccess(balance) {
    return {
        type: DOCTOR_WITHDRAW_SUCCESS,
        balance
    }
}

function _doctorWithdrawFailure(message) {
    return {
        type: DOCTOR_WITHDRAW_FAILURE,
        message
    }
}

export function doctorWithdrawAction(doctorId, withdrawMoney, balance) {
    return async dispatch => {
        dispatch(_doctorWithdrawRequest())

        // Check doctor withdrawal unique
        const response = await paymentApi.doctorWithdrawCheckUnique(doctorId)
        if (response.status === 0) {
            const withdrawals = response.data
            let resUpdateWithdrawal

            if (withdrawals && withdrawals.length > 0) {
                const { amount, doctorWithdrawalID } = withdrawals[0]
                const totalAmount = withdrawMoney + amount

                resUpdateWithdrawal = await paymentApi.updateDoctorWithdrawals(doctorWithdrawalID, {
                    doctor: doctorId,
                    amount: totalAmount
                })
            } else {
                resUpdateWithdrawal = await paymentApi.createDoctorWithdrawals({
                    doctor: doctorId,
                    amount: withdrawMoney
                })
            }

            if (resUpdateWithdrawal.status === 0) {
                const newBalance = balance - withdrawMoney
                dispatch(_doctorWithdrawSuccess(newBalance))
            } else {
                dispatch(_doctorWithdrawFailure(resUpdateWithdrawal.message))
            }
        } else {
            dispatch(_doctorWithdrawFailure(response.message))
        }
    }
}
