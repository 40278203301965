import React from 'react';
import {
  Button, 
  Checkbox, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle,
  FormControlLabel
} from '@material-ui/core';

export default class PickerDialog extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state.value = props.value ? props.value.split(",") : [];
  }

  state = {};

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: (nextProps.value ? nextProps.value.split(",") : [])});
    }
  }

  handleCancel = () => {
      this.setState({ value: (this.props.value ? this.props.value.split(",") : [])});
      this.props.onClose(this.props.value)
  };

  handleOk = () => {
    this.props.onClose(this.state.value.join(","));
  };

  handleChange = (value) => {
        this.setState(prevState => {
            var newValue = [...prevState.value]
            let index = newValue.indexOf(value)
            if (index < 0) {
                newValue.push(value)
            } else {
                newValue.splice(index, 1)
            }
            return {
                value: newValue
            }
        })
  };

  render() {
    const { title, options, ...other } = this.props;
    const { value } = this.state;

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        onEntering={this.handleEntering}
        aria-labelledby="confirmation-dialog-title"
        {...other}
      >
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent>
            {options.map(option => (
                <div key={option}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={value.includes(option)}
                                onChange={() => this.handleChange(option)}
                            />
                        }
                        label={option}
                    />
                </div>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={this.handleOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
