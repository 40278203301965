import apiRequest, { apiRestRequest, sendGenRequest } from './apiRequest'
import { Api, UserType } from 'helper/const'

export function feedsByTime(rowIndex, rows) {
    return apiRequest('feedByTime', {rowIndex: rowIndex, rows})
}

export function feedSearch(rowIndex, rows, filter) {
    return apiRequest('feedGetAll', {rowIndex, rows, filter})
}

export function feedExtra(params) {
    return sendGenRequest(Api.feedApiBaseUrl + '/FeedExtras', 'post', params)
}

export function feedRepliesGetAllByFeedID(feedId) {
    return apiRequest('feedRepliesGetAllByFeedID', {feedId})
}

export function feedAgreeDoctor(feedId, feedDoctorId) {
    return apiRequest('feedAgreeDoctor', {feedId, feedDoctorId})
}

export function feedLikeGet(feedId, feedDoctorId) {
    return apiRequest('feedLikeGet', {
        feedId,
        feedDoctorId,
        feedLikeByPatientId: 0
    })
}

export function feedLikeGetTotal(feedDoctorId) {
    return apiRequest('feedLikeGetTotal', {feedDoctorId})
}

export function doctorKnowCheckUnique(doctorId, userType, knownByUserId) {
    let knownByPatientId = 0
    let knownByDoctorId = 0

    if (userType.toString() === UserType.patient.toString()) {
        knownByPatientId = knownByUserId
    } else {
        knownByDoctorId = knownByUserId
    }

    return apiRequest('doctorKonwCheckUnique', {doctorId, knownByPatientId, knownByDoctorId})
}

export function setDoctorKnow(doctorId, userType, userId) {
    const params = {
        doctorId,
        knownByPatientId: 0,
        knownByDoctorId: 0
    }

    if (userType === UserType.patient) {
        params.knownByPatientId = userId
    } else {
        params.knownByDoctorId = userId
    }

    return apiRestRequest('doctorKnowns', 'post', params)
}

/**
 * Check if current user likes this feed a not
 * 
 * @param {Feed id} feedId 
 * @param {The doctor id posted feed} feedDoctorId 
 * @param {The user id who is checking like or not this doctor} userId 
 * @param {The user type who is checking like or not this doctor} userType 
 * @param {Feed type 1 for question feed 2 for doctr feedd} feedType 
 */
export function getIsLiked(feedId, feedDoctorId, userId, userType, feedType) {
    const params = {
        feedId,
        feedDoctorId,
        likeByPatientId: 0,
        likeByDoctorId: 0,
        likeType: feedType
    }

    if (userType.toString() === UserType.patient.toString()) {
        params.likeByPatientId = userId
    } else {
        params.likeByDoctorId = userId
    }

    return apiRequest('feedLikeCheckUnique', params)
}


/**
 * Set current user likes this feed
 * 
 * @param {Feed id} feedId 
 * @param {The doctor id posted feed} feedDoctorId 
 * @param {The user id who would like this doctor} userId 
 * @param {The user type who would like this doctor} userType 
 * @param {Feed type 1 for question feed 2 for doctr feedd} feedType
 * @param {If like a not} isLike
 */
export function setIsLiked(feedId, feedDoctorId, userId, userType, feedType, isLike = true) {
    const params = {
        feedId,
        feedDoctorId,
        likeByPatientId: 0,
        likeByDoctorId: 0,
        likeType: feedType
    }

    if (userType.toString() === UserType.patient.toString()) {
        params.likeByPatientId = userId
    } else {
        params.likeByDoctorId = userId
    }

    return apiRestRequest('feedLikes', 'post', params)
}

/**
 * Check if a doctor agreed with feed or not
 * 
 * @param {Feed id} feedId 
 * @param {Doctor id posted this feed} feedDoctorId 
 * @param {Agreed doctor id} doctorId 
 * @param {feed type} feedType 
 */
export function getIsAgreed(feedId, feedDoctorId, doctorId, feedType) {
    return apiRequest('feedAgreeCheckUnique', {feedId, feedDoctorId, agreedDoctorId: doctorId, agreedType: feedType})
}

/**
 * Set a doctor agreed with feed or not
 * 
 * @param {Feed id} feedId 
 * @param {Doctor id posted this feed} feedDoctorId 
 * @param {Agreed doctor id} doctorId 
 * @param {feed type} feedType 
 * @param {True if agreed} isAgree
 */
export function setIsAgreed(feedId, feedDoctorId, doctorId, feedType, isAgree) {
    const params = {
        feedId,
        feedDoctorId,
        agreedDoctorId: doctorId,
        agreedType: feedType,
    }

    return apiRestRequest('feedAgreeds', 'post', params)
}