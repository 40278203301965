import React from 'react'
import { FormControl } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import STextInput from "./STextInput";

export default function SDatePicker(props) {
    const { caption, value, handleChange, format } = props
    const dateFormat = format ? format : "DD/MM/YYYY"
    return (
        <FormControl className={"control-container"}>
            <DatePicker
                id="datepicker-input"
                label={caption}
                format={dateFormat}
                value={value}
                onChange={handleChange}
                animateYearScrolling={false}
                TextFieldComponent={(props) => (
                    <STextInput
                        caption={caption}
                        onClick={props.onClick}
                        id={props.id}
                        value={props.value}
                        handleChange={handleChange}
                        disabled={props.disabled}
                        {...props.inputProps}
                    />
                )}
            />
        </FormControl>
    )
}