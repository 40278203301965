import React from 'react'
import { connect } from 'react-redux'
import { IconButton, Button } from "@material-ui/core"
import { ArrowBack } from "@material-ui/icons"
import { FeedType, UserType } from 'helper/const'

import * as questionsAction from 'redux/actions/questions'
import * as notificationApi from 'api/notification'


class PostQuestion extends React.Component {
    constructor(props) {
        super(props)

        if (!props.location.state) {
            const { userId, userType } = props

            if (userType !== UserType.patient) {
                props.history.push({
                    pathname: '/dashboard'
                })
            }

            this.state = {
                feedType: FeedType.patientPosted,
                patientId: userId,
                title: '',
                body: ''
            }
        } else {
            const { question, patientId } = props.location.state
            
            if (question) {
                this.state = {...question}
            } else {
                this.state = {
                    feedType: FeedType.patientPosted,
                    patientId,
                    title: '',
                    body: ''
                }
            }
        }
    }

    handleSave = () => {
        const { feedID, title } = this.state

        let pushMessage
        if (feedID) {
            this.props.dispatch(questionsAction.updateFeed(feedID, this.state))
            pushMessage = `Question '${title}' was updated`
        } else {
            this.props.dispatch(questionsAction.createFeed(this.state))
            pushMessage = `Question '${title}' was posted`
        }

        this.sendNotificationToUser(pushMessage)

        this.props.history.replace('/#/dashboard/feed')
    }

    async sendNotificationToUser(message) {
        console.log("Send notification to all Doctors:", message)

        const rstPushUsers = await notificationApi.getAllDoctors()
        if (rstPushUsers.status === 0) {
            let targetUsers = []
            for (let index = 0; index < rstPushUsers.data.length; index++) {
                const element = rstPushUsers.data[index];
                if (element.pushId) {
                    targetUsers.push(element.pushId)
                }
            }

            if (targetUsers.length > 0) {
                console.log("TargetUsers:", targetUsers)
                notificationApi.sendPush(targetUsers, message)
            }
        }
    }


    handleChange = (key, value) => {
        this.setState({[key] : value})
    }

    render() {
        const { title, body, feedID } = this.state
        const saveButtonCaption = feedID ? "Save" : "Post"
        const pageTitle = feedID ? "Update a question" : 'Post a question'

        return (
            <div className='post-question-container'>
                <div className="title-wrapper">
                    <IconButton
                        className="nav-btn"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => window.history.back()}>
                        <ArrowBack />
                    </IconButton>
                    <div className="title">{pageTitle}</div>
                </div>

                <input
                    type='text'
                    className='question-title'
                    value={title}
                    onChange={(e) => {this.handleChange('title', e.target.value)}}
                    placeholder="Title"
                />

                <textarea
                    className='body'
                    onChange={(e) => {this.handleChange('body', e.target.value)}}
                    value={body}
                    placeholder="Description"
                />

                <Button className='update-button full-width patient' onClick={this.handleSave.bind(this)}>{saveButtonCaption}</Button>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { auth } = state
    const { userProfile } = auth

    let userType = UserType.unknown
    let userId = 0

    if (userProfile) {
        userType = userProfile.userType
        if (userType === UserType.patient) {
            userId = userProfile.patientUserID
        }

        if (userType === UserType.doctor) {
            userId = userProfile.doctorUserID
        }
    }

    return { userType, userId }
}


export default connect(mapStateToProps)(PostQuestion)

