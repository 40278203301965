import React from 'react'
import { Button } from "@material-ui/core"
import { connect } from 'react-redux'
import { UserType } from 'helper/const'

function NavFilter(props) {
    const containerClass = "normal-button nav-filter-button " + (props.userType === UserType.patient ? "patient" : "doctor")

    return (
        <Button
            className={containerClass}
            href="/#/dashboard/services_filter"
            style={{
                marginRight: '10px'
            }} >Filter</Button>
    )
}

function mapStateToProps(state) {
    const { auth } = state

    let userProfile = auth.userProfile
    let userType = UserType.unknown

    if (userProfile) {
        userType = userProfile.userType
    }

    return { userType }
}

export default connect(mapStateToProps)(NavFilter)

