import { combineReducers } from 'redux'
import { auth } from './auth'
import { feeds } from './feeds'
import { favorites } from './favorites'
import { services } from './services'
import { note } from './note'
import { notifications } from './notifications'
import { bookings } from './bookings'
import { questions } from './questions'
import { payment } from './payment'
import { card } from './card'
import { schedule } from './schedule'
import { eradiology } from './eradiologies'
import { elaboratory } from './elaboratories'
import { eprescription } from './eprescription'

const appReducer = combineReducers({
    auth,
    feeds,
    favorites,
    services,
    note,
    notifications,
    bookings,
    questions,
    payment,
    card,
    schedule,
    eradiology,
    elaboratory,
    eprescription
})

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        const pushId = state.auth.pushId

        state = {
            auth: {
                pushId
            }
        }
    }

    if (action.type === 'HIDE-TOAST') {
        const newModel = {
            ...state[action.model],
            toastMessage: null,
            toastVariant: 'info'
        }

        state = {
            ...state,
            [action.model]: newModel
        }
    }

    return appReducer(state, action)
}

export default rootReducer
