import {
  QUESTIONS_REQUEST,
  QUESTIONS_SUCCESS,
  QUESTIONS_FAILURE,

  CREATE_FEED_REQUEST,
  CREATE_FEED_SUCCESS,
  CREATE_FEED_FAILURE,

  UPDATE_FEED_REQUEST,
  UPDATE_FEED_SUCCESS,
  UPDATE_FEED_FAILURE
} from 'redux/actions/questions'

export function questions (
  state = {
    questionsStatus: 0, // 0 - success, 1 - processing, 2 - error
    message: null,
    data: []
  },
  action
) {
  switch (action.type) {
    case QUESTIONS_REQUEST:
      return Object.assign({}, state, {
        questionsStatus: 1
      })
    case QUESTIONS_FAILURE:
      return Object.assign({}, state, {
        questionsStatus: 2,
        message: action.message
      })
    case QUESTIONS_SUCCESS:
      return Object.assign({}, state, {
        questionsStatus: 1,
        data: action.questions
      })

    case CREATE_FEED_REQUEST:
      return {
        ...state,
        questionsStatus: 1
      }
    case CREATE_FEED_FAILURE:
      return {
        ...state,
        questionsStatus: 2,
        message: action.message
      }
    case CREATE_FEED_SUCCESS:
      // var newData = [action.feed, ...state.data]
      return {
        ...state,
        questionsStatus: 0
        // data: newData
      }

    case UPDATE_FEED_REQUEST:
      return {
        ...state,
        questionsStatus: 1
      }
    case UPDATE_FEED_FAILURE:
      return {
        ...state,
        questionsStatus: 2,
        message: action.message
      }
    case UPDATE_FEED_SUCCESS:
      const newData = [...state.data]
      
      var toUpdate = newData.find(x => x.feedID === action.feed.feedID)
      var key;
      for (key in action.feed) {
        if (action.feed.hasOwnProperty(key)) {
          const element = action.feed[key];
          toUpdate[key] = element
        }
      }
      return {
        ...state,
        questionsStatus: 0,
        data: newData
      }
    default:
      return state
  }
}